@use "../../../utils" as *;
@use "../../../components" as *;
@use "./helpers" as *;

@mixin createPassBase {
	#CreatePass {
		overflow-x: hidden;
		margin: 0;
		display: flex;
		flex-direction: column;
		width: 100%;

		&.centerChilds {
			flex: 2;
			justify-content: space-around;
		}

		@include Button;
		@include BadFavIcon;

		&.hasInitialMappingButtonsStyle {
			@include ComponentMapping();
		}

		&:not(.hasInitialMappingButtonsStyle) {
			@include ComponentMapping(true);
		}

		form {
			flex-direction: column;
			justify-content: space-between;

			/* Adaptation of repsFrame comp */
			@include repsFrame;

			.repsFrame {
				label {
					display: flex;
				}
			}

			&.mapForm {
				display: flex;
				flex-direction: column;
				max-height: 100%;
				overflow-y: auto;
				min-height: 30vh;

				.withPadding {
					overflow-y: auto;
					margin-bottom: 5rem;
					padding-right: 0.5rem;

					h5 {
						margin: 0.5rem 0 0.7rem;
						color: $extTextColor1;
					}
				}

				.buttons {
					display: grid;
					grid-template-columns: repeat(2, auto);
					column-gap: convertToREM(12);
					justify-content: start;
				}

				.spinner {
					flex: 2;
					align-self: center;
				}

				@include TogglerFrame {
					margin-top: convertToREM(12);
				}
			}

			/* End of repsFrame adaptation */

			.formContainer {
				margin: 0;
				width: 100%;
				flex-grow: 1;
				display: flex;
				flex-direction: column;

				.optionalForm {
					display: flex;
					flex-direction: column;
				}

				.inputAndLabel {
					width: 100%;
					margin: 0.5rem 0;

					@include fileLine(false) {
						margin-bottom: 0;
						background: $extBgColor3;
						color: $extTextColor1;
						justify-content: space-between;

						.fileLine-action {
							position: static;
							right: unset;
						}

						.fileLine-content {
							display: grid;
							grid-template-columns: auto minmax(0, 1fr);
							align-items: center;

							.fileLine-content-title {
								@include ellipsis(2);
								font-size: clamp(0.6rem, 0.9vw, 0.8rem);
							}
						}
					}

					label {
						margin: 0.25rem 0;
						font-weight: 600;
						display: flex;
						gap: 0.5em;
					}

					input,
					.inputContainer,
					select {
						background-color: $extBgColor3;
						border: $extBgColor3;
						height: 1.8rem;
						padding: 0.2rem 0.3rem;
						box-sizing: border-box;
						font-size: 0.6rem;
						font-weight: 500;
						border-radius: 3px;
					}

					input::placeholder {
						font-weight: 500;
						color: $extTextColor1;
					}

					> input {
						padding: 0.2rem 0.7rem;

						&::placeholder {
							opacity: 60%;
						}
					}

					.inputContainer {
						display: flex;
						align-items: center;

						input {
							flex-grow: 1;
							font-size: 0.6rem;
						}

						button {
							height: 100%;
							width: 1.5rem;
							background: none;
							border: none;
							margin: 0 0.25rem;
							padding: 0.25rem;
							display: flex;
							justify-content: center;
							align-items: center;

							svg {
								height: 100%;
							}
						}

						.withButtonsInput {
							position: relative;
							display: flex;
							justify-content: space-between;
							height: 100%;
							flex: 2;

							input {
								height: 100%;

								&::placeholder {
									opacity: 60%;
								}
							}

							.inputGradient {
								visibility: hidden;
								position: absolute;
								right: 0px;
								top: 0px;
								width: 1.4rem;
								height: 100%;
								background: linear-gradient(
									90deg,
									#f6f7fe7e,
									$extBgColor3 100%
								);
								pointer-events: none;
							}
						}

						.buttonsContainer {
							padding: 0;
							height: 100%;
							display: flex;
							justify-content: center;
							align-items: center;

							button {
								svg {
									path,
									circle,
									g,
									rect {
										fill: $extTextColor2;
									}
								}
							}
						}
					}

					&.withLMargin {
						margin: 2rem 0 2.5rem;
					}
				}

				.inputAndLabel:first-child {
					.imageContainer {
						height: 100%;
						width: 2.2rem;
						background-color: $extBgColor1;
						display: flex;
						justify-content: center;
						align-items: center;
					}

					svg {
						height: 95%;
						object-fit: cover;
						object-position: left;
						max-width: 2rem;
					}

					input {
						padding-left: 0.8rem;
					}
				}

				.resetVals {
					display: flex;
					justify-content: flex-end;

					.resetVals-action {
						display: inline-flex;
						cursor: pointer;
						align-items: center;

						span {
							font-weight: 600;
							font-size: 0.65rem;
							color: $extTextColor2;
						}

						svg {
							margin-right: 0.3rem;
							height: auto;
							width: 0.6rem;
							min-width: 0.6rem;

							path {
								fill: $extTextColor2;
							}
						}
					}
				}
			}

			.errorContainer {
				display: flex;
				align-items: center;
				padding: 0.5rem 0;

				svg {
					min-width: 0.9rem;
					width: 0.9rem;
					margin-right: 0.4rem;
				}

				b {
					font-size: 0.65rem;
					color: $red;
				}

				p {
					font-size: 0.6rem;
				}
			}

			input[type="password"] {
				font-size: 0.9rem;
			}

			input[type="password"]::placeholder {
				font-size: 0.6rem;
			}

			/* ================== FORM ADD PASSWORD RULES ================== */
			/* =========================== FORM ============================ */
			/* ========================== SELECT =========================== */
			.selectContainer {
				display: flex;
				height: 1.8rem;
				background-color: $extBgColor3;

				select {
					padding: 0;
					width: calc(100% - 2rem);
					font-weight: 600;
					-webkit-appearance: none;
					-moz-appearance: none;
					appearance: none;
					background: transparent;
					background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
					background-repeat: no-repeat;
					background-position-x: 101%;
					background-position-y: 5px;

					> * {
						width: 100%;
					}

					&:focus {
						padding: 0;
						outline: none;
					}
				}

				.imageContainer {
					height: 100%;
					display: flex;
					align-items: center;
					flex-shrink: 0;
					margin-left: 0.4rem;

					svg {
						height: 0.6rem;
					}
				}
			}

			#policyRules {
				color: $extTextColor1;
				font-size: 0.5rem;
				font-weight: 600;
				margin-top: 0.3rem;
			}

			/* ================== FORM ADD PASSWORD RULES ================== */
			/* ======================= SUBMIT PART ========================= */
			.showDetailsContainer {
				width: 100%;
				margin: 0.3rem auto 0.3rem;
				display: flex;
				align-items: center;

				.showDetailsContainer-action {
					position: relative;
					border: none;
					font-size: 0.6rem;
					font-weight: 600;
					padding: 0.7rem 0;
					border-radius: 3px;
					color: $extTextColor2;

					&:hover {
						cursor: pointer;
					}
				}
			}

			button[type="submit"] {
				padding: 0.7rem;
				width: 100%;
				font-size: 0.7rem;
			}

			.createPassBut {
				button[type="submit"] {
					padding: 0.7rem;
					width: 100%;
					font-size: 0.7rem;
					background: $extAccentColor1;
					color: $white;

					svg {
						* {
							fill: $white;
						}
					}

					&.grey {
						color: $extTextColor1;
						background: $extBgColor2;
						cursor: default;

						svg {
							* {
								fill: $extTextColor1;
							}
						}
					}
				}
			}
		}
	}
}
