@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;
@use "../../components/" as *;

@mixin thinPopIPassPh {
	.iPassPh {
		margin-bottom: 20px;

		@include mediaLarge {
			margin-bottom: 1vh;
		}

		.message {
			font-size: 14px;
			color: $black;

			@include mediaLarge {
				font-size: clamp(0.6rem, 1vw, 1rem);
			}

			.darkMode & {
				color: $lGrey;
			}
		}

		.details {
			font-family: $mon;
			background-color: $lGrey;
			padding: 14px 15px;
			color: $black;
			font-size: 11px;
			font-weight: 500;
			border-radius: 5px;
			word-break: break-all;
			line-height: 18px;

			@include mediaLarge {
				padding: 1.2vh 1vw;
				font-size: clamp(0.6rem, 0.95vw, 1rem);
			}

			.darkMode & {
				background-color: $dm-b2;
				color: $ciment;
			}
		}

		.iPassPHTitleAction {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.keyTitle {
				font-size: 14px;
				color: $black;

				@include mediaLarge {
					font-size: clamp(0.6rem, 1vw, 1rem);
				}

				.darkMode & {
					color: $lGrey;
				}
			}

			.deleteBtn {
				font-size: 14px;
				color: $orange;
				cursor: pointer;

				@include mediaLarge {
					font-size: clamp(0.6rem, 0.95vw, 1rem);
				}
			}
		}

		.errorTitle {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin-bottom: 30px;

			@include mediaLarge {
				margin-bottom: 2vh;
			}

			.title {
				display: block;
				background-color: $bg-lGrey;
				color: $black;
				padding: 5px 12px;
				border-radius: 20px;
				margin-bottom: 12px;

				@include mediaLarge {
					padding: 0.7vh 0.7vw;
					border-radius: 1vw;
					margin-bottom: 1vh;
				}

				.darkMode & {
					background-color: $dm-b4;
					color: $lGrey;
				}
			}

			.subtitle {
				color: $black;
				font-size: 14px;
				font-weight: 500;

				@include mediaLarge {
					font-size: clamp(0.6rem, 0.95vw, 1rem);
				}

				.darkMode & {
					color: $ciment;
				}

				svg path {
					.darkMode & {
						fill: $ciment;
					}
				}
			}
		}
	}
}
