@use "../utils/variables" as *;
@use "../utils/mixins" as *;

@mixin timer {
	.timer {
		font-family: $mon;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background-color: $lGrey;
		color: $black;
		font-weight: bold;
		display: flex;
		align-items: center;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		box-shadow: $sh3;
		padding: 10px 13px;
		box-sizing: border-box;

		.darkMode & {
			background-color: $dm-b4;
			color: $ciment;
		}

		b {
			margin: 0 25px 0 5px;
		}

		span {
			font-size: 14px;
		}
	}

	@include mediaLarge {
		.timer {
			padding: 1.5vh 1vw;
			margin-bottom: 1vw;

			b {
				margin: 0 2vw 0 0.3vw;
			}

			span {
				font-size: clamp(0.6rem, 0.95vw, 0.9rem);
			}
		}
	}
}
