@use "../../utils/variables" as *;

@mixin wmBranded {
	.branded {
		margin-left: 0.4rem;
		background: $black;
		inline-size: fit-content;
		padding: 0.5rem 1.5rem 0.5rem 1.5rem;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		color: $lGrey;
		box-shadow: 0px 0px 14px -5px #43477b2c;

		span {
			font-size: 0.8rem;
		}
	}
}
