@use "../../utils/variables" as *;

@mixin popupMain {
	.popupMain {
		flex: 2;
		display: flex;
		flex-direction: column;
		padding-right: calc($marginPopup - $scroolBarSizePopup);
		padding-left: $marginPopup;
		margin-top: $extMainMT;
		overflow-y: scroll;

		&.noTop {
			margin-top: unset;
		}
	}
}
