@use "../../utils/variables" as *;

@mixin searchContainerPopup {
	.searchContainer {
		width: 100%;
		display: flex;
		align-items: center;
		background-color: $extBgColor3;
		border: $extBgColor3;
		padding: 0.25rem;
		box-sizing: border-box;
		font-weight: 500;
		border-radius: 3px;
		margin-bottom: 1rem;

		> svg {
			width: 0.1rem;
			min-width: 0.8rem;
			height: auto;

			path {
				fill: $extTextColor2;
			}
		}

		input {
			background: none;
			border: none;
			padding: 0.2rem 0.4rem;
			font-size: 0.75rem;
			flex: 2;
			font-weight: 500;

			&:focus {
				outline: none;
			}

			&::placeholder {
				font-weight: 500;
				color: $anthra;
				font-size: 0.8rem !important;
			}
		}
	}
}
