@use "../utils/variables" as *;

/* -------------------------------------------------------------------------- */
/*                                 FONT-FACES                                 */
/* -------------------------------------------------------------------------- */

@font-face {
	font-family: "Kodchasan";
	font-style: normal;
	font-weight: 500;
	src: url("../../assets/fonts/Kodchasan/Kodchasan-Medium.ttf")
		format("truetype");
}
@font-face {
	font-family: "Kodchasan";
	font-style: normal;
	font-weight: 600;
	src: url("../../assets/fonts/Kodchasan/Kodchasan-SemiBold.ttf")
		format("truetype");
} //local('Kodchasan'),

@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: url("../../assets/fonts/Roboto Mono/RobotoMono-Medium.ttf")
		format("truetype");
}

@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	src: url("../../assets/fonts/Montserrat/Montserrat-Regular.ttf")
		format("truetype");
}
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	src: url("../../assets/fonts/Montserrat/Montserrat-Medium.ttf")
		format("truetype");
}
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600 700;
	src: url("../../assets/fonts/Montserrat/Montserrat-SemiBold.ttf")
		format("truetype");
}
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 800;
	src: url("../../assets/fonts/Montserrat/Montserrat-Bold.ttf")
		format("truetype");
}

@font-face {
	font-family: Telegraf;
	font-style: normal;
	font-weight: 400;
	src: url("../../assets/fonts/Telegraf/Telegraf-Regular.woff2")
		format("woff2");
}

@font-face {
	font-family: Telegraf;
	font-style: normal;
	font-weight: 500;
	src: url("../../assets/fonts/Telegraf/Telegraf-Medium.ttf") format("woff2");
}

@font-face {
	font-family: Telegraf;
	font-style: normal;
	font-weight: 600;
	src: url("../../assets/fonts/Telegraf/Telegraf-SemiBold.woff2")
		format("woff2");
}

@font-face {
	font-family: Telegraf;
	font-style: normal;
	font-weight: 700;
	src: url("../../assets/fonts/Telegraf/Telegraf-Bold.woff2") format("woff2");
}

@font-face {
	font-family: Telegraf;
	font-style: normal;
	font-weight: 800;
	src: url("../../assets/fonts/Telegraf/Telegraf-UltraBold.woff2")
		format("woff2");
}

// @font-face {
// 	font-family: Telegraf;
// 	font-style: normal;
// 	font-weight: 900;
// 	src: url("../../assets/fonts/Telegraf/Telegraf-UltraBold.woff2")
// 		format("woff2");
// }

/* -------------------------------------------------------------------------- */
/*                                    STYLE                                   */
/* -------------------------------------------------------------------------- */

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transition: 0.3s ease-in-out;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

b,
strong {
	font-weight: 600;
}

/* -------------------------------- SCROLLBAR ------------------------------- */

/* width */
::-webkit-scrollbar {
	width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
	background: $lGrey;
	border-radius: 5px;

	.darkMode & {
		background: $dm-b4;
	}
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $ciment;
	border-radius: 5px;

	.darkMode & {
		background: $dm-b5;
	}
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: $black;

	.darkMode & {
		background: $anthra;
	}
}

input {
	::-webkit-scrollbar-thumb {
		background: $ciment;
	}

	::-webkit-scrollbar-thumb:hover {
		background: $bg-dBlue;
	}

	::-webkit-scrollbar-track {
		background: $bg-lGrey;
	}
}
