@use "../utils/variables" as *;
@use "./tooltipHelper" as *;

@mixin slider($type: "") {
	.slider {
		position: relative;
		display: grid;
		grid-template-columns: 0.1fr 7fr 0.1fr;
		grid-template-rows: 0.3fr 1fr;
		grid-column-gap: 0px;
		grid-row-gap: 0px;

		> .title {
			grid-area: 1 / 1 / 2 / 4;
			display: flex;
			align-items: baseline;

			.leftTitle {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 0.5rem;

				.leftTitle-container {
					display: flex;

					span {
						height: 0.5rem;
						width: 0.5rem;
						background-color: $ciment;
						margin-right: 0.2rem;
						margin-top: 0.1rem;
						border-radius: 50%;

						&.active {
							background-color: $orange;
						}
					}
				}
			}
		}

		.hidden {
			visibility: hidden;
		}

		.container {
			display: flex;
			overflow: auto;
			flex: none;

			&.x {
				width: 100%;
				height: 6rem;
				flex-flow: row nowrap;

				&.mandatory-scroll-snapping {
					overflow: hidden;
					width: 100%;
				}

				.sliderItem-wrapper {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-around;
				}

				.sliderItem {
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					background-color: $lGrey;
					width: 19%;
					border-radius: 3px;

					.darkMode & {
						background-color: $dm-b2;
					}

					&.selected {
						background-color: $orange;
						color: $white;

						svg * {
							fill: $white;
						}
					}

					svg {
						height: auto;
						min-width: 1.3rem;
						width: 1.3rem;

						#userFolder * {
							.darkMode & {
								fill: $blue;
							}
						}

						#user_secondTone * {
							.darkMode & {
								fill: $grey;
							}
						}

						g[data-name~="secondTone"] {
							path {
								.darkMode & {
									fill: $blue;
								}
							}
						}

						g[data-name~="people"] {
							path {
								.darkMode & {
									fill: $ciment;
								}
							}
						}
					}

					@include tooltipHelper(
						$place: "lockpass",
						$listMode: false
					);

					.sliderItem-name {
						text-align: left;
						font-weight: 500;
						font-size: 0.7rem;
					}

					@if $type == "thinPop" {
						svg {
							margin: 0;
						}

						.sliderItem-name {
							margin: 0.1rem 0;
						}
					} @else {
						svg {
							margin: 0.7rem 0.6rem 0.5rem 0.6rem;
						}

						.sliderItem-name {
							margin: 0 0.6rem 0.6rem 0.6rem;
						}
					}
				}
			}

			.sliderItem-wrapper {
				text-align: center;
				scroll-snap-align: center;
				flex: none;

				&.hidden {
					visibility: hidden;
				}
			}
		}

		.sliderBtn {
			background-color: $lGrey;
			padding: 0 0.4rem;
			border-radius: 3px;
			display: flex;
			align-items: center;
			margin: 0.65rem 0;

			.darkMode & {
				background-color: $dm-b3;
			}

			> svg {
				* {
					fill: $grey;
					stroke-width: 1;

					.darkMode & {
						fill: $ciment;
					}
				}

				> g > * {
					fill: $grey;
				}
			}

			&.btnRight {
				margin-left: 0.3rem;
				left: unset;
				right: 0.1rem;
			}

			&.btnLeft {
				margin-right: 0.3rem;
			}

			&:hover {
				cursor: pointer;
			}
		}

		@content;
	}
}
