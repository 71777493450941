@use "../utils/variables" as *;
@use "../utils/mixins" as *;

@mixin select {
	.sortBy {
		display: flex;
		flex-grow: 1;
		justify-content: flex-end;
		flex-direction: row;
		align-items: center;

		.sortBy-orderTitle {
			font-size: 0.7rem;
			margin-right: 0.5rem;
			color: $anthra;

			.darkMode & {
				color: $ciment;
			}
		}

		.formGroup {
			display: flex;
			flex-grow: 1;
			align-items: center;
			border-width: 1px 0 1px 1px;
			box-sizing: border-box;

			.dropContainer {
				position: relative;
				background: $lGrey; /* Old browsers */
				color: $ciment;
				user-select: none;
				min-width: fit-content;
				border-radius: 7px;
				display: flex;
				flex-direction: row;
				align-items: center;
				padding-left: 0.2rem;

				.darkMode & {
					background: rgb(134, 90, 232); /* Old browsers */
					background: -moz-linear-gradient(
						45deg,
						rgba(134, 90, 232, 1) 0%,
						rgba(42, 162, 228, 1) 100%
					); /* FF3.6-15 */
					background: -webkit-linear-gradient(
						45deg,
						rgba(134, 90, 232, 1) 0%,
						rgba(42, 162, 228, 1) 100%
					); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(
						45deg,
						rgba(134, 90, 232, 1) 0%,
						rgba(42, 162, 228, 1) 100%
					); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					color: $ciment;
				}

				.dropItems {
					position: absolute;
					background-color: $white;
					border-radius: 7px;
					color: $ciment;
					right: 0;
					top: calc(100% + 0.3rem);
					box-sizing: border-box;
					display: none;
					z-index: 12;
					box-shadow: 0 0 25px $sh4;
					max-height: 20rem;
					overflow-y: scroll;

					svg {
						height: auto !important;
						margin-left: 0.5rem;
					}

					> svg {
						path {
							fill: $orange !important;

							.darkMode & {
								fill: $ciment !important;
							}
						}
					}

					span {
						padding: 0.35rem 0.65rem;
						text-align: center;
						display: block;
						cursor: pointer;
						font-size: 0.7rem;
						color: $bg-dBlue;

						.darkMode & {
							color: $ciment;
						}
					}

					.darkMode & {
						background-color: $dm-b3;
						box-shadow: 0 0 25px rgb(0 0 0 / 50%);
					}

					.dropItems-container,
					.blockSortIntern {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						padding: 0.3rem 0.5rem;
						cursor: pointer;
					}

					.blockSortIntern {
						width: 100%;
						border-radius: 7px;
						padding: 0.4rem 0.3rem 0.4rem 0.75rem;

						&.active {
							background-color: $orange;

							span {
								color: $white;
								flex: none;

								svg path {
									fill: $white;

									.darkMode & {
										fill: $ciment;
									}
								}
							}
						}

						&:not(.active) {
							svg.arrowInvisible {
								path:first-of-type {
									opacity: 0;
								}
							}
						}

						&:not(.active):hover {
							span {
								transition: color $t1;
								color: $orange;
								flex: unset;
							}

							svg.arrowInvisible path {
								transition: opacity $t1;
								opacity: 1;
								fill: $ciment;

								.darkMode & {
									fill: $dm-b5;
								}
							}
						}

						span {
							.darkMode & {
								color: $ciment;
							}
						}
					}

					span {
						display: block;
						padding: 0;
						font-size: 0.85rem;
					}
				}

				.dropContainerTitle {
					padding: 0.35rem 0.65rem;
					text-align: center;
					display: block;
					cursor: pointer;
					font-size: 0.7rem;
					color: $bg-dBlue;

					.darkMode & {
						color: $ciment;
					}
				}

				&.displayItems .dropItems {
					display: block;
				}
			}
		}
	}

	// Selector
	.selectFrame {
		font-family: $mon;
		display: flex;
		flex-direction: column;

		.selectFrame-header {
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			gap: 0.5em;

			@include mediaLarge {
				margin-bottom: 1vh;
			}

			label {
				font-size: 15px;
				font-weight: 600;
				color: $black;

				@include mediaLarge {
					font-size: clamp(0.6rem, 1vw, 1rem);
				}

				.darkMode & {
					font-size: 15px;
					flex: 2;
					color: $ciment;
				}
			}
		}

		select {
			font-family: $mon;
			border: 1.5px solid $ciment;
			background: none;
			padding: 10px 35px 10px 22px;
			color: $black;
			font-size: 15px;
			font-weight: 600;
			border-radius: 5px;
			height: 47px;
			box-sizing: border-box;
			-moz-appearance: none; /* Firefox */
			-webkit-appearance: none; /* Safari and Chrome */
			appearance: none;
			background-size: 10px;
			background-repeat: no-repeat;
			background-image: url("../../assets/images/selectArrow.svg");
			background-position: center right 10px;

			@include mediaLarge {
				padding: 1vh 1.5vw 1vh 1vw;
				font-size: clamp(0.6rem, 1vw, 1rem);
				height: 6vh;
				background-size: 0.5;
				background-position: center right 0.8vw;
			}

			.darkMode & {
				border: 1.5px solid $grey;
				color: $ciment;
				background-image: url("../../assets/images/selectArrowDM.svg");
			}

			&:focus {
				outline: none;
			}
		}

		option {
			.darkMode & {
				color: $ciment;
				background-color: $dm-b3;
			}
		}
	}

	/******************drop-down-PP************************/

	.dropdown-container {
		font-family: $mon;
		border: 1.5px solid $ciment;
		background: none;
		padding: 10px 20px 10px 12px;
		color: $black;
		font-size: 15px;
		font-weight: 600;
		border-radius: 5px;
		height: 47px;
		box-sizing: border-box;

		@include mediaLarge {
			padding: 1vh 0.5vw 1vh 0.5vw;
			font-size: clamp(0.6rem, 1vw, 1rem);
			height: 6vh;
		}

		.darkMode & {
			border: 1.5px solid $grey;
			color: $ciment;
		}

		.dropdown-input {
			cursor: pointer;
			width: 100%;

			.dropdown-selected-value {
				display: flex;
				align-items: center;
				padding: 5px;
				user-select: none;
				justify-content: space-between;
				overflow: hidden;

				p {
					width: 95%;
					margin-top: -0.07rem;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					line-clamp: 2;

					&.textGray {
						color: $greyShades-matSilver;

						.darkMode & {
							color: $anthra;
						}
					}
				}

				svg {
					margin-top: -0.7rem;

					.darkMode & {
						path {
							fill: $ciment;
						}
					}
				}
			}
		}

		.dropdown-menu {
			position: fixed;
			transform: translate(-13px, -6px);
			background-color: $bg-white;
			width: 40.3%;
			border-top: 3px solid $ciment;
			border-bottom: 3px solid $ciment;
			border-right: 1px solid $ciment;
			border-left: 1px solid $ciment;
			border-radius: 3px;
			z-index: 999;

			@include mediaLarge {
				transform: translate(-0.5vw, -0.8vh);
				width: 41.4%;
			}

			.darkMode & {
				color: $ciment;
				background-color: $dm-b3;
			}

			.renderItems {
				height: 25px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 8px;

				.policyName {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					line-clamp: 2;
					padding-right: 1rem;
				}

				.renderForceItem {
					display: flex;
					align-items: center;
					justify-content: center;

					b {
						width: 1.8rem;
						height: 5px;
						border-radius: 5px;
						transform: translateY(1px);

						&.low {
							background-color: $yellow;

							.darkMode & {
								box-shadow: 0px 1px 10px rgba(252, 205, 48, 0.5);
							}
						}

						&.medium {
							background-color: $blueShade;

							.darkMode & {
								background-color: $lBlue;
								box-shadow: 0px 1px 13px rgba(81, 159, 222, 0.5);
							}
						}

						&.high {
							background-color: $green;

							.darkMode & {
								box-shadow: 0px 1px 10px rgba(12, 243, 128, 0.5);
							}
						}
					}

					p {
						font-size: 12px;
						margin-right: 5px;
					}
				}
			}
		}

		.dropdown-item {
			padding: 5px;
			cursor: pointer;
		}

		.dropdown-item:hover {
			background-color: #9fc3f870;
		}

		.dropdown-item.selected {
			background-color: #9fc3f870;
		}
	}

	@content;
}
