@use "../utils/variables" as *;

@mixin tags {
	.tags {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-top: 0.4rem;

		.tag {
			padding: 0.25rem 1.2rem;
			background-color: $lGrey;
			font-size: 0.75rem;
			font-weight: 500;
			border-radius: 3px;
			margin-bottom: 0.2rem;
			margin-right: 0.3rem;
			max-width: 10rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			.darkMode & {
				background-color: $dm-b4;
				color: $lGrey;
			}
		}

		.passTag {
			font-weight: 500;
			font-size: 0.75rem;
			border-radius: 15px;
		}
	}
}
