@use "../utils/variables" as *;

.expiration-date-container {
	position: relative;
	background-color: $greyShades-snow;
	padding: 10px 13px;
	border-radius: 5px;
	z-index: 0;
	overflow: hidden;

	.darkMode & {
		background-color: $dm-b3;

		.content-title {
			color: $white;
		}

		.date-expiration {
			color: $white;
		}
	}

	.hour-icon {
		position: absolute;
		bottom: 0rem;
		right: 0rem;
		width: 3rem;

		.darkMode & {
			> path {
				fill: $ciment;
			}
		}
	}

	.content-title {
		margin: 0 0 20px;
		display: flex;
		gap: 5px;
		font-size: 11px;

		.icon {
			width: 13px;
			height: auto;

			g {
				opacity: 1;
			}

			path {
				fill: $new-lp;
			}
		}
	}

	.bold {
		font-weight: 600;
	}

	.days-expiration {
		font-size: 21px;
		margin: 0;
	}

	.date-expiration {
		margin: 0;
		font-size: 0.6rem;
		color: $greyShades-grey;
	}
}
