@use "../../utils/variables" as *;

@mixin wmCadre {
	.cadre {
		display: inline-block;
		background-color: $bg-main;
		border-radius: 7px;
		box-shadow: 0px 0px 14px -5px #43477b2c;
		backdrop-filter: blur(4px);

		.menu {
			display: flex;
			background-color: $bg-white;
			padding: 1rem 0.5rem 0 2rem;
			border-top-left-radius: 7px;
			border-top-right-radius: 7px;
			color: $black;
			border: 2px $lGrey solid;

			.externe {
				margin: 0 4rem;
			}

			button {
				display: flex;
				font-size: 1.17em;
				font-weight: bold;
				border: none;
				background: none;
				cursor: pointer;
				color: $anthra;
				padding-bottom: 0.5rem;
			}

			.interne,
			.externe,
			.email {
				display: flex;
				flex-direction: column;
				align-content: center;
				justify-content: center;
				align-items: center;

				span {
					height: 3px;
					background-color: transparent;
					border-radius: 9px 9px 0 0;
					box-sizing: border-box;
					display: flex;
					align-content: flex-end;
					align-items: flex-end;
					width: 100%;
				}
			}

			.btnAction {
				display: flex;
				flex-grow: 1;
				justify-content: flex-end;
				align-items: center;
				padding-bottom: 0.5rem;

				button {
					display: flex;
					border: none;
					background: none;
					margin-left: 0.3rem;
					padding-bottom: 0;
					cursor: default;

					svg {
						max-height: 1.3rem;
						max-width: 1.3rem;
						path,
						rect {
							fill: $lGrey;
						}
					}

					&.active svg {
						rect,
						path {
							fill: $actionBlue;
						}
					}

					&:first-child {
						margin-left: 10rem;
						padding-bottom: 0;
					}

					&:last-child {
						svg path {
							fill: $orange;
						}
					}
				}
			}

			.active {
				button {
					color: $black;
					cursor: pointer;
				}
				span {
					background-color: $orange;
				}
			}
		}

		.twochoice {
			display: flex;
			border-bottom: 2px $lGrey solid;
			border-left: 2px $lGrey solid;
			border-right: 2px $lGrey solid;
			border-bottom-left-radius: 7px;
			border-bottom-right-radius: 7px;

			&:not(.interne) {
				.twochoice-container {
					display: grid;
					grid-template-columns: 1fr;
					align-content: start;
					justify-items: start;

					.colorChoice {
						--colorChoice-col-count: 1;
					}
				}
			}

			.twochoice-container {
				.colorChoice {
					background-color: $bg-white;
					margin: 1rem 1rem 0 2rem;
					padding: 1.5rem 1.5rem 0 1.5rem;
					border-radius: 7px;
					display: grid;
					grid-template-columns: repeat(
						var(--colorChoice-col-count, 2),
						1fr
					);
					column-gap: 4rem;
					height: fit-content;

					&.e .lineOne {
						margin-top: 1rem;
					}

					.hExterne {
						color: $black;
						font-size: medium;
						margin: -1.5rem -1.5rem 0 -1.5rem;
						padding: 0.8rem;
						background: $bg-lGrey;
						border-top-left-radius: 7px;
						border-top-right-radius: 7px;
					}

					.lineOne,
					.lineTwo {
						display: inline-block;

						.principale,
						.actions,
						.fonds,
						.pique {
							h3 {
								margin: 0;
								font-size: smaller;
								color: $black;
							}

							.choice {
								display: flex;
								align-items: center;
								margin-top: 0rem;
								margin-bottom: 1.2rem;

								#colorpicker {
									margin-right: -2.7rem;
									z-index: 1;
									border: none;
									background: none;
									height: 50px;
									cursor: pointer;
								}

								span {
									border-radius: 1.5rem 0 0 1.5rem;
									padding: 0.65rem 1rem 0.65rem 3rem;
									background-color: #f6f7fe;
									color: $black;
									font-weight: 500;
									font-size: smaller;
									width: 3rem;
									min-height: 1rem;

									input {
										border: none;
										width: 3.2rem;
										background-color: transparent;
										font-size: 0.8rem;
										display: flex;
										margin-left: -0.05rem;

										&:focus {
											outline: none;
											border: 1px $bg-dBlue solid;
										}
									}
								}
							}
						}

						input[type="color"]::-webkit-color-swatch {
							border-radius: 50%;
							border: none;
						}
					}
				}

				.reinit {
					margin: 0.5rem 1rem 1rem 2rem;
					display: flex;
					align-items: center;

					button {
						border: none;
						display: flex;
						align-items: center;
						padding: 0;
						cursor: pointer;
						padding: 0.05rem 0.3rem 0 0;
						border-radius: 5px;
					}

					svg {
						margin-right: 0.3rem;
						margin-left: 0.3rem;
						width: 1rem;

						path {
							fill: $anthra;
						}
					}

					span {
						color: $black;
						font-size: small;
					}
				}
			}

			.logoChoice {
				background-color: $bg-white;
				margin: 1rem;
				padding: 0 0 1rem 0;
				border-radius: 7px;
				display: inline-block;
				height: fit-content;

				.dimensions-info {
					margin-left: 1rem;
					padding-top: 0.3rem;
					font-size: 10px;
					color: $anthra;
					display: flex;
					flex-direction: column;

					b {
						color: red;
					}
				}
			}

			.companyName {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				align-content: center;

				input {
					margin-top: 1rem;
					border: #b6aec7 0.12rem solid;
					border-radius: 3px;
					height: 1.3rem;
					width: 10rem;
					text-align: center;

					&:focus {
						outline: none;
						border: $dm-oL2 0.12rem solid;
					}
				}
			}

			.title {
				display: flex;
				align-items: center;
				background: $lGrey;
				padding: 0.8rem;
				border-top-left-radius: 7px;
				border-top-right-radius: 7px;
				justify-content: space-between;
				width: 100%;
				box-sizing: border-box;

				.logo {
					display: flex;
					align-items: center;

					> svg {
						margin-right: 0.3rem;
						width: 1.3rem;

						path {
							color: $black;
						}
					}
				}

				h3 {
					margin: 0;
					color: $black;
					font-size: medium;
				}

				h4 {
					margin: 0;
					color: $anthra;
					font-size: small;
				}
			}

			.logoPreview {
				height: 5rem;
				margin: 1rem 1rem 0 1rem;
				background-position: center;
				background-repeat: no-repeat;
				background-origin: content-box;
				background-size: contain;
				background-color: $bg-dark;
				padding: 1rem 2rem;
				width: 14.5rem;
				position: relative;
				border-radius: 3px;
				overflow: hidden;

				&:hover {
					.circleDownload label {
						opacity: 1;
					}
				}

				&.email {
					.circleDownload {
						width: calc(100% + 1.7rem * 2);
						height: 100%;

						label {
							left: -1.7rem;
						}
					}
				}

				&.withPadding {
					.circleDownload {
						width: calc(100% + 4.1rem);
						height: calc(100% + 2.1rem);

						label {
							left: -2rem;
							top: -1rem;
						}
					}
				}

				.circleDownload {
					width: 100%;
					height: 100%;
				}

				.circleDownload label {
					display: flex;
					align-content: center;
					justify-content: center;
					box-sizing: border-box;
					border: 2px white solid;
					align-items: center;
					background-color: rgba(201, 204, 214, 0.25);
					backdrop-filter: blur(3px);
					position: absolute;
					opacity: 0;
					transition: $t1;

					svg {
						width: auto;
						min-height: 1.7rem;

						path {
							fill: white;
						}
					}

					input {
						display: none;
					}
				}

				&.e {
					padding: 0;
					height: 7rem;
					width: 17.5rem;
					background-size: cover;
				}

				&.email {
					padding: 0 1.7rem;
					width: 17.5rem;
					background-size: cover;
				}
			}

			.sizeChoice {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-top: 1rem;

				.logo {
					display: flex;
					width: 100%;
					justify-content: space-evenly;

					button {
						border: none;
						background: none;
						width: 4.5rem;
						height: auto;

						svg {
							width: 100%;
							height: auto;
						}
					}

					.active {
						> svg path {
							fill: $actionBlue;
						}
					}
				}

				.name {
					display: flex;
					width: 100%;
					justify-content: space-evenly;
					text-align: center;
					color: $anthra;
					margin-top: 0.3rem;

					span {
						font-size: smaller;
						width: 4.5rem;
						height: auto;
					}

					.active span {
						color: $black;
					}
				}
			}

			.favIcon {
				display: flex;
				flex-direction: row;
				align-items: center;

				.leftCol {
					display: flex;
					flex-direction: column;
					margin: 1rem 0 0 1rem;
					min-width: fit-content;

					h3 {
						margin: 0;
						color: $black;
						font-size: medium;
					}

					h4 {
						margin: 0;
						color: $anthra;
						font-size: small;
					}

					span {
						color: $anthra;
						font-size: small;
						margin-top: 2rem;
						font-size: 10px;
					}
				}

				.iconFrame {
					display: flex;
					justify-content: center;
					width: 100%;

					.icon {
						height: 3rem;
						background-position: center;
						background-repeat: no-repeat;
						background-origin: content-box;
						background-size: contain;
						padding: 0.6rem;
						width: 3rem;
						position: relative;
						background-color: $lGrey;
						border-radius: 3px;

						.circleDownload label {
							height: 3rem;
							width: 3rem;
							top: calc(50% - 1.5rem);
							left: calc(50% - 1.5rem);
							position: absolute;
						}
					}
				}
			}

			.emailLanguage {
				display: flex;
				justify-content: center;
				margin: 1rem 1rem 0.5rem 1rem;

				select {
					border: none;
					color: $bg-dBlue;
					font-weight: 500;
					font-size: smaller;

					option {
						color: $bg-dBlue;
						font-weight: 500;
						font-size: small;
					}
				}
			}
		}
	}
}
