// @use "../../utils/variables" as *;
@use "../utils/mixins" as *;

@mixin ComponentHelperNoise($opacity) {
	&:after {
		content: " ";
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		background-image: url("../../../src/assets/images/noise.png");
		background-repeat: repeat;
		opacity: $opacity;
		z-index: -1;
		border-image: linear-gradient(
				to right,
				rgb(248, 251, 255),
				rgba(117, 120, 124, 0.944)
			)
			1 1;
		filter: blur(0.375rem);

		@content;
	}
}

@mixin PlasmaRay {
	.plasma-container {
		pointer-events: none;
		position: fixed;
		top: 0;
		left: -15.5rem;
		width: 37.63rem;
		height: 6.77rem;
		transform: rotate(-90deg);
		z-index: 10000;

		@include ComponentHelperNoise(0.1);
	}

	.plasma-content {
		pointer-events: none;
		position: fixed;
		width: 23.16rem;
		height: 1.36rem;
		left: -12rem;
		top: 15rem;
		background: rgba(248, 251, 255, 0.983);
		filter: blur(0.625rem);
		border-radius: 0px 0px 12.5rem 12.5rem;
		transform: rotate(-90deg);
		z-index: 10001;
	}

	.plasma-content2 {
		pointer-events: none;
		position: fixed;
		width: 25rem;
		height: 1.436rem;
		left: -12.7rem;
		top: 17rem;
		background: rgb(248, 251, 255);
		filter: blur(0.375rem);
		border-radius: 12.5rem;
		transform: rotate(-90deg);
	}

	.plasma-content3 {
		position: fixed;
		width: 37.5rem;
		height: 9.375rem;
		left: -14rem;
		top: 13rem;
		background: rgb(241, 242, 245);
		opacity: 0.5;
		filter: blur(3.125rem);
		transform: rotate(-90deg);
	}
}
