@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;
@use "../../components/input" as *;
@use "../../components/mouseFollower" as *;

@mixin SurfaceParams {
	.params-surface {
		height: 70vh;
		width: 35vw;

		.column {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: 1fr 2rem 1.563rem repeat(2, 1fr) 2rem 2.188rem 1fr;
			grid-column-gap: 0.8vw;

			#return-surface {
				grid-area: 1 / 1 / 2 / 2;
			}

			#perso {
				grid-area: 1 / 2 / 2 / 3;
			}

			#account-title {
				grid-area: 3 / 1 / 4 / 2;
			}

			#changePass {
				grid-area: 4 / 1 / 5 / 2;
			}

			#pin {
				grid-area: 4 / 2 / 5 / 3;
			}

			#changeNameCard {
				grid-area: 5 / 1 / 6 / 2;
			}

			#mfa {
				grid-area: 5 / 2 / 6 / 2;
			}

			#optionsPass-title {
				grid-area: 7 / 1 / 8 / 2;
			}

			#persoImport {
				grid-area: 8 / 1 / 9 / 2;
			}

			#persoExport {
				grid-area: 8 / 2 / 9 / 3;
			}

			.card {
				display: flex;
				align-items: center;
				background-color: $white;
				border-radius: 0.375rem;
				padding: 1.3vh 1vw;
				width: 100%;
				max-width: 27rem;
				// min-height: 4.313;
				box-sizing: border-box;
				margin-bottom: 1vw;
				cursor: pointer;

				.darkMode & {
					background-color: $dm-b2;
				}
			}

			.item {
				display: flex;
				justify-content: center;
				flex-direction: column;
				padding-left: 0.7vw;
				flex: 2;

				small {
					font-family: $telegraf;
					color: $greyShades-wetGrey;
					font-size: 0.75rem;
					font-weight: 400;

					&.top-surface {
						font-weight: 500;
						font-size: 0.813rem;
						margin-top: 0.4vh;

						.darkMode & {
							color: $greyShades-matSilver;
						}
					}

					.darkMode & {
						color: $greyShades-matSilver;
					}
				}

				b {
					font-family: $telegraf;
					margin-top: 0.4vh;
					color: $black;
					font-weight: 500;
					line-height: 1.063rem;
					font-size: 1rem;

					// check this font
					&.weight {
						// font-weight: 400;
						.darkMode & {
							color: $greyShades-matSilver;

							&.pin {
								color: $purpleShades-galaxySauce;
							}
						}
					}

					&.weight2 {
						font-weight: 600;
					}

					.darkMode & {
						color: $white;
					}
				}

				&.reverse {
					align-items: flex-end;
					padding-right: 0.7vw;
				}
			}

			.surface-params {
				.params-surface-item {
					display: flex;
					flex-direction: row;
					align-items: center;
				}
			}

			.params-info {
				display: flex;
				flex-direction: row-reverse;
				align-items: center;
				margin-bottom: 0.8rem;
			}

			.title-account {
				font-family: $telegraf;
				font-weight: 600;
				line-height: 1rem;
				font-size: 0.938rem;
				color: $black;

				.darkMode & {
					color: $white;
				}
			}

			.title-options {
				@extend .title-options;
				display: flex;
				align-items: center;

				.darkMode & {
					color: $white;
				}
			}

			.icon {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: var(--bgColor3);
				border-radius: 0.3vw;
				height: 2vw;
				width: 2vw;
				max-width: 2rem;
				max-height: 2rem;

				&.icon-back {
					background-color: $newBlue;
					height: 1.7vw;
					width: 1.7vw;
					margin-top: 0.4rem;

					svg {
						width: 0.688rem;
						height: 0.688rem;
					}

					.darkMode & {
						background-color: $newBlue;
					}
				}

				&.icon-changePass {
					.darkMode & {
						background-color: $dm-b3;

						svg {
							path:nth-child(1) {
								fill: $greyShades-matSilver;
							}

							path:nth-child(2) {
								fill: $white;
							}
						}
					}
				}

				&.icon-vector {
					height: 0.8vw;
					width: 0.8vw;
					background-color: transparent;
					margin-right: 0.6rem;
				}

				&.icon-changePin {
					background-color: $newBlue;
					padding: 0.4rem;
					margin-top: 0.2rem;
					width: 1.1rem;
					height: 1.1rem;

					g {
						rect {
							fill: $bg-white;
						}
					}

					.darkMode & {
						background-color: $purpleShades-galaxySauce;
					}
				}

				&.icon-changeName {
					svg {
						width: 1.25rem;
						height: 1.25rem;
					}

					.darkMode & {
						background-color: $dm-b3;

						svg {
							> g {
								.cls-1 {
									fill: $white;
								}
							}
						}
					}
				}

				&.icon-mfa {
					svg {
						width: 1.25rem;
						height: 1.25rem;
					}

					.darkMode & {
						background-color: $dm-b3;

						svg {
							> g {
								.cls-1 {
									fill: $white;
								}
							}
						}
					}
				}

				&.icon-info {
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 0.438rem;
					width: 2.188rem;
					height: 2.188rem;
					background-color: $white;

					path:nth-child(1) {
						fill: $newBlue;
					}

					path:nth-child(2) {
						fill: $newBlue;
					}

					.darkMode & {
						background-color: $dm-b2;

						path:nth-child(3) {
							fill: $greyShades-snow;
						}

						path:nth-child(4) {
							fill: $greyShades-snow;
						}
					}
				}

				&.icon-persoImport,
				&.icon-export {
					.darkMode & {
						background-color: $dm-b3;

						svg {
							g {
								path {
									fill: $white;

									g {
										path {
											fill: $white;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
