@use "../../utils/variables" as *;
@use "./loadingButton" as *;
@use "../../utils/mixins" as *;
@use "../../utils/functions" as *;

@mixin Button($actionSlideButtons: "") {
	@if $actionSlideButtons == "actionSlideButtons" {
		@include ButtonActionSlide;
	} @else {
		.button {
			font-family: $telegraf;
			font-weight: 600;
			color: $white;
			letter-spacing: 0.03em;
			font-size: 1rem;
			line-height: convertToREM(20.8);
			background-color: $newPrimaryBg;
			border: none;
			border-radius: 5px;
			padding: 0.75rem 1.7rem;
			cursor: pointer;
			position: relative;
			bottom: 0;
			right: 0;
			transition: $t1;
			box-sizing: border-box;

			svg {
				position: relative;
				right: -0.8rem;
				width: 1rem;

				&.ssh-arrow {
					width: 0.5rem;
				}
			}

			@include mediaMedium {
				font-size: 0.75rem;
				padding: 0.56rem 1rem;
			}

			&.dashboardExportButton {
				min-height: convertToREM(45);

				.button-text {
					font-family: $telegraf;
					font-size: 1rem;
					line-height: convertToREM(21);
					font-weight: 600;
				}
			}

			&.linkButton {
				display: flex;
				text-decoration: none;
			}

			.darkMode & {
				background-color: $newPrimaryBg;
			}

			&.textLeft {
				text-align: left;
			}

			&.textRight {
				text-align: right;
			}

			&.noWrap {
				white-space: noWrap;
			}

			&.textCenter {
				text-align: center;
				justify-content: center;
			}

			&.medium {
				width: 170px;

				.icon {
					left: 1.25rem;
				}

				&.iconRight {
					.icon {
						right: 0.3rem;
					}
				}
			}

			&.large {
				width: 100%;

				.icon {
					left: 1.25rem;
				}
			}

			&.loading {
				@include LoadingButton($hasLoader: true);
			}

			&.iconRight {
				&.large {
					.icon {
						right: 1.25rem;
					}
				}

				&.medium {
					.icon {
						right: 0.5rem;
					}
				}

				.icon {
					left: unset;
					right: 1.25rem;
				}
			}

			.icon {
				position: absolute;
				left: 1.25rem;
				right: unset;
				top: 25%;
				width: 1.3125rem;
				height: 1.3125rem;

				@include mediaMedium {
					top: 15%;
				}
			}
			@include mediaMedium {
				top: 15%;
			}

			&.defaultIconPosition {
				padding: 0;

				.icon {
					position: unset;
				}
			}

			&.primary:not(.disabled) {
				> svg:not(.pen) {
					path,
					rect,
					circle {
						fill: $white;

						.darkMode & {
							fill: $white;
						}
					}
				}
			}

			&.iconRelative {
				display: flex;
				align-items: center;
				gap: 1rem;
				padding: convertToREM(12) convertToREM(20);

				.icon {
					position: relative;
					top: unset;
					left: unset;
					right: unset;
				}

				.icon-upload {
					position: absolute;
					left: 1rem;
				}
			}

			&.iconRelative.iconRight {
				flex-direction: row-reverse;
			}

			&.primary:hover:not(.disabled) {
				background: linear-gradient(
						0deg,
						transparentize($brand-black, 0.9) 0%,
						transparentize($brand-black, 0.9) 100%
					),
					$newPrimaryBg;
			}

			&:focus {
				outline: none;
			}

			&.secondary {
				background-color: $extBgColor3;
				color: $black;

				&:hover:not(.disabled) {
					background-color: transparentize(
						$color: $brand-black,
						$amount: 0.9
					);

					.darkMode & {
						background-color: $dm-b4;
					}
				}

				.darkMode &:not(.disabled) {
					background-color: $dm-b4;
					color: $white;
				}

				&.disabled {
					svg {
						path,
						rect,
						circle {
							fill: $greyShades-roseGrey;
						}
					}
				}

				svg {
					width: convertToREM(21);
					height: convertToREM(21);

					path,
					rect,
					circle {
						fill: $btnSecondary;

						.darkMode & {
							fill: $btnSecondaryIcon;
						}
					}

					&.basic-key {
						width: 1rem;
					}

					&.ssh-arrow {
						width: 0.5rem;
					}
				}
			}

			&.disabled {
				cursor: default;
				color: $greyShades-roseGrey;
				background-color: $greyShades-snow;

				.darkMode & {
					background-color: $dm-b3;
				}

				path,
				rect,
				circle {
					fill: $greyShades-roseGrey;
				}
			}

			&.warning {
				@include LoadingButton($hasLoader: false);
			}

			&.clear {
				background-color: transparent;
				color: $black;

				.darkMode & {
					background: none;
					background-color: transparent;
					color: $ciment;
				}

				&:hover {
					background-color: transparent;
					transform: scale(1);
				}

				.icon {
					path,
					rect,
					circle {
						fill: $brand-black;
					}
				}
			}
		}
	}
}

@mixin ButtonActionSlide {
	.button {
		display: flex;
		align-items: center;
		font-family: $telegraf;
		color: $white;
		letter-spacing: 0.03em;
		font-size: 1rem;
		background-color: $newPrimaryBgButton;
		border: none;
		border-radius: 0.2rem;
		cursor: pointer;
		transition: $t1;
		padding: 0.8rem;
		font-weight: 600;

		.darkMode & {
			background-color: $newPrimaryBgButtonDM;
			color: $black;
		}

		@include mediaLarge {
			padding: 0.6rem;
			font-size: 0.8rem;
		}

		svg {
			position: relative;
			right: 0;
			margin: 0 0.4rem 0 0 !important;
			width: 0.8rem;

			&.edit-pass {
				.darkMode & {
					path {
						fill: $black;
					}
				}
			}
		}
	}
}
