@use "../../utils" as *;

@mixin CheckBox($type: "default", $onHover: false, $listMode: true) {
	.checkBox {
		border: 2px solid $ciment;
		border-radius: 4px;
		background-color: $white;
		width: convertToREM(19);
		height: convertToREM(19);
		transition: $t1;
		cursor: pointer;

		&.small {
			width: 11px;
			height: 11px;
		}

		&:not(.lockpass) {
			&.selected {
				background-color: $bg-blue;
				border: 2px solid $bg-blue;
			}

			&:not(.selected, .radio):hover {
				border: 2px solid $bg-blue;
			}
		}

		&.lockpass {
			@include checkboxLockPass($onHover, $listMode);
		}

		&.radio {
			margin: 0;
			margin-right: 0.7rem;
			position: relative;
			display: inline-block;
			width: 7px;
			height: 7px;
			border: 2px solid $newGrey;
			border-radius: 50%;
			background-color: $white;
			min-width: 7px;

			&:checked::before {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background-color: $black;
			}

			&.radioSelected {
				background-color: $accent-blueMetallic;
				border: 2px solid $white;
				box-shadow: 0 0 0 1px $accent-blueMetallic;
			}

			&.disabled {
				background-color: $lAnthra;
				border: 2px solid $lAnthra;
			}
		}

		@if $type == "default" {
			@include checkboxFileDefault($onHover, $listMode);
		}

		@content;
	}
}

@mixin checkboxLockPass($onHover, $listMode) {
	width: convertToREM(17);
	height: convertToREM(17);
	border: solid convertToREM(1) $greyShades-matSilver;

	@if $listMode == false {
		width: convertToREM(20);
		height: convertToREM(20);
		grid-area: checkbox;
		justify-self: start;
	}

	@if $onHover {
		width: convertToREM(21);
		height: convertToREM(21);

		@if $listMode == false {
			width: convertToREM(25);
			height: convertToREM(25);
		}
	}

	.darkMode & {
		background-color: $dm-b4;
		border-color: $dm-b4;
	}

	&.selected {
		width: convertToREM(21);
		height: convertToREM(21);
		border-color: $actionBlue;
		background-color: $actionBlue;

		@if $listMode == false {
			width: convertToREM(25);
			height: convertToREM(25);
		}

		.darkMode & {
			border-color: $accent-blueNeonShade;
			background-color: $accent-blueNeonShade;
		}
	}
}

@mixin checkboxFileDefault($onHover, $listMode) {
	@if $onHover {
		width: convertToREM(19);
		height: convertToREM(19);
	} @else {
		width: convertToREM(15);
		height: convertToREM(15);
	}

	.darkMode & {
		border-color: $dm-b4;
		background-color: $dm-b4;
	}

	&.selected {
		.darkMode & {
			border: 2px solid $purpleShades-light;
			background-color: $purpleShades-light;
		}
	}
}
