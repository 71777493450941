@use "../../utils/" as *;
@use "./popinMixins/mixin" as *;
@use "../../components/" as *;
@use "../../components/tooltipHelper" as *;

@mixin popinHeader($location) {
	// default
	.popinHeader {
		.title {
			color: $anthra;

			@if $location == "in_actionPop" {
				color: $black;
				font-size: 25px;
				font-weight: 600;
				text-transform: uppercase;

				.darkMode & {
					color: $lGrey;
				}
			}

			@include mediaLarge {
				font-size: clamp(0.85rem, 1.4vw, 1.1rem);
			}
		}

		.details {
			@include tooltipHelper("locktransfer", true);

			.move-pass-title {
				margin: 0 !important;
			}

			.topPass-wrapper {
				display: flex;
				align-items: center;
				gap: 0.6em;

				.darkMode & {
					.topPass {
						background-color: #454558;
					}
				}

				.topPass {
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #d7d9ea;
					border-radius: 50%;
					min-width: 1rem;
					height: 1rem;

					> p {
						display: flex;
					}

					> p > b {
						font-size: 0.7rem;
						font-weight: 600;
					}
				}

				> span {
					font-size: 11px;
					font-weight: 500;
					max-width: 135px;
					color: #ada8b6;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
			}

			b {
				.darkMode & {
					color: $ciment;
				}
			}
		}

		// in_actionPop
		@if $location == "in_actionPop" {
			@include InActionPop;
		}

		// in_lockPassActions
		@if $location == "in_lockPassActions" {
			@include inLockPassActions;
		}

		// in_thinPop
		@if $location == "in_thinPop" {
			@include inThinPop;
		}

		// in_thinPop_leftPart
		@if $location == "in_thinPop_leftPart" {
			@include inThinPopLeftPart;
		}

		// in_headerOnLeft_leftPart
		@if $location == "in_headerOnLeft_leftPart" {
			min-width: 8rem;
		}
	}
}
