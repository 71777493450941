@use "../utils/variables" as *;
@use "../utils/mixins" as *;

$tr1: 1.7s;

@mixin loaders {
	.loader {
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		animation: 0.4s ease-in-out appear;
		animation-fill-mode: backwards;
		background-color: $white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 10000;

		&.noShow {
			display: none;
		}

		.crossRefresh {
			position: absolute;
			top: 1.3rem;
			right: 1.3rem;
			cursor: pointer;

			.crossRefresh-icon {
				width: 2.3rem;
				height: 2.3rem;

				g {
					path {
						fill: $brand-black;
						stroke: $brand-black;

						.darkMode & {
							fill: $white;
							stroke: $white;
						}
					}
				}
			}
		}

		&.uploadMode {
			background-color: $bg-padlockPop;

			.darkMode & {
				background-color: $dm-padlockPop;
			}
		}

		&.transparency {
			background-color: $bg-padlockPop;
		}

		.darkMode & {
			background-color: $dm-log1;
		}

		.loader-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			flex: 2;

			.remainingTime {
				h3 {
					font-size: 1.2rem;
					font-weight: 500;
					color: #6f6f7c;

					b {
						color: #519fde;
					}
				}
			}
		}

		.counter {
			text-align: center;

			h3 {
				font-family: $mon;
				font-size: 15px;
				font-weight: 500;

				@include mediaLarge {
					font-size: clamp(0.6rem, 1vw, 1rem);
				}

				.darkMode & {
					color: $ciment;
				}
			}

			b {
				text-align: center;
				font-family: $kod;
				font-size: 40px;
				color: $black;

				@include mediaLarge {
					font-size: clamp(2rem, 3vw, 3.5rem);
				}

				.darkMode & {
					color: $lGrey;
				}

				small {
					color: $ciment;
					font-size: 21px;
					margin-left: 3px;
					font-family: $mon;

					@include mediaLarge {
						font-size: clamp(1.5rem, 2vw, 2.5rem);
						margin-left: 0.5vw;
					}

					.darkMode & {
						color: $ciment;
					}
				}
			}
		}

		> .wait {
			margin: 0.5rem 0.5rem 1.5rem;

			.darkMode & {
				color: $lGrey;
			}
		}
	}

	#loader-1 {
		display: flex;

		span {
			height: 0.5rem;
			width: 0.5rem;
			margin-right: 2px;
			background: $ciment;
			border-radius: 0px;
		}
	}

	#loader-1 {
		span {
			&:nth-child(1) {
				border-radius: 100%;
				-webkit-animation: scale $tr1 0.1s infinite
					cubic-bezier(0.6, -0.28, 0.735, 0.045);
				animation: scale $tr1 0.1s infinite
					cubic-bezier(0.6, -0.28, 0.735, 0.045);
			}

			&:nth-child(2) {
				border-radius: 100%;
				-webkit-animation: scale $tr1 0.2s infinite
					cubic-bezier(0.6, -0.28, 0.735, 0.045);
				animation: scale $tr1 0.2s infinite
					cubic-bezier(0.6, -0.28, 0.735, 0.045);
			}

			&:nth-child(3) {
				border-radius: 100%;
				-webkit-animation: scale $tr1 0.3s infinite
					cubic-bezier(0.6, -0.28, 0.735, 0.045);
				animation: scale $tr1 0.3s infinite
					cubic-bezier(0.6, -0.28, 0.735, 0.045);
			}

			&:nth-child(4) {
				border-radius: 100%;
				-webkit-animation: scale $tr1 0.4s infinite
					cubic-bezier(0.6, -0.28, 0.735, 0.045);
				animation: scale $tr1 0.4s infinite
					cubic-bezier(0.6, -0.28, 0.735, 0.045);
			}

			&:nth-child(5) {
				border-radius: 100%;
				-webkit-animation: scale $tr1 0.5s infinite
					cubic-bezier(0.6, -0.28, 0.735, 0.045);
				animation: scale $tr1 0.5s infinite
					cubic-bezier(0.6, -0.28, 0.735, 0.045);
			}
		}
	}

	/* Loader bar */
	.loaderBar {
		width: 100%;
		margin-bottom: 0.3rem;
		display: flex;
		align-items: center;

		.lbWrapper {
			flex: 2;
			position: relative;
			background-color: $lGrey;
			border-radius: 1rem;
			overflow: hidden;
			padding: 0.25rem;

			.darkMode & {
				background-color: $white;
			}

			.lb {
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				border-radius: 1rem;

				&.lbOne {
					z-index: 1;
					background-color: $yellow;
				}

				&.lbTwo {
					background-color: $lBlue;
				}
			}
		}

		.endFrame {
			position: relative;

			span {
				position: absolute;
				top: -100%;
				right: 0;
				color: $black;
				font-size: 0.63rem;
				white-space: nowrap;

				.darkMode & {
					color: $ciment;
				}
			}

			.endIcon {
				padding: 0 0.2rem 0 0.8rem;

				svg {
					height: 1rem;

					path {
						fill: $white;

						.darkMode & {
							fill: $black;
						}
					}
				}
			}
		}

		&.red .lbWrapper {
			.lb {
				&.lbOne {
					background-color: transparent;
				}

				&.lbTwo {
					background-color: $red;
				}
			}
		}

		&.lightBG {
			.endFrame {
				span {
					color: $black;

					.darkMode & {
						color: $white;
					}
				}

				.endIcon {
					svg {
						path {
							fill: $black;

							.darkMode & {
								fill: $lGrey;
							}
						}
					}
				}
			}

			&.red {
				.endFrame {
					span {
						top: -50%;
					}
				}
				.endIcon {
					width: 30px;
					height: 30px;
					margin: 0.1em 0.4em 0;
					border-radius: 100%;
					background-color: $red;
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 0.2rem;

					svg {
						padding: 0;
						path {
							fill: $white;
						}
					}
				}
			}
		}
	}

	/* Loader bars */
	.spinner {
		margin: 1rem;
		display: flex;
		align-items: center;

		&.mini {
			margin: 0.1rem;

			.rect {
				width: 0.18rem;
				height: 1rem;
				margin: 0.1rem;
				border-radius: 20%;
			}
		}

		&.stop .rect {
			animation: sk-stop 0.5s ease-in-out;
			animation-fill-mode: forwards;
		}

		.rect {
			background-color: $bg-blue;
			width: 0.3rem;
			height: 2rem;
			display: inline-flex;
			border-radius: 1rem;
			margin: 0.23em;
			animation: sk-stretchdelay 1.3s infinite ease-in-out;

			.darkMode & {
				background-color: $dm-lBlue;
			}

			&.rect1 {
				animation-delay: 0.4s;
			}

			&.rect2 {
				animation-delay: 0.2s;
			}

			&.rect3 {
				animation-delay: 0s;
			}

			&.rect4 {
				animation-delay: 0.2s;
			}

			&.rect5 {
				animation-delay: 0.4s;
			}
		}
	}

	.circleProgress {
		height: 3rem;
		width: 3rem;
		overflow: hidden;

		svg {
			height: 100%;
			width: 100%;
			position: relative;

			circle {
				stroke: $red;
				stroke-linecap: round;
				stroke-width: 10%;
				fill: none;
				transform-origin: right top;
				z-index: 1;
				stroke-dasharray: 251.32;
				stroke-dashoffset: 0;
				stroke-width: 4;

				&.path {
					stroke: $white;
					z-index: 100;
				}
			}
		}
	}

	/* Animation en cercle  */
	.loader-wrapper {
		position: relative;
		width: 150px;
		height: 150px;
		z-index: 1010;

		.loader-completion {
			position: absolute;
			top: 5px;
			left: 5px;
			margin: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			width: 100%;
			font-weight: 500;
			font-size: 2rem;
			color: #ada8b6;

			em {
				color: #181a3a;
				font-style: normal;
				font-weight: 500;
				margin-right: 0.4rem;

				.darkMode & {
					color: #ada8b6;
				}
			}
		}

		.loader-section {
			position: fixed;
			top: 0;
			width: 51%;
			height: 100%;
			z-index: 20;

			&.section-left {
				left: 0;
			}

			&.section-right {
				right: 0;
			}
		}
	}

	#loader {
		display: block;
		position: relative;
		left: 50%;
		top: 50%;
		width: 150px;
		height: 150px;
		margin: -75px 0 0 -75px;
		border-radius: 50%;
		border: 3px solid transparent;
		border-top-color: #d7d9ea;
		-webkit-animation: spin 1.7s linear infinite;
		animation: spin 1.7s linear infinite;
		z-index: 1010;

		&::before {
			content: "";
			position: absolute;
			top: 5px;
			left: 5px;
			right: 5px;
			bottom: 5px;
			border-radius: 50%;
			border: 3px solid transparent;
			border-top-color: #f47532;
			-webkit-animation: spin-reverse 0.6s linear infinite;
			animation: spin-reverse 0.6s linear infinite;
		}
	}

	.loaded {
		#loader {
			opacity: 0;
			transition: all 0.3s ease-out;
		}

		.loader-wrapper {
			visibility: hidden;
			-webkit-transform: translateY(-100%);
			transform: translateY(-100%);
			transition: all 0.3s 1s ease-out;

			.loader-section {
				-webkit-transform: translateX(-100%);
				transform: translateX(-100%);
				transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
			}
		}
	}
}
