@use "../utils/variables" as *;
@use "./input/mixin" as *;
@use "../components/toggler/toggler" as *;

@mixin formV2 {
	form.v2 {
		width: 100%;

		.focused {
			margin-top: 0 !important;
		}

		.line {
			display: flex;
			align-items: center;
			margin-top: 0.5rem;
			margin-bottom: 1.3rem;

			@include TogglerFrame {
				margin-right: 0.3rem;
			}
		}

		.smtp {
			flex-direction: column;
			align-items: flex-start;

			.selfSigned {
				display: flex;
				align-items: center;
				margin-top: 0.3rem;

				.selfSigned-title {
					font-size: 0.7rem;
					color: $anthra;
					font-weight: 500;
					font-size: 0.8rem;
				}
			}
		}

		.duplex {
			display: flex;

			.inputFrame:first-child {
				margin-right: 0.7rem;
			}

			&.twoThirds {
				.inputFrame {
					&:first-child {
						flex: 2;
					}

					&:last-child {
						flex: 1;
					}
				}
			}
		}

		.inputFrame {
			.inputTitle {
				h4 {
					font-weight: 600;
				}
			}

			&:last-of-type {
				margin-bottom: 0;
			}

			.inputWrap {
				width: 100%;

				input {
					width: 100%;
					background-color: $lGrey;
					border: none;

					&::placeholder {
						color: $anthra;
					}

					.darkMode & {
						background-color: $dm-b2;
					}
				}

				.inputWrapContainer {
					input {
						width: 100%;
						background-color: $lGrey;
						border: none;

						&::placeholder {
							color: $anthra;
						}

						.darkMode & {
							background-color: $dm-b2;
						}
					}
				}
			}

			&.pass {
				.action {
					display: flex;
					align-items: center;
					height: 100%;
					right: 1rem !important;
					bottom: 1.2rem !important;
				}
			}
		}
	}
}
