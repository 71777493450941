@use "../utils/variables" as *;
@use "./confirmMFA" as *;
@use "./setupMFA" as *;
@use "./stepDisplayer" as *;
@use "./getRecoveryMFA" as *;

@mixin CreateMFA {
	.content {
		flex-grow: 1;
		min-height: 30.66rem;
		max-height: 90vh;
		overflow-y: auto;
		overflow-x: hidden;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		display: inline-flex;
		flex-direction: column;
		gap: 1.5rem;
		position: relative;
		padding: 0.9rem 4.8rem;

		@media (max-height: 760px) {
			min-height: unset;
			overflow-y: scroll;
		}

		.contentContainer {
			border-radius: 0.3rem;
			position: relative;
		}
	}

	@include ConfirmMFA();
	@include SetupMFA();
	@include StepDisplayer();
	@include GetRecoveryMFA();
}
