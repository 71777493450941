@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;
@use "./addPp" as *;
@use "../../components/ppComposition" as *;
@use "../../components/inputs/newInput" as *;

@mixin PasswordPolitics() {
	&.passwordPolicy {
		.thinPopinFrameInside {
			overflow-y: clip;

			.popinWrap {
				min-width: 25rem;
				font-family: $telegraf;

				@include mediaMedium {
					min-width: 20rem;
					max-width: 20rem;
				}

				.helperLink {
					border-top-right-radius: 0.5rem;
					border-bottom-right-radius: 0.5rem;
				}

				#thinPopBody {
					.buttons {
						padding-bottom: 0;
						padding-top: 0.25rem;
					}
				}

				.thinPopHeader {
					padding: 0.75rem 1.25rem;
					left: -14rem;
					width: 10.75rem;

					.darkMode & {
						background-color: $dm-b2;
					}

					.popinHeader {
						margin-bottom: 0;

						.title {
							font-weight: 600;

							.darkMode & {
								color: $white;
							}
						}

						.details {
							svg {
								width: 1.75rem;
								height: 1.75rem;
								max-width: unset;

								path {
									fill: $newOrange;

									.darkMode & {
										fill: $dm-oL2;
									}
								}
							}

							.tooltipHelper-children {
								.content-text {
									white-space: unset;
									max-width: 100%;
								}
							}
						}
					}
				}

				// Component reset
				p {
					margin: 0;
				}

				.passwordStrength {
					&.strength {
						.intensity-mark {
							.mark {
								&.medium {
									background-color: $surfaceBlueSide;
								}
							}
						}
					}
				}

				#thinPopBody,
				.popinBody {
					padding: 0;
					overflow: visible;
					position: relative;
					max-height: 90.5vh;
					display: block;

					.darkMode & {
						background-color: $dm-b2;
					}

					@include NewInput;
				}
			}
		}

		@include AddPP();
	}

	.ppDecorator {
		right: -2.7rem;

		path {
			fill: $newPrimaryBg;

			.darkMode & {
				fill: $surfaceBlueSide;
			}
		}
	}

	.passwordPolitics {
		overflow-y: auto;
		padding: 1.37rem 1.25rem 1.38rem;
		max-height: 84vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 1rem;
		height: 40rem;

		&.isLoading {
			display: flex;
			flex-direction: column;
			flex: 1;

			.policyTitle {
				max-width: 80%;
			}

			.loaderContainer {
				display: flex;
				justify-content: center;
				align-items: center;
				flex: 1;

				.spinner {
					transform: scale(0.7);

					.rect {
						background-color: $greyShades-grey;
					}
				}
			}
		}

		.policyTitle {
			color: $greyShades-wetGrey;
			font-size: 1rem;
			font-weight: 600;
			margin: 0;

			@include mediaMedium {
				font-size: 0.75rem;
			}

			.darkMode & {
				color: $white;
			}
		}

		.container {
			@include PpComposition;
		}

		.noPPs {
			height: 100%;
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 2rem;

			@include mediaMinHeightCustom(760px) {
				gap: 5rem;
			}

			.ppsIllu {
				width: 16.72431rem;
				height: 19.67081rem;
			}

			.descrTitle {
				font-size: 1rem;
				font-weight: 600;
				line-height: normal;
				margin: 2rem 0 0.5rem;
			}

			.descr {
				color: $greyShades-wetGrey;
				font-weight: 500;
				font-size: 1rem;

				.darkMode & {
					color: $white;
				}
			}

			.buttons {
				.button {
					.button-text {
						font-size: 0.75rem;
					}

					&.secondary {
						.icon {
							width: 1rem;
							height: 1rem;
							left: 1.4rem;
							top: 31%;
						}
					}
				}
			}
		}

		.ppsList {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.titleContainer {
				display: flex;
				gap: 0.75rem;
				margin: 0 0 1.25rem;

				.sortBy-orderTitle {
					margin-right: 0;
				}
			}

			button {
				margin: 1.25rem 0 0;
			}
		}
	}
}
