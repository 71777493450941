@use "../utils/variables" as *;

@mixin GetHiddenList {
	.GetHiddenList {
		width: 100%;

		.securedContent {
			position: relative;
			width: 100%;
			background-color: $greyShades-snow;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.darkMode & {
				background-color: $dm-b4;
			}

			.button {
				position: absolute;
				cursor: pointer;

				.icon {
					width: 2.1rem;
					height: 2.1rem;
				}
			}

			.blurrer {
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				backdrop-filter: blur(0.2rem);
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				background: none;
				border: none;

				.darkMode & {
					.icon {
						* {
							fill: $greyShades-white;
						}
					}

					.seeMoreText {
						color: $greyShades-white;
					}
				}

				.icon {
					background: none;

					* {
						fill: $brand-black;
					}
				}

				.seeMoreText {
					color: $brand-black;
					font-family: Montserrat;
					font-size: 0.9rem;
					font-weight: 600;
				}
			}

			.blurButton {
				top: 0.75rem;
				left: 1.02rem;
				width: 2.7rem;
				height: 2.7rem;
				background-color: $white;
				border: none;
				border-radius: 0.3rem;
				display: flex;
				justify-content: center;
				align-items: center;

				.darkMode & {
					background-color: $dm-b3;

					.icon {
						* {
							fill: $greyShades-matSilver;
						}
					}
				}

				.icon {
					* {
						fill: $greyShades-grey;
					}
				}
			}

			.listTitle {
				color: $brand-black;
				font-size: 0.9rem;
				font-weight: 600;

				.darkMode & {
					color: $greyShades-white;
				}
			}

			.list {
				flex: 1;
				list-style-type: none;
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 0.72rem 0.9rem;
				margin: 1.5rem;
				padding: 0;

				.listItem {
					color: $brand-black;
					font-size: 0.85rem;
					font-weight: 600;

					.darkMode & {
						color: $greyShades-white;
					}
				}
			}
		}

		.buttonsContainer {
			flex: 1;
			display: flex;
			align-items: center;
			background-color: $accent-blueAction;

			.darkMode & {
				background-color: $purpleShades-galaxySauce;
			}

			.button {
				flex-grow: 1;
				margin: 0.66rem 1.02rem;
				color: $white;
				border: none;
				background: none;
				display: grid;
				grid-template-columns: auto auto;
				justify-content: center;
				gap: 0.5rem;
				color: $greyShades-white;
				text-align: center;
				font-family: Montserrat;
				font-size: 0.9rem;
				font-weight: 600;
				cursor: pointer;

				.icon {
					width: 1.26rem;
					height: 1.26rem;

					* {
						fill: $greyShades-white;
						stroke: $greyShades-white;
					}
				}
			}

			.line {
				background-color: $white;
				width: 0.13rem;
				height: 1.38rem;
			}
		}
	}
}
