@use "../../utils/" as *;
@use "../../components/" as *;
@use "./popinHeader" as *;

@mixin HeaderPop($popup, $product: "") {
	.head:not(.pin) {
		display: flex;
		flex-flow: column;
		overflow-y: auto;
		font-family: $telegraf;

		@if $product == "lockPassTrash" {
			margin-bottom: convertToREM(25);
		} @else {
			margin-bottom: convertToREM(20);
		}

		.head-title {
			font-weight: 600;
			font-size: convertToREM(21);
			line-height: convertToREM(22);
			word-wrap: break-word;

			small {
				display: inline-flex;
				align-items: center;
				margin-left: 0.15rem;
				position: relative;
				bottom: -0.15rem;
				margin-top: 0.2rem;
				color: $anthra;
			}

			svg {
				margin: 0;
				height: auto;
				width: 1rem;
				min-width: 1rem;
				margin-right: 0.3rem;
			}
		}

		.head-option {
			display: flex;
			align-items: center;
			justify-content: space-between;

			&.customStylePopup {
				display: flex;
				align-items: flex-start;
				text-align: left;
				word-break: normal;
				max-height: none;
				overflow: auto;
				// width: min-content;
			}

			svg {
				margin: 0 0.3rem;
				path {
					fill: $black;

					.darkMode & {
						fill: $ciment;
					}
				}

				rect {
					.darkMode & {
						fill: $ciment;
					}
				}
			}
		}

		.head-subtitle {
			font-size: convertToREM(16);
			color: $grey;
			font-weight: 500;
			margin-top: convertToREM(8);
			margin-bottom: 0;
			line-height: convertToREM(17);

			&.customStyle {
				color: $red;

				.darkMode & {
					color: $red;
				}
			}

			&.isRed {
				color: $red;

				.darkMode & {
					color: $red;
				}
			}

			&.warning {
				color: $red;
			}

			.darkMode & {
				color: $ciment;
			}

			span {
				color: $red;
			}
		}

		svg {
			margin-left: calc(50% - 0.9rem);
			width: 1.8rem;
			height: 1.8rem;
			z-index: 1;
			margin-top: 0.5rem;
			margin-bottom: -0.8rem;
		}

		.head-text {
			background-color: $bg-lGrey;
			padding: 1rem;
			line-height: convertToREM(17);
			font-weight: 500;
			border-radius: 5px;
			font-size: convertToREM(16);
			max-height: 100%;
			overflow-wrap: break-word;
			word-wrap: break-word;
			-ms-word-break: break-all;
			word-break: break-word;

			&.head-option {
				user-select: text;
				cursor: auto;
			}

			.darkMode & {
				color: $white;
				background-color: $dm-b3;
			}
		}

		.containerHelpText {
			padding: 0.3rem 0 0 0;
			display: flex;
			align-items: center;
			gap: 1rem;
			margin-bottom: -0.8rem;

			.helpIcon {
				width: 1rem;
				height: 1rem;
				margin: 0;
			}

			p {
				font-size: 0.85rem;
				font-weight: 500;
				color: $greyShades-wetGrey;

				.darkMode & {
					color: $greyShades-matSilver;
				}
			}
		}
	}

	@if $popup == "actionPop" {
		@include popinHeader("in_actionPop");
	}

	@if $popup == "thinPop" {
		.thinPopHeader {
			position: relative;

			#underPopinHeader {
				position: absolute;
				top: calc(100% + 0.6rem);
				left: 0;
				overflow: hidden;

				svg {
					width: 0.6rem;
				}

				svg:nth-child(2) {
					width: 2.7rem;
					height: 2.7rem;
				}

				p {
					font-size: 0.75rem;
				}
			}

			@include popinHeader("in_thinPop_leftPart");
		}

		@include popinHeader("in_thinPop");
	}
}
