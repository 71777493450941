@use "../utils/variables" as *;
@use "../utils/mixins" as *;

@mixin fileLine($enableEllipsis: true) {
	.fileLine {
		position: relative;
		display: flex;
		align-items: center;
		background-color: $bg-lGrey;
		border-radius: 8px;
		border: 1px solid $ciment;
		padding: 0.7rem 0.5rem;
		margin-bottom: 2vh;

		.darkMode & {
			background-color: $dm-b3;
			border: 1px solid $dm-b4;
		}

		.icon-container {
			svg {
				margin-right: 0.4rem;
				height: 0.9rem;
				min-height: 0.9rem;

				path {
					fill: $extPique;
				}

				&:not(.orange) {
					path {
						.darkMode & {
							fill: $grey;
						}
					}
				}
			}
		}

		.fileLine-content {
			display: flex;
			align-items: center;
			font-size: clamp(0.6rem, 0.9vw, 0.8rem);
			font-weight: 500;
			word-break: break-all;

			@if $enableEllipsis {
				@include ellipsis(2);
			}

			.darkMode & {
				color: $ciment;
			}

			svg {
				margin-right: 0.4rem;
				height: 0.9rem;
				min-height: 0.9rem;

				path {
					fill: $extPique;
				}

				&:not(.orange) {
					path {
						.darkMode & {
							fill: $grey;
						}
					}
				}
			}
		}

		.fileLine-action {
			position: absolute;
			right: 0;
			margin-right: 0.4rem;
			font-size: clamp(0.6rem, 0.7vw, 0.8rem);
			font-weight: 500;
			color: $anthra;
			cursor: pointer;

			.darkMode & {
				color: $lGrey;
			}
		}

		@content;
	}
}
