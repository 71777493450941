@use "../../utils/variables" as *;
@use "../../components/fileLine" as *;

@mixin extPopStyle {
	.extPopStyle {
		display: flex;
		flex-direction: column;
		width: 20rem;
		padding-top: 0.6rem;
		padding-right: calc($marginPopup - $scroolBarSizePopup);
		padding-bottom: 0;
		padding-left: calc($marginPopup - $scroolBarSizePopup);

		/* ====================== SCROLLBAR RULES ===================== */

		::-webkit-scrollbar {
			width: $scroolBarSizePopup;
		}

		::-webkit-scrollbar-thumb {
			background: $extBgColor2;
			border-radius: 5px;
		}

		::-webkit-scrollbar-thumb:hover {
			background: $extBgColor2;
		}

		::-webkit-scrollbar-track {
			background: $extBgColor3;
			background: none;
			border-radius: 5px;
		}

		@include fileLine;

		form.mapForm {
			position: relative;

			> .withPadding {
				margin-bottom: 3rem;
			}

			> .buttons {
				background-color: $extBgColor1;
				position: fixed;
				z-index: 1000;
				bottom: 0;
				padding: 1rem 0;
				width: 100%;
			}
		}

		&.followIds {
			overflow: visible;
			width: unset;
		}

		.emptyText {
			display: block;
			color: $extTextColor2;
			opacity: 0.4;
			font-weight: 500;
			text-align: center;
			font-size: 0.7rem;
		}

		& + .redux-toastr {
			.top-left {
				width: 100%;
			}
		}
	}
}
