@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;
@use "../../components/mouseFollower" as *;
@use "../../components/toggler/newSwitch" as *;

@mixin Surface {
	.surfaceFrame {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 400;

		&.filter-back {
			background: linear-gradient(
					0deg,
					rgba(246, 247, 254, 0.3),
					rgba(246, 247, 254, 0.3)
				),
				linear-gradient(
					245.46deg,
					rgba(140, 151, 250, 0.05) 0%,
					rgba(140, 151, 250, 0) 100%
				);
			backdrop-filter: blur(1.5px);

			.darkMode & {
				background: linear-gradient(
						0deg,
						rgba(32, 32, 48, 0.5),
						rgba(32, 32, 48, 0.5)
					),
					linear-gradient(
						245.46deg,
						rgba(140, 151, 250, 0.05) 0%,
						rgba(140, 151, 250, 0) 100%
					);
				backdrop-filter: blur(3px);
			}
		}

		.surface {
			@include DebugTool(2, ".surface", false);
			left: 4.2vw;
			top: 3vh;
			position: fixed;
			border-radius: 0.938rem;
			padding: 3vh 1.5vw;
			z-index: 1001;
			border: 10px solid $white;
			-webkit-backdrop-filter: blur(5px);
			backdrop-filter: blur(5px);
			background: linear-gradient(
					90deg,
					rgba(255, 255, 255, 0) 0%,
					rgba(255, 255, 255, 0) 19.22%,
					rgba(255, 255, 255, 0.0506916) 22.92%,
					rgba(255, 255, 255, 0.13) 32.71%,
					rgba(255, 255, 255, 0) 33.26%,
					rgba(255, 255, 255, 0.13) 36.76%,
					rgba(255, 255, 255, 0) 37.18%,
					rgba(255, 255, 255, 0) 100%
				),
				linear-gradient(
					0deg,
					rgba(244, 117, 50, 0.05) 59.16%,
					rgba(244, 117, 50, 0) 100%
				),
				linear-gradient(
					40.41deg,
					rgba(140, 151, 250, 0.2) 0%,
					rgba(140, 151, 250, 0) 100%
				),
				linear-gradient(
					133.17deg,
					rgba(24, 28, 58, 0.1) 19.73%,
					rgba(24, 26, 58, 0) 79.45%
				),
				rgba(255, 255, 255, 0.7);
			box-shadow: 0px 3px 25px rgba(40, 46, 102, 0.07);
			cursor: default;

			.darkMode & {
				border: 10px solid;
				border-image: linear-gradient(to right, $dm-b3, $dm-b2) 1 1;
				background: linear-gradient(
						0deg,
						rgba(244, 117, 50, 0.05) 65%,
						rgba(244, 117, 50, 0) 107.08%
					),
					linear-gradient(
						40.41deg,
						rgba(140, 151, 250, 0.15) 0%,
						rgba(140, 151, 250, 0) 100%
					),
					rgba(32, 32, 48, 0.7);
				box-shadow: 0px 3px 25px rgba(40, 46, 102, 0.07);
				backdrop-filter: blur(10px);
			}

			.frame {
				display: flex;
				flex-direction: column;

				.top {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					padding: 0 0.5vw;
					margin-bottom: 4vh;

					.user {
						display: flex;
						align-items: flex-end;

						.pic {
							height: 2.6vw;
							width: 2.6vw;
							max-height: 2.8rem;
							max-width: 2.8rem;
							min-height: 2rem;
							min-width: 2rem;
							border-radius: 0.3vw;
							background-color: $newBlue;
							display: flex;
							align-items: center;
							justify-content: center;
							color: $white;
							margin-right: 1vw;
							font-size: clamp(0.8rem, 0.95vw, 1.2rem);
							letter-spacing: -0.1rem;

							.darkMode & {
								background-color: $newBlue;
								color: $white;
							}
						}

						.name {
							color: $black;
							display: flex;
							flex-direction: column;
							font-size: clamp(0.8rem, 0.85vw, 1rem);

							.darkMode & {
								color: $ciment;
							}

							b {
								font-family: $telegraf;
								font-weight: 500;
								color: $black;
								// font-size: clamp(0.9rem, 0.95vw, 1.15rem);
								font-size: 1.438rem;
								margin-bottom: 0.2vh;

								.darkMode & {
									color: $white;
								}
							}

							span {
								font-family: $telegraf;
								font-weight: 600;
								font-size: 0.688rem;

								.darkMode & {
									color: $greyShades-matSilver;
								}
							}
						}
					}

					.darkOpt {
						background-color: $white;
						border-radius: 0.313rem;
						display: flex;
						align-items: center;
						padding: 0.938rem;
						width: 10.813rem;
						height: 2.875rem;
						max-width: 27rem;
						box-sizing: border-box;

						.darkMode & {
							background-color: $dm-b2;
						}

						.changeMode {
							display: flex;
							align-items: center;
							justify-content: space-around;
							width: 100%;
							min-width: 4rem;

							b {
								font-family: $telegraf;
								height: 1rem;
								font-style: normal;
								font-weight: 500;
								font-size: 0.869rem;
								line-height: 1rem;

								.darkMode & {
									color: $white;
								}
							}
						}
					}
				}

				.body {
					width: 35vw;
					height: 60vh;
					display: flex;

					.col {
						display: flex;
						flex-direction: column;
						width: calc(50% - 1rem);
						margin: 0 0.5vw;
						max-width: 50%;

						a.line {
							text-decoration: none;
						}

						.line {
							background-color: $white;
							border-radius: 0.375rem;
							display: flex;
							padding: 1.3vh 1vw;
							width: 100%;
							// min-width: 15vw;
							max-width: 27rem;
							box-sizing: border-box;
							margin-bottom: 1vw;

							.darkMode & {
								background-color: $dm-b2;
							}

							.icon-lock {
								display: flex;
								align-items: center;
								justify-content: center;
								background-color: $greyShades-snow;
								border-radius: 0.3vw;
								padding: 0.5rem;
								margin-top: 0.2rem;
								width: 1.188rem;
								height: 1.188rem;

								g {
									rect {
										fill: $new-blue;

										.darkMode & {
											fill: $white;
										}
									}
								}

								.darkMode & {
									background-color: $dm-b3;
								}
							}

							.icon-leave {
								@extend .icon-lock;
								background-color: $new-blue;

								svg {
									width: 1.188rem;
									height: 1.188rem;

									rect,
									path {
										fill: $white;
									}

									path {
										stroke: $white;
									}

									text {
										.darkMode & {
											fill: $brand-orange;
										}
									}
								}
							}

							.icon-params {
								@extend .icon-lock;
								display: flex;
								justify-content: center;
								align-items: center;
								padding: 0.438rem;
								background-color: $greyShades-snow;

								svg {
									width: 35px;

									path:nth-child(1) {
										fill: $newBlue;
									}

									path:nth-child(2) {
										fill: $newBlue;
									}
								}

								.darkMode & {
									background-color: $dm-b3;

									path:nth-child(3) {
										fill: $greyShades-snow;
									}

									path:nth-child(4) {
										fill: $greyShades-snow;
									}
								}
							}

							.icon-q {
								@extend .icon-leave;
								background-color: $accent-blueMetallic;

								svg {
									g {
										path {
											.darkMode & {
												fill: $white;
											}
										}
									}
								}

								.darkMode & {
									background-color: $accent-blueMetallic;
								}
							}

							.corpse {
								display: flex;
								flex-direction: column;
								align-self: center;
								padding-left: 0.7vw;
								flex: 2;

								small {
									color: $grey;
									font-family: $telegraf;
									font-size: clamp(0.65rem, 0.75vw, 0.85rem);
									font-weight: 500;

									&.fullOut {
										font-family: $telegraf;
										font-style: normal;
										font-size: 0.75rem;
										font-weight: 500;
										color: $newBlue;

										.darkMode & {
											color: $brand-orange;
										}
									}

									&.orgaName {
										font-weight: 400;
									}

									.darkMode & {
										color: $greyShades-matSilver;
									}
								}

								b {
									font-family: $telegraf;
									color: $black;
									margin-top: 0.4vh;
									font-size: 1rem;
									font-weight: 500;

									&.orgaName {
										font-weight: 500;
									}

									&.optMode {
										font-weight: 500;
										font-size: 0.938rem;
									}

									.darkMode & {
										color: $white;
									}
								}
							}

							.openList {
								// width: 0.5vw;
								display: flex;
								align-self: center;
								justify-content: center;
								align-items: center;
								margin-right: 0.4vw;

								.chevron {
									display: flex;
									align-items: center;
									justify-content: center;
									border-radius: 0.3vw;
									height: 1.7vw;
									width: 1.7vw;

									svg {
										width: 0.5vw;
										transform: rotate(90deg);
										transition: $t1;

										path {
											fill: $black;

											.darkMode & {
												fill: $white;
											}
										}
									}

									.darkMode & {
										background-color: $dm-b3;
									}
								}

								&.reverse {
									svg {
										transform: rotate(270deg);
									}
								}
							}

							&.solo {
								display: flex;
								align-items: flex-end;

								&:not(.noPointer) {
									cursor: pointer;
								}
							}

							&.last {
								margin-top: auto;
							}

							&.multi {
								flex-direction: column;
								padding: 0.5vh 1vw;

								.line-item {
									display: flex;
									margin: 1vh 0;
									cursor: pointer;
									align-items: flex-end;

									&:last-of-type {
										.icon {
											background-color: $bg-blue;

											.darkMode & {
												background-color: $ciment;
											}
										}
									}
								}
							}

							&.list {
								z-index: -1;
								position: relative;
								top: calc(-1vw - 10px);
								height: 0;
								padding: 0;
								overflow: hidden;
								margin-bottom: 0;
								transition: 0.3s ease-in-out;
								transform: scale(0.97);

								&.opened {
									z-index: 1;
									height: auto;
									max-height: 20vh;
									overflow-y: auto;
									padding: 1.5vh 0 1vh;
									transform: scale(1, 1) !important;

									> div {
										margin: 0;
										padding: 2vh 0.7vw;
										border-top: 1px solid $lGrey;

										.darkMode & {
											border-top: 1px solid $dm-b4;
										}
									}

									> div:first-of-type {
										border: none;

										.darkMode & {
											border: none;
										}
									}
								}
							}

							&.listTopOpened {
								box-shadow: 0 2px 20px $sh2;
								z-index: 5;

								.darkMode & {
									box-shadow: 0 2px 20px $dm-sh0;
								}
							}

							&.dmActive {
								.darkMode & {
									background: rgb(152, 84, 233);
									background: linear-gradient(
										45deg,
										rgba(152, 84, 233, 1) 0%,
										rgba(104, 113, 230, 1) 50%,
										rgba(47, 158, 228, 1) 100%
									);
									position: relative;
								}

								&::after {
									.darkMode & {
										position: absolute;
										width: calc(100% - 4px);
										height: calc(100% - 4px);
										left: 2px;
										top: 2px;
										content: "";
										z-index: 1;
										background-color: $dm-b2;
										border-radius: 0.375rem;
									}
								}

								.icon {
									.darkMode & {
										background-color: $dm-lBlue;
										z-index: 2;
									}
								}

								.corpse {
									.darkMode & {
										z-index: 2;
									}
								}
							}

							&.surfaceCTB {
								flex-direction: column;
								align-items: flex-start;
								background-color: $bg-white;

								.darkMode & {
									background-color: $black;
								}

								&:hover {
									> b {
										color: $lBlue;
									}
								}

								> b {
									color: $greyShades-matSilver;
								}

								.endFrame {
									span {
										color: $greyShades-matSilver;
									}

									svg {
										* {
											fill: $greyShades-matSilver;
										}
									}
								}
							}

							&:hover {
								> b {
									color: $bg-dBlue;

									font-size: clamp(1.2rem, 1.15vw, 1.4rem);
									margin-bottom: 0.1rem;

									.darkMode & {
										color: $white;
									}
								}

								&.noM {
									margin-bottom: 0.5rem;
								}
							}

							> .CTBCircleGraph {
								display: flex;
								justify-content: flex-start;
								align-items: center;
								gap: 1rem;

								> span {
									color: $grey;
									font-size: clamp(0.8rem, 0.8vw, 0.9rem);
									font-weight: 500;
									margin-bottom: 1.5rem;

									// .darkMode & {
									// 	color: $grey;
									// }

									.darkMode & {
										color: $greyShades-matSilver;
									}
								}
							}
						}
					}

					.apiVersion {
						font-family: $telegraf;
						color: $greyShades-wetGrey;
						font-size: 0.688rem;
						font-weight: 400;

						.darkMode & {
							color: $greyShades-matSilver;
						}
					}

					.lang-options {
						display: flex;
						justify-content: flex-end;
						align-items: center;
						max-height: 1.3rem;
						margin-top: auto;

						&.listTopOpened {
							margin-top: 0.5rem;
						}

						.select-lang {
							display: flex;
							justify-content: flex-end;
							align-items: center;
							max-height: 1.3rem;

							.icon-alpha {
								margin: 0 0.5rem 0 0;

								.darkMode & {
									svg {
										path {
											fill: $greyShades-matSilver;
										}
									}
								}
							}

							.corpse {
								font-family: $telegraf;
								font-size: 13px;
								font-weight: 500;
								margin: 0 0.75rem 0 0;
								height: 100%;

								b {
									margin-right: 0.75rem;
								}

								.darkMode & {
									color: $greyShades-matSilver;
								}
							}

							svg {
								path {
									fill: $greyShades-roseGrey;

									.darkMode & {
										fill: $greyShades-matSilver;
									}
								}
							}
						}

						.reverse {
							transform: rotateX(0.5turn);
						}
					}

					.lang-list {
						align-self: flex-end;
						margin-top: auto;
						border: 1px solid $greyShades-matSilver;
						background-color: $greyShades-snow;
						border-radius: 10px;

						.darkMode & {
							background-color: $dm-b2;
							border: 1px solid $dm-b3;
						}

						.corpse-list-item {
							font-family: $telegraf;
							padding: 0.5rem 0.7rem 0.5rem 0.6rem;
							width: 7rem;
							font-size: 0.813rem;
							font-weight: 500;
							text-align: right;

							.darkMode & {
								color: $greyShades-matSilver;
							}

							&:hover {
								background-color: $newPrimaryBg;
								color: $greyShades-matSilver;
							}
						}
					}

					.displayOn {
						padding-top: 0.3rem;
						display: flex;
					}
				}
			}
		}
	}

	@include mouseFollower;
	@include NewSwitch;
}
