@use "./newSwitch" as *;
@use "../../utils/mixins" as *;
@use "../../utils/variables" as *;

@mixin TogglerFrame {
	.togglerFrame {
		display: flex;
		align-items: center;
		gap: 0.75rem;

		.textContainer {
			font-size: 1rem;
			font-weight: 600;
			margin: 0;

			&.customText {
				font-size: 0.75rem;
				font-style: normal;
				font-weight: 500;
			}

			@include mediaMaxCustom(1200px) {
				font-size: 0.75rem;
			}

			.darkMode & {
				color: $greyShades-matSilver;
			}
		}

		@include NewSwitch;

		@content;
	}
}
