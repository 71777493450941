/* -------------------------------------------------------------------------- */
/*                             COMPONENTS/MAPPING                             */
/* -------------------------------------------------------------------------- */

@use "../utils/index.scss" as *;

@mixin ComponentMapping($additionnalButtonsListStyle: false) {
	.componentMapping {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: minmax(0, 1fr);

		.mappingWrapper {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: minmax(0, 1fr) auto;
			justify-items: center;
			row-gap: convertToREM(36);

			&.hasSingleRow {
				grid-template-rows: minmax(0, 1fr);
			}
		}

		.mappingContainer {
			display: grid;
			grid-template-columns: minmax(0, 1fr);
			grid-template-rows: auto minmax(0, 1fr);
			width: min(100%, calc(100% - convertToREM(160)));

			@include mediaLarge {
				width: min(100%, calc(100% - convertToREM(80)));
			}

			@include mediaMedium {
				width: 100%;
			}

			&.isFullWidth {
				width: 100%;
			}

			&.hasSingleRow {
				grid-template-rows: minmax(0, 1fr);
			}

			.mappingTitle {
				margin: convertToREM(8) 0 convertToREM(13);
				font-size: convertToREM(14);
				color: $anthra;
				font-weight: 600;

				.darkMode & {
					color: $ciment;
				}
			}

			.mappingContent {
				display: grid;
				grid-template-rows: minmax(0, 1fr);
				grid-auto-rows: auto;
				row-gap: convertToREM(20);
			}

			.mappingLoader {
				display: grid;
				grid-template-columns: 1fr;
				grid-template-rows: minmax(0, 1fr);
				place-items: center;
				padding: convertToREM(26);
			}
		}

		.buttonsList {
			justify-self: start;
			display: grid;
			grid-template-columns: repeat(2, auto);
			justify-content: start;
			column-gap: convertToREM(12);

			@if $additionnalButtonsListStyle {
				position: fixed;
				z-index: 3000;
				bottom: 0;
				left: 0;
				width: 100%;
				height: convertToREM(70);
				box-sizing: border-box;
				padding: convertToREM(12) convertToREM(18);
				align-content: center;
				background-color: $greyShades-white;

				.darkMode & {
					background-color: #111;
				}
			}
		}
	}
}
