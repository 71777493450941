@use "../utils/" as *;

@mixin DescItemAction($includeHover: "newSlide") {
	.desc-item-action-container {
		display: grid;
		grid-template-columns: repeat(2, convertToREM(30));
		column-gap: convertToREM(10);
		align-items: center;
	}

	.desc-item-action {
		position: relative;
		background-color: $greyShades-snow;
		border-radius: convertToREM(3);
		width: convertToREM(30);
		height: convertToREM(30);
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		transition-duration: 0.3s;

		.darkMode & {
			background-color: $dm-b2;
		}

		@if $includeHover != "newSlide" {
			&:hover {
				background-color: $greyShades-matSilver;

				.darkMode & {
					background-color: $dm-b4;
				}
			}
		}

		.action-link {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			max-width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		svg {
			width: convertToREM(14);
			height: auto;

			&.copyToClip {
				margin: 0;

				* {
					stroke: $greyShades-wetGrey;

					.darkMode & {
						stroke: $greyShades-matSilver;
					}
				}
			}

			* {
				fill: $greyShades-wetGrey;

				.darkMode & {
					fill: $greyShades-matSilver;
				}
			}
		}
	}
	@content;
}

@mixin hidenSeek {
	.hidenSeek {
		display: flex;
		align-items: center;
		justify-items: center;
		margin-left: 12px;
		cursor: pointer;

		&.customIcon {
			margin-left: -0.5rem;
		}

		svg {
			path {
				fill: $black;

				.darkMode & {
					fill: $ciment;
				}
			}

			rect {
				.darkMode & {
					fill: $ciment;
				}
			}
		}

		@content;
	}
}

@mixin containerHidenSeek($page: "", $inExtension: false) {
	.containerHidenSeek {
		width: min(100%, 20rem);
		margin-top: 0.8rem;
		display: flex;
		align-items: center;
		gap: 12px;

		@if $page == "pass" {
			margin-top: 0;
			width: 100%;
		}

		&.password,
		&.Totp {
			width: 100%;
			margin: 0;
			align-items: normal;
			gap: 0;
			flex-direction: column;

			.remaining-time {
				margin: 0;
				font-size: convertToREM(11);
				line-height: convertToREM(13);
			}

			.passwordStrength {
				margin-bottom: convertToREM(5);
				display: flex;
				justify-content: space-between;

				.intensity-mark {
					display: flex;
				}
			}

			.hiddenSeekPart {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: auto;

				.hiddenSeekSlide {
					box-sizing: border-box;
					width: 100%;
					padding: 1.1rem 0.7rem;
					background-color: $lGrey;
					min-height: convertToREM(50);
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: convertToREM(12) convertToREM(22) convertToREM(12)
						convertToREM(15);
					border-radius: convertToREM(5);

					&.customHeight {
						padding: convertToREM(0) convertToREM(22)
							convertToREM(0) convertToREM(15);
					}

					.darkMode & {
						background-color: $dm-b3;
					}

					.hidenSeekText {
						margin: 0;
						overflow: visible;
						word-break: break-all;

						.darkMode & {
							color: $greyShades-white;
						}

						&.pdm {
							width: 100%;
							word-break: normal;
						}

						&.passFont {
							font-family: $rob;
							font-weight: 500;
						}

						&.customStyle {
							font-size: 1.5rem;
						}
					}
				}

				.icon-device {
					svg {
						margin-left: -0.188rem;
						width: 3.438rem;
						height: 1.75rem;

						.darkMode & {
							path {
								fill: $bg-lGrey;
							}
						}
					}
				}

				.icon-device {
					svg {
						margin-left: -0.188rem;
						width: 3.438rem;
						height: 1.75rem;

						.darkMode & {
							path {
								fill: $bg-lGrey;
							}
						}
					}
				}

				.copyToClip {
					min-width: 0.9rem;
					min-height: 0.9rem;
				}
			}
		}

		&.Totp {
			@if $inExtension {
				margin-top: 0.8rem;
			}

			.totp-text-container {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: 0 0 convertToREM(5);

				p {
					font-size: convertToREM(9);
					line-height: convertToREM(9);
					font-weight: 600;
					color: $greyShades-wetGrey;
					margin: 0;

					@include mediaLarge {
						font-size: clamp(0.6rem, 0vw, 0.8rem);
					}

					.darkMode & {
						color: $ciment;
					}

					em {
						margin-right: convertToREM(5);
						font-size: convertToREM(9);
						line-height: convertToREM(9);
						font-weight: 800;
						font-style: normal;
						color: $greyShades-wetGrey;

						.darkMode & {
							color: $lGrey;
						}
					}
				}

				// button {
				// 	@include resetBtn;
				// 	font-size: convertToREM(13);
				// 	line-height: convertToREM(16);
				// 	font-weight: 600;
				// 	color: $brand-black;

				// 	.darkMode & {
				// 		color: $lGrey;
				// 	}
				// }
			}

			.hiddenSeekPart .hiddenSeekSlide {
				.hidenSeekText {
					width: auto;
					flex-grow: 1;
					font-size: 1rem;
					padding: 0 0 0 convertToREM(19);
					font-size: convertToREM(21);
					line-height: convertToREM(26);

					&.readable {
						letter-spacing: 0.2em;
					}

					&.masked {
						letter-spacing: 0.4em;
					}

					&.cool {
						color: $lBlue;

						.darkMode & {
							color: $white;
						}
					}

					&.stressed {
						color: $red;
					}
				}
			}
		}

		.hidenSeekText {
			overflow-wrap: anywhere;
			width: auto;
			padding: 0 1rem;

			&.readable {
				letter-spacing: 0.4em;
			}

			&.masked {
				letter-spacing: 0.4em;
				font-size: 1.5rem;
			}

			&.stressed {
				color: $red;
			}
		}

		.strength {
			display: flex;
			flex-direction: row;
			align-items: center;

			span {
				width: 1.8rem;
				height: 5px;
				border-radius: 5px;
				background-color: $matSilver;
				margin-right: 0.3rem;

				.darkMode & {
					background-color: $dm-b4;
				}
			}

			p {
				font-size: 12px;
				color: $greyShades-wetGrey;
				font-weight: 600;
				margin: 0 0 0 0.5rem;

				.darkMode & {
					color: $matSilver;
				}
			}
		}

		@if $page == "pass" {
			.buttonsContainer {
				display: grid;
				grid-template-columns: convertToREM(18) convertToREM(16);
				column-gap: convertToREM(18);
				align-items: center;

				&.is-not-allowed-to-show {
					.hidenSeek {
						&:last-child {
							grid-column: 2 / 3;
						}
					}
				}

				.hidenSeek,
				.hiddenSeek {
					&:first-child {
						padding: 0;

						svg {
							width: convertToREM(18);
						}
					}

					svg {
						margin: 0;
						height: auto;
						width: convertToREM(16);
						max-width: 100%;
						max-height: 100%;

						&.copyToClip {
							* {
								stroke: $greyShades-wetGrey;

								.darkMode & {
									stroke: $greyShades-matSilver;
								}
							}
						}

						&.download {
							cursor: pointer;
						}

						* {
							fill: $greyShades-wetGrey;

							.darkMode & {
								fill: $greyShades-matSilver;
							}
						}
					}
				}
			}
		}

		@include DescItemAction;

		@include hidenSeek {
			margin-left: 0;
			word-wrap: break-word;

			.desc-item-action {
				svg:not(.copyToClip) {
					* {
						fill: $greyShades-wetGrey;

						.darkMode & {
							fill: $greyShades-matSilver;
						}
					}
				}
			}
		}

		@content;
	}
}
