@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;

@mixin NumInput() {
	.numInput {
		display: flex;
		justify-content: flex-start;
		padding: 0.25rem;
		background-color: $white;
		height: 1.81rem;

		@include mediaMaxCustom(1200px) {
			height: 1.38rem;
		}

		.darkMode & {
			background-color: $dm-b3;
		}

		.counterValue {
			width: 2rem;
			text-align: center;
			-moz-appearance: textfield;
			padding: 0;
			margin: 0;
			border: none;
			z-index: 5;
			font-weight: 500;

			@include mediaMaxCustom(1200px) {
				width: 1.5rem;
				font-size: 0.75rem;
			}

			.darkMode & {
				background-color: $dm-b2;
				color: $white;
			}

			&::-webkit-inner-spin-button,
			&::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			&:focus {
				border: none;
				outline: none;
			}
		}

		.counterBtn {
			padding: 0.25rem;
			border-radius: 0.25rem;
			cursor: pointer;
			display: flex;
			align-items: center;
			background-color: $white;
			border: none;
			position: relative;

			.darkMode & {
				background-color: $dm-b2;
				color: $white;
			}

			&.animateLeft {
				&:after {
					content: "";
					position: absolute;
					top: 0;
					left: 7px;
					width: 100%;
					height: 100%;
					background-color: transparentize($surfaceBlueSide, 0.5);
					border-radius: 100%;
					opacity: 0;
					animation: numClickLeft 0.5s ease-in-out;
					z-index: 1;
				}
			}

			&.animateRight {
				&:after {
					content: "";
					position: absolute;
					top: 0;
					right: 7px;
					width: 100%;
					height: 100%;
					background-color: transparentize($surfaceBlueSide, 0.5);
					border-radius: 100%;
					opacity: 0;
					animation: numClickRight 0.5s ease-in-out;
					z-index: 1;
				}
			}

			.icon {
				width: 1.31rem;
				height: 1.31rem;

				@include mediaMaxCustom(1200px) {
					width: 1rem;
					height: 1rem;
				}

				path {
					fill: $greyShades-wetGrey;
					z-index: 1;

					.darkMode & {
						fill: $white;
					}
				}
			}

			&:hover {
				background-color: transparentize($surfaceBlueSide, 0.9);

				.icon {
					color: $newPrimaryBg;
				}
			}
		}
	}
	@content;
}

@keyframes numClickLeft {
	0% {
		transform: scale(0);
		left: 0;
		opacity: 0;
	}
	10% {
		transform: scale(0.5);
		left: 0;
		opacity: 1;
	}
	50% {
		transform: scale(1);
		left: 0;
		opacity: 1;
	}
	60% {
		left: -0.1rem;
		transform: scale(1);
		opacity: 1;
	}
	75% {
		left: -0.5rem;
		transform: scale(0.8);
		opacity: 1;
	}
	79% {
		left: -0.6rem;
		transform: scale(0.7, 0.8);
		opacity: 1;
	}
	85% {
		left: -0.6rem;
		transform: scale(0.5, 0.7);
		opacity: 1;
	}
	90% {
		transform: scale(0.5);
		left: -1rem;
		opacity: 0;
	}
	100% {
		transform: scale(0.5);
		left: -1.2rem;
		opacity: 0;
	}
}

@keyframes numClickRight {
	0% {
		transform: scale(0);
		right: 0;
		opacity: 0;
	}
	10% {
		transform: scale(0.5);
		right: 0;
		opacity: 1;
	}
	50% {
		transform: scale(1);
		right: 0;
		opacity: 1;
	}
	60% {
		right: -0.1rem;
		transform: scale(1);
		opacity: 1;
	}
	75% {
		right: -0.5rem;
		transform: scale(0.8);
		opacity: 1;
	}
	79% {
		right: -0.6rem;
		transform: scale(0.7, 0.8);
		opacity: 1;
	}
	85% {
		right: -0.6rem;
		transform: scale(0.5, 0.7);
		opacity: 1;
	}
	90% {
		transform: scale(0.5);
		right: -1rem;
		opacity: 0;
	}
	100% {
		transform: scale(0.5);
		right: -1.2rem;
		opacity: 0;
	}
}

// A supprimer si on garde le nouvel input partout dans la webapp
@mixin OldNumInput() {
	.numInput {
		display: flex;
		flex: 2;
		justify-content: flex-end;
		align-items: center;
		margin-top: 0.3rem;

		.counterBtn {
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			background-color: $lGrey;
			border-radius: 100%;
			min-height: 1.5rem;
			max-height: 1.5rem;
			min-width: 1.5rem;
			max-width: 1.5rem;
			outline: none;
			border: none;

			.darkMode & {
				background-color: $dm-b5;
			}

			svg {
				min-height: 0.8rem;
				min-width: 0.8rem;

				path {
					fill: $bg-dark;

					.darkMode & {
						fill: $lGrey;
					}
				}
			}
		}

		.counterValue {
			text-align: center;
			background-color: $lGrey;
			border-radius: 3px;
			width: 3rem;
			height: 1.4rem;
			padding-right: 0 !important;
			padding: 0.1rem 0.5rem;
			margin: 0 0.3rem;
			font-weight: 600;

			.darkMode & {
				background-color: $dm-b3;
				color: $ciment;
			}
		}
	}
}
