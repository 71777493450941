@use "../../utils/variables" as *;

@mixin devNoticeLs {
	#devNoticeLs {
		margin: 1rem;
		font-size: 120%;
		width: 20rem;
		font-family: $mon;

		h1 {
			color: $blue;
			font-size: 1.1rem;
		}

		h2 {
			color: $grey;
			font-size: 0.9rem;
		}

		i {
			font-size: 0.9rem;
		}

		.devTabs {
			display: flex;
			margin-top: 4rem;
			margin-bottom: 1rem;

			.devTabs-item {
				margin-right: 1rem;
				padding: 0.3rem 1rem;

				&.active {
					background-color: $blue;
					border-radius: 3px;
					color: $white;
				}
			}
		}

		.devForm {
			display: flex;
			flex-direction: column;
			margin-bottom: 2rem;

			label {
				margin-top: 1rem;
				margin-bottom: 0.2rem;
				font-size: 0.9rem;
			}

			.assetsSection {
				margin-bottom: 5rem;

				.head {
					margin-bottom: 1rem;

					> h3 {
						margin: 0 0 0.3rem 0;
					}
				}

				.list {
					width: 90vw;
					display: flex;
					flex-wrap: wrap;

					> .asset {
						width: 5rem;
						max-width: 5rem;
						border: 1px solid #eaeaea;
						padding: 0.5rem 1rem;
						display: flex;
						flex-direction: column;
						align-items: center;
						overflow: hidden;
						margin: 0.3rem;
						border-radius: 3px;

						.imgFrame {
							height: 3rem;
							display: flex;
							justify-content: center;
							align-items: center;
							margin-bottom: 1rem;

							> img {
								width: 3rem;
								height: auto;
							}
						}

						> small {
							text-align: center;
							font-size: 0.7rem;
							max-width: 100%;
							text-overflow: ellipsis;
							overflow: hidden;
							display: -webkit-box;
							word-break: break-all;
							-webkit-line-clamp: 2; /* number of lines to show */
							line-clamp: 2;
							-webkit-box-orient: vertical;
							font-weight: 500;
						}

						&.dark {
							background-color: #222;
							border: 1px solid #222;

							> small {
								color: #eaeaea;
							}
						}
					}
				}
			}
		}
	}
}
