@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;
@use "../../components/select" as *;

@mixin NavMobile($navMini: false) {
	.mobileNav {
		@include DebugTool(2, ".mobileNav");
		display: flex;
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;

		@include mediaMinCustom {
			display: none;
		}

		> .navTop {
			@include mediaMedium {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 15px 20px;
				width: 100%;
				position: relative;
				z-index: 502;
				background-color: $white;
				box-shadow: 0 0 25px $sh4;

				.darkMode & {
					background-color: $dm-b2;
				}
			}

			> svg {
				@include mediaMedium {
					height: 20px;
					width: auto;
				}

				path {
					.darkMode & {
						fill: $anthra;
					}
				}
			}

			.menuToggle {
				@include mediaMedium {
					position: absolute;
					left: 20px;
				}

				svg {
					@include mediaMedium {
						width: auto;
						height: 23px;
					}

					path {
						@include mediaMedium {
							fill: $black;

							.darkMode & {
								fill: $white;
							}
						}
					}

					line {
						@include mediaMedium {
							stroke: $black;

							.darkMode & {
								stroke: $white;
							}
						}
					}
				}
			}
		}

		.navPop {
			@include mediaMedium {
				z-index: 501;
				display: none;
				position: fixed;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: $lGrey;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				padding: 80px 20px 20px 20px;
				box-sizing: border-box;
				background-size: contain;
				background-repeat: no-repeat;
				background-image: url("../../../assets/decorators/navMobileWave.svg");
				background-position: bottom;

				.darkMode & {
					background-color: $dm-b3;
				}
			}

			&.show {
				@include mediaMedium {
					display: flex;
				}
			}

			.navPopCrumb {
				@include mediaMedium {
					background-color: $white;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 15px 25px;
					box-sizing: border-box;
					border-radius: 5px;
					width: 100%;

					.darkMode & {
						background-color: $dm-b2;
						color: $white;
					}
				}
			}

			.navActions {
				display: flex;

				@include select;

				> div {
					@include mediaMedium {
						margin-left: 20px;
					}

					svg {
						path {
							.darkMode & {
								fill: $newGrey;
								stroke: $newGrey;
							}
						}
					}
				}

				.lf-player-container {
					svg {
						g:first-of-type {
							g:first-of-type {
								g:first-of-type {
									path:first-of-type {
										fill: none;
									}
								}
							}
						}
					}
				}
			}

			.credits {
				display: flex;
				height: auto;
				padding-bottom: 0;
			}
		}
	}
}
