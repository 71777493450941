@use "./variables" as *;
@use "./mixins" as *;

@keyframes transition {
	0% {
		transform: scale(0.7);
		width: 0%;
		max-height: 50vh;
		opacity: 0;
		top: -50px;
	}
	99% {
		transform: scale(1);
	}
	100% {
		transform: unset;
		width: 100%;
		max-height: unset;
		opacity: 1;
		top: 0;
	}
}

@keyframes transition2 {
	0% {
		transform: scale(0.7);
		width: 0%;
		max-height: 50vh;
		opacity: 0;
		top: -50px;
	}
	99% {
		transform: scale(1);
	}
	100% {
		transform: unset;
		width: 100%;
		max-height: unset;
		opacity: 1;
		top: 0;
	}
}

@keyframes appear {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes vanishScale {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		transform: scale(1.2);
	}
}

@-webkit-keyframes scale {
	0% {
		transform: scale(0);
	}
	25% {
		transform: scale(0.9, 0.9);
		background: $ciment;
	}
	50% {
		transform: scale(1, 1);
		margin: 0 3px;
		background: $ciment;
	}
	100% {
		transform: scale(0);
	}
}

@keyframes scale {
	0% {
		transform: scale(0);
	}
	25% {
		transform: scale(0.9, 0.9);
		background: $ciment;
	}
	50% {
		transform: scale(1, 1);
		margin: 0 3px;
		background: $ciment;
	}
	100% {
		transform: scale(0);
	}
}

@keyframes sk-stretchdelay {
	0%,
	100% {
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	}
	50% {
		transform: scaleY(1);
		-webkit-transform: scaleY(1);
	}
}

@keyframes sk-stop {
	0%,
	100% {
		height: 0.2rem;
		position: relative;
		bottom: 0.3rem;
	}
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes spin-reverse {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(-360deg);
	}
}

@keyframes spin-reverse {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(-360deg);
		transform: rotate(-360deg);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeSlideIn {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fadeSlideInBottom {
	0% {
		opacity: 0;
		transform: translateY(-20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fadeSlideInLeft {
	0% {
		opacity: 0;
		transform: translateX(20px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes fadeSlideInRight {
	0% {
		opacity: 0;
		transform: translateX(-20px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@-moz-keyframes fadeSlideIn {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@-webkit-keyframes fadeSlideIn {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes wiggle {
	30% {
		transform: rotate(-20deg);
	}
	80% {
		transform: rotate(10deg);
	}
}

@keyframes toastAppearance {
	0% {
		opacity: 0;
	}
}

@keyframes toastAppearance {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes toastRemoval {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes showIdsAppear {
	from {
		opacity: 0;
		top: 10%;
	}
	to {
		opacity: 1;
		top: 0;
	}
}

@keyframes showIdsHide {
	from {
		opacity: 1;
		top: 0;
	}
	to {
		opacity: 0;
		top: 10%;
	}
}

@keyframes followIdsAppear {
	from {
		opacity: 0;
		top: -50%;
	}
	to {
		opacity: 1;
		top: 0;
	}
}

@keyframes followIdsHide {
	from {
		opacity: 1;
		top: 0;
	}
	to {
		opacity: 0;
		top: -50%;
	}
}

@keyframes explode1 {
	0% {
		opacity: 0.6;
		transform: scale(0.3);
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 0;
		transform: scale(0.55);
	}
}

@keyframes explode2 {
	0% {
		opacity: 0.6;
		transform: scale(0.3) rotate(45deg);
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 0;
		transform: scale(0.55) rotate(45deg);
	}
}

@keyframes jump {
	30% {
		bottom: 5px;
	}
	50% {
		bottom: 0;
	}
	80% {
		bottom: 3px;
	}
}

@keyframes show {
	0% {
		max-height: 0;
	}
	100% {
		max-height: unset;
	}
}

@keyframes hide {
	0% {
		max-height: unset;
	}
	100% {
		max-height: 0;
	}
}

@keyframes bounceLoad {
	0% {
		left: 0;
	}
	20% {
		left: 7px;
	}
	40% {
		left: 0;
	}
	100% {
		left: 0;
	}
}

@keyframes helpAppear {
	0% {
		opacity: 0;
		visibility: visible;
		height: 100vh;
	}
	100% {
		opacity: 1;
		visibility: visible;
		height: 100vh;
	}
}

@keyframes slideDesappear {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes bounce {
	30% {
		bottom: 5px;
	}
	50% {
		bottom: 0;
	}
	70% {
		bottom: 3px;
	}
}

@keyframes ToggleChange {
	0% {
		left: 3px;
	}
	50% {
		left: 10px;
	}
	100% {
		left: 26px;
	}
}

@keyframes miniToggleChange {
	0% {
		left: 3px;
	}
	50% {
		left: 8px;
	}
	100% {
		left: 15px;
	}
}

@keyframes ToggleDashboardChange {
	0% {
		left: 0%;
	}
	30% {
		left: 20%;
	}
	60% {
		left: 40%;
	}
	100% {
		left: 60%;
	}
}

@keyframes bounceRightLoad {
	0% {
		left: 0;
	}
	20% {
		left: 0.5vw;
	}
	40% {
		left: 0;
	}
	100% {
		left: 0;
	}
}

@keyframes bounceRightLoadLight {
	0% {
		left: 0;
	}
	20% {
		left: 12px;
	}
	40% {
		left: 0;
	}
	100% {
		left: 0;
	}
}

@keyframes bounceRightTranslate {
	0% {
		transform: translateX(0);
	}
	20% {
		transform: translateX(12px);
	}
	40% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes infinite-spinning {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* for Firefox */
@-moz-keyframes slideX {
	from {
		-moz-transform: translateX(100%);
	}
	to {
		-moz-transform: translateX(-100%);
	}
}

/* for Chrome */
@-webkit-keyframes slideX {
	from {
		-webkit-transform: translateX(100%);
	}
	to {
		-webkit-transform: translateX(-100%);
	}
}

@keyframes slideX {
	from {
		-moz-transform: translateX(100%);
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
	to {
		-moz-transform: translateX(-100%);
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}
}
