@use "../../../utils/" as *;

@mixin inLockPassActions {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 0;

	.title {
		font-weight: 600;
	}

	.details {
		width: 100%;
		display: flex;
		align-items: center;

		> img {
			object-fit: cover;
			object-position: left;
			height: 2.2rem;
			width: 2.2rem;
		}

		> .icons {
			height: 2.5rem;
			width: 2.8rem;
		}

		> img,
		> .icons {
			margin-right: 0.8rem;
			border-radius: 3px;
		}

		.icons {
			position: relative;

			&.pair {
				img {
					position: absolute;
					height: 1.75rem;
					width: 1.75rem;
					object-fit: cover;
					object-position: left;
					border-radius: 3px;

					&:first-child {
						bottom: 0;
						left: 0;
						z-index: 1;
					}

					&:last-child {
						top: 0;
						right: 0;
						background-color: $white;
						box-shadow: 0 0 0.25rem $sh4;
						z-index: 2;
					}
				}
			}

			&.cluster {
				img {
					position: absolute;
					height: 1.3rem;
					width: 1.3rem;
					object-fit: cover;
					object-position: left;
					border-radius: 3px;

					&:first-child {
						bottom: 0;
						left: 0;
						z-index: 1;
					}

					&:nth-child(2) {
						top: 0;
						left: 10%;
						box-shadow: 0 0 0.25rem $sh4;
						z-index: 2;
					}

					&:last-child {
						bottom: 20%;
						right: 0;
						background-color: $white;
						box-shadow: 0 0 0.25rem $sh4;
						z-index: 3;
					}
				}
			}
		}
	}
}
