@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;
@use "../../components/" as *;

@mixin licenceView {
	.licenseViewStatus {
		margin: 0.4rem 0 1.7rem;

		.licenseIndic-container {
			margin-bottom: 1rem;
			text-transform: uppercase;
			display: flex;
			align-items: center;

			&.noUp {
				text-transform: none;
			}

			.licenseIndic-text {
				margin-left: 0.1rem;
				font-size: 0.8rem;
				margin-top: 0.1rem;
				flex: 2;
			}

			small {
				margin-top: 0.1rem;
				background-color: $violet;
				color: $white;
				padding: 0.3rem 0.7rem;
				border-radius: 5px;
				font-size: 0.7rem;

				.darkMode & {
					color: $grey;
				}
			}
		}

		.loaderBar {
			margin-bottom: 0.7rem;
		}

		.licenseView-dates {
			margin-top: 0.4rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			small {
				color: $anthra;
				font-size: 0.6rem;
				font-weight: 500;
			}

			b {
				font-size: 0.75rem;
				margin-left: 1rem;
			}
		}
	}

	.licGetError {
		padding: 4rem 0;
		text-align: center;
		color: $anthra;
	}

	.licenseViewInfos {
		display: flex;
		flex-direction: column;
		margin: 1rem 0;

		@include tags;

		.licenseViewInfos-container {
			display: flex;
			flex-direction: column;
			margin-bottom: 1.2rem;

			.licenseViewInfos-label {
				color: $anthra;
				font-size: 0.8rem;
				margin-bottom: 0.3rem;

				.darkMode & {
					color: $ciment;
				}

				&.idLabel {
					display: flex;
					justify-content: space-between;
				}
			}

			.licenseViewInfos-smallInfo {
				color: $black;
				font-size: 0.8rem;

				.darkMode & {
					color: $lGrey;
				}

				&.id {
					text-transform: uppercase;
					font-size: 0.7rem;
				}
			}

			&.line {
				flex-direction: row;
				align-items: center;
				margin-bottom: 0.4rem;

				.licenseViewInfos-label {
					font-size: 0.7rem;
					margin: 0.07rem 0.5rem 0 0;
				}

				.licenseViewInfos-smallInfo {
					font-size: 0.8rem;
				}
			}

			&.users {
				margin-top: 0.8rem;

				.licenseViewInfos-smallInfo {
					font-size: 0.8rem;
				}

				> svg {
					margin-right: 0.6rem;
					width: 1rem;
					height: auto;

					path,
					circle,
					ellipse {
						fill: $anthra;

						.darkMode & {
							fill: $ciment;
						}
					}
				}
			}

			&.products {
				margin-top: 1rem;
			}
		}
	}

	.licenseViewToast {
		background-color: $lGrey;
		padding: 0.7rem 0.8rem;
		margin: 0.7rem 0;
		display: flex;
		border-radius: 3px;

		.darkMode & {
			background-color: $dm-b2;
		}

		.text {
			display: flex;
			flex-direction: column;
			margin-left: 0.5rem;

			b {
				font-size: 0.9rem;
				margin-bottom: 0.3rem;
				color: $white;
			}

			span {
				font-weight: 500;
				font-size: 0.8rem;
				color: $ciment;
			}
		}
	}
}
