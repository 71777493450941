@use "../../utils/variables" as *;

@mixin externalWm {
	.externalWm {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		background-position: center;
		background-repeat: no-repeat;
		background-origin: content-box;
		overflow-y: auto;
		background-color: $black;
		background-size: cover;
		z-index: 500;
		justify-content: center;

		.centralPanel {
			display: flex;
			position: absolute;
			width: calc(100% / 3.5);
			justify-content: center;
			flex-direction: column;

			.logoTop {
				height: 7vh;
				min-height: 3rem;
				max-height: 5rem;
				background-position: center;
				background-repeat: no-repeat;
				background-origin: content-box;
				background-size: contain;
				width: 40%;
				position: relative;
				margin-top: 2rem;
				display: flex;
				align-self: center;
			}

			.topLine {
				display: flex;
				border: 4px $bg-white solid;
				box-sizing: border-box;
				border-radius: 5px;
				width: calc(100% / 5);
				margin: 5rem 0 0.5rem 0;
			}

			.topPanel {
				display: flex;
				padding: 1rem 1.5rem;
				border-radius: 7px;
				background-color: $bg-white;

				.circle {
					display: flex;
					min-height: 4rem;
					min-width: 4rem;
					background-color: $ciment;
					border-radius: 50%;
				}

				.line {
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					padding-left: 3rem;

					.line-container {
						display: flex;
						box-sizing: border-box;
						border-radius: 5px;

						&.lineTop {
							width: 10rem;
							border: 4px $ciment solid;
						}

						&.lineBottom {
							width: 17rem;
							border: 4px $bg-lGrey solid;
						}
					}
				}
			}

			.middleLine {
				display: flex;
				border: 4px $bg-white solid;
				box-sizing: border-box;
				border-radius: 5px;
				width: calc(100% / 3);
				margin-left: calc(100% / 3);
				margin: 2rem 0;
				align-self: center;
			}

			.bottomPanel {
				display: flex;
				flex-direction: column;
				align-items: center;
				background-color: $bg-white;
				padding: 0 1.5rem;
				border-radius: 7px;

				.topLine {
					display: flex;
					border: 7px $ciment solid;
					box-sizing: border-box;
					border-radius: 5px;
					width: calc(100% / 4);
					margin: 2rem 0;
				}

				.topBox,
				.middleBox,
				.bottomBox {
					display: flex;
					align-content: center;
					padding: 0.7rem 0;
					background-color: $bg-lGrey;
					width: 100%;
					border-radius: 7px;

					.logo {
						margin: 0 1rem;

						svg {
							height: 2.5rem;
						}
					}

					.topBoxLine,
					.middleBoxLine,
					.bottomBoxLine {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						width: 100%;
						align-items: center;
						padding: 0 1rem;

						.leftLine,
						.rightLine {
							display: flex;
							border-radius: 5px;
							height: 0.6rem;
							background-color: $ciment;
						}

						.leftLine {
							width: 10rem;
						}

						.rightLine {
							width: 3rem;
						}
					}
				}

				.topBox,
				.bottomBox {
					margin: 0.5rem 0;
				}

				.button {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 3.5rem;
					background-color: var(--ogButton);
					margin: 2rem 0;
					border-radius: 3px;

					.line {
						border: 4px $bg-white solid;
						box-sizing: border-box;
						border-radius: 5px;
						width: 40%;
					}
				}
			}

			.bottomline {
				display: flex;
				border: 4px $bg-white solid;
				box-sizing: border-box;
				border-radius: 5px;
				width: calc(100% / 5);
				margin: 0.5rem 0 2rem 0;
				align-self: flex-end;
			}

			.logoBottom {
				display: flex;
				justify-content: center;
				align-items: center;
				padding-top: 7vh;
				margin-bottom: 2rem;

				.logoLine {
					border: 3px $bg-white solid;
					box-sizing: border-box;
					border-radius: 5px;
					width: calc(100% / 7);
					max-height: 0;
					margin-right: 0.8rem;
				}

				svg path {
					fill: $bg-white;
				}
			}
		}
	}
}
