@use "../../utils" as *;
@use "../buttons/buttons" as *;

////////////////////////////////////////
///////////////    NAV    //////////////
////////////////////////////////////////

@mixin ButtonNavControl {
	.navControl {
		@include DebugTool(3, ".navControl", false);
		position: absolute;
		bottom: 5rem;
		right: -25px;
		background-color: $bg-blue;
		height: 42px;
		width: 42px;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-items: center;

		@include mediaLarge {
			bottom: 10vh;
			right: -1.7vw;
			height: 3vw;
			width: 3vw;
			border-radius: 0.3rem;
		}

		@include mediaMedium {
			display: none;
		}

		.darkMode & {
			background-color: $purpleShades-galaxySauce;
		}

		// .darkMode & {
		// 	box-shadow: 0 0 35px rgba(66, 143, 229, 0.5);
		// 	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#865ae8+0,2aa2e4+100 */
		// 	background: rgb(134, 90, 232); /* Old browsers */
		// 	background: -moz-linear-gradient(
		// 		45deg,
		// 		rgba(134, 90, 232, 1) 0%,
		// 		rgba(42, 162, 228, 1) 100%
		// 	); /* FF3.6-15 */
		// 	background: -webkit-linear-gradient(
		// 		45deg,
		// 		rgba(134, 90, 232, 1) 0%,
		// 		rgba(42, 162, 228, 1) 100%
		// 	); /* Chrome10-25,Safari5.1-6 */
		// 	background: linear-gradient(
		// 		45deg,
		// 		rgba(134, 90, 232, 1) 0%,
		// 		rgba(42, 162, 228, 1) 100%
		// 	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		// 	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#865ae8', endColorstr='#2aa2e4',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
		// }

		img {
			transition: 0.3s ease-in-out;
			height: 17px;
			color: $greyShades-snow;
			transform: rotate(180deg);

			@include NavMini {
				transform: rotate(0deg);
			}

			@include mediaLarge {
				height: 1.1vw;
			}

			.darkMode & {
				height: 13px;

				@include NavMini {
					transform: rotate(0deg);
				}
			}
		}

		@include NavMini {
			img {
				transform: rotate(0deg);

				.darkMode & {
					transform: rotate(0deg);
				}
			}
		}
	}
}

////////////////////////////////////////
///////////////   SLIDE   //////////////
////////////////////////////////////////

@mixin ButtonSlideCall {
	.call {
		@include DebugTool(3, ".call", false);
		position: absolute;
		display: flex;
		justify-content: center;
		right: 20px;
		top: 0;
		z-index: 11;
		cursor: pointer;
		height: 1.9rem;
		width: 1.7rem;
		border-radius: 0 0 0.5rem 0.5rem;

		@include mediaMedium {
			display: none;
		}

		.darkMode & {
			background-color: $accent-blueMetallic;
		}

		.icon {
			path {
				.darkMode & {
					fill: $white;
				}
			}

			&.closeCall path {
				stroke: $white;
				width: 1.75rem;
				height: 1.75rem;

				.darkMode & {
					fill: $dm-b2;
				}
			}
		}
	}
}

@mixin ButtonSlideAction {
	.actions {
		@include DebugTool(3, ".actions");
		padding: 10px;
		background-color: $newPrimaryBg;
		box-shadow: -5px 0 10px $sh1;
		min-height: convertToREM(50);
		box-sizing: border-box;

		.darkMode & {
			background-color: $surfaceBlueSide;
			box-shadow: -5px 0 10px $dm-sh1;
		}

		@include mediaLarge {
			padding: 0.6vw 0.1vw 0.6vw 0.2vw;
		}

		.actions-container {
			display: flex;
			justify-content: space-between;

			.darkMode & {
				color: $lGrey;
			}
		}

		.action-button-container {
			position: relative;

			.actions-btn {
				text-decoration: none;
				cursor: pointer;
				font-size: 15px;
				color: $white;
				white-space: nowrap;

				.darkMode & {
					color: $black;
				}

				@include mediaLarge {
					font-size: clamp(0.8rem, 0.9vw, 1.1rem);
				}

				&.isTrashAction {
					display: flex;
					flex-direction: row-reverse;
					align-items: center;
					text-decoration: none;
					font-size: convertToREM(15);
					font-weight: 600;
					line-height: convertToREM(18);

					svg {
						margin: 0 convertToREM(10) 0 0;
						width: auto;
						height: convertToREM(19);

						* {
							stroke: transparent;

							.darkMode & {
								stroke: transparent;
							}
						}
					}
				}

				svg:not(.pen) {
					height: 1rem;
					width: 0.9rem;
					margin-left: 7px;

					@include mediaLarge {
						height: 0.85vw;
						width: 0.85vw;
						margin-left: 0.5vw;
					}

					path,
					rect {
						fill: $white;
						stroke: none;

						.darkMode & {
							fill: $black;
							stroke: none;
						}
					}
				}
			}

			.underLine-indic {
				position: absolute;
				bottom: -0.5rem;
				height: convertToREM(2);
				width: 100%;
				opacity: 0.3;
				background-color: $actionIndic;

				.darkMode & {
					background-color: $actionIndicDM;
				}

				&.warning-indic {
					background-color: $actionWarnIndic;
				}
			}
		}
	}

	@include Button("actionSlideButtons");
}

////////////////////////////////////////
///////////////   BOARD   //////////////
////////////////////////////////////////

@mixin ButtonBoardAction {
	.actionBtn {
		@include DebugTool(3, ".actionBtn", false);
		position: absolute;
		right: 30px;
		bottom: 30px;
		z-index: var(--actionBtnIndex, 100);

		@include mediaLarge {
			right: 1.5vw;
			bottom: 1.5vw;
		}

		.actionList {
			position: absolute;
			bottom: 150px;
			right: -30px;
			transform: rotate(90deg);
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			transition: $t1;
			z-index: -1;
			opacity: 0;
			visibility: hidden;
			pointer-events: none;

			> div {
				cursor: pointer;
				background-color: $bg-lGrey;
				border: 2px solid $white;
				border-radius: 5px;
				padding: 15px;
				overflow: hidden;
				white-space: nowrap;
				margin-bottom: 15px;
				width: auto;
				transition: $t1;
				position: relative;
				bottom: 0;
				right: 0;
				box-shadow: 1px 3px 5px $sh1;

				.darkMode & {
					color: $ciment;
					background-color: $dm-b3;
					border: 2px solid $dm-b5;
					box-shadow: 0 0 10px $dm-sh0;
				}

				@include mediaLarge {
					padding: 1.9vh 1.3vw;
					margin-bottom: 1.5vh;
					font-size: clamp(0.8rem, 1vw, 1rem);
				}

				&:hover {
					right: 2px;
					bottom: 5px;
					box-shadow: 3px 5px 7px $sh3;

					.darkMode & {
						color: $lGrey;
						box-shadow: 2px 5px 15px $dm-sh1;
					}

					@include mediaLarge {
						right: 2px;
						bottom: 5px;
						box-shadow: 3px 5px 7px $sh3;
					}
				}
			}
		}

		&.opened {
			.actionList {
				transform: rotate(0);
				right: calc(100% + 25px);
				bottom: 0px;
				z-index: 1;
				opacity: 1;
				visibility: visible;
				pointer-events: initial;

				@include mediaLarge {
					right: calc(100% + 1vh);
					bottom: 0px;
				}
			}

			.btn {
				&::before {
					content: "";
					position: absolute;
					width: 200px;
					height: 150px;
					right: 0;
					bottom: 0;
				}

				&::after {
					content: "";
					position: absolute;
					width: 105%;
					height: 105%;
					right: -5%;
					bottom: -5%;
				}

				> svg {
					transform: rotate(45deg);
				}
			}
		}

		.btn {
			background-color: $bg-blue;
			border-radius: 100%;
			height: 67px;
			width: 67px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			transition: $t1;
			position: relative;
			bottom: 0;
			right: 0;

			@include mediaLarge {
				height: 4.2vw;
				width: 4.2vw;
			}

			.darkMode & {
				background: rgb(132, 57, 206); /* Old browsers */
				background: -moz-linear-gradient(
					45deg,
					rgba(132, 57, 206, 1) 0%,
					rgba(97, 77, 208, 1) 100%
				); /* FF3.6-15 */
				background: -webkit-linear-gradient(
					45deg,
					rgba(132, 57, 206, 1) 0%,
					rgba(97, 77, 208, 1) 100%
				); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(
					45deg,
					rgba(132, 57, 206, 1) 0%,
					rgba(97, 77, 208, 1) 100%
				); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8439ce', endColorstr='#614dd0', GradientType=1); /* IE6-9 fallback on horizontal gradient */
				box-shadow: 0 0 15px $dm-bV1;
			}

			&:hover {
				transform: scale(1.03);
				box-shadow: 4px 7px 13px $sh3;
				bottom: 4px;
				right: 1px;

				.darkMode & {
					box-shadow: 2px 5px 35px $dm-bV2;
				}
			}

			> svg {
				transition: 0.3s ease-in-out;
				height: 38px;
				width: 38px;

				@include mediaLarge {
					height: 2.3vw;
					width: 2.3vw;
				}
			}
		}
	}
}

@mixin ButtonBoardControls {
	> div {
		height: 41px;
		width: 41px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 3px;
		cursor: pointer;
		margin-right: 10px;
		position: relative;
		transition: $t1;
		right: 0;
		bottom: 0;

		@include mediaLarge {
			height: 2.6vw;
			width: 2.6vw;
			border-radius: 0.2vw;
			margin-right: 0.8vw;
		}

		@include mediaMedium {
			height: 41px;
			width: 41px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 3px;
			cursor: pointer;
			margin-right: 10px;
			position: relative;
			transition: $t1;
			right: 0;
			bottom: 0;
		}

		&:hover {
			box-shadow: 1px 7px 12px $sh1;
			bottom: 2px;
			right: 1px;

			.darkMode & {
				box-shadow: 1px 7px 12px $dm-sh0;
			}

			@include mediaMedium {
				box-shadow: 1px 7px 12px $sh1;
				bottom: 2px;
				right: 1px;
			}

			&::after {
				content: none;
				position: absolute;
				bottom: -10px;
				right: -10px;
				height: 100%;
				width: 100%;

				@include mediaMedium {
					content: "";
				}
			}
		}
	}
}

@mixin ButtonBoardFullscreen {
	@include ButtonBoardControls;

	.fullScreen {
		@include DebugTool(3, ".fullScreen", false);
		background: $bg-blue;

		.darkMode & {
			box-shadow: 0 0 35px rgba(66, 143, 229, 0.4);
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#865ae8+0,2aa2e4+100 */
			background: rgb(134, 90, 232); /* Old browsers */
			background: -moz-linear-gradient(
				45deg,
				rgba(134, 90, 232, 1) 0%,
				rgba(42, 162, 228, 1) 100%
			); /* FF3.6-15 */
			background: -webkit-linear-gradient(
				45deg,
				rgba(134, 90, 232, 1) 0%,
				rgba(42, 162, 228, 1) 100%
			); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(
				45deg,
				rgba(134, 90, 232, 1) 0%,
				rgba(42, 162, 228, 1) 100%
			); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#865ae8', endColorstr='#2aa2e4', GradientType=1); /* IE6-9 fallback on horizontal gradient */
		}

		svg {
			height: 25px;

			@include mediaCustom {
				height: 1.4vw;
			}
		}

		path,
		line {
			stroke: $white;
		}

		&.block {
			background: $anthra;

			path,
			line {
				stroke: $lGrey;
			}

			&:hover {
				bottom: 0;
				right: 0;
				box-shadow: unset;
			}
		}

		&:hover {
			.darkMode & {
				box-shadow: 0 3px 45px rgba(66, 143, 229, 0.6);
			}
		}
	}
}

@mixin ButtonBoardDisplayMode {
	@include ButtonBoardControls;

	.listMode {
		@include DebugTool(3, ".listMode", false);
		background-color: $ciment;

		.darkMode & {
			background-color: $dm-b3;
		}

		svg {
			height: 30px;

			@include mediaCustom {
				height: 1.3vw;
			}
		}

		path,
		line {
			fill: $black;

			.darkMode & {
				fill: $ciment;
			}
		}
	}
}

////////////////////////////////////////
///////////////   PopUp   //////////////
////////////////////////////////////////
@mixin ButtonPopUpMini {
	.miniButton {
		display: flex;

		&.col {
			flex-direction: column;
			gap: convertToREM(12);
		}

		.trashButton {
			padding-left: 3rem;
			padding-right: 3rem;

			.validate-button {
				word-break: break-word;
			}
		}

		&.hasBigIcon {
			> button {
				> svg.icon {
					height: convertToREM(20);
					left: convertToREM(17);
				}
			}
		}

		&.customIcon {
			button {
				svg.iconArrow {
					transform: rotate(180deg);
				}
			}
		}

		> button {
			position: relative;
			width: 100%;
			margin: 0;

			> svg {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0.5rem;
				height: 1rem;

				&.iconCross {
					left: 1rem;
				}

				#contourExclam {
					fill: none;
					stroke: $brand-black;
				}

				#exclam,
				circle {
					fill: $brand-black;
				}
			}

			.validateSvg {
				position: absolute;
				left: 10%;
				top: 50%;
				transform: translate(-50%, -50%);
			}

			// &:not(.grey):not(.orange) {
			// 	background-color: $bg-blue-action;
			// }

			&.disableBtn {
				cursor: not-allowed;
			}
		}

		@content;
	}
}

@mixin ButtonPopUpLarge {
	.largeBtn {
		background-color: $lGrey;
		padding: 0.5rem;
		border-radius: 0.2rem;
		margin-bottom: 0.5rem;
		display: flex;
		align-items: center;
		cursor: pointer;
		transition: $t1;
		border: 2px solid $lGrey;

		.darkMode & {
			background-color: $dm-b2;
			border: 2px solid $dm-b2;
		}

		> svg {
			margin: 0 1vw 0 0.4vw;
			max-width: 1.3rem;
			max-height: 1.3rem;

			path {
				.darkMode & {
					fill: $ciment;
					stroke: $ciment;
				}
			}
		}

		p {
			margin: 0;
			width: 100%;
			text-align: center;
		}

		> div {
			display: flex;
			flex-direction: column;

			div.title {
				display: flex;

				> b {
					font-size: clamp(0.8rem, 0.95vw, 1rem);

					.darkMode & {
						color: $ciment;
					}
				}

				> small {
					font-size: clamp(0.6rem, 0.75vw, 0.8rem);
					margin-left: auto;
					color: $anthra;

					.darkMode & {
						color: $dm-lBlue;
					}
				}
			}

			span {
				font-size: clamp(0.7rem, 0.85vw, 0.9rem);
				font-weight: 500;
				margin: 0;
				margin-top: 0.3vh;
				color: $anthra;

				.darkMode & {
					color: $grey;
				}
			}
		}

		&:not(.disabled):hover {
			border: 2px solid $bg-blue;

			.darkMode & {
				background-color: $dm-lBlue;
				border: 2px solid $dm-lBlue;
				color: $white;
			}

			> svg path {
				fill: $white;
				stroke: $white;
			}

			> div {
				div.title {
					> b {
						.darkMode & {
							color: $white;
						}
					}

					> small {
						.darkMode & {
							color: $white;
						}
					}
				}

				span {
					.darkMode & {
						color: $white;
					}
				}
			}
		}

		&.disabled {
			opacity: 50%;
			cursor: default;
		}
	}
}
