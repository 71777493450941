@use "../utils/variables" as *;

@mixin licenseWarnMsg {
	.licenseWarnMsg {
		.darkMode & {
			border: none;
		}

		.header {
			position: relative;
			margin-bottom: 2rem;
			border-top-right-radius: 3px;
			border-top-left-radius: 3px;
			overflow: hidden;

			.bg {
				max-height: 7rem;
				overflow: hidden;
				background: rgb(28, 31, 93);
				background: linear-gradient(
					45deg,
					rgba(28, 31, 93, 1) 0%,
					rgba(28, 31, 93, 1) 50%,
					rgba(55, 60, 154, 1) 100%
				);

				svg {
					width: 100%;
					height: auto;
				}
			}

			.over {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				backdrop-filter: blur(3px);

				.darkMode & {
					height: 100%;
				}
			}

			.ls {
				position: absolute;
				bottom: -0.3rem;
				left: 1.7rem;
				background-color: $white;
				padding: 0.5rem 0.7rem 0.3rem;
				border-top-left-radius: 3px;
				border-top-right-radius: 3px;

				.darkMode & {
					background-color: $dm-b2;
					padding: 0.7rem 0.7rem 0.4rem;
				}

				svg {
					width: auto;
					height: clamp(2rem, 2.7vw, 3rem);

					.darkMode & {
						height: 2.2rem;
					}

					path {
						.darkMode & {
							fill: $white;
						}
					}
				}
			}
		}

		.body {
			padding: 1rem 1.3rem 0;
			font-weight: 500;
			color: $black;

			.body-title {
				font-size: clamp(1.2rem, 1.4vw, 1.4rem);
				margin: 0.7rem 0 0.3rem;
				color: $black;
				text-transform: unset;
				font-weight: 600;

				.darkMode & {
					color: $lGrey;
				}
			}

			.body-text {
				color: $anthra;
				font-size: clamp(0.85rem, 1vw, 1rem);
				line-height: 140%;
				margin: 0;

				.darkMode & {
					color: $ciment;
				}
			}

			.plan {
				margin-top: 2rem;
				position: relative;
				padding-left: 10%;

				&::before {
					position: absolute;
					left: 3%;
					content: "";
					height: 80%;
					width: 2px;
					background-image: linear-gradient(
						$ciment 33%,
						rgba(255, 255, 255, 0) 0%
					);
					background-position: right;
					background-size: 3px 0.8rem;
					background-repeat: repeat-y;

					.darkMode & {
						background-image: linear-gradient(
							$dm-lBlue 33%,
							rgba(255, 255, 255, 0) 0%
						);
					}
				}

				.plan-text {
					margin-bottom: 2rem;
					display: flex;
					flex-direction: column;

					b {
						margin-bottom: 0.3rem;
						font-size: clamp(0.9rem, 1.05vw, 1.1rem);

						.darkMode & {
							color: $ciment;
						}
					}

					p {
						margin: 0;
						color: $anthra;
						font-size: clamp(0.8rem, 0.95vw, 0.95rem);
						line-height: 130%;

						.darkMode & {
							color: $grey;
						}
					}
				}
			}

			.gift {
				display: flex;
				align-items: center;
				margin-top: 3rem;
				margin-bottom: 1rem;

				svg {
					margin-right: 1.2rem;
					margin-left: 0.2rem;
					width: 5rem;
					height: auto;
				}

				.gift-content {
					display: flex;
					flex-direction: column;

					.gift-content-title {
						color: $blue;
						margin-bottom: 0.4rem;
						font-size: 0.9rem;
						line-height: 130%;

						.darkMode & {
							color: $dm-lBlue;
						}
					}

					span {
						font-size: 0.8rem;
						color: $anthra;
						font-weight: 500;

						.darkMode & {
							color: $ciment;
						}
					}
				}
			}
		}

		.buttons {
			padding: 1.3rem 0.8rem;

			button {
				width: 100%;
			}

			.adminShouldCo {
				text-align: center;
				padding: 0 10% 0.5rem;
				color: $grey;
				font-weight: 500;
				font-size: clamp(0.8rem, 1vw, 1rem);
				line-height: 140%;
				box-sizing: border-box;

				.darkMode & {
					color: $ciment;
				}
			}
		}
	}
}
