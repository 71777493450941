@use "../../utils/variables" as *;

@mixin LoadingButton($hasLoader) {
	position: relative;
	color: $brand-black;
	background: repeating-linear-gradient(
		45deg,
		rgb(252, 205, 48, 0.7),
		rgb(252, 205, 48, 0.7) 1.25rem,
		rgb(252, 205, 48) 1.25rem,
		rgb(252, 205, 48) 2.5rem
	);

	.darkMode & {
		background: repeating-linear-gradient(
			45deg,
			rgb(252, 205, 48, 0.9),
			rgb(252, 205, 48, 0.9) 1.25rem,
			rgb(252, 205, 48) 1.25rem,
			rgb(252, 205, 48) 2.5rem
		);
	}

	&:hover {
		background: repeating-linear-gradient(
			45deg,
			rgb(252, 205, 48, 0.9),
			rgb(252, 205, 48, 0.9) 1.25rem,
			rgb(252, 205, 48) 1.25rem,
			rgb(252, 205, 48) 2.5rem
		);
	}

	&:disabled {
		color: $greyShades-roseGrey;
		background: repeating-linear-gradient(
			45deg,
			transparentize($greyShades-snow, 0.3),
			transparentize($greyShades-snow, 0.3) 1.25rem,
			transparentize($greyShades-roseGrey, 0.85) 1.25rem,
			transparentize($greyShades-roseGrey, 0.85) 2.5rem
		);

		.iconWarning {
			#exclam,
			circle {
				fill: $greyShades-matSilver;

				.darkMode & {
					fill: $greyShades-matSilver;
				}
			}

			#contourExclam {
				stroke: $greyShades-matSilver;
			}
		}
	}

	.icon {
		path {
			fill: $black;
		}
	}

	.iconWarning {
		width: 1.5rem;
		height: 1.5rem;
		opacity: 1;
		transition: opacity 0.2s ease-in-out;

		.background {
			display: none;
		}

		#contourExclam {
			fill: transparent;
		}

		#exclam,
		circle {
			fill: $brand-black;

			.darkMode & {
				fill: $brand-black;
			}
		}
	}

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: all 0.2s ease-in-out;
	}

	&.is-loading {
		.icon-warning {
			opacity: 0;
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			backdrop-filter: blur(7px);
			border-radius: 0.313rem;
		}
	}

	&:not(:disabled) {
		.button-text {
			@if $hasLoader {
				&:after {
					content: "";
					position: absolute;
					left: -20px;
					top: -40%;
					width: 110%;
					height: 180%;
					background-color: $white;
					z-index: -1;
					border-radius: 0.313rem;
					animation: 3s linear loadingButton forwards;
				}
			}
		}
	}

	.button-text {
		position: relative;
		z-index: 10;
	}
}

@keyframes loadingButton {
	0% {
		width: 100%;
		opacity: 1;
	}

	90% {
		width: 0.625rem;
		opacity: 1;
	}

	100% {
		opacity: 0;
		width: 0.625rem;
	}
}
