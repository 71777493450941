@use "../utils/variables" as *;
@use "../utils/mixins" as *;

@mixin tabs {
	.tabs {
		transition: 0.3s ease-in-out;

		.tabsHead {
			display: flex;
			justify-content: space-around;
			padding: 15px 5px;
			margin-top: 10px;
			margin-bottom: 15px;

			@include mediaLarge {
				padding: 1.3vh 0.4vw;
				margin-top: 1vh;
				margin-bottom: 1vh;
			}

			.tabsHeadChild {
				cursor: pointer;
				font-size: 18px;
				color: $grey;
				font-weight: 500;
				position: relative;
				transition: 0.3s ease-in-out;

				@include mediaLarge {
					font-size: clamp(0.6rem, 1.1vw, 1rem);
				}

				.darkMode & {
					color: $grey;
				}

				&::after {
					content: "";
					transition: 0.5s ease-in-out;
					position: absolute;
					bottom: -13px;
					left: 12.5%;
					background-color: $grey;
					opacity: 0;
					width: 75%;
					height: 2px;
					border-radius: 2px;
				}

				&.active {
					color: $black;
					font-weight: 600;

					.darkMode & {
						color: $lGrey;
						font-weight: 600;
					}

					&::after {
						opacity: 1;
						background-color: $orange;

						.darkMode & {
							box-shadow: 0 0 15px $dm-oL;
						}
					}
				}

				&.disabled {
					display: flex;
					justify-content: space-around;
					cursor: default;

					.tooltipHelper {
						margin-right: 0.5rem;

						g {
							path {
								fill: $grey;

								.darkMode & {
									fill: $greyShades-matSilver;
								}
							}
						}
					}
				}
			}
		}

		.tabsBody {
			display: flex;
			padding-top: 30px;

			@include mediaLarge {
				padding-top: 1vh;
			}

			.tabsBody-child {
				overflow: hidden;
				position: relative;

				animation-name: transition;
				animation-duration: 0.3s;
				animation-timing-function: ease-in;
				animation-direction: reverse;
				animation-fill-mode: forwards;

				&.active {
					animation-name: transition2;
					animation-direction: normal;
				}
			}
		}

		@content;
	}
}
