@use "../../../utils/variables" as *;
@use "../../../utils/mixins" as *;
@use "./helpers" as *;

@mixin detection {
	#Detection {
		margin: 0;
		display: flex;
		flex-direction: column;
		width: 100%;

		.full {
			.siteInfos {
				display: flex;
				flex-direction: column;
				align-items: center;
				row-gap: 0.4rem;
				color: $extTextColor1;

				.favIcon {
					width: 30px;
					height: 30px;
					position: relative;
					border-radius: 0.2rem;

					img {
						height: 100%;
						width: 100%;
						position: unset;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
					}
				}

				.link {
					font-size: 0.6rem;
					font-weight: 500;
					color: $extTextColor2;
					max-width: 80%;

					@include ellipsisHelperPopup;
				}

				.title {
					margin: 0;
					text-transform: capitalize;
					color: $extTextColor1;
					max-width: 90%;

					@include ellipsisHelperPopup;
				}
			}

			.warnMessage {
				display: flex;
				align-items: center;
				margin-top: 1rem;

				svg {
					width: 1.2rem;
					height: 1.2rem;
					flex-shrink: 0;
				}

				p {
					font-size: 0.55rem;
					margin-left: 0.7rem;
				}
			}

			.toggleContainer {
				display: flex;
				flex-direction: column;
				row-gap: 0.5rem;
				margin-top: 1rem;

				.toggleSwitch {
					$toggleWidth: 1.8rem;
					$toggleSize: 0.75rem;
					$toggleSizeActive: 0.2 * $toggleSize + $toggleSize;
					$toggleMargin: 0.15rem;

					display: flex;
					padding: 0.6rem 0.3rem;
					align-items: center;
					border-radius: 0.3rem;
					cursor: pointer;
					-webkit-tap-highlight-color: transparent;

					.textContainer {
						display: flex;
						flex-direction: column;
						justify-content: center;

						h3 {
							color: $extTextColor1;
							font-size: 0.85rem;
							font-weight: 600;
							margin: 0 0 0.2rem;
						}

						p {
							color: $anthra;
							font-size: 0.6rem;
							line-height: 120%;
							font-weight: 500;
						}
					}

					i {
						position: relative;
						display: inline-block;
						margin-right: 1rem;
						width: $toggleWidth;
						height: calc(2 * $toggleMargin + $toggleSize);
						background-color: $extBgColor2;
						border-radius: 23px;
						vertical-align: text-bottom;
						transition: all 0.2s linear reverse;
						flex-shrink: 0;

						&::after {
							content: "";
							position: absolute;
							left: 0;
							width: $toggleSize;
							height: $toggleSize;
							background-color: $white;
							border-radius: $toggleSize;
							transform: translate3d(
								$toggleMargin,
								$toggleMargin,
								0
							);
							will-change: transform;
							transition: all 0.2s ease-in-out reverse;
						}
					}

					&:active {
						i::after {
							width: $toggleSizeActive;
						}

						input:checked + i::after {
							transform: translate3d(
								calc(
									$toggleWidth - $toggleSizeActive -
										$toggleMargin
								),
								$toggleMargin,
								0
							);
						}
					}

					input {
						width: 0px;
						opacity: 0;
						@include accessibility(" ~ i");

						&:checked + i {
							background-color: $extToggleColor;

							&::after {
								transform: translate3d(
									calc(
										$toggleWidth - $toggleSize -
											$toggleMargin
									),
									$toggleMargin,
									0
								);
							}
						}
					}

					&.important {
						.darkMode & {
							background: $extBgColor4;
						}

						.textContainer {
							h3,
							p {
								.darkMode & {
									color: $white;
								}
							}
						}
					}

					&.focus {
						will-change: transform;
						animation: focusAnimation 1s 2 forwards;

						i {
							&::after {
								background-color: #fff;
								animation: focusColorAnimation 1s 2 forwards;
							}
						}
					}

					@keyframes focusAnimation {
						0% {
							box-shadow: 0px 0px 0px 2px
								rgba($extAccessibilityShadow, 0);
						}
						40% {
							box-shadow: 0px 0px 0px 4px
								rgba($extAccessibilityShadow, 0.18);
						}
						80% {
							box-shadow: 0px 0px 0px 6px
								rgba($extAccessibilityShadow, 0);
						}
					}

					@keyframes focusColorAnimation {
						0% {
							background-color: $white;
						}
						40% {
							background-color: $extBgColor2;
						}
						80% {
							background-color: $white;
						}
					}
				}
			}

			.resetContainer {
				margin-top: 2rem;
				padding: 0 0.3rem;

				button.primary {
					width: 100%;

					@include popupBtn;

					padding: 0.6rem 1rem;
					position: relative;
					justify-content: center;

					svg {
						position: absolute;
						left: 1rem;
					}
				}

				p {
					margin-top: 0.7rem;
					color: $anthra;
					line-height: 120%;
					font-size: 0.6rem;
					font-weight: 500;
				}
			}

			.resetAll {
				margin: 2rem 0.3rem 0;
				font-size: 0.7rem;
				color: $grey;
				cursor: pointer;
			}
		}
	}
}
