@use "../utils/variables" as *;
@use "./getHiddenList" as *;

@mixin GetRecoveryMFA {
	.GetRecoveryMFA {
		.contentContainer {
			display: grid;
			grid-template-columns: 100%;
			gap: 1.5rem;

			.warningParagraph {
				position: relative;
				background-color: $greyShades-snow;
				padding: 0 0.9rem;
				border-radius: 0.18rem;

				.darkMode & {
					background-color: $dm-b4;

					.warningText {
						color: $greyShades-white;
					}
				}

				.warningText {
					color: $brand-black;
					font-family: Montserrat;
					font-size: 0.9rem;
					font-weight: 500;
				}

				.warnSign {
					position: absolute;
					top: -0.75rem;
					left: calc(50% - 0.75rem);
					width: 1.5rem;
					height: 1.5rem;
				}
			}

			@include GetHiddenList;

			.submitButton {
				width: 100%;
				padding-left: 3rem;
				overflow: hidden;
				white-space: break-spaces;
				transition:
					background-color 0.5s ease-in-out,
					color 0.5s ease-in-out;

				&.disabled {
					background-color: $greyShades-snow;
					color: $greyShades-roseGrey;

					.icon {
						* {
							fill: $greyShades-roseGrey;
						}
					}

					.darkMode & {
						background-color: $dm-b3;
						color: $greyShades-white;

						.icon {
							* {
								fill: $greyShades-white;
							}
						}
					}
				}

				.icon {
					left: 1.02rem;
					top: calc(50% - 0.63rem);
					height: 1.26rem;
					width: 1.26rem;
					transition: fill 0.5s ease-in-out;
				}
			}
		}
	}
}
