@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;

@mixin MouseFollowerHelper {
	z-index: 999;
	position: fixed;
	top: 50%;
	left: 50%;

	.mouseFollower-container {
		height: 0;
		width: 0;
		min-height: 2.5rem;
		min-width: 2.5rem;
		position: absolute;
		background-size: contain;
		background-position: left top;
		background-repeat: no-repeat;
		filter: blur(2px);

		.darkMode & {
			filter: blur(4px);
			opacity: 0.2;
		}
	}

	@content;
}
