@use "../utils/" as *;

@mixin PasswordStrength {
	.passwordStrength {
		&.strength {
			display: flex;
			align-items: center;
			gap: 0.7rem;

			.strength-message {
				color: #737390;
			}

			.intensity-mark {
				display: flex;
				gap: 0.3rem;

				.mark {
					position: relative;
					width: 1.8rem;
					height: 0.313rem;
					border-radius: 0.313rem;
					background-color: $matSilver;

					.darkMode & {
						background-color: $dm-b4;
					}

					&.low {
						background-color: $yellow;

						.darkMode & {
							box-shadow: 0px 1px 0.625rem rgba(252, 205, 48, 0.5);
						}
					}

					&.medium {
						background-color: $blueShade;

						.darkMode & {
							background-color: $lBlue;
							box-shadow: 0px 1px 0.813rem rgba(81, 159, 222, 0.5);
						}
					}

					&.high {
						background-color: $green;

						.darkMode & {
							box-shadow: 0px 1px 0.625rem rgba(12, 243, 128, 0.5);
						}
					}
				}
			}
		}

		@content;
	}
}
