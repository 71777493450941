@use "../utils/variables" as *;
@use "../utils/mixins" as *;

@mixin forgot {
	.forgot,
	.connexionStd {
		> *:not(.connexionStd) {
			justify-content: space-between;
		}

		.includeShowPass {
			position: relative;

			input {
				box-sizing: border-box;
				padding-right: 3rem;
				width: 100%;

				&::placeholder {
					.darkMode & {
						color: $matSilver;
					}
				}
			}

			span {
				cursor: pointer;
				position: absolute;
				top: 40%;
				right: 1rem;
				margin: 0;
				transform: translate(0, -50%);

				@include mediaLarge {
					top: 40%;
				}
			}

			svg g {
				path,
				rect {
					fill: $ciment;
				}
			}
		}

		.buttons {
			margin-top: 2rem;
			margin-bottom: 1rem;
		}

		label {
			font-size: 13px;
			color: $black;
			font-weight: 500;
			margin-bottom: 2vh;

			.darkMode & {
				color: $ciment !important;
			}

			@include mediaLarge {
				font-size: clamp(0.6rem, 0.7vw, 0.9rem);
				margin-bottom: 2vh;
			}
		}

		.securityIndic {
			margin-top: 1vw;

			@include mediaLarge {
				margin-top: 1vw;
			}

			.securityIndic-strength {
				font-size: 14px;

				@include mediaLarge {
					font-size: clamp(0.7rem, 1vw, 1rem);
				}

				.darkMode & {
					color: $ciment;
				}
			}

			.securityIndic-symbol {
				display: flex;
				width: 70%;
				padding-top: 2.5vh;

				span {
					flex: 1;
					height: 5px;
					border-radius: 5px;
					background-color: $ciment;
					margin-right: 10px;

					@include mediaLarge {
						height: 0.8vh;
						margin-right: 1vw;
					}
				}

				.down {
					background-color: $indicators-warning;
				}

				.mid {
					background-color: $blueShades-smokedSky;
				}

				.top {
					background-color: $indicator-success;
				}
			}
		}
	}

	@content;
}
