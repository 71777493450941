@use "../../components/toggler/toggler" as *;
@use "../../components/inputs/numInput" as *;
@use "../../components/passwordStrength" as *;
@use "../../components/ppComposition" as *;
@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;
@use "../../components/inputs/newInput" as *;
@use "../../components/ppComposition" as *;

@mixin AddPP() {
	.ppForm-component {
		&.editComponent {
			position: absolute;
			z-index: 10000;
			right: -24.44rem;
			top: 0;

			@include mediaMaxCustom(1200px) {
				right: -20.8rem;
			}

			.container {
				background-color: $white;
				padding: 1.25rem;
				width: 20.25rem;
				max-height: 80vh;
				overflow-y: auto;
				border-radius: 0.25rem;

				@include mediaMaxCustom(1200px) {
					max-width: 16.68rem;
				}

				.darkMode & {
					background-color: $dm-b2;
				}

				@include PpComposition;

				.title {
					margin-bottom: 0.94rem;

					.darkMode & {
						color: $white;
					}

					.ppNameEdit {
						width: 100%;

						.nameContainer {
							display: flex;
							justify-content: space-between;
							align-items: center;
							gap: 0.6rem;

							.iconTrash {
								min-width: 1.31rem;
								min-height: 1.31rem;
								width: 1.31rem;
								height: 1.31rem;
								gap: 0.6rem;
								cursor: pointer;

								@include mediaMaxCustom(1200px) {
									width: 1rem;
									height: 1rem;
								}

								#traits {
									path {
										.darkMode & {
											stroke: $dm-b2;
										}
									}
								}
								path {
									fill: $greyShades-wetGrey;
								}
							}
						}

						.name {
							display: flex;
							align-items: center;
							gap: 0.63rem;

							.content-text {
								text-overflow: ellipsis;
								white-space: nowrap;
								word-break: break-word;
								max-height: 22px;
								overflow: hidden;
							}

							@include mediaMaxCustom(1200px) {
								font-size: 1rem;
							}
						}

						.inputFrame {
							margin: 0;
						}

						.icon {
							width: 1.1rem;
							height: 1.1rem;
							min-width: 1.1rem;
							min-height: 1.1rem;
							cursor: pointer;

							@include mediaMaxCustom(1200px) {
								width: 1rem;
								height: 1rem;
							}

							path {
								fill: $greyShades-grey;
							}
						}
					}
				}

				.tagsContainer {
					display: flex;
					gap: 0.44rem;

					.tag {
						padding: 0.25rem 0.5rem;
						background-color: $greyShades-snow;
						border-radius: 6.25rem;
						font-size: 0.75rem;
						display: flex;
						align-items: center;
						gap: 0.3rem;

						@include mediaMaxCustom(1200px) {
							font-size: 0.56rem;
							padding: 0.1rem 0.37rem;
						}

						.darkMode & {
							background-color: $dm-b3;
							color: $white;
						}

						.text {
							margin-top: 0.1rem;
						}

						.icon {
							width: 0.55rem;
							height: 0.55rem;

							&.iconClose {
								min-width: 0.55rem;
								min-height: 0.55rem;

								@include mediaMaxCustom(1200px) {
									width: 0.3rem;
									height: 0.3rem;
								}

								.darkMode & {
									path {
										fill: $white;
									}
								}
							}

							@include mediaMaxCustom(1200px) {
								width: 0.56rem;
								height: 0.56rem;
							}
						}
					}
				}

				.noModif {
					color: $greyShades-wetGrey;
					font-size: 0.75rem;
					font-weight: 500;
					margin: 0;
					text-align: center;

					.darkMode & {
						color: $greyShades-grey;
					}
				}
			}
		}

		.title {
			display: flex;
			align-items: center;
			gap: 0.62rem;
			margin: 0 0 1.87rem;
			font-weight: 600;
			font-size: 1.3rem;

			.icon {
				width: 1.3125rem;
				height: 1.3125rem;

				path {
					fill: $new-lp;
				}
			}

			.validateContainer {
				.icon {
					path {
						fill: $greyShades-grey;
					}
				}
			}
		}

		@include TogglerFrame() {
			&:not(:last-child) {
				margin-bottom: 1.25rem;

				@include mediaMaxCustom(1200px) {
					margin-bottom: 1rem;
				}
			}
		}

		@include NewInput;
	}
}
