@use "../../../utils/" as *;

@mixin TrashPassRestorationSelection {
	.categorySelectionContainer {
		margin-bottom: convertToREM(25);
		padding: convertToREM(15) convertToREM(15) convertToREM(15)
			convertToREM(9);
		display: grid;
		grid-template-columns: convertToREM(30) minmax(0, 1fr);
		align-items: start;
		column-gap: convertToREM(7);
		border-left: solid convertToREM(4) $indicators-error;
		border-radius: convertToREM(3);
		background-color: $greyShades-snow;

		.darkMode & {
			background-color: $dm-b4;
		}

		.iconWarningContainer {
			display: grid;
			place-items: center;

			.icon {
				width: convertToREM(30);
				height: auto;

				#Signals > path {
					.darkMode & {
						fill: $greyShades-roseSnow;
					}
				}
			}
		}

		.categorySelection {
			display: grid;
			grid-template-columns: minmax(0, 1fr);
			row-gap: convertToREM(20);

			.message {
				margin: 0;
				font-size: convertToREM(15);
				line-height: convertToREM(18);
			}

			.categorySelectionInputContainer {
				display: grid;
				grid-template-columns: minmax(0, 1fr);
				justify-items: start;
				row-gap: convertToREM(3);

				.inputLabel {
					color: $black;
					font-size: convertToREM(13);
					font-weight: 600;
					line-height: convertToREM(16);

					.darkMode & {
						color: $greyShades-matSilver;
					}
				}

				.input {
					-webkit-appearance: none;
					appearance: none;
					justify-self: stretch;
					padding: convertToREM(16) convertToREM(15);
					border: solid convertToREM(1.5) $greyShades-matSilver;
					border-radius: convertToREM(5);
					color: $black;
					font-family: $mon;
					font-size: convertToREM(15);
					font-weight: 500;
					line-height: convertToREM(18);
					outline: none;

					.darkMode & {
						color: $greyShades-matSilver;
						background-color: $dm-b3;
					}
				}
			}

			.categorySelectionChoiceButtonsContainer {
				display: grid;
				grid-template-columns: repeat(2, minmax(0, 1fr));
				column-gap: convertToREM(8);

				.choiceButtonContainer {
					position: relative;
					display: grid;
					grid-template-columns: minmax(0, 1fr);
					grid-template-rows: minmax(0, 1fr);

					&:not(.isDisabled, .isActive):hover {
						.button {
							cursor: pointer;
						}

						.choiceButton {
							background-color: $greyShades-snow;

							.darkMode & {
								background-color: $dm-b3;
							}

							.iconContainer {
								.icon {
									* {
										.darkMode & {
											fill: $greyShades-white;
										}
									}
								}
							}

							.choiceName {
								.darkMode & {
									color: $greyShades-white;
								}
							}
						}
					}

					&.isActive {
						.choiceButton {
							background-color: $actionBlue;

							.darkMode & {
								background-color: $purpleShades-purple;
							}

							.iconContainer {
								.icon {
									* {
										fill: $greyShades-white;
									}
								}
							}

							.choiceName {
								color: $greyShades-white;
							}
						}
					}

					&.isDisabled {
						.choiceButton {
							background-color: $greyShades-snow;

							.darkMode & {
								background-color: $dm-b3;
							}

							.iconContainer {
								.icon {
									* {
										fill: $greyShades-wetGrey;
									}
								}
							}
						}
					}

					.button {
						@include resetBtn;
						position: relative;
						z-index: 20;
						grid-column: 1 / 2;
						grid-row: 1 / 2;
						cursor: default;
					}

					.choiceButton {
						position: relative;
						z-index: 10;
						grid-column: 1 / 2;
						grid-row: 1 / 2;
						display: grid;
						grid-template-columns: minmax(0, 1fr);
						justify-items: center;
						row-gap: convertToREM(7);
						padding: convertToREM(11);
						border-radius: convertToREM(4);
						background-color: $greyShades-white;
						transition-duration: 0.3s;

						.darkMode & {
							background-color: $dm-b3;
						}

						.iconContainer {
							height: convertToREM(12);
							display: grid;
							place-items: center;

							.icon {
								width: auto;
								height: 100%;

								* {
									fill: $greyShades-roseGrey;
									transition-duration: 0.3s;
								}
							}
						}

						.choiceName {
							margin: 0;
							color: $greyShades-grey;
							font-size: convertToREM(11);
							font-weight: 500;
							line-height: convertToREM(13);
							transition-duration: 0.3s;
						}
					}
				}
			}
		}
	}
}
