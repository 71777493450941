@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;
@use "../../components/input" as *;
@use "../../components" as *;
@use "../../components/tabs" as *;
@use "../../components/forgot" as *;
@use "../../components/select" as *;
@use "../../components/newSwitch" as *;

@mixin extLoginContent {
	.extLogin {
		display: flex;
		flex-direction: column;
		position: relative;
		min-height: 554px;
		min-width: 320px;
		overflow: hidden;

		div.logo {
			width: 40vw;
			text-align: center;
			margin-top: 3vh;

			.darkMode & {
				background-color: $dm-log1;
			}

			svg {
				width: 60%;
				height: auto;
				margin-top: 0.5rem;

				path {
					.darkMode & {
						fill: $white;
					}
				}
			}

			h1 {
				width: max-content;
				font-size: 1.4rem;
				margin-left: 1.6rem;

				.darkMode & {
					color: $anthra;
				}
			}

			b {
				.darkMode & {
					color: $ciment;
				}
			}
		}

		.watermark {
			display: none;
		}

		.frame {
			flex: 2;
			display: flex;
			flex-direction: column;

			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,eaebf2+100&1+0,0.19+100 */
			background: -moz-linear-gradient(
				top,
				rgba(255, 255, 255, 1) 0%,
				rgba(234, 235, 242, 0.19) 100%
			); /* FF3.6-15 */
			background: -webkit-linear-gradient(
				top,
				rgba(255, 255, 255, 1) 0%,
				rgba(234, 235, 242, 0.19) 100%
			); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(
				to bottom,
				rgba(255, 255, 255, 1) 0%,
				rgba(234, 235, 242, 0.19) 100%
			); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#30eaebf2',GradientType=0 ); /* IE6-9 */

			h1 {
				margin: none;
				text-transform: uppercase;
				font-size: 0.938rem;
				// margin-left: 14vw;
				padding-left: 2.5rem !important;
				font-weight: 500;
				color: $grey;

				b {
					font-weight: 500;
					color: $black;

					.darkMode & {
						color: $ciment;
					}
				}
			}
		}

		.darkMode & {
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#28282e+0,1a1a1d+100 */
			background: rgb(40, 40, 46); /* Old browsers */
			background: -moz-linear-gradient(
				top,
				rgba(40, 40, 46, 1) 0%,
				rgba(26, 26, 29, 1) 100%
			); /* FF3.6-15 */
			background: -webkit-linear-gradient(
				top,
				rgba(40, 40, 46, 1) 0%,
				rgba(26, 26, 29, 1) 100%
			); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(
				to bottom,
				rgba(40, 40, 46, 1) 0%,
				rgba(26, 26, 29, 1) 100%
			); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#28282e', endColorstr='#1a1a1d',GradientType=0 ); /* IE6-9 */
		}

		@include lockerFrame() {
			.lockerFrame {
				position: absolute;
				left: 0;
				top: 5vh;
				width: 100%;
				height: 100%;
				z-index: 10;
				flex: 2;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.locker {
					background-color: $white;
					width: 15.625rem;
					border: 0.125rem solid $lGrey;
					box-shadow: 0 0 25px $sh1;
					border-radius: 5px;
					padding: 25px 20px;
					position: relative;

					.extHead {
						display: flex;
						justify-content: space-between;
						margin-bottom: 15px;

						h2 {
							color: $black;
							font-size: 16px;
							font-weight: 600;
							margin: 0;
							text-transform: uppercase;

							.darkMode & {
								color: $ciment;
							}
						}

						&.forgotTitle {
							h2 {
								font-size: 15px;

								.darkMode & {
									color: $ciment;
								}
							}

							span {
								svg {
									width: 0.8rem;
								}

								.darkMode & {
									svg path {
										fill: $ciment;
									}
								}
							}
						}

						.options {
							cursor: pointer;

							svg {
								height: 20px;
								transition: 0.6s ease;
							}

							svg path {
								.darkMode & {
									fill: $ciment;
								}
							}

							&.active {
								svg path {
									fill: $black;

									.darkMode & {
										fill: $dm-green1;
									}
								}
							}
						}

						&.pin {
							font-size: 12px;
							font-weight: 500;

							.head-action {
								color: $anthra;
								cursor: pointer;
								margin-right: 1rem;
								white-space: nowrap;

								.darkMode & {
									color: $dm-red1;
									text-shadow: 0 0 15px $dm-rL;
								}

								svg {
									height: 8px;
									transform: rotate(180deg);

									path {
										fill: $anthra;

										.darkMode & {
											stroke: $dm-red1;
										}
									}
								}
							}

							.head-title {
								color: $black;
								overflow: hidden;
								text-overflow: ellipsis;
								max-width: 64%;

								.darkMode & {
									color: $ciment;
								}
							}
						}
					}

					.extForgot {
						margin-bottom: 0;
						margin-top: 7vh;

						input {
							margin-bottom: 30px;
							border: none;
							border-radius: 5px;
							background-color: $bg-lGrey;
							padding-left: 1rem;
							font-size: 12px;
							font-weight: 500;
							letter-spacing: 0.02em;
						}

						.buttons {
							button {
								font-size: 0.8rem;
								width: 100%;
								padding: 0.6rem;
								margin-bottom: 2rem;

								&.withIcon {
									> svg {
										height: auto;
										width: 0.6rem;
										position: absolute;
										left: 0.7rem;
									}
								}
							}
						}
					}

					@include tabs() {
						.extLoginTabsHead {
							display: flex;
							justify-content: space-around;
							padding: 15px 1.563rem;
							margin-top: 10px;
							margin-bottom: 8vh;

							.tabsHead-child {
								cursor: pointer;
								font-size: 13px;
								color: $grey;
								font-weight: 500;
								position: relative;
								transition: 0.3s ease-in-out;

								&::after {
									content: "";
									transition: 0.5s ease-in-out;
									position: absolute;
									bottom: -13px;
									left: 12.5%;
									background-color: $grey;
									opacity: 0;
									width: 75%;
									height: 2px;
									border-radius: 2px;
								}

								&.active {
									color: $black;
									font-weight: 600;

									.darkMode & {
										color: $white;
									}

									&::after {
										opacity: 1;
										background-color: $orange;

										.darkMode & {
											background-color: $dm-green1;
											box-shadow: 0 0 25px $dm-gL;
										}
									}
								}
							}
						}

						.extTabsBody {
							display: flex;
							max-height: 11rem;

							.tabsBody-child {
								overflow: hidden;
								position: relative;

								animation-name: transition;
								animation-duration: 0.3s;
								animation-timing-function: ease-in;
								animation-direction: reverse;
								animation-fill-mode: forwards;

								&.active {
									animation-name: transition2;
									animation-direction: normal;
								}
							}
						}

						form {
							display: flex;
							flex-direction: column;
							min-height: 90%;
							box-sizing: border-box;

							input {
								margin-bottom: 15px;
								border: none;
								border-radius: 5px;
								background-color: $bg-lGrey;
								padding-left: 1rem;
								font-size: 12px;
								font-weight: 500;
								letter-spacing: 0.02em;

								&.noBotM {
									margin-bottom: 0;
								}

								&:focus {
									outline: none;
								}

								.darkMode & {
									background-color: $dm-b4;
									color: $ciment;
									font-weight: 500;
								}
							}

							@include forgot() {
								.includeShowPass {
									input {
										&::placeholder {
											.darkMode & {
												color: $matSilver;
											}
										}
									}

									svg {
										width: 17px;
										g {
											path,
											rect {
												fill: $ciment;
											}
										}
									}
								}
							}
						}
					}

					.buttons {
						button {
							font-size: 0.8rem;
							width: 100%;
							padding: 0.6rem;

							> svg {
								height: 1rem;
								width: 0.6rem;
								position: absolute;
								left: 0.7rem;
							}
						}
					}

					form {
						&.pin {
							margin-top: 0;

							h2 {
								text-transform: uppercase;
								font-size: 17px;
								font-weight: 600;
								color: $black;
								margin-bottom: 20px;
							}

							.actions {
								&.pin {
									@include select {
										.selectFrame {
											display: flex;
											flex-direction: row;
											align-items: inherit;

											.selectFrame-header {
												margin-bottom: 0;

												label {
													font-size: small;

													.darkMode & {
														font-size: small;
														color: var(
															--labelColor,
															$anthra
														);
													}
												}
											}

											select {
												border: none;
												padding: 0 0.5rem;

												.darkMode & {
													border: none;
													background-image: url("../../../assets/images/selectArrowDM.svg");
												}
											}
										}
									}
								}
							}

							.extScreen {
								box-sizing: border-box;
								height: 1.3rem;
								width: 100%;
								border-radius: 5px;
								border: 2px solid $ciment;
								background-color: $bg-lGrey;
								font-family: $kod;
								font-size: 20px;
								font-weight: 500;
								position: relative;
								padding: 22px 20px 15px;
								letter-spacing: 3px;
								margin-bottom: 1.5rem;
								display: flex;
								align-items: center;

								&.error {
									border-color: $red;
								}

								.darkMode & {
									border: 2px solid $dm-b4;
									background-color: $dm-b4;
									color: $lGrey;
								}

								.screen-action {
									font-size: 0;
									position: absolute;
									right: 18px;
									top: calc(50% - 8.5px);
									cursor: pointer;

									svg {
										height: 14px;

										path {
											transition: $t1;

											.darkMode & {
												fill: $ciment;
											}
										}

										&:hover path {
											stroke: $black;

											.darkMode & {
												fill: $dm-red1;
												stroke: $dm-red1;
											}
										}
									}
								}
							}

							.extPad {
								display: flex;
								flex-wrap: wrap;
								justify-content: space-between;
								align-items: flex-start;

								.pad-btn {
									display: block;
									position: relative;
									width: calc(20% - 10px);
									border-radius: 5px;
									margin-bottom: 0.8rem;
									border: 2px solid $ciment;
									cursor: pointer;
									transition: $t1;
									right: 0;
									bottom: 0;

									.darkMode & {
										border: 2px solid $dm-b4;
									}

									&::before {
										content: "";
										display: block;
										padding-top: 100%;
									}

									span {
										position: absolute;
										top: 0;
										left: 0;
										bottom: 0;
										right: 0;
										background-color: $bg-lGrey;
										font-family: $kod;
										font-size: 13px;
										font-weight: 600;
										line-height: 100%;
										height: 100%;
										border-radius: 3px;
										text-align: center;
										display: flex;
										align-items: center;
										justify-content: center;

										.darkMode & {
											color: $white;
											background-color: $dm-b4;
										}
									}

									small {
										position: absolute;
										bottom: 0.01rem;
										right: 0.2rem;
										font-weight: 600;
										color: $grey;
										font-size: 0.7rem;
									}
								}

								&.hideKeys {
									.pad-btn small {
										display: none;
									}
								}
							}

							.extKeyboardModFrame {
								display: flex;
								align-items: center;
								margin-top: 0.7rem;

								.togglerFrame {
									transform: scale(0.3);
									margin-left: -1.1rem;
									@include NewSwitch;
								}

								.keyboardModFrame-info {
									margin-left: -0.8rem;
									font-size: 0.7rem;
									font-weight: 500;
									color: $anthra;

									.darkMode & {
										color: $ciment;
									}
								}
							}
						}
					}
				}

				.forgotPass {
					margin-top: 10px;
					margin-bottom: -20px;
					width: 285px;
					text-align: left;
					font-weight: 500;
					color: $grey;
					font-size: 10px;
					display: flex;
					justify-content: space-between;

					span {
						cursor: pointer;
						transition: $t1;

						&:hover {
							color: $black;
						}
					}
				}
			}
		}
	}
}
