@use "../utils/variables" as *;
@use "../utils/mixins" as *;
@use "./catTree" as *;

@mixin selectCategory($inThinPop: false) {
	.SelectCategory {
		flex: 1;
		width: 100%;
		height: 0;
		display: flex;
		flex-direction: column;
		align-items: center;

		&.loading {
			justify-content: center;
		}

		&:not(.loading) {
			justify-content: space-between;
		}

		.SelectCategory-container {
			height: 100%;
			width: 100%;
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;

			.contentWrapper {
				height: 0px;
				width: 100%;
				flex: 1;
				display: flex;
				flex-direction: column;

				@include catTree(true);
			}
		}

		.searchContainer {
			width: 100%;
			height: 2.1rem;
			margin: 0.5rem 0 1em 0;
			background-color: $bg-lGrey;
			display: flex;
			align-items: center;
			padding: 0.6rem 0.8rem;
			box-sizing: border-box;

			.darkMode & {
				background-color: $dm-b4;
			}

			.inputFrame {
				height: 100%;
				width: 100%;
				margin-top: 0;
				margin-bottom: 0;

				.inputWrap {
					height: 100%;
					display: flex;
					align-items: center;
					transition: 0.3s ease-in-out;
					margin-bottom: 8px;

					input {
						flex: 1;
						border: none;
						background: $dm-b4;
						font-size: 0.8rem;
						font-weight: 500;
						color: $black;
						font-family: $mon;
						padding: clamp(0.6rem, 1.8vh, 0.9rem)
							clamp(0.7rem, 2vw, 0.9rem);
						padding-left: 0;

						::placeholder {
							color: $greyShades-wetGrey;
						}

						.darkMode & {
							color: $white;
						}

						&::placeholder {
							.darkMode & {
								color: $white;
							}
						}

						&:focus {
							outline: none;
						}
					}

					svg {
						padding: 0.15rem;
						border-radius: 50%;
						background-color: $orange;

						* {
							fill: $white;
							stroke: $white;
						}
					}
				}
			}

			svg {
				border: none;
				max-height: 80%;
				width: auto;

				*:not(rect) {
					stroke: $anthra;
				}

				path {
					.darkMode & {
						fill: $white;
					}
				}
			}
		}

		section {
			backdrop-filter: none;
		}

		.whiteBackground {
			margin-bottom: 1rem;
		}

		.buttons {
			width: 100%;

			#foldCategories {
				margin-bottom: 1rem;
				font-weight: 600;
				margin-top: 0.2rem;
				background: none;
				border: none;
				color: $lCoal;
			}

			#foldCategories:hover {
				cursor: pointer;
			}

			.submit {
				position: relative;
				text-align: center;
				width: 100%;
				height: 2.4rem;
				border-radius: 3px;
				font-family: "Montserrat";
				font-size: 0.9rem;
				font-weight: 600;
				padding: 0.2rem 0.8rem 0.2rem 0.8rem;
				box-sizing: border-box;
				border: none;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				display: flex;
				justify-content: center;
				align-items: center;

				@if $inThinPop == false {
					margin-top: 0.2rem;
				}

				svg {
					flex-shrink: 0;
					width: 1.2rem;
					height: 1.2rem;
					margin-right: 0.8rem;

					g > * {
						stroke: none;
					}

					* {
						fill: $anthra;

						.darkMode & {
							stroke: none;
						}
					}
				}

				p {
					max-width: 85%;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.empty-block {
					width: 2rem;
				}

				&:hover {
					&.blue {
						cursor: pointer;
					}
				}

				&.blue {
					background-color: $actionBlue;
					color: $white;

					svg {
						* {
							fill: $white;
						}
					}
				}

				&.grey {
					background-color: $lGrey;
					color: $black;
				}
			}
		}
	}
}
