@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;

@mixin NewInput() {
	.inputFrame {
		&.focused {
			margin-top: 0;
		}

		.inputWrapContainer {
			.inputContainer {
				input {
					border: 1px solid $greyShades-matSilver;
					background-color: $greyShades-snow;

					&::placeholder {
						color: $greyShades-wetGrey;
					}

					.darkMode & {
						background-color: $dm-b4;
						border: 1px solid $dm-b4;
					}
				}
			}

			.inputTitle {
				margin: 0;

				.inputHeading {
					font-weight: 600;
				}
			}

			.content-text {
				color: $indicators-error;
			}
		}
	}
}
