@use "../../utils/variables" as *;

@mixin wmBuyOption {
	.wmBuyOption {
		position: absolute;
		left: 100%;
		background-color: $black;
		color: $white;
		text-align: left;
		padding: 1.3rem 0.5rem 1.3rem 0.9rem;
		width: 8rem;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		> svg {
			margin-bottom: 0.7rem;
			height: 1.3rem;
			width: auto;

			path {
				fill: $yellow;
			}
		}

		b {
			font-size: 0.9rem;
			margin-bottom: 0.5rem;
		}

		span {
			font-size: 0.8rem;
			font-weight: 500;
		}

		button {
			display: flex;
			align-items: center;
			margin-top: 1rem;
			border: none;
			background: none;
			color: $lBlue;
			font-weight: 600;
			font-size: 0.9rem;
			padding: 0;
			font-family: $mon;
			cursor: pointer;

			svg {
				height: 0.7rem;
				width: auto;
				margin-left: 0.3rem;

				path {
					fill: $lBlue;
				}
			}
		}
	}
}
