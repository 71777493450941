@use "../utils/variables" as *;

.password-security-slider {
	position: relative;

	&:hover {
		.slider-controller {
			opacity: 1;
		}
	}

	.slider-controller {
		opacity: 0;
		width: 1.8rem;
		height: 1.8rem;
		background-color: $white;
		border: none;
		border-radius: 50%;
		transition: all 0.3s ease-in-out;

		svg {
			width: 0.5rem;
			height: 0.8rem;

			path {
				fill: $brand-black;
			}
		}

		&.next {
			position: absolute;
			left: -0.9rem;
			top: 30%;
		}

		&.previous {
			position: absolute;
			right: -0.9rem;
			top: 30%;
		}
	}
}

.password-security {
	display: flex;
	background-color: $indicators-warning;
	padding: 0.8rem 0.9rem;
	border-radius: 5px;
	align-items: center;
	gap: 15px;

	.item-content {
		color: $brand-black;

		.number {
			margin: 0;
			font-size: 1.1rem;
			font-weight: 600;
		}

		.description {
			margin: 0;
			font-size: 0.8rem;
			line-height: 1rem;

			.bold {
				font-size: 0.9rem;
				font-weight: 600;
			}
		}
	}

	.highlight-button {
		cursor: pointer;
		background-color: $white;
		color: $brand-black;
		padding: 0.2rem 0.9rem;
		display: flex;
		gap: 0.3rem;
		border-radius: 0.9rem;
		font-weight: 600;
		border: none;

		svg {
			width: 0.7rem;

			path {
				fill: $brand-black;
			}
		}
	}
}
