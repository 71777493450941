@use "../utils/variables" as *;
@use "../utils/mixins" as *;
@use "../utils/animations" as *;

@mixin tooltipHelper(
	$place: "",
	$listMode: false,
	$ellipsisLines: "",
	$autoIconWidth: false
) {
	.tooltipHelper {
		display: grid;
		max-width: 100%;
		z-index: 10;
		position: relative;
		overflow: hidden;

		@if $place == "compassbody" {
			position: absolute !important;
			height: 100%;
			width: 100%;
		}

		&-content {
			position: fixed;
			font-size: 10px;
			padding: 5px 10px;
			background: $black;
			color: #ffffff;
			text-align: center;
			border-radius: 5px;
			pointer-events: none;
			transition:
				all 0.3s ease-out,
				cubic-bezier(0.68, -0.55, 0.265, 1.55);
			z-index: 9000;
			word-break: normal;
			overflow-wrap: break-word;
			max-width: 12rem;
			left: 50%;

			&-extend {
				max-width: 16rem;
			}

			.darkMode & {
				background-color: $dm-b3;
				color: $ciment;
				border: 2px solid $dm-bL1;
			}

			&-text {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 5;
				overflow: hidden;
			}

			&.topStyle {
				// Styles pour lorsque le tooltip est en haut
				animation: fadeSlideIn 0.4s ease-in-out;

				&:before {
					position: absolute;
					content: "";
					height: 8px;
					width: 8px;
					background: $black;
					transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
					left: 50%;
					transform: translate(-50%, 0) rotate(45deg);
					bottom: -4px;

					.darkMode & {
						background-color: $dm-b3;
						color: $ciment;
						border-bottom: 2px solid $dm-bL1;
						border-right: 2px solid $dm-bL1;
						bottom: -6px !important;
					}
				}
			}

			&.bottomStyle {
				// Styles pour lorsque le tooltip est en bas
				animation: fadeSlideInBottom 0.4s;

				&:before {
					position: absolute;
					content: "";
					height: 8px;
					width: 8px;
					bottom: 9px;
					background: $black;
					transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
					top: -4px; // Ajustez si nécessaire
					left: 50%;
					transform: translate(-50%, 0) rotate(45deg);

					.darkMode & {
						background-color: $dm-b3;
						color: $ciment;
						border-top: 2px solid $dm-bL1;
						border-left: 2px solid $dm-bL1;
						top: -6px !important;
					}
				}
			}

			&.leftStyle {
				// Styles pour lorsque le tooltip est à gauche
				animation: fadeSlideInLeft 0.4s;

				&:before {
					position: absolute;
					content: "";
					height: 8px;
					width: 8px;
					background: $black;
					transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
					top: 50%;
					right: -4px; // Ajustez si nécessaire
					transform: translate(0, -50%) rotate(45deg);

					.darkMode & {
						background-color: $dm-b3;
						color: $ciment;
						border-right: 2px solid $dm-bL1;
						border-top: 2px solid $dm-bL1;
						right: -6px !important;
					}
				}
			}

			&.rightStyle {
				// Styles pour lorsque le tooltip est à droite
				animation: fadeSlideInRight 0.4s;

				&:before {
					position: absolute;
					content: "";
					height: 8px;
					width: 8px;
					background: $black;
					transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
					top: 50%;
					left: -4px; // Ajustez si nécessaire
					transform: translate(0, -50%) rotate(45deg);

					.darkMode & {
						background-color: $dm-b3;
						color: $ciment;
						border-left: 2px solid $dm-bL1;
						border-bottom: 2px solid $dm-bL1;
						left: -6px !important;
					}
				}
			}

			&.inExtension {
				.content-text {
					white-space: nowrap;
				}

				&.rightStyle {
					animation: fadeSlideInRight 0.4s;
					left: 50% !important;
					top: 50% !important;
					transform: translateY(-50%) !important;
				}

				&.topStyle {
					animation: fadeSlideIn 0.4s ease-in-out;
					bottom: 100%;
					left: 50%;
					transform: translateX(-50%) !important;
				}
			}
		}

		&-children {
			max-width: 100%;
			display: grid;
			position: relative;
			z-index: 1;
			cursor: default;
			min-width: 0;

			@if $place == "compassbody" or $place == "cardrepo" {
				@include ellipsis(false, true);
			}

			> .content-text,
			.content-text-logs {
				@if $place ==
					"lockpass" or
					$place ==
					"locktransfer" or
					$place ==
					"cardrepo"
				{
					@if $listMode == false {
						@include ellipsis(2);
					} @else {
						@include ellipsis(false, true);
					}
				}

				@if $place == "slider" {
					@if $ellipsisLines == "1" {
						@include ellipsis(1, false);
					} @else {
						@include ellipsis($addWrap: true);
					}
				}

				.darkMode & {
					color: $ciment;
				}
			}

			> span {
				margin-left: 0;

				.customColor {
					color: $indicators-error;
				}

				.rootCat,
				h2 {
					font-weight: 600;
					font-size: 0.9rem;
					margin-left: 0.4rem;

					@include ellipsis(1, false);

					.darkMode & {
						color: $ciment;
					}

					&.mfa {
						padding: 0.24rem 0.6rem 0.24rem 0.42rem;
						border-radius: 0.3rem;
						font-size: 0.78rem;
						font-weight: 600;
						display: grid;
						gap: 0.3rem;
						align-items: center;

						.tagLabel {
							grid-column: 2;
						}
					}

					&.active {
						background-color: $accent-blueAction;
						color: $greyShades-white;
					}

					&.inactive {
						background-color: $indicators-warning;
						color: $brand-black;

						.icon {
							.firstKey {
								fill: $brand-black;
							}

							.secondKey {
								fill: $greyShades-white;
							}
						}
					}
				}

				.content-text-file {
					margin-right: 2.5rem;
				}

				.content-text-file-slide {
					margin-right: 0.8rem;
				}

				> span > p {
					display: block !important;
					max-width: 250px;
				}
			}

			&.userMfaTooltip {
				width: -moz-fit-content;
				width: max-content;
			}

			.childrenHidden {
				opacity: 0;
				position: absolute !important;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				height: 100%;
				cursor: pointer;
			}

			.childrenHidden {
				opacity: 0;
				position: absolute !important;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				height: 100%;
				cursor: pointer;
			}

			> h1,
			> h2,
			> h3,
			> h4 {
				font-size: 0.8rem !important;
				line-height: 1rem !important;
				margin-bottom: 0 !important;
				margin-top: 0 !important;
				display: -webkit-box;
				max-width: 200px;
				-webkit-line-clamp: 4;
				-webkit-box-orient: vertical;
				text-overflow: unset !important;
				white-space: normal !important;
			}

			.icon {
				display: flex;
				align-items: center;
				justify-content: center;
				height: auto;

				path & :not(#Groupe_361) {
					fill: $accent-blueMetallic;
				}
			}

			svg {
				max-height: 1.063rem;
				max-width: 1.063rem;

				@if $autoIconWidth {
					width: 100%;
					height: auto;
				} @else {
					width: 15px;
					height: 15px;
				}

				path {
					.darkMode & {
						fill: $ciment;
					}
				}

				&.black {
					path,
					circle,
					ellipse {
						fill: $black;

						.darkMode & {
							fill: $ciment;
						}
					}
				}

				&.black line {
					stroke: $black;
				}

				&.orange {
					path,
					circle,
					ellipse {
						fill: $orange;
					}
				}

				&.orange line {
					stroke: $orange;
				}

				&.blue {
					path,
					circle,
					ellipse {
						fill: $bg-blue;
					}
				}

				&:not(.orange, .userIcon, .groupIcon, .modoIcon) {
					circle,
					path {
						.darkMode & {
							fill: $accent-blueMetallic;
						}
					}
				}
			}

			.tooltipHelper-children > svg {
				max-height: 1.1vw;
				max-width: 1.1vw;
			}

			.tooltipHelper-children {
				&.pass {
					svg {
						path {
							fill: $accent-blueMetallic;
						}
					}
				}
			}

			.tooltipHelper-childrenCopy > svg {
				max-height: 1.1vw;
				max-width: 1.1vw;
			}

			.tooltipHelper-childrenCopy {
				position: absolute;
				top: 0;
				bottom: 0;
				opacity: 0;
				z-index: 0;
				pointer-events: none;
				white-space: nowrap;
			}

			.tooltipHelper-childrenCopy-inCompass {
				position: absolute;
				top: 0;
				opacity: 0;
				z-index: 0;
				pointer-events: none;
			}

			.tooltipHelper-not-relative:hover .tooltipHelper-content,
			.tooltipHelper:hover .tooltipHelper-content {
				opacity: 1;
				visibility: visible;
				pointer-events: auto;
			}

			.tooltipHelper-content,
			.tooltipHelper-content::before,
			.tooltipHelper:hover .tooltipHelper-content,
			.tooltipHelper:hover .tooltipHelper-content::before {
				background: $black;
				color: #ffffff;
			}

			@content;
		}

		&.tag {
			width: max-content;
			height: fit-content;
			padding: 0.24rem 0.6rem 0.24rem 0.42rem;
			border-radius: 0.3rem;
			font-size: 0.78rem;
			font-weight: 600;
			display: grid;
			gap: 0.3rem;
			align-items: center;

			* {
				width: fit-content;
			}

			.icon {
				grid-column: 1;
			}

			.tagLabel {
				grid-column: 2;
			}

			.flexText {
				display: grid;
				gap: 0.2rem;

				svg {
					width: 2rem;
				}
			}

			&.hasMfa {
				color: $greyShades-white;
				background-color: $accent-blueAction;
			}

			&.hasNoMfa {
				color: $brand-black;
				background-color: $indicators-warning;

				.icon {
					.firstKey {
						fill: $brand-black;
					}

					.secondKey {
						fill: $greyShades-white;
					}
				}
			}
		}
	}
}
