@use "../../../utils" as *;

@mixin InActionPop {
	margin-bottom: 3vh;

	@include mediaLarge {
		margin-bottom: 2vh;
	}

	.details {
		display: flex;
		align-items: center;
		padding-top: 10px;
		margin-left: 7px;

		@include mediaLarge {
			padding-top: 1vh;
			margin-left: 0.7vw;
		}

		.details-icon-heading {
			color: $ciment;
			font-size: 18px;
			font-weight: 500;

			@include mediaLarge {
				font-size: clamp(0.85rem, 1vw, 1rem);
			}

			.darkMode & {
				color: $anthra;
			}
		}

		.details-content {
			margin-left: 12px;
			display: flex;
			align-items: center;

			@include mediaLarge {
				margin-left: 0.7vw;
			}

			span {
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 42rem;
				cursor: default;
			}

			svg {
				margin-right: 5px;
				height: 17px;
				max-width: 20px;

				@include mediaLarge {
					max-width: 1vw;
				}

				path {
					fill: $orange;

					.darkMode & {
						stroke: $orange;
					}
				}

				line {
					.darkMode & {
						fill: $lGrey;
						stroke: $lGrey;
					}
				}
			}

			span {
				font-weight: 500;
				font-size: 18px;
				color: $black;

				@include mediaLarge {
					font-size: clamp(0.85rem, 1vw, 1rem);
				}

				.darkMode & {
					color: $ciment;
				}
			}
		}
	}
}
