@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;

@mixin NavLinks($navMini: false) {
	.navLink {
		text-decoration: none;
		color: $grey;

		.navLinkContent {
			position: relative;
			display: flex;
			align-items: center;
			// text-transform: uppercase;
			font-size: 0.938rem;
			letter-spacing: 0.25px;
			margin-bottom: 2vh;
			padding: 0.5rem 0.625rem;
			border-radius: 0.313rem;

			.newTag {
				position: absolute;
				right: -0.313rem;
				padding: 0.25rem 0.5rem;
				background-color: $indicators-warning;
				border-radius: 0.625rem;
				color: $brand-black;
				font-weight: 600;
				font-size: 0.5625rem;

				@include mediaMaxCustom(1024px) {
					position: relative;
					margin-left: 0.625rem;
				}

				@if $navMini {
					position: absolute;
					right: -0.5rem;
					top: 2.5rem;

					@include mediaMinCustom(1200px) {
						position: absolute;
						right: 0;
						top: 2.5rem;
					}

					@include mediaMaxCustom(1024px) {
						position: relative;
						top: 0;
					}
				}

				.darkMode & {
					background-color: $purpleShades-portailEdge;
					color: $white;
				}
			}

			.test {
				width: 1.8rem;
				height: 1.8rem;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 0.3rem;
			}

			.iconNav {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 0.9rem;
				height: 1.25rem;
				width: 1.25rem;

				svg path,
				svg circle {
					fill: $greyShades-wetGrey;
				}

				@if $navMini {
					.darkMode & {
						svg path,
						svg circle {
							fill: $greyShades-grey;
						}
					}
				}
			}

			@include mediaCustom {
				font-size: clamp(0.75rem, 0.9vw, 1rem);
				letter-spacing: 0.02em;
				margin-bottom: 1vh;
				margin-top: 1vh;
				padding: 1vh 0.7vw;
				border-radius: 0.3rem;

				@if $navMini {
					margin-bottom: 1.1vh;
					margin-top: 1.1vh;
					padding: 0.5vw;
					border-radius: 0.3rem;
					height: 1.5vw;
					width: 1.5vw;
					position: relative;

					.test,
					.iconNav {
						width: 70%;
						height: 70%;
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}

			@include mediaMedium {
				margin-bottom: 1.313rem;
			}

			.navLinkContent-title {
				transition: $t1;
				font-size: 0.9375rem;
				font-weight: 600;
				color: $greyShades-wetGrey;
				white-space: nowrap;

				.darkMode & {
					color: $greyShades-snow;
				}

				@if $navMini {
					display: none;
				}
			}

			.lf-player-container {
				margin-right: 0.75rem;

				@include mediaCustom {
					margin-right: 0.7vw;
					width: clamp(1.3rem, 1.3vw, 1.7rem) !important;
				}

				svg path {
					.darkMode & {
						fill: $ciment;
						stroke: $ciment;
					}
				}
			}
		}

		&:not(.active) {
			&:hover {
				.navLinkContent-title {
					color: $bg-blue;

					.darkMode & {
						color: $white;
					}
				}
			}
		}

		&.active {
			color: $black;

			.darkMode & {
				color: $lGrey;
			}

			.navLinkContent {
				background-color: $bg-lGrey;
				position: relative;

				@if $navMini {
					// @include mediaLarge {
					// 	border: 2px solid var(--ogBlue);
					// }
				}

				@include mediaMedium {
					background-color: $bg-main;
				}

				.darkMode & {
					// background-color: $dm-b3;
					background-color: transparent;
				}

				.navLinkContent-title {
					color: $brand-black;
					.darkMode & {
						// text-shadow: 0 0 15px $dm-wL;
						color: $greyShades-white;
					}
				}

				svg path,
				svg circle {
					fill: $brand-orange;

					.darkMode & {
						fill: $brand-orange;
					}
				}

				&::after {
					content: "";
					position: absolute;
					top: 0;
					right: -0.625rem;
					background-color: $brand-orange;
					width: 0.188rem;
					height: 2.5rem;
					border-bottom-left-radius: 0.188rem;
					border-top-left-radius: 0.188rem;

					@include mediaLarge {
						right: -0.625rem;
						width: 0.15rem;
						height: 100%;

						@if $navMini {
							content: "";
							width: -1.375rem;
							position: absolute;
							top: -0.25rem;
							right: -38%;
							background-color: $brand-orange;
							height: 2.5rem;
							border-bottom-left-radius: 0.188rem;
							border-top-left-radius: 0.188rem;
						}
					}

					@include mediaMaxCustom(1330px) {
						@if $navMini {
							right: -0.9rem;
						}
					}

					@include mediaMedium {
						content: none;
					}

					.darkMode & {
						background-color: $orange;
						box-shadow: 0 0 0.938rem $dm-oL2;
					}
				}
			}
		}

		@content;
	}
}
