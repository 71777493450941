@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;
@use "../../components/" as *;
@use "./thinPopIPassPh" as *;
@use "./thinPopLeftList" as *;
@use "./ssoFrame" as *;
@use "./changeWindowDimensions" as *;
@use "../../components/input/_mixin" as *;
@use "../../components/toggler/toggler" as *;

@mixin BodyPop($popup) {
	.popinBody {
		@if $popup == "actionPop" {
			position: relative;
			z-index: 10;
			display: flex;
			flex-direction: column;
			overflow: auto;

			.noPaddingButOverflow {
				overflow-y: auto;
				overflow-x: hidden;
			}

			.bigInputFrame {
				width: 70%;
				margin-top: 10px;
				margin-bottom: 10px;

				@include mediaLarge {
					margin-top: 1vh;
					margin-bottom: 1vh;
				}
			}

			@include thinPopIPassPh;
			@include thinPopLeftList;

			.withPadding {
				padding: 10px 4vw 25px;
				overflow-y: auto;
				display: flex;
				flex-direction: column;

				@include mediaLarge {
					padding: 1vh 3vw 3vh;
				}

				.noticeText {
					display: flex;
					margin-left: 1vw;
					margin-right: 1vw;
					margin-bottom: 2vw;
					text-align: center;
					background: $bg-lGrey;
					padding: 2vw;
					border-radius: 5px;
					color: $black;

					.darkMode & {
						background: $dm-b4;
						color: $lGrey;
					}

					.noticeText-content {
						text-align: center;
						font-size: clamp(0.5rem, 1vw, 1rem);

						span {
							text-align: left;
							display: block;
							margin: 0.5rem 0 0 1rem;
							margin-top: 1rem;
							font-weight: 500;
						}
					}
				}

				.line {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					margin-bottom: 30px;
					margin-top: 40px;

					@include mediaLarge {
						margin-bottom: 3vh;
						margin-top: 4vh;
					}

					.lineContainer {
						flex: 2;
						margin-left: 20px;
						display: flex;
						align-items: center;

						@include mediaLarge {
							margin-left: 1vw;
						}

						b {
							color: $black;
							font-size: 15px;
							font-weight: 500;
							margin-right: 10px;

							@include mediaLarge {
								font-size: clamp(0.7rem, 1vw, 1rem);
								margin-right: 0.7vw;
							}

							.darkMode & {
								color: $ciment;
							}
						}
					}
				}

				&.center {
					align-items: center;

					.inputFrame {
						width: 80%;
					}
				}
			}

			.changePin {
				@include lockerFrame($inChangePin: true) {
					.lockerFrame {
						position: static;

						.head.pin {
							margin-top: 20px;
							margin-bottom: 15px;
						}

						.locker {
							box-shadow: none;
							border: none;
							padding: 5px;
							bottom: 0;

							.darkMode & {
								background-color: $dm-b3;
							}
						}
					}
				}
			}

			// check in children prop for ThinPop & ActionPop components
			// for elements selected by selector below
			> div {
				display: flex;
				flex-direction: column;

				@include InputFrame;

				.duplex {
					display: flex;
					justify-content: space-between;
					align-items: flex-end;

					.line {
						width: calc(50% - 10px);

						@include select {
							.selectFrame {
								width: 100%;
							}
						}
					}

					&.autoW {
						.line {
							width: auto;
						}
					}

					&.alignCenter {
						align-items: center;
					}

					&.noMargin {
						.line {
							margin: 0;
						}
					}
				}

				.line {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 30px;
					margin-top: 40px;

					@include mediaLarge {
						margin-bottom: 3vh;
						margin-top: 4vh;
					}

					&.forcedNotification {
						.togglerFrame {
							.toggler.on {
								background-color: $greyShades-grey;
								border-color: $greyShades-grey;
							}
						}

						.tooltipHelper {
							.content-text {
								display: flex;
								align-items: center;
							}
						}
					}

					.line-container {
						display: flex;
						gap: 0.5rem;
						margin-left: 1vw;
					}

					&.minM {
						margin-bottom: 20px;
						margin-top: 20px;

						@include mediaLarge {
							margin-bottom: 2vh;
							margin-top: 2vh;
						}

						&.mTop {
							margin-top: 40px;

							@include mediaLarge {
								margin-top: 4vh;
							}
						}
					}

					&.download {
						margin-top: 0px;
					}

					&.upload {
						margin-top: 0px;
					}

					&.noMBot {
						margin-bottom: 6px;

						@include mediaLarge {
							margin-bottom: 0.5vh;
						}
					}

					.inputFrame {
						margin-bottom: 0;
					}

					.line-text {
						color: $anthra;
					}

					.lineContainer {
						flex: 2;
						margin-left: 20px;
						display: flex;
						align-items: center;

						@include mediaLarge {
							margin-left: 1vw;
						}

						b {
							color: $black;
							font-size: 15px;
							font-weight: 500;
							margin-right: 10px;

							@include mediaLarge {
								font-size: clamp(0.7rem, 1vw, 1rem);
								margin-right: 0.7vw;
							}

							.darkMode & {
								color: $ciment;
							}
						}
					}
				}

				.fullLine {
					margin-bottom: 30px;
					margin-top: 40px;
					display: flex;
					align-items: center;

					@include mediaLarge {
						margin-bottom: 3vh;
						margin-top: 4vh;
					}

					.fullLine-container {
						display: flex;
						flex-direction: column;
						flex: 2;
						margin-right: 40px;

						@include mediaLarge {
							margin-right: 4vh;
						}

						b {
							color: $black;
							font-size: 15px;
							font-weight: 500;
							margin-bottom: 7px;

							@include mediaLarge {
								font-size: clamp(0.8rem, 1.1vw, 1.1rem);
								margin-bottom: 1vh;
							}

							.darkMode & {
								color: $lGrey;
							}
						}

						p {
							margin: 0;
							color: $anthra;
							font-size: 13px;
							font-weight: 500;

							@include mediaLarge {
								font-size: clamp(0.7rem, 1vw, 1rem);
							}

							.darkMode & {
								color: $grey;
							}
						}
					}
				}

				.seeMoreOptions {
					display: flex;
					align-items: center;
					font-size: 15px;
					font-weight: 500;
					cursor: pointer;

					@include mediaLarge {
						font-size: clamp(0.7rem, 1vw, 1rem);
					}

					.darkMode & {
						color: $grey;
						transition: $t1;
					}

					&:hover {
						.darkMode & {
							color: $ciment;
						}
					}

					svg {
						margin-left: 10px;
						height: 13px;
						width: 13px;

						@include mediaLarge {
							margin-left: 0.7vw;
							height: 1vw;
							width: 1vw;
						}

						path {
							fill: $ciment;

							.darkMode & {
								stroke: $grey;
							}
						}
					}
				}

				.soloLine {
					margin-top: 30px;
					margin-bottom: 30px;
					color: $black;
					cursor: pointer;
					display: flex;
					align-items: center;
					word-break: break-all;

					.darkMode & {
						color: $ciment;
					}

					svg {
						margin-right: 15px;

						path {
							.darkMode & {
								fill: $grey;
							}
						}
					}

					&.minor {
						color: $grey;
						font-weight: 500;
						margin: 0 0 0.5rem 0;

						.darkMode & {
							color: $ciment;
						}
					}
				}

				.mTop {
					margin-top: 3em;

					@include mediaLarge {
						margin-top: 2vh;
					}
				}

				.mBottom {
					margin-bottom: 3em;

					@include mediaLarge {
						margin-bottom: 2vh;
					}
				}
			}

			.buttons {
				display: flex;
				flex-direction: row;
				padding-bottom: 7px;
				padding-left: 7px;
				gap: 1rem;
				margin-top: 1.5rem;

				@include mediaLarge {
					padding-bottom: 1vh;
					padding-left: 0.5vw;
				}
			}

			.simpleWord {
				margin: 0;
				margin-bottom: 13px;
				font-size: 14px;
				color: $anthra;
				font-weight: 600;

				.darkMode & {
					color: $ciment;
				}
			}
		}

		@if $popup == "thinPop" {
			overflow-y: auto;
			display: flex;
			flex-direction: column;

			.darkMode & {
				color: $ciment;
				box-shadow: 0 0 2rem 0 $dm-padlockPop;
			}

			.buttons {
				// .helper class below references to helper scss component
				button,
				.helper {
					width: 100%;
					margin-top: 0.5rem;

					button {
						width: 100%;
					}
				}
			}

			@include ssoFrame;
			@include selectCategory(true);
			@include changeWindowDimensions;
			@include InputSearch;
		}
	}
}
