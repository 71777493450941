@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;
@use "../../components/" as *;

@mixin changeWindowDimensions {
	#changeWindowDimensions {
		position: absolute;
		top: calc(50% - 1.85rem);
		right: -0.9rem;
		z-index: 5;
		padding: 0.8rem 0.3rem;
		border-radius: 4px;
		background-color: $actionBlue;

		.darkMode & {
			background: rgb(134, 90, 232); /* Old browsers */
			background: -moz-linear-gradient(
				45deg,
				rgba(134, 90, 232, 1) 0%,
				rgba(42, 162, 228, 1) 100%
			); /* FF3.6-15 */
			background: -webkit-linear-gradient(
				45deg,
				rgba(134, 90, 232, 1) 0%,
				rgba(42, 162, 228, 1) 100%
			); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(
				45deg,
				rgba(134, 90, 232, 1) 0%,
				rgba(42, 162, 228, 1) 100%
			); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#865ae8', endColorstr='#2aa2e4',GradientType=1 );
		}

		&:hover {
			cursor: pointer;
		}

		.wDBlock {
			position: relative;
			height: 1.3rem;
			width: 1.1rem;

			svg {
				position: absolute;

				* {
					fill: $white;
				}

				&:first-child {
					top: 0;
					left: 0;
					rotate: 180deg;
				}

				&:last-child {
					bottom: 0;
					right: 0;
				}
			}

			&.displayOnlyMyCursor {
				position: fixed;
				top: 0;
				left: 0;
				width: 100vw;
				height: 100vh;
				cursor: grab;
			}
		}
	}
}
