@use "../utils/" as *;
@use "../components/tripleKeys" as *;

@mixin completionCircle($location: "") {
	.CompletionCircle {
		height: convertToREM(30);
		width: 3rem;
		position: relative;
		overflow: hidden;
		display: flex;
		align-items: center;

		@if $location == "slide" {
			width: convertToREM(25);
			height: convertToREM(25);
		}

		.CompletionCircle-container {
			position: relative;
			width: convertToREM(30);
			height: 100%;
			display: grid;
			grid-template-columns: minmax(0, 1fr);
			align-items: center;
			justify-items: center;

			@if $location == "slide" {
				width: convertToREM(25);
			}
		}

		.timer-icon {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 10;
			height: 100%;
			width: 100%;
			max-height: none;
			max-width: none;

			&.withLabel {
				.circleToComplete {
					transform: translate(50%, 50%) rotateZ(0);
				}
			}

			&.stressed {
				.circleToComplete {
					stroke: $indicators-error;
				}
			}

			.circleToComplete {
				stroke-dasharray: convertToREM(30);
				transform: translate(50%, 50%) rotateZ(-90deg);
				fill: transparent;
				stroke: $greyShades-matSilver;
				stroke-linecap: round;
				fill-rule: nonzero;
				transition: 1s linear all;

				@if $location == "slide" {
					stroke-width: 11%;
				} @else {
					stroke-width: 6%;
				}
			}
		}

		p {
			z-index: 2;
			flex-shrink: 1;
			font-size: 0.8rem;
			font-weight: 600;
		}

		.icon {
			z-index: 2;
			height: 0.7rem;
			width: 0.7rem;
			position: absolute;
			top: 35%;
			left: 25%;
		}

		.totp-label {
			position: absolute;
			z-index: 30;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			background-color: $greyShades-matSilver;
			border-radius: 3px;
			padding: convertToREM(3);
			transition-duration: 0.3s;

			.darkMode & {
				background-color: $greyShades-wetGrey;
			}

			&.stressed {
				background-color: $indicators-error;
			}

			svg {
				display: block;
				width: convertToREM(17);
				height: convertToREM(6);
				max-height: 100%;
				max-width: 100%;

				* {
					fill: $greyShades-wetGrey;

					.darkMode & {
						fill: $greyShades-snow;
					}
				}
			}
		}

		@include tripleKeys(100%, 100%);

		&.focused {
			.circleToComplete {
				fill: $white;
				stroke: $accent-blueMetallic;

				.darkMode & {
					fill: $dm-b4;
					stroke: $accent-blueNeonShade;
				}

				&.stressed {
					stroke: $indicators-error;
				}
			}

			.totp-label {
				background-color: $accent-blueMetallic;

				.darkMode & {
					background-color: $accent-blueNeonShade;
				}

				&.stressed {
					background-color: $indicators-error;
				}

				* {
					fill: $greyShades-snow;
				}
			}
		}
	}

	@content;
}
