@use "../../utils/" as *;
@use "../../components/" as *;
@use "./popinHeader" as *;

@mixin thinPopLockPass {
	&.headerOnLeft {
		.popinWrap {
			position: relative;

			.thinPopHeader {
				position: absolute !important;
				top: 0;
				left: -16.5rem;
				width: 14.1rem;
				margin-right: 1rem;

				@include popinHeader("in_headerOnLeft_leftPart");
			}
		}
	}

	&.pwdThinPop {
		&.headerOnLeft {
			.popinWrap {
				height: 92.5vh;
			}

			.thinPopinFrameInside {
				.thinPopHeader {
					box-sizing: border-box;
					padding: convertToREM(15) convertToREM(20);
					width: convertToREM(250);

					.popinHeader {
						margin-bottom: 0;

						.title {
							margin-bottom: convertToREM(8.5);
							color: $greyShades-wetGrey;
							font-size: convertToREM(15);
							font-weight: 600;
							line-height: convertToREM(18);
						}

						.details {
							display: grid;
							grid-template-columns: auto minmax(0, 1fr);
							column-gap: convertToREM(15);
							row-gap: convertToREM(10);
							align-items: center;
							justify-content: start;

							.details-icon-heading {
								margin: 0.4rem -0.2rem 0;
							}

							img {
								width: convertToREM(40);
								height: convertToREM(40);
								margin: 0;
								object-fit: cover;
							}

							b {
								color: $black;
								font-size: convertToREM(18);
								font-weight: 600;
								line-height: convertToREM(22);
							}

							.url {
								grid-column-start: span 2;
								display: flex;
								justify-content: space-between;
								align-items: center;

								span {
									margin-right: convertToREM(8);
									@include ellipsis;
									width: 90%;
									white-space: nowrap;
								}
							}
						}
					}
				}

				#thinPopBody.popinBody {
					padding: convertToREM(19) convertToREM(20) convertToREM(15);

					.withPadding {
						display: grid;
						grid-template-columns: minmax(0, 1fr);
						row-gap: convertToREM(15);
						align-content: start;

						&.sshMode {
							> :nth-child(5) {
								margin-top: 1rem;
							}
						}

						.categoryContainer {
							h4 {
								font-size: convertToREM(13);
								line-height: convertToREM(16);
							}

							.choices-button {
								display: flex;
								width: 50%;
								padding-bottom: 1.5rem;
							}

							.fileLine {
								padding: convertToREM(12), convertToREM(20);
								font-size: convertToREM(15);
								line-height: convertToREM(18);
							}
						}

						.urlIp-container {
							display: grid;
							grid-template-columns: 78% 1fr;
							grid-template-rows: 1fr;
						}

						.seeMoreOptionsDropdown {
							display: flex;
							align-items: center;
							box-sizing: border-box;
							font-family: $mon;
							flex: 2;
							padding: 0 1rem 0 1rem;
							color: $black;
							font-size: 15px;
							font-weight: 500;
							width: 100%;
							height: convertToREM(32);
							border-radius: convertToREM(5);
							background-color: $backgroundSurf;
							cursor: pointer;
							margin-bottom: 1.5rem;

							&.spacing {
								margin: 1.5rem 0 1.5rem 0;
							}

							.darkMode & {
								background-color: $dm-b2;
							}

							.dropDownContainer {
								display: flex;
								align-items: center;
								align-self: stretch;
								justify-content: space-between;
								width: 100%;

								p {
									font-size: convertToREM(12);
									color: $newTextCol1;

									.darkMode & {
										color: $greyShades-matSilver;
									}
								}

								svg {
									width: 1rem;
									height: 0.6rem;
									path {
										fill: $greyShades-grey;
									}
								}
							}
						}

						.seeMoreOptions {
							position: relative;

							svg {
								* {
									fill: $greyShades-grey;
									stroke: $greyShades-grey;
								}
							}
						}
					}

					.forceShowTitle {
						margin: 0;

						.sshKey-switch {
							padding-top: 0.5rem;

							&.withPadding {
								padding-top: 2rem;
							}
						}
					}

					.inputWrapContainer {
						> .inputWrapActions {
							padding: 2.3rem 0 0;
						}
					}

					.inputFrame {
						&.is-borderless {
							.inputWrap {
								input,
								textarea {
									border: none;
									height: 0;
									background-color: $lGrey;

									.darkMode & {
										background-color: $dm-b4;
									}
								}
							}

							.inputIndicators {
								display: none;
							}
						}

						&.input-totp,
						&.passwordInput {
							.inputWrap input {
								padding-right: convertToREM(40);
							}
						}

						&.input-totp {
							.inputWrap input {
								color: $black;
								font-size: convertToREM(15);
								line-height: convertToREM(18);

								.darkMode & {
									color: $greyShades-matSilver;
								}

								&::placeholder {
									color: $greyShades-grey;
								}
							}
						}

						&.pass.passwordInput {
							.action.input-with-icon {
								right: convertToREM(50);
							}

							.inputWrap {
								.genBtn {
									svg {
										* {
											fill: $black;

											.darkMode & {
												fill: $greyShades-matSilver;
											}
										}
									}
								}
							}
						}

						&.urlInput,
						&.tagsInput {
							&:not(.tagsInput) {
								margin-top: convertToREM(20);
							}

							.inputWrap {
								position: relative;

								input {
									padding-right: convertToREM(53);
								}

								.action {
									position: absolute;
									top: 50%;
									right: convertToREM(15);
									padding-left: 0;
									transform: translateY(-50%);
								}
							}

							.inputWrapActions {
								padding: 2.25rem 0 0;

								.backgroud-icon {
									border-radius: convertToREM(5);
									background-color: $white;
									padding: 0.3rem 0.3rem 0 0.2rem;
									margin: -0.4rem 0.4rem 0;

									&.blue {
										background-color: $newPrimaryBg;

										svg {
											path {
												fill: $white;
											}
										}
									}

									.darkMode & {
										background-color: $dm-b2;

										&.blue {
											background-color: $newPrimaryBg;
										}
									}
								}
							}
						}

						&.pwdDesc {
							margin-bottom: convertToREM(20);
						}

						.inputTitle {
							margin-bottom: convertToREM(5);
							justify-content: space-between;

							.inputHeading {
								color: $black;
								font-size: convertToREM(13);
								line-height: convertToREM(16);

								.darkMode & {
									color: $greyShades-white;
								}
							}

							.inputTitleButton {
								@include resetBtn;
								font-size: convertToREM(11);
								line-height: convertToREM(13);
							}

							.helper {
								svg {
									width: convertToREM(12);
									height: auto;

									path {
										fill: $greyShades-matSilver;
									}
								}
							}
						}

						.inputWrap {
							> input,
							textarea {
								border: solid convertToREM(0.5)
									$greyShades-matSilver;
								border-radius: convertToREM(5);
								padding: convertToREM(15) convertToREM(16);
								resize: vertical;

								.darkMode & {
									border-color: $dm-b4;
								}
							}

							&.focusedBorder {
								border: 1.5px solid $newPrimaryBg;

								.darkMode & {
									border: 1.5px solid $newBlue;
								}
							}

							.passFont {
								padding-right: 7rem;
							}

							.passProtectedKey {
								padding-right: 1rem;
							}

							& ~ .showGenMenu {
								margin-top: convertToREM(5);

								& ~ .genMenu {
									margin-top: convertToREM(5);
								}
							}
						}
					}

					.SelectCategory
						.SelectCategory-container
						.contentWrapper
						.CatTree
						.categoriesList
						.Category {
						.helper-content-wrapper {
							display: none;
						}

						.helper:hover {
							.helper-content-wrapper {
								display: flex;
							}
						}
					}

					.buttons {
						padding-top: 0.3135rem;
						padding-bottom: 0;

						button {
							display: flex;
							justify-content: center;

							&:last-child {
								margin-top: convertToREM(10);

								svg {
									transform: rotateZ(0);
									rotate: 0deg;
								}
							}

							.button-text {
								@include ellipsis(1, false);
								max-width: 89%;
							}

							&.grey {
								svg {
									* {
										.darkMode & {
											fill: $greyShades-white;
										}
									}
								}
							}

							.icon {
								&.iconCheck {
									* {
										stroke: none;
									}
								}
							}
						}
					}
				}
			}
		}

		&:not(.headerOnLeft) {
			.thinPopHeader {
				width: 25rem;
				box-sizing: border-box;

				.details {
					display: flex;
					align-items: center;

					b {
						flex: 1;
						width: 0;

						h2 {
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
					}
				}
			}
		}

		.thinPopinFrameInside {
			.popinBody {
				overflow: visible !important;
			}

			.leftPthinPopHeaderart {
				margin-bottom: 1rem;

				@include popinHeader("in_lockPassActions");
			}

			.popinWrap {
				max-width: none;
				max-height: none;
				min-height: 75vh;
				display: flex;
				flex-direction: column;
			}

			.popinBody {
				height: 100%;
				flex: 1;
				overflow-x: visible;
				overflow-y: visible;
				box-sizing: border-box;
			}
		}
	}
}
