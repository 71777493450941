@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;
@use "../../components/input" as *;
@use "../../components" as *;
@use "../../components/toggler/toggler" as *;

@mixin loginContent {
	.login {
		position: relative;
		height: 100vh;
		overflow: hidden;
		flex: 2;
		display: flex;
		flex-direction: column;
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,eaebf2+100&1+0,0.19+100 */
		background: -moz-linear-gradient(
			top,
			rgba(255, 255, 255, 1) 0%,
			rgba(234, 235, 242, 0.19) 100%
		); /* FF3.6-15 */
		background: -webkit-linear-gradient(
			top,
			rgba(255, 255, 255, 1) 0%,
			rgba(234, 235, 242, 0.19) 100%
		); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(
			to bottom,
			rgba(255, 255, 255, 1) 0%,
			rgba(234, 235, 242, 0.19) 100%
		); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#30eaebf2',GradientType=0 ); /* IE6-9 */

		.darkMode & {
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#28282e+0,1a1a1d+100 */
			background: rgb(40, 40, 46); /* Old browsers */
			background: -moz-linear-gradient(
				top,
				rgba(40, 40, 46, 1) 0%,
				rgba(26, 26, 29, 1) 100%
			); /* FF3.6-15 */
			background: -webkit-linear-gradient(
				top,
				rgba(40, 40, 46, 1) 0%,
				rgba(26, 26, 29, 1) 100%
			); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(
				to bottom,
				rgba(40, 40, 46, 1) 0%,
				rgba(26, 26, 29, 1) 100%
			); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#28282e', endColorstr='#1a1a1d',GradientType=0 ); /* IE6-9 */
		}

		div.logo {
			display: flex;
			align-items: center;
			text-align: center;
			margin-top: 2.25rem;
			margin-left: 2.2rem;

			@include mediaLarge {
				padding-top: 1.25rem;
			}

			.darkMode & {
				background-color: $dm-log1;
			}

			svg {
				width: 7.85rem;
				height: auto;

				@include mediaLarge {
					width: 7.85rem;
					height: auto;
					margin-top: 0;
				}

				path {
					.darkMode & {
						fill: $white;
					}
				}
			}

			h1 {
				margin: 0;
				margin-left: 4.5rem;
				text-transform: uppercase;
				font-size: 35px;
				font-weight: 500;
				color: $grey;

				@include mediaLarge {
					font-size: clamp(1.1rem, 1.7vw, 1.3rem);
				}

				.darkMode & {
					color: $anthra;
				}

				b {
					font-weight: 500;
					color: $black;

					.darkMode & {
						color: $ciment;
					}
				}
			}
		}

		.changePin {
			.lockerFrame {
				position: static;

				.head.pin {
					margin-top: 20px;
					margin-bottom: 15px;
				}

				.locker {
					box-shadow: none;
					border: none;
					padding: 5px;
					bottom: 0;

					.darkMode & {
						background-color: $dm-b3;
					}
				}
			}
		}

		.watermark {
			position: absolute;
			right: 0;
			bottom: 95%;
			transform: rotate(270deg);
			transform-origin: right bottom;
			font-size: 170px;
			color: transparent;
			-webkit-text-stroke: 2.5px $ciment;
			opacity: 0.37;

			@include mediaLarge {
				font-size: 7.7vw;
				-webkit-text-stroke: 0.18vw $ciment;
			}

			.darkMode & {
				-webkit-text-stroke: 1.5px $grey;
				opacity: 0.24;
			}
		}
	}
}
