// Fonts

$kod: "Kodchasan", sans-serif;
$mon: "Montserrat", sans-serif;
$rob: "Roboto", sans-serif;
$telegraf: "Telegraf", sans-serif;

// Backgrounds

$bg-white: #ffffff;
$bg-lGrey: #f6f7fe;
$bg-main: rgba(201, 204, 214, 0.25);
$bg-blue: var(--ogBlue);
$bg-blueDark: #39499b;
$bg-blue-action: var(--ogActionBlue);
$bg-dBlue: #181a3a;
$bg-dark: var(--ogBlack);
$bg-actionPop: rgba(32, 32, 48, 0.85);
$bg-padlockPop: rgba(255, 255, 255, 0.7);
$bg-pile: #d9dcfd;

// Elements & fonts
$violet: #6f62d1;
$white: #ffffff;
$lGrey: #f6f7fe;
$transparentlGrey: rgba(246, 247, 254, 0.5);
$ciment: #d7d9ea;
$grey: #ada8b6;
$newGrey: #9393b3;
$matSilver: #d7d9ea;
$lCoal: #979798;
$anthra: #6f6f7c; //#858390;//#8F8B97;
$lAnthra: #8f8b97;
$orange: var(--ogOrange); //#F47532;
$newOrange: var(--ogNewOrange);
$black: #181a3a;
$blueShade: #97a2d8;
$blue: #39499b;
$newBlue: #8c97fa;
$actionBlue: var(--ogActionBlue);
$buttonExterne: var(--ogButton);

$yellow: #fccd30;
$lYellow: #fad86a;
$lBlue: #519fde;
$red: #f71c53;
$green: #0cf380;

$pastel-green: #6edc5f;
$gainsboro: #dddddd;

// new colors

$new-lp: #ffa674;
$new-lpLight: #ffc3a1;

$new-lt: #6fe3c7;
$new-ltLight: #b2ecde;

$new-lf: #fcea9b;
$new-lfLight: #fff7d3;

$new-mng: #c984ff;
$new-mngLight: #d4a7f8;

$new-blue: #481af5;
$new-blue-rgb: rgb(72, 26, 245);
$new-blue-300: #8c97fa;

$greyShades-wetGrey: #737390;
$greyShades-grey: #9393b3;
$greyShades-roseGrey: #b6aec7;
$greyShades-matSilver: #d7d9ea;
$greyShades-snow: #f6f7fe;
$greyShades-roseSnow: #e8dafc;
$greyShades-white: #ffffff;
$backgroundSurf: #fafafe;

$purpleShades-light: #865ae8;
$purpleShades-dreamAccent: #a790e3;
$purpleShades-galaxySauce: #9482dd;
$purpleShades-purple: #6f62d1;
$purpleShades-sunsetShades: #c0a2e8;
$purpleShades-roseSnow: #e8dafc;
$purpleShades-portailEdge: #7f73d6;
$brand-black: #181a3a;
$brand-blue: #39499b;
$brand-orange: #f47532;

$blueShades-smokedSky: #97a2d8;
$blueShades-smokedSky2: #92a1ed;
$blueShades-lagoon: #6a7ac8;
$blueShades-river: #4c5fbd;
$blueShades-neon: #519fde;

$indicators-error: #f71c53;
$indicators-warning: #fccd30;
$indicator-success: #0cf380;

$accent-blueNeonShade: #519fde;
$accent-blueMetallic: #3b67f8;
$accent-blueAction: #2833b7;

$newPrimaryBg: #481af5;
$newPrimaryBgButton: #181a3a1a;
$newPrimaryBgButtonDM: #ffffff1a;

$purple5: #8d91ee;
$white5: #e8e9f6;

$bgDashboard: #fdfdff;
$bgOrange: #f9dfd5;

$newTextCol1: #737390;

$actionIndic: #9393b3ff;
$actionIndicDM: #d7d9eaff;
$actionWarnIndic: #f71c53ff;

// Shadows

$sh7: rgba(90, 105, 120, 0.7);
$sh5: rgba(100, 75, 100, 0.5);
$sh4: rgba(201, 204, 214, 0.4);
$sh3: rgba(160, 160, 220, 0.2);
$sh2: rgba(201, 204, 214, 0.2);
$sh1: rgba(215, 217, 234, 0.25);
$sh0: rgba(215, 217, 234, 0.1);

$sh1_1: rgba(40, 46, 102, 0.05);
$sh1_2: rgba(40, 46, 102, 0.1);
$sh1_3: rgba(40, 46, 102, 0.15);
$sh1_4: rgba(40, 46, 102, 0.2);
$shDM1_1: rgba(23, 22, 46, 0.3);
$shDM1_2: rgba(23, 22, 46, 0.4);
$shDM1_3: rgba(23, 22, 46, 0.5);
$shDM1_4: rgba(23, 22, 46, 0.6);

// Transitions

$t01: 0.1s ease-in-out;
$t1: 0.3s ease-in-out;
$t2: 0.7s ease-in-out;

// Custom cursors
//$closeCursor: url("./close.png"), auto;
//$closeCursorLight: url("./closeLight.png"), auto;

// Dark Mode

$dm-b0: #030303;
$dm-b1: #121217;
$dm-b2: #202030;
$dm-b3: #2e2e43;
$dm-b4: #454558;
$dm-b5: #676781;

$dm-sh0: rgba(0, 0, 0, 0.25);
$dm-sh1: rgba(0, 0, 0, 0.5);
$dm-sh2: rgba(24, 24, 42, 0.59);

$dm-padlockPop: rgba(24, 24, 29, 0.93);

$dm-oL: rgba(244, 117, 50, 0.5); // orange Light
$dm-oL2: rgba(244, 117, 50, 1); // orange Light2
$dm-wL: rgba(255, 255, 255, 0.5); // white Light
$dm-gL: rgba(40, 229, 122, 0.5); // green light
$dm-gL2: rgba(40, 229, 122, 0.35); // green light 2
$dm-rL: rgba(232, 18, 84, 0.65); // red Light

$dm-lBlue: #2aa2e4;
$dm-bL0: rgba(66, 143, 229, 0.1);
$dm-bL1: rgba(66, 143, 229, 0.2);
$dm-bL2: rgba(66, 143, 229, 0.4);

$dm-bV1: rgba(109, 23, 209, 0.5);
$dm-bV2: rgba(109, 23, 209, 0.7);

$dm-green1: #28e57a;
$dm-red1: #e81254;

$dm-log1: #28282e;

:root[data-theme="light"] {
	--textColor: #0098c6;
}

:root {
	--textColor: #c67700;
}

$textColor: var(--textColor);

// Export for vertical input range
:export {
	lGrey: $lGrey;
	dmB3: $dm-b3;
	brandBlack: $brand-black;
	brandOrange: $brand-orange;
	newBlueDark: $newBlue;
	greyShadesMatSilver: $greyShades-matSilver;
	greyShadesSnow: $greyShades-snow;
}

////////// POPUP //////////

// Theme

:root[data-theme="theme-light"] {
	--textColor1: #333333;
	--textColor2: #6f6f7c;
	--bgColor1: #fafafa;
	--bgColor2: #d7d9ea;
	--bgColor3: #f6f7fe;
	--ogBlack: #181a3a;
	--ogOrange: #f47532;
	--ogNewOrange: #ffa674;
	--ogActionBlue: #2833b7;
	--ogActionBlue2: #2833b7;
	--toggleColor: #0cf380;
	--toggleColor44: rgb(161, 167, 231);
	--accessibilityShadow: 40, 52, 183;
	--bgRgbValues: 255, 255, 255;
	--bottomShadesRgb: 246, 247, 254;
	--ogBlue: #39499b;
	--offWhite: #fbfbff;
}

:root[data-theme="theme-dark"] {
	--textColor1: #e3f5fc;
	--textColor2: #d7d9ea;
	--bgColor1: #2e2e43;
	--bgColor2: #4f4f6e;
	--bgColor3: #444463;
	--ogBlack: #444463;
	--ogOrange: #f47532;
	--ogActionBlue: rgb(134, 90, 232);
	--ogActionBlue2: #519fde;
	--toggleColor: #519fde;
	--accessibilityShadow: 161, 167, 231;
	--bgRgbValues: 69, 69, 88;
	--bottomShadesRgb: 47, 47, 69;
	--ogBlue: #2aa2e4;
	--offWhite: #2e2e43;
}

// Variables

$extBgColor1: var(--bgColor1);
$extBgColor2: var(--bgColor2);
$extBgColor3: var(--bgColor3);
$extBgColor4: var(--ogBlack);
$extTextColor1: var(--textColor1);
$extTextColor2: var(--textColor2);
$extPique: var(--ogOrange);
$extAccentColor1: var(--ogActionBlue);
$extAccentColor2: var(--ogActionBlue2);
$extToggleColor: var(--toggleColor);
$extAccessibilityShadow: var(--accessibilityShadow);
$bgRgbValues: var(--bgRgbValues);
$bottomShadesRgb: var(--bottomShadesRgb);

// Layout

$marginPopup: 0.6rem;
$scroolBarSizePopup: 5px;

$extHeightHeader: 1.8rem;
$extHeightMain: 28rem;
$extHeightNav: 3.5rem;
$extMainMT: 1.3rem;

$extHeightPopup: $extHeightHeader + $extMainMT + $extHeightMain + $extHeightNav;

// Funnel (Dashboard Management)

:root {
	--circleStroke: 1rem;
	--pieChartSize: 5em;
	--color2: #6f62d1;
	--color2: #39499b;
	--color2: hwb(230 23% 39%);
	--responsiveSize: clamp(0.7rem, 0.6vw, 1rem);
}

$funnelAccentVar: 240;
$base-color: hwb($funnelAccentVar 46% 42%);
$funnelAccentColor1: #6f62d1;
$funnelAccentColor2: #ffe600;
$funnelTextColor1: #808080;
$funnelBgColor1: #f5f5f5;
$funnelWarnColor1: #ff4500;
$colorBlack: #000000;
$lengthLoop: 6;
$circleStroke: var(--circleStroke);
$responsiveSize: var(--responsiveSize);

$btnSecondary: #a49cde;
$btnSecondaryIcon: #e4ddfe;
$offWhite: var(--offWhite);
$surfaceBlueSide: #8c97fa;
$btnSecondaryIcon: #e4ddfe;

// Sizes
$sizeS: 0.5rem;
$sizeM: 0.75rem;
$sizeL: 1.25rem;
$sizeXL: 2rem;
