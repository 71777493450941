@use "../utils/variables" as *;

@mixin buttonIndic {
	.buttonIndic {
		position: absolute;
		bottom: 1.3rem;
		right: 1rem;
		background-color: $actionBlue;
		height: 2rem;
		width: 2rem;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 100%;
		z-index: 10;
		cursor: pointer;
		box-shadow: 1px 0px 20px $sh1;

		svg {
			height: 0.8rem;
			width: auto;

			path {
				fill: $white;
			}
		}

		&::after {
			position: absolute;
			top: 110%;
			left: calc(50% - 0.2rem);
			content: "";
			width: 0.4rem;
			height: 0.4rem;
			background-color: $actionBlue;
			border-radius: 100%;
		}
	}
}
