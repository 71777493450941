@use "../../../utils/variables" as *;
@use "./helpers" as *;

@mixin popupHeaderBase {
	/* ======================== HEADER RULES ======================= */
	#PopupHeader {
		position: relative;
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding-right: $marginPopup;
		padding-left: $marginPopup;
		box-sizing: border-box;
		margin: 0;

		> * {
			background: none;
			border: none;
		}

		.left {
			display: flex;
			align-items: center;
			height: 100%;
			max-width: 94%;

			.left-btn {
				padding: 0.3rem 0.6rem 0.3rem 0.4rem;
				margin-right: 0.3rem;
				border-radius: 0.2rem;

				@include accessibility("");
				@include extHover("");

				svg {
					width: 0.5rem;
					min-width: 0.5rem;
				}
			}

			> svg {
				width: 1.3rem;
				min-width: 1.3rem;
				margin-right: 0.5rem;
				height: auto;

				path {
					.darkMode & {
						fill: $lGrey;
					}
				}
			}

			.whitemark {
				margin-right: 0.5rem;
				width: 1.3rem;
				min-width: 1.3rem;

				.darkMode & {
					position: relative;

					&::before {
						content: "";
						position: absolute;
						inset: 0;
						background: #ffffff65;
						border-radius: 0.2rem;
						z-index: -1;

						-webkit-backdrop-filter: saturate(180%) blur(20px);
						backdrop-filter: saturate(180%) blur(20px);
					}
				}

				img,
				svg {
					height: 100%;
					width: 100%;
					height: auto;
				}
			}

			.left-child {
				h3 {
					line-height: inherit;
					font-size: 0.6rem;
				}
			}
		}

		.buttonExt {
			button {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 100%;
				padding: 0.4rem 0.6rem;
				border-radius: 0.3rem;
				font-size: 0.7rem;
				font-weight: bold;
				color: $extTextColor1;

				@include backdropFilter(180%, 20px, 0.3);
				@include accessibility("");
				@include extHover("");

				> svg {
					margin-left: 0.5rem;
					height: auto;
					width: 0.8rem;
					min-width: 0.8rem;

					path {
						fill: $extAccentColor2;
						transition: $t1;
					}
				}
			}
		}

		button {
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: center;

			svg {
				height: auto;
				width: 1rem;
				min-width: 1rem;

				path {
					fill: $extTextColor1;
					transition: $t1;
				}
			}

			p {
				font-size: 0.65rem;
				margin-bottom: 0.1rem;
			}

			img:hover {
				cursor: pointer;
			}

			&:hover {
				path {
					fill: $lBlue;
				}
			}
		}

		#pwdNb {
			width: 1.1rem;
			height: 1.1rem;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			color: white;
			background-color: #39499b;
			font-size: 0.55rem;
			font-weight: 600;
		}
	}
}
