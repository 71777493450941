@use "../../utils/variables" as *;

@mixin emailWm {
	.emailWm {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		z-index: 500;
		background-color: red;

		.topBar {
			display: flex;
			background-color: $dm-b3;
			width: 100%;
			min-height: 2rem;

			.svg {
				display: flex;
				justify-content: flex-end;
				padding-top: 0.3rem;
				padding-left: 1rem;
				padding-right: 0.3rem;

				svg {
					height: 0.7rem;
					display: flex;
				}
			}

			svg path,
			svg rect {
				fill: $dm-b5;
			}

			.ligne {
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;

				span {
					display: flex;
					width: 8rem;
					border: 3px $dm-b4 solid;
					box-sizing: border-box;
					border-radius: 5px;

					&:nth-child(2) {
						width: 6rem;
					}
				}
			}

			.dot {
				display: flex;
				flex-grow: 1;
				justify-content: flex-end;
				align-items: center;
				margin-right: 1rem;

				span {
					box-sizing: border-box;
					border-radius: 100%;
					background-color: $dm-b5;
					height: 0.85rem;
					width: 0.85rem;

					&:nth-child(2) {
						margin: 0 0.5rem;
					}
				}
			}
		}

		.containBox {
			display: flex;
			height: 100%;

			.leftBar {
				display: flex;
				height: 100%;
				width: 18rem;
				background-color: $dm-b4;

				.box {
					display: flex;
					flex-direction: column;
					justify-content: center;
					height: 6rem;
					width: 100%;
					background-color: $dm-b5;
					margin: 1rem 1rem 0 2rem;
					border-radius: 7px;

					span {
						display: flex;
						height: 0.5rem;
						box-sizing: border-box;
						border-radius: 5px;
						margin-left: 0.5rem;

						&:nth-child(1) {
							background-color: $ciment;
							width: 6rem;
						}

						&:nth-child(2) {
							margin-top: 1rem;
							margin-bottom: 0.5rem;
							background-color: $dm-b4;
							width: 10rem;
						}

						&:nth-child(3) {
							background-color: $dm-b4;
							width: 8rem;
						}
					}
				}
			}

			.rightBar {
				display: flex;
				height: 100%;
				width: 100%;
				flex-direction: column;

				.top {
					display: flex;
					width: 100%;
					height: 4rem;
					background-color: $dm-b4;
					align-items: center;
					padding-left: 1rem;

					.circle {
						border-radius: 100%;
						background-color: $dm-b5;
						height: 2rem;
						width: 2rem;
						display: flex;
						align-items: center;
						justify-content: center;

						h3 {
							color: $white;
						}
					}

					.ligne {
						display: flex;
						flex-direction: column;
						margin-left: 1rem;

						span {
							box-sizing: border-box;
							border-radius: 7px;
							background-color: $dm-b5;
							height: 0.5rem;
							width: 8rem;
						}

						div {
							display: flex;
							flex-direction: row;
							align-items: center;

							p {
								margin: 0.5rem 0 0 0;
								color: $white;
							}

							span {
								box-sizing: border-box;
								border-radius: 7px;
								background-color: $dm-b5;
								height: 0.5rem;
								width: 10rem;
								margin: 0.5rem 0 0 0.5rem;
							}
						}
					}
				}

				.bottom {
					width: 100%;
					height: 100%;
					background-color: $lGrey;
					display: flex;
					justify-content: center;
					align-items: center;
					overflow-y: auto;

					.container {
						display: flex;
						flex-direction: column;
						padding-bottom: 5rem;
						padding-top: 39.6rem;
						margin-left: 0.4rem;
					}

					.central {
						display: flex;
						flex-direction: column;
						background-color: $white;
						width: 40rem;
						height: fit-content;

						.banner {
							display: flex;
							margin: 1rem 1rem 0 1rem;
							height: 8rem;
							background-position: center;
							background-repeat: no-repeat;
							background-origin: content-box;
							background-size: cover;
						}

						.arrow {
							display: flex;
							height: 2rem;
							width: 2rem;
							background-color: $white;
							margin: -0.3rem 0 0 3rem;
							transform: rotate(45deg);
							box-shadow: 0px 2px 10px #5775a529;
							border-radius: 2px;
						}

						.boxBg {
							display: flex;
							flex-direction: column;
							background-color: $lGrey;
							padding-bottom: 1rem;
							width: calc(100% - 2rem);
							align-self: center;
							border-radius: 5px;

							.body {
								display: flex;
								height: 10rem;
								background-color: $white;
								width: calc(100% - 2rem);
								align-self: center;
								margin-top: -1.4rem;
								z-index: 1;
								flex-direction: column;
								box-shadow: 0px 2px 10px #5775a529;
								border-radius: 5px;

								span {
									box-sizing: border-box;
									border-radius: 7px;
									background-color: $lGrey;
									height: 0.6rem;
									width: 10rem;
									margin: 0.5rem 0 0 1.2rem;

									&:nth-child(1) {
										margin-top: 1.65rem;
										background-color: $ciment;
									}
								}

								.box {
									display: flex;
									margin: 2.3rem 0 0 1.4rem;

									.circle {
										border-radius: 100%;
										background-color: $ciment;
										height: 2rem;
										width: 2rem;
										display: flex;
										align-items: center;
										justify-content: center;

										h3 {
											color: $white;
										}
									}

									.ligne {
										display: flex;
										flex-direction: column;
										margin-left: 1.4rem;

										span {
											box-sizing: border-box;
											border-radius: 7px;
											background-color: $lGrey;
											height: 0.6rem;
											width: 10rem;
											margin: 0.5rem 0 0 0.5rem;

											&:nth-child(1) {
												background-color: $ciment;
											}
										}
									}
								}
							}
						}

						.button {
							display: flex;
							align-items: center;
							justify-content: center;
							width: calc(100% - 17rem);
							height: 2.7rem;
							background-color: var(--ogButton);
							margin: 2rem 0;
							border-radius: 3px;
							align-self: center;

							.line {
								border: 4px $bg-white solid;
								box-sizing: border-box;
								border-radius: 5px;
								width: 40%;
							}
						}

						.bottomline {
							box-sizing: border-box;
							border-radius: 7px;
							background-color: $ciment;
							height: 0.6rem;
							width: 7.1rem;
							margin: -0.3rem 1.6rem 0 0.5rem;
							align-self: flex-end;
						}

						.logo {
							display: flex;
							flex-direction: column;
							align-items: center;
							margin-top: 3.3rem;

							svg {
								height: 1rem;
								width: 1rem;
							}

							span {
								box-sizing: border-box;
								border-radius: 7px;
								background-color: $lGrey;
								height: 0.5rem;
								width: 5.4rem;
								margin: 0.5rem 0 2rem 0.5rem;
							}
						}

						&.email {
							margin-top: 2rem;
						}
					}
				}
			}
		}
	}
}
