@use "../../utils/variables" as *;
@use "../../components" as *;

@mixin followIdsFrame {
	#followIdsFrame {
		--textColor1: #333333;
		--bgColor3: #f6f7fe;

		max-height: 90vh;
		width: 17rem;
		padding: 2rem;
		display: flex;
		flex-direction: column;
		padding: 0.6rem 2rem 0.3rem 0.8rem;
		visibility: visible !important;
		overflow: hidden;
		animation: followIdsHide 0.5s ease-in-out;
		position: absolute;
		width: 100%;

		form {
			.createPassBut {
				button[type="submit"] {
					&.grey {
						color: $black;
						background: $ciment;
						cursor: default;

						svg {
							* {
								fill: $black;
							}
						}
					}
				}
			}
		}

		&.show {
			overflow-y: auto;
			animation: followIdsAppear 0.5s ease-in-out;
		}

		&.maxxed {
			width: 25rem;
		}

		&.domToastViewFrame {
			max-width: 100%;
			box-sizing: border-box;
			padding-right: 3rem;
			padding-bottom: 3rem;

			> #followIds {
				padding: 0.5rem 0.8rem;
				box-shadow: 0 0 1rem $sh4;
			}
		}

		.domToastView {
			display: flex;
			align-items: center;
			position: relative;
			width: 100%;

			.spinner {
				flex: 2;
				justify-content: center;
			}

			> svg {
				width: 1.3rem;
				min-width: 1.3rem;
				margin-right: 1rem;
			}

			> p {
				font-size: 0.8rem;
			}

			.stateIcon {
				position: absolute;
				bottom: 90%;
				width: 100%;
				display: flex;
				justify-content: center;
				height: 1.4rem;

				> svg {
					height: auto;
					width: 1.4rem;
					position: fixed;
				}
			}
		}

		@include Button;
		@include CheckBox;
		@include selectCategory;
	}
}

@mixin followIds {
	#followIds {
		box-shadow: 0 0 1.4rem $sh4;
		background-color: $white;
		padding: 1rem;
		border: 1px solid $lGrey;
		border-radius: 3px;
		z-index: 1;
		transition: $t1;
		max-height: 100%;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		&.hide {
			overflow: hidden;
			max-height: 0;
			visibility: hidden;
			margin: 0;
		}

		> svg {
			margin-bottom: 0.5rem;
			min-height: 1.3rem;
			height: 1.3rem;
			width: auto;
		}

		.followIdsTitle {
			margin-top: 0.5rem;

			span {
				font-size: 0.8rem;
				color: $anthra;
				font-weight: 500;
			}

			h3 {
				color: $black;
				margin-top: 0.3rem;
				font-size: 0.85rem;
			}
		}

		.popupPage {
			form {
				.formContainer {
					.inputAndLabel {
						.inputContainer,
						.fileLine,
						input {
							background: $lGrey;
						}
					}
				}
			}
		}

		.passWithCatView {
			margin-top: 1rem;
			overflow: hidden;

			> span {
				display: flex;
				align-items: center;
				margin-bottom: 0.25rem;

				> svg {
					width: 0.7rem;
					min-width: 0.7rem;
					height: auto;
					margin-right: 0.4rem;
				}

				> b {
					font-size: 0.75rem;
					color: $anthra;
				}
			}

			.passWithCatView-container {
				background-color: $lGrey;
				border-radius: 3px;
				padding: 0.5rem 0.7rem;
				display: flex;
				align-items: center;

				.passBody {
					margin-left: 0.5rem;
					display: flex;
					flex-direction: column;

					> b {
						font-size: 0.75rem;
						margin-bottom: 0.15rem;
						color: $anthra;
					}

					> span {
						display: flex;
						align-items: center;
						font-size: 0.75rem;
						font-weight: 500;
						color: $black;
						font-weight: 600;

						> svg {
							min-width: 0.45rem;
							width: 0.45rem;
							margin-right: 0.3rem;

							path,
							circle {
								fill: $lCoal;
							}
						}
					}
				}
			}

			> small {
				color: $anthra;
				font-size: 0.6rem;
				font-weight: 500;
			}
		}

		.twoLinesMax {
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			display: -webkit-box;
			font-weight: 500;
			margin-right: 0.5rem;
		}
	}
}

@mixin closeFollowFrame {
	.closeFollowFrame {
		display: flex;
		justify-content: space-between;
		padding-bottom: 2rem;
		position: relative;
		overflow: hidden;
		order: 2;
		min-height: 1.5rem;

		&.hide {
			overflow: hidden;
			max-height: 0;
			visibility: hidden;
			margin: 0;
		}

		.main,
		.secondary {
			background-color: $extPique;
			color: $white;
			font-size: 0.75rem;
			font-weight: 500;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			min-height: 1rem;
			padding: 0.3rem 1.7rem;
			border-bottom-right-radius: 7px;
			border-bottom-left-radius: 7px;
			margin: 0 0.3rem;
			cursor: pointer;
			transition: $t1;
			transform: translateY(-7%);

			> svg {
				height: 0.6rem;
				width: auto;
				margin-right: 0.5rem;

				path {
					fill: $white;
				}
			}
		}

		.secondary {
			padding: 0.3rem 0.7rem;
			background-color: $black;
			color: $lGrey;
			font-size: 0.6rem;
			transform: translateY(-70%);

			> svg {
				height: 0.4rem;
				width: auto;
				margin-right: 0.4rem;
			}
		}

		.shadow {
			transition: $t1;
			position: absolute;
			top: -1rem;
			height: 0.8rem;
			width: calc(95% - 4rem);
			box-shadow: 0 0 0.5rem $sh5;
		}

		&:hover {
			.main,
			.secondary {
				padding: 0.7rem 1rem;
				z-index: 0;
				box-shadow: 0 0 1rem $sh3;
			}

			.main {
				padding: 0.7rem 2rem;
			}

			.secondary {
				transform: translateY(-7%);
			}

			.shadow {
				box-shadow: 0 0.1rem 1.3rem $sh5;
			}
		}
	}
}
