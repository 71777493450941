@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;

#useAppMsg {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	background: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 1) 0%,
		rgba(130, 150, 200, 0.39) 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

	@include mediaMaxCustom(400px) {
		background: #fff;
		padding: 2vh 0;
	}

	.useAppMsg-container {
		display: flex;
		align-items: center;
		flex-direction: column;
		text-align: center;
		background-color: rgba(255, 255, 255, 0.9);
		border-radius: 5px;
		max-width: 40rem;
		padding: 5vw;
		box-sizing: border-box;
		backdrop-filter: blur(20px);

		@include mediaMaxCustom(700px) {
			max-width: 90%;
			width: 90%;
			padding: 5vw;
		}

		@include mediaMaxCustom(400px) {
			background-color: transparent;
			padding: 3vw;
			width: 100%;
			max-width: 100%;
			backdrop-filter: none;
		}

		.link {
			img {
				max-height: 4rem;
				max-width: 4rem;
				border-radius: 0.5rem;
			}
		}

		h1 {
			font-size: 2rem;
			font-weight: 600;
			margin: 0;
			margin-top: 2rem;
			margin-bottom: 1rem;
			text-transform: uppercase;
		}

		.subtitle {
			margin: 0;
			font-size: 1.1rem;
			max-width: 90%;
		}

		.content {
			display: flex;
			align-items: center;
			justify-content: space-around;
			margin-top: 2rem;
			max-width: 100%;

			@include mediaMaxCustom(1000px) {
				flex-direction: column;
			}

			.content-link {
				min-width: 20vw;
				max-width: 2rem;
				padding: 2vw;
				max-width: 40%;
				box-sizing: border-box;

				@include mediaMaxCustom(1000px) {
					width: 40%;
					padding: 2vw;
					max-width: unset;
				}

				@include mediaMaxCustom(700px) {
					width: 60%;
					padding: 4vw;
				}

				@include mediaMaxCustom(400px) {
					width: 70%;
					max-width: unset;
					padding: 2vh 0;
				}

				img {
					margin: 0;
					width: 100%;
				}
			}
		}
	}
}
