@use "../../../utils/" as *;
@use "./helpers" as *;
@use "../../../components/" as *;

@mixin homePopupBase {
	#HomePopup {
		position: relative;

		.homeLeftTopShadow {
			position: absolute;
			height: 5rem;
			width: 5rem;
			right: calc(100% + 1rem);
			top: 10%;
			box-shadow: 0 0 3rem 1rem $sh1;

			.darkMode & {
				box-shadow: 0 0 3rem 1rem $dm-sh2;
			}
		}

		.website {
			margin-top: 1rem;
			padding: 0.4rem;
			border-radius: 0.3rem;
			box-shadow: 0 1px 0.5rem $sh0;

			.topPart {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 1rem;

				.siteInfos {
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 100%;
					min-width: 0;
					min-height: 0;

					.flex {
						display: flex;
						min-width: 0;
						min-height: 0;

						.favIcon {
							width: 30px;
							height: 30px;
							position: relative;

							img {
								height: 100%;
								width: 100%;
								position: unset;
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;
							}
						}

						.text {
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							padding: 0 0.5rem;
							box-sizing: border-box;
							overflow: hidden;

							.link {
								font-size: 0.6rem;
								font-weight: 500;
								color: $extTextColor2;

								@include ellipsisHelperPopup;
							}

							.title {
								margin: 0;
								text-transform: capitalize;
								color: $extTextColor1;
								font-size: 0.9rem;

								@include ellipsisHelperPopup;
							}
						}
					}

					.addPwd {
						border: none;
						background: $extAccentColor1;
						border-radius: 200px;
						margin: 0;
						padding: 0;
						flex-shrink: 0;
						height: 1.5rem;
						width: 1.5rem;
						display: flex;
						align-items: center;
						justify-content: center;

						@include accessibility("");
						@include extHover("");

						span {
							font-size: 1.7rem;
							color: $white;
						}
					}
				}
			}

			.center {
				display: flex;
				justify-content: center;
				transform: translateY(calc(0.8rem + 0.4rem));
				margin-top: -0.8rem;

				.secondaryButton {
					flex-grow: 0;
					background-color: $extBgColor3;
					color: $extTextColor1;
					@include popupBtn;
				}
			}

			@include Credential {
				border: none;
			}
		}

		.others {
			margin-top: 4rem;
			@include Credential;
		}

		nav {
			margin: 1.5rem 0 0;

			.current {
				color: coral;
			}

			button {
				position: relative;
				width: 100%;
				margin: 0.6rem auto;
				display: flex;
				justify-content: center;
				align-items: center;
				border: none;
				font-size: 0.75rem;
				font-weight: 600;
				padding: 0.7rem;
				border-radius: 3px;

				svg {
					position: absolute;
					top: 30%;
					left: 0.85rem;
					height: 40%;
				}
			}

			button:hover {
				cursor: pointer;
			}
		}

		@include BadFavIcon;

		img {
			position: absolute;
			top: 30%;
			left: 0.85rem;
			height: 40%;
		}

		.submit {
			background-color: $extAccentColor2;
			color: white;

			svg g > * {
				fill: white;
			}
		}

		@include SearchBarSection;
	}
}
