@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;
@use "../../components/" as *;
@use "./popinHeader" as *;

@mixin warningPop() {
	#miniPopFrameTarget.miniPop.warning {
		.head {
			p {
				color: $red;
			}

			.head-text {
				text-align: left;

				em {
					font-style: normal;
					font-weight: 600;
				}
			}
		}

		.buttons {
			button {
				padding: 0.7rem 1rem;
				background: repeating-linear-gradient(
					45deg,
					$lYellow 0%,
					$lYellow 9.5%,
					$yellow 9.5%,
					$yellow 19%
				);
				color: $brand-black;
				font-size: 0.8rem;
			}
		}
	}
}
