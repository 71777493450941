@use "../../../utils/" as *;
@use "../../../components" as *;
@use "./helpers" as *;

@mixin credentialsDetailsBase {
	.CredentialDetails {
		width: 100%;
		margin: 0.6rem auto 0.4rem;
		flex-direction: column;
		justify-content: space-between;

		&:not(.full) {
			background-color: unset;
			backdrop-filter: none;
			-webkit-backdrop-filter: none;
		}

		h1,
		h2 {
			font-size: 0.7rem;
			font-weight: 600;
		}

		h1 {
			color: $extTextColor2;
		}

		h2 {
			color: $extTextColor1;
		}

		.descriptionContainer p {
			font-size: 0.5rem;
			font-weight: 500;
			color: $extTextColor1;
		}

		.siteInfos {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			row-gap: 0.4rem;

			.favIcon {
				width: 30px;
				height: 30px;
				position: relative;
				border-radius: 0.2rem;
				align-self: center;

				img {
					height: 100%;
					width: 100%;
					position: unset;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
				}
			}

			.link {
				font-size: 0.6rem;
				font-weight: 500;
				color: $extTextColor2;
				max-width: 80%;
				text-transform: capitalize;

				.link-text {
					padding-left: 0.2rem;
					display: inline-flex;

					b {
						background-color: $ciment;
						height: 0.8rem;
						width: 0.8rem;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 100%;

						.darkMode & {
							background-color: $dm-b4;
						}
					}
				}

				@include ellipsisHelperPopup;
			}

			.passwordButtons {
				height: 100%;
				display: flex;
				align-items: center;
				z-index: 2;
				color: $extTextColor2;

				&.hover {
					opacity: 0%;
					pointer-events: none;
					animation: opacity 0.4s ease-in-out;
				}

				.passwordButtons-action {
					background-color: $extBgColor3;
					border-radius: 0.2rem;
					border: none;
					fill: $extTextColor2;
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 0 0.2rem;
					padding: 0;
					width: 1.5rem;
					height: 1.5rem;

					.copyListButton svg {
						width: 95%;
					}

					svg {
						width: auto;
						height: convertToREM(17);
						padding: 0.1rem;
					}

					svg > *,
					g > * {
						fill: $extTextColor2;
					}

					@include extHover("");

					&:hover {
						background-color: $extAccentColor2;
						cursor: pointer;

						svg > *,
						g > * {
							fill: $white;
						}
					}
				}
			}

			.title {
				margin: 0;
				text-transform: capitalize;
				color: $extTextColor1;

				word-break: break-word;
			}

			.description {
				font-size: 0.7rem;
				font-weight: 500;
				color: $extTextColor2;
				opacity: 0.8;
			}
		}

		.credentialNoRights {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 3rem 1rem;
			text-align: center;

			b {
				font-size: 0.8rem;
				margin-bottom: 0.2rem;
				color: $extTextColor1;
			}

			span {
				font-size: 0.7rem;
				color: $extTextColor2;
			}

			svg {
				margin-bottom: 0.8rem;
				width: unset;
				height: 1rem;

				path,
				rect {
					fill: $extAccentColor2;
				}
			}
		}

		.credentialNoData {
			text-align: center;
			font-size: 0.8rem;
			font-weight: 500;
			color: $extTextColor1;
			margin: 3rem 0;
		}

		/* ================ CREDENTIAL DETAILS PAGE RULES ============== */
		/* =================== HIDE/SHOW VALUES RULES ================== */
		.visibilityContainer {
			width: 100%;
			display: flex;
			justify-content: center;

			.visibilityContainer-content {
				margin: 0.8rem 0;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 1.2rem;
				background-color: $extBgColor3;
				border-radius: 0.3rem;

				@include extHover("");

				button {
					display: flex;
					justify-content: center;
					align-items: center;
					border: none;
					background: none;
					padding: 0;
					border-radius: 0.2rem;

					@include accessibility("");

					svg {
						width: 1.6rem;
						height: 1.3rem;
						border-radius: 0.3rem;
					}
				}

				.inactive {
					background-color: $extBgColor3;
					padding: 0.2rem 0.3rem;

					g > * {
						fill: $lCoal;
					}
				}

				.active {
					background-color: $extAccentColor2;
					padding: 0.2rem 0.3rem;

					g > * {
						fill: $white;
					}
				}
			}
		}

		/* ================ CREDENTIAL DETAILS PAGE RULES ============== */
		/* =================== INPUTS CONTAINERS RULES ================= */

		.Detail {
			h2 {
				margin-top: 0.8rem;
				margin-bottom: 0.1rem;

				svg {
					max-height: 0.6rem;
					width: 0.6rem;
					margin-right: 0.2rem;

					g > * {
						fill: #979798;
					}
				}
			}

			.detailContainer {
				height: 2rem;
				padding: 0.3rem 0.5rem;
				background-color: $extBgColor3;
				display: flex;
				justify-content: space-between;
				align-items: center;

				input {
					border: none;
					background: none;
					font-size: 0.7rem;
					font-weight: 500;

					&[type="password"] {
						letter-spacing: 3px;
						font-size: 1rem;
					}
				}

				button {
					background: none;
					border: none;
					border-radius: 0.2rem;

					@include accessibility("");
					@include extHover("");

					svg {
						height: 0.8rem;
						width: 0.8rem;

						path,
						circle {
							fill: $grey;
						}
					}
				}

				&.urlList {
					height: unset;
					background-color: unset;
					display: grid;
					grid-template-columns: minmax(0, 1fr);
					row-gap: convertToREM(5);
					padding-top: 0.8rem;

					.urls {
						width: 100%;
						display: grid;
						grid-auto-flow: column;
						align-items: center;
						justify-content: start;
						column-gap: convertToREM(6);

						&:hover {
							cursor: pointer;
						}

						.detailContainer-link {
							position: relative;
							font-size: 0.7em;
							line-height: 0.9rem;
							color: $extTextColor1;
							display: flex;
							align-items: center;
							color: $greyShades-grey;
						}

						svg {
							height: auto;
							width: 0.8rem;

							path,
							circle {
								fill: $grey;
							}
						}
					}
				}
			}
		}

		/* ================ CREDENTIAL DETAILS PAGE RULES ============== */
		/* ===================== URL CONTAINER RULES =================== */
		.urlContainer {
			margin: 1.4rem auto;
			font-size: 0.65rem;
			font-weight: 500;
			color: $extTextColor2;
			display: flex;
			align-items: center;

			@include ellipsisHelperPopup;
			word-break: break-word;
			white-space: normal;

			svg {
				max-height: 0.8rem;
				width: 0.8rem;
				min-width: 0.8rem;
				height: auto;
				margin-right: 0.3rem;
				vertical-align: middle;

				g > * {
					fill: $extTextColor2;
				}
			}

			&:hover {
				cursor: pointer;
			}
		}

		@include containerHidenSeek("pass", true) {
			@include completionCircle {
				.CompletionCircle {
					height: convertToREM(22);

					.remaining-time {
						font-size: convertToREM(8);
					}
				}
			}

			&.Totp {
				.totp-elem {
					@include containerInfoSlide(2rem, true, true, 7%, 2, true);
				}

				.totp-text-container {
					p {
						font-size: convertToREM(11);

						em {
							font-size: convertToREM(11.2);
							color: $brand-black;
						}
					}
				}

				.hiddenSeekPart {
					.hiddenSeekSlide {
						padding: convertToREM(3) convertToREM(11)
							convertToREM(3) convertToREM(8);
						min-height: 0;

						.copyToClip {
							min-width: 0;
							width: convertToREM(13);
							min-height: 0;
							height: auto;

							* {
								fill: $grey;
								stroke: $brand-black;
							}
						}
					}
				}
			}
		}
	}
}
