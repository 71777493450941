@use "../../organisms/popup" as *;
@use "../../components" as *;
@use "../../utils" as *;
@use "../../components/toggler/toggler" as *;
@use "../../components/tooltipHelper" as *;
@use "../../organisms/popup/passwordPolitics" as *;

//////////////////////////////////
///////////  MINI POP ////////////
//////////////////////////////////
@mixin miniPopFrame {
	.miniPopFrame {
		$popup: "miniPop";

		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-backdrop-filter: blur(3px);
		backdrop-filter: blur(3px);
		background-color: rgba(215, 217, 234, 0.23);
		z-index: 1100;

		.darkMode & {
			background-color: rgba(26, 26, 47, 0.2);
		}

		&.noBlur {
			-webkit-backdrop-filter: blur(1px);
			backdrop-filter: blur(1px);
		}

		&.is-trash-pass {
			.miniPopFrame-container {
				width: 100%;
				height: 100%;

				@include HeaderPop($popup, "lockPassTrash");

				.miniPop {
					width: 100%;
					max-width: convertToREM(415);
					padding: convertToREM(15) convertToREM(20);

					@include TrashPassRestorationSelection;

					@include ButtonLayout("PopupButton") {
						> button {
							font-size: convertToREM(15);
							font-weight: 600;
							line-height: convertToREM(18);
						}
					}
				}
			}
		}

		@include animHelperFrame;
		@include select;

		.newLicDropSessions {
			width: 100%;

			button {
				width: 100%;
			}
		}

		.errorFormMsg {
			font-size: 0.7rem;
			color: $red;
			font-weight: 500;
			min-height: 1rem;
			margin-left: 0.1rem;
			margin-top: 1rem;
			margin-bottom: 0.4rem;
		}

		.miniPopFrame-container {
			top: 0;
			left: 0;
			position: absolute;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-end;
			padding: 0.5vw;
			box-sizing: border-box;

			.mandatory {
				color: $red;
			}

			@include formV2;

			@include TogglerFrame {
				margin-right: 0.5rem;
			}

			.miniPop {
				background-color: $white;
				border-radius: 4px;
				min-width: 20rem;
				max-width: 24rem;
				padding: clamp(0.8rem, 2.2vh, 1.2rem) clamp(1rem, 2vw, 1.3rem)
					clamp(0.6rem, 1.4vh, 0.9em);
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				color: $black;
				cursor: default;
				position: relative;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;

				&.size-height {
					max-height: 50vh;
				}

				&.removeMfaPopup {
					width: 24.9rem;
				}

				.darkMode & {
					background-color: $dm-b2;
					color: $ciment;
				}

				&.importMiniPop {
					min-width: convertToREM(440);
					padding: convertToREM(20);

					.loaderContainer {
						width: 100%;
						display: flex;
						justify-content: center;
						margin-bottom: convertToREM(10);
					}
				}

				&.ppDelete {
					padding-bottom: 1.25rem;

					.head:not(.pin) {
						.head-subtitle {
							color: $greyShades-wetGrey;
						}
					}

					.miniButton.col > button {
						margin-bottom: 0.75rem;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}

				&.LFHandleDuplicateMinipop {
					button {
						font-size: 0.9rem;
					}

					// @include ButtonLayout("PopupButton") {
					//     > button.orange {
					//         background: repeating-linear-gradient(45deg, $indicators-warning, $indicators-warning convertToREM(20), rgba($indicators-warning, 0.8) convertToREM(20), rgba($indicators-warning, 0.8) convertToREM(40));
					//         background-size: 100% 100%;
					//         background-repeat: no-repeat;
					//         background-position: left center;

					//         .icon {
					//             * {
					//                 .darkMode & {
					//                     fill: $black;
					//                 }
					//             }
					//         }
					//     }

					//     > button.grey {
					//         .icon * {
					//             stroke-width: convertToREM(0.7);

					//             .darkMode & {
					//                 stroke: $greyShades-matSilver;
					//             }
					//         }
					//     }
					// }
				}

				.buttonsContainer {
					display: flex;
					flex-direction: column;
					gap: convertToREM(12);
				}
			}

			@include HeaderPop($popup);

			@include InputFrame {
				.inputFrame {
					&.error {
						.errorMsg {
							align-self: flex-start;
						}
					}

					.inputTitle {
						opacity: 1 !important;
					}

					.inputWrap {
						&.params {
							input {
								border: none;

								.darkMode & {
									border: none;
								}

								&:focus {
									.darkMode & {
										border: none;
									}
								}
							}
						}

						input {
							.darkMode & {
								background-color: $dm-b3;
							}

							&:focus {
								border: 1.5px solid $black;

								.darkMode & {
									border: 1.5px solid $grey;
								}
							}
						}
					}

					&.focused {
						margin-top: 0 !important;
					}
				}
			}

			.center {
				text-align: center;
			}

			@include ButtonLayout("PopupButton");
		}

		@include floatingToast;
		@include warningPop;
		@include notifsPop;
	}
}

//////////////////////////////////
////////// ACTION POP ////////////
//////////////////////////////////
@mixin popinFrame {
	.popinFrame {
		$popup: "actionPop";

		.darkMode & {
			background-color: $bg-actionPop;
		}

		@include Default;
		@include animHelperFrame;
		@include select;
		@include lockerFrame;
		@include tabs;

		.containerPopinFrame {
			width: 51%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-end;
			margin-bottom: 3vh;
			position: relative;

			@include mediaLarge {
				margin-bottom: 2vh;
			}

			@include LoaderPop;

			#actionPopClose svg {
				margin-right: 10px;
				margin-bottom: 10px;
				width: 30px;
				height: 30px;
				cursor: pointer;

				@include mediaLarge {
					margin-right: 0.3vw;
					margin-bottom: 0.5vw;
					width: 1.7vw;
					height: 1.7vw;
				}

				&:hover {
					animation: 0.4s 0.2s ease-in-out wiggle;
				}

				path {
					height: 30px;
					stroke: $white;

					@include mediaLarge {
						height: 1.7vw;
					}

					.darkMode & {
						stroke: $ciment;
					}
				}
			}

			.popinWrap {
				background-color: $white;
				width: 100%;
				box-sizing: border-box;
				padding: 25px;
				border-radius: 5px;
				position: relative;
				display: flex;
				flex-direction: column;
				max-height: 85vh;
				justify-content: flex-start;
				overflow: hidden;

				@include mediaLarge {
					padding: 2vh 1.7vw;
					border-radius: 0.4vw;
				}

				.darkMode & {
					background-color: $dm-b3;
				}

				.helpLine {
					display: flex;
					align-items: center;
					margin-top: 10px;
					margin-bottom: 25px;

					@include mediaLarge {
						margin-top: 1.4vh;
						margin-bottom: 3vh;
					}

					> svg {
						min-width: 21px;
						margin-right: 13px;
					}

					span {
						font-size: 14px;
						font-weight: 500;
						color: $anthra;

						@include mediaLarge {
							font-size: clamp(0.7rem, 1vw, 1rem);
						}

						.darkMode & {
							color: $grey;
						}
					}
				}
			}

			@include HeaderPop($popup);

			.watermark {
				position: absolute;
				right: 7%;
				top: -7%;
				left: -20px;
				text-align: right;
				transform: rotate(-90deg);
				transform-origin: center right;
				font-size: 130px;
				color: $ciment;
				opacity: 0.13;
				text-transform: uppercase;
				z-index: 1;

				@include mediaMinCustom(1600px) {
					font-weight: 500;
					cursor: default;
					overflow: hidden;
					width: calc(100% + 20px);
				}

				@include mediaLarge {
					font-size: 7vw;
				}

				.darkMode & {
					-webkit-text-stroke: 1.5px $grey;
					color: transparent;
				}
			}

			@include BodyPop($popup);
			@include ThinBodyPop($popup);
		}

		.importPassMenu {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin: 15px 0 10px;

			// > button {
			// 	border: none;
			// 	background-color: $bg-blue;
			// 	color: $white;
			// 	font-family: $mon;
			// 	font-weight: 600;
			// 	padding: 10px 15px;
			// 	border-radius: 5px;
			// 	cursor: pointer;
			// }

			@include fileLine {
				flex: 2;
				margin-left: 0;
			}
		}

		.importKeePass {
			flex-direction: column;
			justify-content: flex-start;
			align-items: stretch;

			.inputPass {
				justify-content: flex-end;

				.action {
					right: 1.5vw !important;
					align-self: center;
				}
			}

			.kdbxSelect {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				p {
					margin-right: 1rem;
					font-weight: 500;

					.darkMode & {
						color: $greyShades-matSilver;
					}
				}

				.selectFrame select {
					width: 12rem;
				}
			}

			.warningKdbx {
				//margin-top: 0.3rem;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				font-size: 12px;
				font-weight: 500;

				p {
					.darkMode & {
						color: $greyShades-matSilver;
					}
				}

				svg {
					margin-right: 0.5rem;
					width: 1rem;

					g {
						circle {
							fill: $indicators-warning;
						}

						path:first-child {
							stroke: $indicators-warning;
						}

						path:last-child {
							fill: $indicators-warning;
						}
					}
				}
			}
		}
	}
}

//////////////////////////////////
///////////  THIN POP ////////////
//////////////////////////////////
@mixin thinPopinFrame {
	.thinPopinFrame {
		$popup: "thinPop";

		@include Default;

		backdrop-filter: blur(6px);
		background-color: rgba(215, 217, 234, 0.4);

		.darkMode & {
			background-color: rgba(26, 26, 47, 0.2);
			backdrop-filter: blur(7px);
		}

		&.licenseWarnMsg {
			.thinPopinFrameInside {
				.popinWrap {
					max-width: 30rem;
				}
			}
		}

		@include formV2;
		@include animHelperFrame;
		@include select;
		@include buttonIndic;
		@include WhiteBackground;
		@include thinPopLockPass;
		@include licenceView;
		@include licenseWarnMsg;
		@include PasswordPolitics;

		.thinPopinFrameInside {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-end;
			position: relative;

			.thinPopinClose {
				position: fixed;
				top: 5vh;
				right: 3rem;
				background-color: $white;
				border-radius: 100%;
				height: 2.8rem;
				width: 2.8rem;
				display: flex;
				align-items: center;
				justify-content: center;
				z-index: 10;
				transition: $t1;
				cursor: pointer;

				> svg {
					height: auto;
					width: 1.1rem;

					path {
						fill: $anthra;
						transition: $t1;
					}

					g[data-name~="secondTone"] {
						path {
							.darkMode & {
								fill: $blue;
							}
						}
					}

					g[data-name~="people"] {
						path {
							.darkMode & {
								fill: $ciment;
							}
						}
					}
				}

				&:hover {
					background-color: $actionBlue;

					path {
						fill: $white;
					}
				}
			}

			.popinWrap {
				max-height: 90vh;
				width: 27.5rem;
				display: flex;
				flex-direction: column;

				// this selector can target ==> .popinHeader, .waterMark, .popinBody, .thinPopHeader
				> div,
				> header {
					background-color: $white;
					border-radius: 5px;
					padding: 0.6rem 0.9rem;
					cursor: default;

					.darkMode & {
						background-color: $dm-b2;
						box-shadow: 0 0 2rem 0 $dm-padlockPop;
					}
				}

				@include HeaderPop($popup);

				&.noPadding {
					.popinBody {
						padding: 0;
					}
				}

				@include BodyPop($popup);
				@include ThinBodyPop($popup);
			}

			@include slider("thinPop") {
				.sliderBtn {
					&.btnLeft,
					&.btnRight {
						margin-right: 0;
						margin-left: 0;
					}

					.darkMode & {
						background-color: $dm-b2;
					}
				}

				.x.container {
					> div {
						margin: 0.65rem 0;

						.sliderItem {
							width: calc(50% - 0.3rem);
							height: 100%;
							display: block;
							margin: 0 0.1rem;
							text-align: start;

							&:first-child {
								margin-left: 0.1rem;
								margin-right: 0.05rem;
							}

							&:last-child {
								margin-left: 0.05rem;
								margin-right: 0.1rem;
							}
						}

						.notSelected {
							display: flex;
							gap: 0.2rem;
							justify-content: center;

							@include tooltipHelper(
								$place: "slider",
								$ellipsisLines: "1"
							);

							svg {
								margin-top: -0.1px;

								* {
									fill: $ciment;
								}

								&.folderPositionSvg path:first-child {
									fill: $greyShades-roseGrey;
								}

								&.folderPositionSvg
									path[data-name~="secondTone"] {
									fill: var(--ogOrange);
								}
							}

							span {
								color: $anthra;
								margin-top: -0.3rem;
								margin-left: 0.5rem;
								width: 8rem;
								display: inline-block;
								overflow: hidden;
								text-overflow: ellipsis;
							}

							p {
								font-size: 0.6rem;
								color: $anthra;
								font-weight: 400;
								margin: 0;
							}
						}
					}
				}

				.title .leftTitlePop {
					width: 100%;
					justify-content: space-between;
				}

				.sliderItem {
					padding: 0.6rem;
					box-sizing: border-box;

					.helper {
						.folderPositionSvg {
							max-height: none;
							max-width: none;
							height: 1.6rem;
							width: auto;
							margin: 0;
						}
					}

					.sliderItem-name {
						margin: 0.2rem 0;
					}
				}
			}
		}
	}
}

@mixin ssh {
	.sshPopup {
		position: fixed;
		display: flex;
		justify-content: center;
		align-items: center;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
		background-color: $bg-actionPop;
		cursor: default;

		.screenTerm {
			position: relative;
			border: 1rem solid black;
			border-radius: 1rem;

			#actionPopClose {
				cursor: pointer;
				position: absolute;
				top: -3.5rem;
				right: 0;

				@include mediaLarge {
					top: -3.5rem;
					right: 0;
				}
			}

			.titlePopTerm {
				position: absolute;
				top: -3rem;
				left: 0;
				height: 0.8rem;
				background-color: $surfaceBlueSide;
				padding: 0.3rem 0.5rem;
				border-radius: 1rem;
				display: flex;
				align-items: center;

				@include mediaLarge {
					top: -3rem;
					left: 0;
				}

				svg {
					position: relative;
					top: -0.2rem;
					width: 1rem;
					height: 1rem;
					padding: 0rem 0.5rem 0rem 0.1rem;

					&.closeTerm {
						height: 1.8rem;
						width: 1.8rem;
					}

					&.chevronTerm {
						width: 0.5rem;
						top: 0;
					}

					path {
						fill: $white;
					}
				}

				.darkMode & {
					background-color: $bg-pile;

					.darkMode & {
						svg {
							path {
								fill: $black;
							}
						}
					}
				}

				.titleTextPopTerm {
					.darkMode & {
						color: $black;
					}
				}
			}

			&:hover {
				cursor: none;
			}

			.terminal-element-8 {
				display: none;
			}

			.terminal-element-2 {
				div:nth-of-type(2) {
					display: none;
				}
			}
		}
	}
}

//////////////////////////////////
///////// HEADLESS POP ///////////
//////////////////////////////////
@include HeadlessPopup;
