@use "../utils/variables" as *;

@mixin toastr {
	.redux-toastr {
		.top-center {
			margin-top: 0.5rem;
			width: 50%;
			max-width: 27rem;
			display: flex;
			flex-direction: column;
			align-items: center;

			.toastr {
				background-color: $greyShades-white;
				color: $extTextColor1;
				box-shadow: unset;
				width: unset;
				opacity: 0.98;
				font-family: "Montserrat";
				margin: 0;
				cursor: not-allowed;
				min-height: unset;

				&:hover {
					box-shadow: none;
				}

				> div {
					display: flex;
					position: relative;
				}

				.rrt-left-container {
					width: 100%;
					height: 1.7rem;
					display: flex;
					justify-content: center;
					top: 0rem;
					overflow: visible;

					.rrt-holder {
						width: auto;
						height: 100%;
						position: static;
						margin-top: unset;
						line-height: unset;

						svg {
							height: 100%;
							width: auto;
						}
					}
				}

				.rrt-center-container {
					position: relative;
					width: 3rem;
					overflow: visible;

					.rrt-holder {
						display: flex;
						justify-content: center;
						align-items: center;
						margin-top: unset;
						right: 0;
						top: 0;
						height: 100%;
						left: unset;
						width: auto;
						font-family: "Montserrat";

						svg {
							height: auto;
							width: 2.2rem;
							position: absolute;
							right: -1.3rem;
						}
					}
				}

				.rrt-middle-container {
					position: static;
					flex: 2;
					margin-left: unset;
					background-color: $extBgColor1;
					padding: 1.3rem 1rem 0.7rem;
					box-shadow: 0 0 0.5rem $sh1;
					border-radius: 7px;
					min-width: 13rem;
					max-width: 20rem;
					margin: 0.8rem 0.5rem 0.5rem;
					float: none;
					font-family: "Montserrat";
					border: 1px solid $extBgColor3;
					border-left: none;
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;

					.rrt-title {
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						white-space: nowrap;
						font-family: "Montserrat";
						font-size: 1rem;
						font-weight: 600;
						margin-bottom: 0;
						color: $extTextColor1;
					}

					.rrt-text {
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						font-family: "Montserrat";
						font-size: 0.85rem;
						font-weight: 500;
						margin-top: 0.3rem;
						color: $extTextColor2;
						line-height: 120%;
					}
				}

				.rrt-right-container {
					display: none;
				}

				.rrt-progress-container {
					margin: unset;
					right: 0.5rem;
					bottom: 0.5rem;
					height: 3px;
					width: calc(100% - 1rem);
					opacity: 0.5;
				}

				&.rrt-success {
					.rrt-progressbar {
						background-color: #218051;
					}
				}

				&.rrt-warning {
					.rrt-progress-container {
						opacity: 0.8;
					}

					.rrt-progressbar {
						background-color: #fad86a;
					}
				}

				&.rrt-error {
					.rrt-progressbar {
						background-color: #db3b29;
					}
				}
			}
		}

		.bottom-center,
		.top-center {
			margin-left: 0 !important;
			left: 25% !important;
		}
	}
}
