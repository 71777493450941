@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;
@use "../../components" as *;

@mixin thinPopLeftList {
	.leftList {
		display: flex;
		margin-bottom: 20px;

		@include mediaLarge {
			margin-bottom: 2vh;
		}

		.noUsers {
			flex: 2;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			height: 250px;
			color: $grey;

			@include mediaLarge {
				height: 30vh;
			}
		}

		.helpLine {
			font-size: 13px;

			@include mediaLarge {
				font-size: clamp(0.6rem, 0.8vw, 0.9rem);
			}
		}

		.list {
			flex: 3;
			display: flex;
			flex-direction: column;
			max-width: 45%;

			.title {
				background-color: initial;
				padding-left: 0;
				font-size: 14px;
				margin-bottom: 7px;

				@include mediaLarge {
					font-size: clamp(0.7rem, 1vw, 0.9rem);
					margin-bottom: 1vh;
				}

				.darkMode & {
					color: $ciment;
				}
			}

			.list-items {
				max-height: 250px;
				min-height: 250px;
				overflow-x: auto;
				padding-right: 3px;
				flex: 2;

				@include mediaLarge {
					max-height: 40vh;
					min-height: 30vh;
				}

				.list-items-item {
					margin-bottom: 7px;
					background-color: $bg-lGrey;
					border-radius: 3px;
					display: flex;
					align-items: center;
					padding: 0.3rem 8px;
					font-size: 13px;
					color: $black;
					font-weight: 500;
					cursor: pointer;
					border: 1.5px solid $bg-lGrey;
					overflow: hidden;

					#list-items-item-icons {
						@include tooltipHelper {
							&-children {
								min-width: 1.1rem;
							}
						}
					}

					.group {
						margin: 0 2rem 0 0.6rem;
					}

					.allNameUsers {
						margin: 0 2rem 0 0.8rem;
						max-width: 35%;
						min-width: 35%;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
						word-break: break-word;
						overflow: hidden;
					}

					.checkBox {
						&.small {
							margin-right: 0.6rem;
						}
					}

					@include mediaLarge {
						margin-bottom: 0.8vh;
						padding-left: 0.5vw;
						padding-right: 0.5vh;
						font-size: clamp(0.6rem, 0.8vw, 0.9rem);
					}

					.darkMode & {
						background-color: $dm-b2;
						color: $ciment;
						border: 1.5px solid $dm-b2;

						@include mediaLarge {
							margin-right: 1vw;
						}
					}

					.checkBox {
						margin-right: 0.5em;

						.darkMode & {
							border: 2px solid $dm-b4;
							background-color: $dm-b4;
						}
					}

					svg {
						max-height: 4rem;
						height: 100%;

						path,
						ellipse,
						circle,
						rect {
							fill: $black;

							.darkMode & {
								fill: $ciment;
							}
						}
					}

					.item-name {
						flex: 2;
						margin-left: 15px;
						margin-right: 0.4rem;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						@include mediaLarge {
							margin-left: 1vw;
						}
					}

					.item-owner {
						flex: 2;
						display: flex;
						justify-content: flex-end;
						max-width: 70%;
						text-align: end;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
						word-break: break-word;
						overflow: hidden;
					}

					.ownerCategory {
						flex: 2;
						display: flex;
						justify-content: flex-end;
					}

					.strength {
						display: flex;
						flex-direction: row;
						align-items: center;
						margin: 0;

						span {
							width: 1.4rem;
							height: 0.3rem;
							border-radius: 5px;
							background-color: $matSilver;
							margin-right: 0.3rem;

							.darkMode & {
								background-color: $dm-b4;
							}
						}
					}

					// @include helper {
					// 	&:nth-of-type(3) {
					// 		margin-left: 5px;
					// 	}

					// 	&:nth-of-type(3),
					// 	&:nth-of-type(4) {
					// 		@include mediaLarge {
					// 			margin-left: 0.4vw;
					// 		}
					// 	}
					// }

					&.selected {
						border: 1.5px solid $bg-blue;

						.darkMode & {
							border: 1.5px solid $dm-lBlue;
						}

						.checkBox {
							.darkMode & {
								background-color: $dm-lBlue;
								border: 2px solid $dm-lBlue;
							}
						}
					}

					&-icons {
						display: flex;
						gap: 0.5em;
						margin-right: 0.5em;
					}
				}
			}

			.select {
				margin-bottom: 0;
				margin-top: 10px;
				background-color: $bg-lGrey;
				height: 35px;
				border-radius: 3px;
				display: flex;
				align-items: center;
				padding: 0 8px;
				font-size: 13px;
				color: $black;
				font-weight: 500;
				cursor: pointer;
				justify-content: space-between;
				text-decoration: underline;

				@include mediaLarge {
					margin-bottom: 0;
					margin-top: 1vh;
					height: 4vh;
					padding: 0 0.5vw;
					font-size: clamp(0.6rem, 0.8vw, 0.9rem);
				}

				.darkMode & {
					background-color: $dm-b2;
					color: $grey;
					text-decoration: none;
				}

				.btn {
					cursor: pointer;
					text-decoration: underline;

					.darkMode & {
						transition: $t1;
					}

					&:hover {
						.darkMode & {
							color: $ciment;
						}
					}

					&.btnUnselectAll {
						flex: 2;
						text-align: right;
					}
				}
			}

			.orange {
				* {
					fill: $orange;
				}
			}
		}

		.actions {
			margin-left: 15px;
			flex: 2;
			padding: 17px 15px 12px;
			display: flex;
			flex-direction: column;

			@include mediaLarge {
				margin-left: 1vw;
				padding: 1.5vh 1vw;
			}

			&.selected {
				border-radius: 3px;
				border: 2px solid $lGrey;
				max-width: 45%;

				.darkMode & {
					border: 2px solid $dm-b4;
				}

				.userProfile {
					flex: 2;
					font-size: 15px;

					@include mediaLarge {
						font-size: clamp(0.6rem, 1vw, 1rem);
					}

					.top {
						display: flex;
						flex-direction: column;
						align-items: flex-start;

						.darkMode & {
							color: $ciment;
						}

						.top-title {
							flex: 2;
							overflow: hidden;
							word-wrap: break-word;
							white-space: pre-wrap;
							max-width: 100%;
							margin-bottom: 0.1rem;
						}

						small {
							color: $anthra;
							word-break: break-all;

							.darkMode & {
								color: $grey;
							}
						}
					}

					.message-not-modifiable {
						.darkMode & {
							color: $ciment;
						}
					}

					.bluePill {
						margin-top: 13px;
						background-color: $bg-blue;
						display: inline-flex;
						align-items: center;
						padding: 5px 13px;
						border-radius: 15px;
						color: $white;
						margin-right: 3px;

						@include mediaLarge {
							margin-top: 1vh;
							padding: 0.5vh 0.9vw;
							border-radius: 1vw;
							margin-right: 0.2vw;
						}

						svg {
							max-height: 13px;
							max-width: 13px;
							margin-right: 5px;

							@include mediaLarge {
								max-height: 1vw;
								max-width: 1vw;
								margin-right: 0.3vw;
								vertical-align: top;
							}

							path {
								fill: $white;
							}

							circle {
								fill: $white;
							}
						}

						/* Temporary fix for the group2.svg file. The image does not display correctly in bluePills with the same rules as the other svg. */
						&.group > svg {
							max-height: unset;
							max-width: unset;

							* {
								fill: $white;
							}
						}
					}

					.greyPill {
						margin-top: 13px;
						background-color: $bg-lGrey;
						display: inline-block;
						padding: 5px 13px;
						border-radius: 15px;
						color: $black;
						margin-right: 3px;

						@include mediaLarge {
							margin-top: 1vh;
							padding: 0.5vh 0.9vw;
							border-radius: 1vw;
							margin-right: 0.2vw;
						}

						svg {
							max-height: 13px;
							max-width: 13px;
							margin-right: 5px;

							@include mediaLarge {
								max-height: 1vw;
								max-width: 1vw;
								margin-right: 0.3vw;
								vertical-align: top;
							}

							path {
								fill: $black;
							}

							line {
								stroke: $black;
							}
						}
					}

					.desc {
						margin-top: 13px;
						font-size: 14px;

						@include mediaLarge {
							margin-top: 1.4vh;
							font-size: clamp(0.6rem, 0.9vw, 0.9rem);
						}

						.darkMode & {
							color: $ciment;
						}

						.desc-container {
							margin-bottom: 7px;

							@include mediaLarge {
								margin-bottom: 0.9vh;
							}
						}

						span {
							font-weight: 400;
							margin-right: 3px;
						}

						p {
							white-space: pre-wrap;
							word-wrap: break-word;
							font-weight: 500;
							color: $anthra;
						}
					}
				}

				.multisNum {
					display: flex;
					justify-content: center;
					align-items: center;
					flex: 2;
					flex-flow: column;
					color: $black;

					.darkMode & {
						color: $ciment;
					}

					.multisNum-text {
						margin-top: 1rem;
						text-align: center;
						font-size: 14px;
						font-weight: 400;

						.darkMode & {
							color: $ciment;
						}
					}
				}

				.interact {
					font-size: 0.9rem;
					display: flex;
					justify-content: space-between;
					align-items: flex-end;

					@include mediaLarge {
						font-size: clamp(0.6rem, 0.95vw, 1rem);
					}

					span {
						text-decoration: underline;
						cursor: pointer;

						.darkMode & {
							color: $ciment;
						}

						&.red {
							color: $orange;
							text-decoration: none;
						}

						&.grey {
							color: $grey;
							text-decoration: none;
						}
					}
				}
			}
		}
	}
}
