@mixin animHelperFrame {
	.animHelperFrame {
		padding: 3px 7px;

		&.yellow svg > g > g:nth-child(2) path:first-child {
			fill: rgba(255, 255, 255, 0.2);
		}

		&.yellow svg > g > g:last-child path,
		&.yellow svg > g > g:first-child path {
			fill: #eacb64;
		}

		@content;
	}
}
