@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;
@use "./surface" as *;
@use "./surfaceParams" as *;
@use "../../components/plasmaRay" as *;
@use "../../components/animHelperFrame" as *;

@mixin NavTop($navMini: false) {
	> .navTop {
		@include DebugTool(2, ".navTop");
		overflow: hidden;
		position: relative;

		@include mediaMedium {
			display: none;
		}

		.navHello {
			color: $white;
			background-color: $bg-dBlue;
			display: flex;
			align-items: center;
			padding: 2vh 0.4vw 2.3vh;
			height: auto;
			margin-bottom: 25px;
			position: relative;
			cursor: pointer;

			@if $navMini {
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding: 2vh 0.4vw 1vh;

				svg {
					position: relative;
					top: -7px;
					left: 16px;
				}
			}

			@include mediaLarge {
				padding: 2vh 1vw 2.5vh;
				margin-bottom: 3vh;

				@if $navMini {
					padding: 2vh 1vw 1.4vh;
				}
			}

			.darkMode & {
				color: $ciment;
				background-color: $dm-b1;
			}

			.navHello-initials {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 1.9vw;
				width: 1.9vw;
				min-width: 1.6vw;
				min-height: 1.6vw;
				max-width: 1.8rem;
				max-height: 1.8rem;
				border-radius: 0.2vw;
				background: linear-gradient(
						0deg,
						rgba(255, 255, 255, 0.1),
						rgba(255, 255, 255, 0.1)
					),
					linear-gradient(
						135deg,
						rgba(244, 117, 50, 0.25) 0%,
						rgba(57, 73, 155, 0.1) 49.54%,
						rgba(40, 51, 183, 0.25) 100%
					);
				letter-spacing: -0.07vw;
				color: $white;
				margin-right: 0.5vw;
				font-size: clamp(0.7rem, 0.75vw, 0.9rem);
				font-weight: 600;
				font-family: $mon;

				.darkMode & {
					background: linear-gradient(
							0deg,
							rgba(0, 0, 0, 0.05),
							rgba(0, 0, 0, 0.05)
						),
						linear-gradient(
							135deg,
							rgba(108, 99, 255, 0.25) 0%,
							rgba(167, 144, 227, 0.1) 49.54%,
							rgba(167, 144, 227, 0.25) 100%
						);
					color: $white;
				}

				@if $navMini {
					margin: 0;
				}

				@include mediaLarge {
					font-size: clamp(0.6rem, 0.7vw, 1rem);
				}
			}

			.navHello-content {
				display: flex;
				justify-content: space-between;
				align-items: center;
				cursor: pointer;
				flex: 2;
				max-width: calc(100% - (1.8rem + 0.9vw));
				position: relative;

				// .openSurface {
				// 	transform: rotate(90deg);
				// 	width: 0.5vw;
				// }

				.navHello-content-container {
					display: flex;
					flex-direction: column;
					max-width: 80%;

					b {
						font-size: clamp(0.65rem, 0.7vw, 0.8rem);
						margin-bottom: 0.2vh;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						font-family: $mon;
						line-height: 13px;
						background: linear-gradient(
							180deg,
							$white 6.25%,
							#e8edf2 50%,
							#eff3f7 50.01%,
							$white 92.19%
						);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						background-clip: text;
						font-weight: 600;
					}

					small {
						font-size: clamp(0.55rem, 0.6vw, 0.7rem);
						color: $ciment;
						text-overflow: ellipsis;
						overflow: hidden;
						font-family: $mon;
						line-height: 11px;
						font-weight: 500;
					}
				}
			}

			@include animHelperFrame {
				padding: 0;
				position: absolute;
				right: 0.5rem;
				z-index: 10;

				@if $navMini {
					flex: unset;
					max-width: unset;
					margin-top: 0.5rem;
				}
			}
		}

		@include PlasmaRay;

		@include Surface;
		@include SurfaceParams;

		.sandboxBody {
			position: fixed;
			display: flex;
			justify-content: center;
			align-items: center;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 1000;
			background-color: $bg-actionPop;

			.cardBody {
				width: 70rem;
				height: 50rem;
				background-color: $bg-lGrey;
				display: flex;
				flex-direction: column;
				border-radius: 13px;
				align-items: center;

				.close {
					height: fit-content;
					margin: 0.5rem 0.5rem -1.5rem 0rem;
					display: flex;
					z-index: 2;
					position: relative;
					justify-content: flex-end;
					width: 100%;
					height: 10%;

					> svg {
						height: 0.8rem;
					}
				}

				////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

				@import url(https://fonts.googleapis.com/css?family=Roboto:300);

				.login-page {
					width: 24rem;
					margin: auto;
				}

				.form {
					position: relative;
					z-index: 1;
					background: #ffffff;
					max-width: 360px;
					padding: 3rem 3rem 3rem 3rem;
					text-align: center;
					box-shadow:
						0 0 20px 0 rgba(0, 0, 0, 0.2),
						0 5px 5px 0 rgba(0, 0, 0, 0.24);

					.close {
						display: flex;
						justify-content: flex-end;
						margin: 0 0 1rem 0;
						height: fit-content;
						padding: 0.3rem 0.3rem 0 0.3rem;

						> svg {
							height: 0.8rem;
						}
					}

					.explain {
						display: flex;
						text-align: start;
						font-size: 0.5rem;
						flex-direction: column;

						> p {
							font-size: 0.7rem;
							text-align: center;
							margin: 0rem;
						}
					}

					button {
						font-family: "Roboto", sans-serif;
						text-transform: uppercase;
						outline: 0;
						background: #4caf50;
						width: 100%;
						border: 0;
						padding: 5px;
						color: #ffffff;
						font-size: 10px;
						-webkit-transition: all 0.3 ease;
						transition: all 0.3 ease;
						cursor: pointer;
					}

					input {
						font-family: "Roboto", sans-serif;
						outline: 0;
						background: #f2f2f2;
						width: 70%;
						border: 0;
						margin: 0 0 15px;
						padding: 7px;
						box-sizing: border-box;
						font-size: 10px;
						text-align: center;
					}
				}

				.newSwitch {
					display: flex;
					margin: 1rem 0 2rem 0;
					align-items: center;

					.textAddDateExpi {
						padding: 0 0 0 0.7rem;
						font-size: 10px;
					}

					.switch {
						/* switch */
						--switch-width: 2rem;
						--switch-height: 1rem;
						--switch-bg: rgb(131, 131, 131);
						--switch-checked-bg: rgb(0, 218, 80);
						--switch-offset: calc(
							(var(--switch-height) - var(--circle-diameter)) / 2
						);
						--switch-transition: all 0.2s
							cubic-bezier(0.27, 0.2, 0.25, 1.51);
						/* circle */
						--circle-diameter: 0.75rem;
						--circle-bg: #fff;
						--circle-shadow: 1px 1px 2px rgba(146, 146, 146, 0.45);
						--circle-checked-shadow: -1px 1px 2px
							rgba(163, 163, 163, 0.45);
						--circle-transition: var(--switch-transition);
						/* icon  */
						--icon-transition: all 0.2s
							cubic-bezier(0.27, 0.2, 0.25, 1.51);
						--icon-cross-color: var(--switch-bg);
						--icon-cross-size: 6px;
						--icon-checkmark-color: var(--switch-checked-bg);
						--icon-checkmark-size: 10px;
						/* effect line*/
						--effect-width: calc(var(--circle-diameter) / 2);
						--effect-height: calc(var(--effect-width) / 2 - 1px);
						--effect-bg: var(--circle-bg);
						--effect-border-radius: 1px;
						--effect-transition: all 0.2s ease-in-out;

						display: inline-block;

						input {
							display: none;
						}

						svg {
							-webkit-transition: var(--icon-transition);
							-o-transition: var(--icon-transition);
							transition: var(--icon-transition);
							position: absolute;
							height: auto;
						}

						.checkmark {
							width: var(--icon-checkmark-size);
							color: var(--icon-checkmark-color);
							-webkit-transform: scale(0);
							-ms-transform: scale(0);
							transform: scale(0);
						}

						.cross {
							width: var(--icon-cross-size);
							color: var(--icon-cross-color);
						}

						.slider {
							-webkit-box-sizing: border-box;
							box-sizing: border-box;
							width: var(--switch-width);
							height: var(--switch-height);
							background: var(--switch-bg);
							border-radius: 999px;
							display: -webkit-box;
							display: -ms-flexbox;
							display: flex;
							-webkit-box-align: center;
							-ms-flex-align: center;
							align-items: center;
							position: relative;
							-webkit-transition: var(--switch-transition);
							-o-transition: var(--switch-transition);
							transition: var(--switch-transition);
							cursor: pointer;

							.circle {
								width: var(--circle-diameter);
								height: var(--circle-diameter);
								background: var(--circle-bg);
								border-radius: inherit;
								-webkit-box-shadow: var(--circle-shadow);
								box-shadow: var(--circle-shadow);
								display: -webkit-box;
								display: -ms-flexbox;
								display: flex;
								-webkit-box-align: center;
								-ms-flex-align: center;
								align-items: center;
								-webkit-box-pack: center;
								-ms-flex-pack: center;
								justify-content: center;
								-webkit-transition: var(--circle-transition);
								-o-transition: var(--circle-transition);
								transition: var(--circle-transition);
								z-index: 1;
								position: absolute;
								left: var(--switch-offset);
							}
						}

						.slider::before {
							content: "";
							position: absolute;
							width: var(--effect-width);
							height: var(--effect-height);
							left: calc(
								var(--switch-offset) + (var(--effect-width) / 2)
							);
							background: var(--effect-bg);
							border-radius: var(--effect-border-radius);
							-webkit-transition: var(--effect-transition);
							-o-transition: var(--effect-transition);
							transition: var(--effect-transition);
						}

						input:checked + .slider {
							background: var(--switch-checked-bg);
						}

						input:checked + .slider .checkmark {
							-webkit-transform: scale(1);
							-ms-transform: scale(1);
							transform: scale(1);
						}

						input:checked + .slider .cross {
							-webkit-transform: scale(0);
							-ms-transform: scale(0);
							transform: scale(0);
						}

						input:checked + .slider::before {
							left: calc(
								100% - var(--effect-width) -
									(var(--effect-width) / 2) -
									var(--switch-offset)
							);
						}

						input:checked + .slider .circle {
							left: calc(
								100% - var(--circle-diameter) -
									var(--switch-offset)
							);
							-webkit-box-shadow: var(--circle-checked-shadow);
							box-shadow: var(--circle-checked-shadow);
						}
					}
				}

				////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
			}

			& .createArbo {
				background-color: unset;
			}
		}
	}
}
