@use "../../utils/variables" as *;
@use "../../layout/extension/base/helpers" as *;

@mixin showIdsFrame {
	.showIdsFrame {
		z-index: 2147483642;
		padding: 0.5rem 1rem 1.5rem;
		max-height: 100%;
		box-sizing: border-box;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		position: absolute;
		visibility: visible !important;
		animation: showIdsHide 0.2s ease-out;
		width: 100%;

		&.show {
			overflow-y: auto;
			animation: showIdsAppear 0.2s ease-out;
		}

		.showIdsFrame-container {
			width: 100%;
			margin-top: 0.3rem;
			box-shadow: 0px 0.3rem 1.5rem $sh4;
			border: 1.5px solid $extBgColor3;
			padding: 0.5rem;
			background-color: $white;
			box-sizing: border-box;
			overflow-y: auto;
			border-radius: 3px;
			max-height: 100%;
			overflow-y: auto;
			display: flex;
			flex-direction: column;

			.head {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0.3rem;
				margin-bottom: 0.7rem;

				> svg {
					width: auto;
					height: 1rem;
				}

				> .close {
					position: absolute;
					top: 0.15rem;
					right: 0.2rem;
					cursor: pointer;
					@include accessibility("");
					@include extHover("");

					> svg {
						height: 0.9rem;
						width: auto;
						path {
							fill: $anthra;
						}
					}
				}
			}

			.list {
				padding-bottom: 0.5rem;
				display: flex;
				flex-direction: column;
				overflow-y: auto;

				.category {
					margin: 0.7rem 0;

					&:first-child {
						margin-top: 0;
					}

					h3 {
						margin: 0 0 0.3rem;
						background-color: $lGrey;
						border-radius: 0.6rem;
						width: fit-content;
						padding: 0.25rem 0.7rem;
						font-size: 0.8rem;
						display: grid;
						grid-template-columns: auto minmax(0, 1fr);
						align-items: center;

						> svg {
							min-width: 0.8rem;
							width: 0.8rem;
							height: auto;
							margin-right: 0.5rem;
						}

						.categoryCatName {
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
					}

					.category-content {
						display: flex;
						flex-direction: column;
						background-color: $lGrey;
						padding: 0.3rem 0.5rem;
						border-radius: 3px;
						cursor: pointer;
						margin-bottom: 0.3rem;
						@include accessibility("");
						@include extHover("");

						> b {
							font-size: 0.85rem;
							color: $extTextColor1;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							align-items: center;
						}

						.category-content {
							margin-top: 0.2rem;
							display: flex;
							justify-content: space-between;
							font-weight: 500;

							> span {
								color: $extTextColor2;
								font-size: 0.8rem;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								align-items: center;
								max-width: 50%;
							}

							> small {
								text-align: right;
								color: $extTextColor2;
								font-size: 0.8rem;
								max-width: 40%;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								align-items: center;

								> svg {
									height: auto;
									width: 0.5rem;
									min-width: 0.5rem;
									margin-right: 0.1rem;

									path {
										fill: $extPique;
									}
								}
							}
						}
					}
				}

				> .spinner {
					margin-top: 1rem;
					justify-content: center;
				}

				> .noResult {
					margin-top: 1rem;
					font-size: 0.8rem;
					font-weight: 500;
					color: $extTextColor2;
					display: flex;
					justify-content: center;
					align-items: center;

					> svg {
						height: auto;
						width: 0.75rem;
						margin-right: 0.3rem;
						margin-bottom: 0.1rem;

						path {
							fill: $lCoal;
						}
					}
				}
			}
		}
	}
}
