@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;
@use "../../components/" as *;
@use "../../components/tooltipHelper" as *;

@mixin ssoFrame {
	.ssoFrame {
		margin: 0 0 3rem;

		&.cardOne {
			margin-bottom: 0.5rem;
		}

		.dots {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 15%;
			align-self: flex-start;
			padding-top: 0.3rem;
			height: 1.3rem;

			.dot {
				min-height: 0.5rem;
				min-width: 0.5rem;
				max-width: 0.5rem;
				max-height: 0.5rem;
				background-color: $ciment;
				border-radius: 100%;

				&.done {
					background-color: $lBlue;
				}
			}
		}

		form {
			display: flex;
			flex-direction: column;
			align-items: center;

			.buttons {
				button {
					padding: 0.8rem 0.5rem 0.8rem 0.5rem;

					span {
						height: 100%;
						display: flex;

						&:nth-child(1) {
							margin-right: 1.3rem;
						}
					}
				}
			}

			.form-text {
				font-weight: 500;
				margin-bottom: 2rem;

				&.limit,
				&.loadedFile {
					text-align: left;
					align-self: flex-start;
					margin-top: 0.2rem;
					margin-bottom: 0;
					font-size: 0.7rem;
					color: $anthra;
				}

				&.loadedFile {
					height: fit-content;
					margin-top: 0.2rem;
					color: $brand-black;
					font-size: 0.8rem;
					display: flex;
					align-items: center;

					.darkMode & {
						color: $lGrey;
					}

					button {
						height: 1rem;
						width: 1rem;
						border: none;
						background: none;
						margin-left: 0.2rem;
						cursor: pointer;

						svg {
							height: 100%;
							width: 100%;

							* {
								fill: $brand-black;

								.darkMode & {
									fill: $lGrey;
								}
							}
						}
					}
				}
			}

			.or {
				margin: 1.5rem 0 1rem;
				color: $grey;
				position: relative;
				font-size: 1rem;

				&::after,
				&::before {
					content: "";
					position: absolute;
					bottom: calc(50% - 3px);
					height: 4px;
					width: 4rem;
					background-color: $lGrey;
					border-radius: 3px;
				}

				&::before {
					right: 200%;
				}

				&::after {
					left: 200%;
				}
			}

			.line {
				.line-container {
					@include tooltipHelper() {
						min-width: max-content !important;
					}
				}
			}

			.inputFrame {
				margin-bottom: 0.8rem;
			}
		}

		.ssoComplete {
			.inputFrame {
				.inputWrap {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
				}
			}

			.buttons {
				button {
					&.withIcon {
						&.first {
							color: $lGrey;
							width: 30%;
							padding: 0.8rem 1.5rem;
							margin-top: 0.8rem;
							margin-bottom: -2.8rem;

							.darkMode & {
								background-color: $bg-lGrey;
							}
						}

						&.second {
							display: flex;
							flex-direction: row;
							// justify-content: flex-start;
							justify-content: space-between;
							align-items: center;
							padding: 0.8rem 1.5rem 0.8rem 0.8rem;
							margin-top: 0.8rem;
							margin-bottom: -2.8rem;

							.button-icon {
								margin-right: 2rem;
							}
						}
					}
				}
			}

			.top {
				display: flex;
				flex-direction: column;
				color: $black;

				.darkMode & {
					color: $lGrey;
				}

				.desc {
					font-size: 0.8rem;
					color: $anthra;
					padding: 0.5rem 0 2.3rem 0;

					&.error {
						color: $funnelWarnColor1;
						font-weight: 500;
					}
				}

				.useConf {
					font-size: 0.8rem;
					color: $anthra;
					align-self: center;
					padding-bottom: 1rem;
				}
			}

			.inputed {
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: $lGrey;
				color: $anthra;
				border-radius: 3px;
				padding: 0.7rem 1rem 0.7rem 0.6rem;
				margin-bottom: 0.3rem;

				.darkMode & {
					background-color: $dm-b2;
				}

				.inputed-url {
					display: flex;
					overflow: hidden;
					display: inline-block;
					text-overflow: ellipsis;
					white-space: nowrap;
					padding-right: 0.5rem;
					font-size: 0.75rem;
				}

				.seeConf {
					padding-right: 0.5rem;
					cursor: pointer;

					svg {
						height: auto;
						width: 0.85rem;
					}
				}

				.cloneConf {
					cursor: pointer;

					svg {
						height: auto;
						width: 1rem;

						g path,
						path {
							fill: $anthra;
							stroke: none;
							stroke-width: 0;
						}
					}
				}
			}

			.bottom {
				@extend .top;

				.desc {
					font-size: 0.7rem;
					color: $anthra;
					padding: 0.5rem 0 1rem 0;
				}
			}
		}
	}
}
