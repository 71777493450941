@use "../../utils" as *;

@mixin StepThree {
	.importStepThreeContainer {
		// TODO: delete
		.animation {
			width: 364px;
			height: 327px;
			margin: 0 auto;
		}

		.stateContainer {
			position: relative;
			display: flex;
			justify-content: center;
			margin-top: convertToREM(8);

			.dot {
				position: absolute;
				border-radius: 100%;
				width: convertToREM(9);
				height: convertToREM(9);
				background-color: $green;
				box-shadow: 0px 1px 10px 0px transparentize($green, 0.3);

				&.hidden {
					opacity: 0;
					transition: opacity 0.2s ease-in-out;
				}

				&.show {
					opacity: 1;
					transition: opacity 0.2s ease-in-out;
				}
			}

			.errorPill {
				position: absolute;
				width: auto;
				display: flex;
				align-items: center;
				gap: convertToREM(4);
				background-color: $black;
				color: $white;
				padding: convertToREM(4) convertToREM(8);
				border-radius: 6.25rem;
				font-size: convertToREM(12);
				line-height: convertToREM(12.7);
				font-weight: 500;
				overflow: hidden;
				opacity: 0;

				&.show {
					opacity: 1;
					transition: opacity 0.2s ease-in-out;
				}

				.darkMode & {
					background-color: $white;
					color: $black;
				}

				.text {
					margin: 0;
				}

				.dot {
					position: relative;
					background-color: $yellow;
					box-shadow: 0px 1px 10px 0px transparentize($yellow, 0.3);
				}

				.bold {
					font-weight: 600;
				}
			}
		}

		.informations {
			margin-top: convertToREM(40);

			.progression {
				color: $newPrimaryBg;
				font-size: convertToREM(50);
				font-weight: 400;
				line-height: convertToREM(53);

				.darkMode & {
					color: $newBlue;
				}
			}

			.state {
				font-weight: 600;
				font-size: convertToREM(16);
				line-height: convertToREM(17);
				margin: convertToREM(8) 0;

				.darkMode & {
					color: $white;
				}
			}

			.timeEstimation {
				font-weight: 500;
				font-size: convertToREM(12);
				line-height: convertToREM(13);
				margin: 0;

				.darkMode & {
					color: $ciment;
				}

				.highlighted {
					color: $newBlue;
				}
			}
		}
	}
}

// TODO: mettre dans fichier animations
@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
