@use "../../../utils/" as *;

@mixin inThinPop {
	margin-bottom: 0.9rem;
	color: $black;
	position: relative;

	.darkMode & {
		color: $ciment;
	}

	> .title {
		margin-bottom: 0.5rem;
		font-weight: 500;
		font-size: 0.9rem;
	}

	> .details {
		display: flex;
		align-items: center;

		.move-pass-h2 {
			margin-top: 0.2rem;
			margin-bottom: 0.2rem;
			max-width: 280px;
			overflow-wrap: break-word;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-size: 1.2rem;
		}

		> h2 {
			margin-bottom: 0.2rem;
			margin-top: 0.2;
		}

		.darkMode & {
			color: $white;
		}

		> svg {
			margin-right: 0.5rem;
			height: 1.3rem;
			max-width: 1.2rem;
			width: auto;

			.darkMode & {
				filter: drop-shadow(0 0 0.3rem $dm-oL);
			}

			path {
				fill: $orange;
			}
		}

		img {
			width: 1.2rem;
			height: 1.2rem;
			margin-right: 0.6rem;
		}
	}

	> .spinner {
		position: absolute;
		right: 1.3rem;
		top: 0;
		height: 100%;
		margin: 0;
	}
}
