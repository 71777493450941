@use "../utils/variables" as *;
@use "../utils/mixins" as *;
@use "../utils/animations" as *;
@use "./stepDisplayer" as *;

@mixin RemoveMFA {
	.RemoveMFA {
		&.content {
			flex-grow: 1;
			min-height: unset;
			max-height: 100%;
			overflow-y: scroll;
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.2rem;
			position: relative;
			padding: 0.9rem 4.8rem;

			&::-webkit-scrollbar {
				display: none;
			}

			@media (max-width: 575px) {
				padding: 0.3rem 0.6rem;
				box-sizing: border-box;
				gap: 0.25rem;
			}

			.informationWrapper {
				.removeMFAText {
					color: $greyShades-grey;
					font-size: 0.9rem;
					font-weight: 500;
					margin-bottom: 0.6rem;

					.darkMode & {
						color: $greyShades-matSilver;
					}
				}

				.supportLink {
					color: $brand-black;
					font-family: Montserrat;
					font-size: 0.9rem;
					font-weight: 500;
					text-decoration: none;
					cursor: pointer;

					&:visited,
					&:active {
						color: $brand-black;
						text-decoration: none;
					}

					.darkMode & {
						color: $greyShades-white;

						&:visited,
						&:active {
							color: $greyShades-white;
						}
					}
				}
			}

			.inputWrapper {
				width: 100%;
				display: grid;
				grid-template-columns: 1fr;
				gap: 0.18rem;
				margin-bottom: 0.3rem;

				.mfaCodeLabel {
					color: $greyShades-wetGrey;
					font-family: Montserrat;
					font-size: 0.78rem;
					font-weight: 500;
					font-weight: 500;
					animation: 0.3s ease-in 0s appear;
					animation-fill-mode: forwards;

					@media (max-width: 575px) {
						display: none;
					}
				}

				.inputContainer {
					display: flex;
					align-items: center;
					background-color: $greyShades-snow;
					border: $matSilver 2px solid;
					border-radius: 0.3rem;
					padding: 0.6rem 0.9rem;

					.darkMode & {
						background-color: $dm-b4;
					}

					&.invalid {
						border-color: $red;
					}

					.input {
						flex: 1;
						background: none;
						border: none;
						color: $brand-black;
						font-family: "Montserrat";
						font-weight: 500;
						font-size: 0.9rem;

						.darkMode & {
							color: $greyShades-white;
						}

						&:focus {
							outline: none;
						}

						&::placeholder {
							color: $greyShades-grey;
							font-family: Montserrat;
							font-size: 0.9rem;
							font-weight: 500;

							.darkMode & {
								color: $greyShades-white;
							}
						}
					}

					.removeButton {
						background: none;
						border: none;
						cursor: pointer;

						.icon {
							width: 0.9rem;
							height: 0.9rem;

							* {
								fill: $brand-black;

								.darkMode & {
									fill: $greyShades-white;
								}
							}
						}
					}
				}

				.invalidText {
					color: $indicators-error;
					font-family: Montserrat;
					font-size: 0.66rem;
					font-weight: 500;
					margin: 0;
				}
			}

			.buttonsContainer {
				display: grid;
				grid-template-columns: 1fr;
				gap: 0.6rem;
				width: 100%;

				.cancel {
					background-color: $greyShades-snow;
					padding: 0.66rem 1.02rem;
					display: flex;
					justify-content: center;
					align-items: center;
					border: none;
					border-radius: 0.18rem;
					color: $greyShades-roseGrey;
					font-family: Montserrat;
					font-size: 0.9rem;
					font-weight: 600;
					cursor: pointer;

					.darkMode & {
						background: none;
						color: $greyShades-white;
					}
				}
			}
		}
	}
}
