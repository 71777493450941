@use "./animHelperFrame" as *;
@use "../utils/variables" as *;

@mixin floatingToast {
	.floatingToastFrame {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: absolute;
		right: calc(100% - 1rem);
		bottom: 0.5rem;
		z-index: 10;
		max-height: 30vh;
		width: 50vw;

		.floatingToast {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			background-color: $bg-white;
			border-radius: 5px;
			padding: 1rem 1.2rem;
			margin-right: 0.5rem;
			max-width: 85%;

			.darkMode & {
				background-color: $dm-b3;
			}

			&.shadow {
				box-shadow: -5px 0 10px rgba(215, 217, 234, 0.25);

				.darkMode & {
					box-shadow: -5px 0 10px $dm-sh2;
				}
			}

			svg {
				min-width: 2rem;
				min-height: 2rem;
			}
		}

		.floatingToast-container {
			display: flex;
			flex-direction: column;
			text-align: right;
			margin-right: 1.2rem;

			b {
				color: $black;
				margin-bottom: 0.5rem;
				font-size: clamp(0.8rem, 1vw, 0.9rem);

				.darkMode & {
					color: $ciment;
				}
			}

			span {
				color: $anthra;
				font-size: clamp(0.7rem, 0.9vw, 0.8rem);
				line-height: 140%;

				.darkMode & {
					color: $grey;
				}
			}
		}

		&.isV2 {
			right: calc(100% - 31rem);
			bottom: 1.5rem;

			.floatingToast-container {
				text-align: center;
			}
		}

		&.alignBottom {
			align-items: flex-end;
		}
		@include animHelperFrame;
	}
}
