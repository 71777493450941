@use "../utils/variables" as *;

@mixin StepDisplayer($stepNumber: 3) {
	.StepDisplayer {
		width: 100%;
		align-items: flex-start;
		display: inline-flex;
		flex: 0 0 auto;
		flex-direction: column;
		gap: 0.42rem;
		position: relative;
		margin-bottom: 1.32rem;

		.stepTitle {
			align-items: center;
			align-self: stretch;
			display: flex;
			height: 2.4rem;
			justify-content: space-between;
			position: relative;

			.title-wrapper {
				color: $brand-black;
				font-family: "Montserrat", Helvetica;
				font-size: 1.14rem;
				font-weight: 600;
				letter-spacing: 0;
				line-height: normal;
				position: relative;
				width: fit-content;

				.darkMode & {
					color: $greyShades-white;
				}
			}

			.text-wrapper {
				color: $greyShades-grey;
				font-family: "Montserrat", Helvetica;
				color: $greyShades-grey;
				font-size: 0.9rem;
				font-weight: 600;
				letter-spacing: 0.054rem;
				line-height: normal;
				position: relative;
				white-space: nowrap;
				width: fit-content;

				.darkMode & {
					color: $greyShades-roseGrey;
				}
			}
		}

		.stepGraph {
			display: flex;
			width: 10.8rem;
			gap: 0.3rem;

			.step {
				flex-grow: 1;
				height: 0.3rem;
				background-color: $matSilver;
				border-radius: 0.15rem / 50%;
				margin-right: 0.3rem;

				&:not(:first-child) {
					margin-left: 0.3rem;
				}

				.darkMode & {
					background-color: $dm-b4;
				}
			}

			.currentStep {
				flex-grow: 3;
				background-color: $blueShades-smokedSky;

				.darkMode & {
					background-color: $purpleShades-sunsetShades;
				}
			}
		}
	}
}
