@use "./mixins" as *;
@use "./functions" as *;
@use "./variables" as *;
@use "../components/header" as *;
@use "../components/errorNotFound" as *;
@use "../components/padlock" as *;
@use "../components/lockerFrame" as *;
@use "../components/uploadFrame" as *;
@use "../components/tooltipHelper" as *;
@use "../pages/webApp/dashboard/components/navbar" as *;
@use "../organisms/navigation" as *;
@use "../pages/webApp/params/layout/" as *;

@mixin MainLayout {
	#mLayout {
		// @include DebugTool(0, "#mLayout");
		display: flex;
		height: 100vh;
		font-family: $mon;
		font-weight: 600;
		transition: 0.3s ease-in-out;
		max-height: 100vh;
		overflow: hidden;
		box-sizing: border-box;

		@include mSpace;

		@include padlock {
			--labelColor: #{$grey};

			@include lockerFrame;
		}
		@include uploadFrame;
		@include tooltipHelper;

		// Responsive Tablet
		@include mediaMedium {
			display: flex;
			flex-direction: column;
			height: 100vh;
			max-height: unset;
			overflow: auto;
		}

		@content;
	}
}

@mixin CentralFrame {
	.centralFrame {
		flex: 2;
		display: flex;
		margin: 0;
		z-index: 100;
		user-select: none;

		&.oldFrame {
			display: flex;
			flex: 2 1;
			margin: 20px 30px 20px 50px;
			z-index: 100;

			@include mediaCustom(1025px, 1600px) {
				margin: 3vh 2vw 2vh;
				z-index: 300;
			}
		}

		&.dashboard {
			z-index: unset;
			flex: 2 1;
			position: relative;
			display: grid;
			grid-template-rows: auto minmax(0, 1fr) auto;
			max-width: min(
				calc(100vw - 60px),
				var(--dashboardW, convertToREM(1360))
			);
			margin: 1rem 3vh 2vw 2vh;

			&.is-overview {
				margin-bottom: 0;

				@include mediaMinCustom(1440px) {
					--dashboardW: #{convertToREM(1570)};
					margin-right: 0;
					margin-left: convertToREM(70);
				}

				@include NavbarBoard {
					@include mediaMinCustom(1440px) {
						.mainNavbarHeader {
							margin-left: convertToREM(70);
							// remove margin-left size and padding-left size from width
							width: calc(min(100%, 85rem) - 6.375rem);
						}
					}
				}
			}

			@include mediaMedium {
				margin: 1rem auto 20px;
			}
		}

		@include mediaCustom {
			margin: 0;
			z-index: 300;
		}

		@content;
	}
}

@mixin mSpace {
	.mSpace {
		flex: 2;
		background: $bg-main;
		height: 100%;
		position: relative;

		&.dashboard,
		&.options {
			background-color: $bgDashboard;
		}

		&.options {
			position: unset;
		}

		.darkMode & {
			background-color: $dm-b1;
		}

		@include Header;

		> .watermark {
			z-index: 1;
			position: absolute;
			left: -20px;
			top: -50px;
			font-size: 200px;
			font-weight: 500;
			text-transform: uppercase;
			color: $white;
			opacity: 0.52;
			cursor: default;
			overflow: hidden;
			width: calc(100% + 20px);

			.darkMode & {
				color: $dm-b0;
			}

			@include mediaCustom {
				top: -2.5vw;
				font-size: clamp(7rem, 8.5vw, 11rem);
			}
		}

		.central {
			display: flex;
			height: 100%;
			z-index: 10;

			&.overviewBackground {
				background-color: #fdfdff;

				.darkMode & {
					background-color: $dm-b1;
				}
			}

			&.logsBackground {
				background: linear-gradient(270deg, #ffffff 0%, #fcfdfd 86.17%);

				.darkMode & {
					background: $dm-b1;
				}
			}

			&.dashboard {
				z-index: unset;
			}

			&.actionBtnOver {
				.centralFrame {
					z-index: unset;
				}

				.actionBtn {
					--actionBtnIndex: 99999;
				}
			}
		}

		@include errorNotFound;
		@include HomepageParams;
	}
}

@mixin NavMini {
	&.mini {
		@include NavCredits(true);
		@include NavLinks(true);
		@include NavMenu(true);
		@include NavMobile(true);
		@include NavTop(true);

		@content;
	}
}

/* -------------------------------------------------------------------------- */
/*                              ADDITIONAL STYLE                              */
/* -------------------------------------------------------------------------- */

@include mediaLarge {
	body {
		font-size: 15px;
	}
}

// The following selector is necessary for not messing up the "view more" mecanism when downloading a file.
// Inserting a link seemed to mess up the position of elements in the viewport, preventing some of them to display, which has an impact on the dispatch made to update the item showed in the board;
#downloadLink {
	position: absolute;
	width: 0px;
	height: 0px;
}
