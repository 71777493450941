@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;
@use "../../components/" as *;
@use "../../components/input/mixin" as *;

@mixin notifsPop($page: "") {
	.notificationsPopup {
		.popupBody {
			padding: 0.9rem 0.9rem 0.9rem 0.9rem;

			.status {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 1.7rem;

				.inputContainer {
					display: flex;
					align-items: center;
					column-gap: 0.8rem;

					label {
						font-size: 0.8rem;
					}
				}
			}
		}

		.buttons {
			width: 100%;

			// button {
			// 	position: relative;
			// 	height: 2.6rem;
			// 	width: 100%;
			// 	display: flex;
			// 	justify-content: center;
			// 	align-items: center;
			// 	background-color: $accent-blueAction;
			// 	color: $white;
			// 	font-size: 0.9rem;
			// 	font-weight: 600;
			// 	padding: 1rem;
			// 	border: none;
			// 	border-radius: 3px;
			// 	cursor: pointer;

			// svg {
			// 	position: absolute;
			// 	top: calc(100% - 1.6rem);
			// 	left: 1rem;
			// }
			// }
		}
	}
}
