@use "../utils/variables" as *;
@use "../utils/mixins" as *;
@use "./inputs/numInput" as *;
@use "./passwordStrength" as *;

@mixin PpComposition {
	.component-ppComposition {
		@include NumInput;

		position: relative;
		background-color: $greyShades-snow;
		padding: 1.25rem 1.25rem 2.5rem;
		margin: 1.88rem 0 2.7rem;

		.darkMode & {
			background-color: $dm-b3;
			color: $white;
		}

		.subtitle {
			font-size: 1rem;
			font-weight: 500;
			margin-bottom: 1.25rem;

			&.last {
				margin-top: 1.87rem;
			}

			@include mediaMaxCustom(1200px) {
				font-size: 0.75rem;
				margin-bottom: 1rem;
			}
		}

		.numContainer {
			display: flex;
			align-items: center;
			gap: 0.75rem;

			&:not(:last-child) {
				margin-bottom: 0.9rem;
			}

			.detail {
				font-size: 1rem;
				font-weight: 600;

				@include mediaMaxCustom(1200px) {
					font-size: 0.75rem;
				}
			}
		}

		.containerTitle {
			position: absolute;
			top: -0.7rem;
			left: 25%;
			border-radius: 6.25rem;
			background-color: $white;
			display: flex;
			gap: 0.25rem;
			font-size: 0.75rem;
			font-weight: 500;
			padding: 0.25rem 0.5rem;

			.darkMode & {
				background-color: $dm-b2;
				color: $white;
			}

			.icon {
				width: 0.75rem;
				height: 0.75rem;
			}
		}

		.ppForce {
			position: absolute;
			bottom: -1.2rem;
			width: 80%;
			left: 5%;
			background-color: $brand-black;
			color: $white;
			border-radius: 0.3rem;
			padding: 0.75rem;

			@include mediaMaxCustom(1200px) {
				font-size: 0.56;
				padding: 0.56rem;
			}

			.darkMode & {
				background-color: $white;
			}

			@include PasswordStrength {
				justify-content: space-between;

				&.strength {
					.strength-message {
						color: $white;
						font-size: 0.75rem;
						font-weight: 600;

						.darkMode & {
							color: $black;
						}
					}
				}
			}
		}

		@content;
	}
}
