@use "../utils/" as *;

@mixin tripleKeys($circleHeight, $circleWidth, $location: "") {
	.triple-keys {
		position: absolute;
		z-index: 20;
		height: $circleHeight;
		width: $circleWidth;
		display: grid;
		grid-template-columns: convertToREM(8);
		grid-template-rows: minmax(0, 1fr);
		justify-content: center;
		align-items: center;
		top: 0;
		left: 0;

		svg {
			grid-row: 1 / 2;
			grid-column: 1 / 2;
			width: auto;
			height: convertToREM(15);
			max-height: none;
			max-width: none;
			transform: translateX(convertToREM(2));

			* {
				fill: $greyShades-grey;

				.darkMode & {
					fill: $greyShades-matSilver;
				}
			}

			&:last-of-type {
				transform: translateX(convertToREM(-2));

				* {
					fill: $greyShades-matSilver;

					.darkMode & {
						fill: $greyShades-grey;
					}
				}
			}
		}
	}
}
