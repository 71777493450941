@use "../../utils/variables" as *;
@use "./base/index.scss" as *;
@use "./base/helpers" as *;
@use "../../components/tooltipHelper" as *;

@mixin baseStyle {
	.extPopStyle,
	#followIdsFrame {
		margin: 0;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
			"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
			"Helvetica Neue", sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		scrollbar-width: thin;
		scrollbar-color: rgba(215, 217, 234, 0.5) rgba(246, 247, 254, 0);
		overflow-x: hidden;
		transition: unset;
		min-height: $extHeightPopup;
		max-height: $extHeightPopup;
		min-width: 20rem;

		h1,
		h2,
		h3,
		h4,
		p,
		span,
		label,
		input,
		button,
		div,
		optgroup,
		option {
			font-family: "Montserrat";
		}

		@include tooltipHelper("locktransfer", true);

		header {
			margin-bottom: 0.8rem;
			display: flex;
			align-items: center;
			height: $extHeightHeader;

			> div:first-child {
				display: flex;
				align-items: center;
				height: $extHeightHeader;
			}

			button {
				background: none;
				border: none;
			}

			button:hover {
				cursor: pointer;
			}
		}

		h1 {
			line-height: 1.05rem;
			font-size: 0.85rem;
			font-weight: 600;
			color: $extTextColor1;
			margin: 0;
		}

		h3 {
			line-height: 0.9rem;
			font-size: 0.7rem;
			font-weight: 600;
			color: $extTextColor2;
			margin: 0;
		}

		p {
			font-size: 0.5rem;
			font-weight: 600;
			color: $extTextColor1;
			margin: 0;
		}

		label {
			display: block;
			color: $extTextColor1;
			font-size: 0.65rem;
			font-weight: 600;
		}

		input:not([type="radio"]) {
			color: $extTextColor1;
			width: 100%;
		}

		input::placeholder {
			color: $extTextColor1;
		}

		input:focus {
			outline: none;
		}

		ul {
			padding: 0;
		}

		li {
			list-style: none;
		}

		button:hover {
			cursor: pointer;
		}

		.overflowCtrl {
			overflow-wrap: break-word;
			word-wrap: break-word;
			-ms-word-break: break-all;
			word-break: break-all;
		}

		.loader {
			position: absolute;
		}

		&.loaderNoBg {
			.loader {
				background: none;
			}
		}

		/* ====================== SCROLLBAR RULES ===================== */
		::-webkit-scrollbar-thumb {
			background: #d7d9ea;
		}

		::-webkit-scrollbar-thumb:hover {
			background: $black;
		}

		::-webkit-scrollbar-track {
			background: $lGrey;
		}

		&.dev {
			position: fixed;
			background-color: $extBgColor1;
			border-radius: 3px;
			top: 1rem;
			right: 3rem;
			z-index: 2147483642;
			box-shadow: 0px 0px 1rem $sh4;
			overflow-y: auto;
			overflow-x: hidden;

			/* ======================== STYLE EXCEPTIONS FOR DEV ======================= */

			.submit.fixed {
				position: relative;
			}
		}

		.spinner {
			align-self: center;
			justify-content: center;
			margin-bottom: 5rem;
		}

		footer {
			width: 100%;
			display: flex;
			justify-content: space-between;
			height: 3rem;
			background: $extAccentColor1;
			border-radius: 5px;
			overflow: visible;

			button {
				background: none;
				border: none;
				display: flex;
				gap: 0.5em;
				align-items: center;
				justify-content: center;
				flex: 1;
				padding: 0;
				border-radius: 0.2rem;
				@include accessibility("");

				&.BottomDetailButton {
					flex-direction: column;

					h2,
					p {
						margin: 0;
					}
				}

				h2,
				p {
					color: $white;
					margin: 0.4rem 0;
				}

				h2 {
					height: 0.85rem;
					font-weight: 500;
					font-size: 0.5rem;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				p {
					font-weight: 500;
					font-size: 0.45rem;
				}

				svg {
					height: 100%;

					* {
						fill: $white;
					}
				}

				&:focus {
					h2,
					p {
						color: $ciment;
					}

					svg * {
						fill: $ciment;
					}
				}
			}
		}

		.submit {
			background-color: $extAccentColor2;
			color: white;
			position: relative;
			width: 92%;
			margin: 0.6rem auto;
			display: flex;
			justify-content: center;
			align-items: center;
			border: none;
			font-size: 0.6rem;
			font-weight: 600;
			padding: 0.7rem;
			border-radius: 3px;

			svg {
				position: absolute;
				top: 30%;
				left: 0.85rem;
				height: 40%;

				g > * {
					fill: white;
				}
			}

			&.fixed {
				position: fixed;
				bottom: 0;
				left: 4%;
				z-index: 10;
			}
		}

		@include createPassBase;
		@include detection;
		@include generatePass;
		@include robustness;
		@include credentialsDetailsBase;
		@include homePopupBase;
		@include listPassBase;
		@include popupHeaderBase;
	}
}
