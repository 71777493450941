@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;
@use "../../components/" as *;

@mixin WhiteBackground {
	.whiteBackground {
		position: relative;
		width: 100%;
		height: 2rem;
		border-radius: 3px;
		background-color: $white;

		.darkMode & {
			background: rgb(134, 90, 232); /* Old browsers */
			background: -moz-linear-gradient(
				45deg,
				rgba(134, 90, 232, 1) 0%,
				rgba(42, 162, 228, 1) 100%
			); /* FF3.6-15 */
			background: -webkit-linear-gradient(
				45deg,
				rgba(134, 90, 232, 1) 0%,
				rgba(42, 162, 228, 1) 100%
			); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(
				45deg,
				rgba(134, 90, 232, 1) 0%,
				rgba(42, 162, 228, 1) 100%
			); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#865ae8', endColorstr='#2aa2e4',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
			color: $lGrey;
		}

		.changeStep {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding: 0.5rem 0.8rem;
			opacity: 0.7;
			border: none;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-radius: 3px;
			overflow: hidden;
			background-color: $actionBlue;

			.darkMode & {
				background: none;
			}

			&:hover {
				cursor: pointer;
			}

			p {
				color: $white;
				height: 40%;
				display: flex;
				align-items: center;
				font-weight: 500;
				font-size: 0.75rem;
				width: 100%;
				font-family: "Montserrat";

				svg {
					height: 100%;
					padding: 0.2rem;
					border-radius: 3px;
					width: 0.9rem;
					height: 0.9rem;
					background-color: $white;
					margin-right: 0.8rem;

					* {
						fill: $actionBlue;

						.darkMode & {
							fill: $dm-b3;
						}
					}
				}
			}

			&:focus {
				outline: none;
			}

			> svg {
				position: absolute;
				height: 3.2rem;
				width: 3.2rem;
				top: 5%;
				right: 2%;
				opacity: 0.21;
				z-index: -1;

				.darkMode & {
					opacity: 0.3;
				}

				* {
					fill: $white;

					.darkMode & {
						fill: $dm-b3;
					}
				}
			}
		}
	}
}
