@use "../../../utils/" as *;

@mixin inThinPopLeftPart {
	.title {
		font-size: 1rem;
		font-weight: medium;
		color: $anthra;
		margin-bottom: 0.4rem;
	}

	.details {
		width: 100%;
		display: flex;

		> img,
		> .icons {
			height: 2.5rem;
			width: 2.8rem;
			margin-right: 0.8rem;
			border-radius: 3px;
		}

		img {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;

			&.badIcon[alt] {
				color: transparent;

				&::after {
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 1.6rem;
					color: $ciment;
					position: absolute;
					top: 0px;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: $lGrey;
					text-align: center;
					content: attr(alt);
					.darkMode & {
						color: $ciment;
						background-color: $dm-b3;
					}
				}
			}
		}

		.icons {
			position: relative;

			&.pair {
				img {
					position: absolute;
					height: 1.75rem;
					width: 1.75rem;
					object-fit: cover;
					object-position: left;
					border-radius: 3px;
				}

				img:first-child {
					bottom: 0;
					left: 0;
					z-index: 1;
				}

				img:last-child {
					top: 0;
					right: 0;
					background-color: $white;
					box-shadow: 0 0 0.25rem $sh4;
					z-index: 2;
				}
			}

			&.cluster {
				img {
					position: absolute;
					height: 1.3rem;
					width: 1.3rem;
					object-fit: cover;
					object-position: left;
					border-radius: 3px;

					&:first-child {
						bottom: 0;
						left: 0;
						z-index: 1;
					}

					&:nth-child(2) {
						top: 0;
						left: 10%;
						box-shadow: 0 0 0.25rem $sh4;
						z-index: 2;
					}

					&:last-child {
						bottom: 20%;
						right: 0;
						background-color: $white;
						box-shadow: 0 0 0.25rem $sh4;
						z-index: 3;
					}
				}
			}
		}

		.details-icon-heading {
			margin-right: 0.8rem;
		}

		b {
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;

			.darkMode & {
				color: $ciment;
			}

			h2 {
				width: 100%;
				max-width: 140px;
				font-size: 0.85rem;
				color: $black;
				font-weight: 600;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				margin: 0.3rem 0;

				.darkMode & {
					color: $ciment;
				}
			}

			p {
				font-size: 0.8rem;
				font-weight: 400;
				color: $lAnthra;
				margin: 0.3rem 0;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}
