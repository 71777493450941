@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;

@mixin NavCredits($navMini: false) {
	.credits {
		@include DebugTool(2, ".credits");
		font-size: clamp(0.5rem, 0.7vw, 0.8rem);
		height: 48px;
		background-color: $dm-b1;
		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			width: 100%;
		}

		@if $navMini {
			display: flex;
			justify-content: center;
			align-items: center;

			.darkMode & {
				background-color: $dm-b1;
			}
		}

		@include mediaLarge {
			font-size: clamp(0.5rem, 0.7vw, 0.8rem);
			height: 48px;
			background-color: $black;
			// padding-bottom: 1vh;
			// background-position: right top;

			.darkMode & {
				background-color: $dm-b1;
			}

			@if $navMini {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 48px;
				padding-bottom: 0;
				// background-position: center bottom;
			}
		}

		// @include mediaMedium {
		// 	display: none;
		// }

		// .darkMode & {
		// 	background-image: url("../../../assets/decorators/navBotWaveDM.svg");
		// 	color: $grey;
		// }

		// img {
		// 	max-height: 14px;

		// 	@if $navMini {
		// 		@include mediaLarge {
		// 			max-height: 3vh;
		// 			margin-bottom: 3vh;
		// 		}
		// 	}

		// 	@include mediaLarge {
		// 		max-height: 0.8vh;
		// 	}
		// }

		// svg {
		// 	margin-left: 10px;
		// 	height: 14px;

		// 	@if $navMini {
		// 		margin-left: 0px;
		// 		margin-bottom: 20px;
		// 		height: 18px;

		// 		@include mediaLarge {
		// 			margin-bottom: 3vh;
		// 			height: 3vh;
		// 		}
		// 	}

		// 	@if $navMini == false {
		// 		@include mediaCustom {
		// 			margin-left: 0;
		// 			height: clamp(0.9rem, 1.3vw, 1.6rem);
		// 		}
		// 	}

		// 	path {
		// 		.darkMode & {
		// 			fill: $grey;
		// 		}
		// 	}
		// }
	}
}
