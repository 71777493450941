@use "../../../utils/" as *;
@use "../../../components/" as *;

@media screen and (max-width: 100px) {
	body {
		min-width: 20rem;
		min-height: $extHeightPopup;
	}
}

/*======================================================================================================*/
/*======================================================================================================*/
/*======================================================================================================*/
/*============================================= SCSS LOGIC =============================================*/
/*======================================================================================================*/
/*======================================================================================================*/
/*======================================================================================================*/

@mixin accessibility($suffix) {
	&:focus#{$suffix} {
		box-shadow: 0px 0px 0px 3px rgba($extAccessibilityShadow, 0.18);
		outline: none;
		stroke: none;
		animation: accessibilityFade 2s forwards;
	}

	&:active#{$suffix} {
		box-shadow: 0px 0px 0px 5px rgba($extAccessibilityShadow, 0.078) !important;
	}

	@keyframes accessibilityFade {
		from {
			box-shadow: 0px 0px 0px 3px rgba($extAccessibilityShadow, 0.18);
		}
		to {
			box-shadow: 0px 0px 0px 5px rgba($extAccessibilityShadow, 0);
		}
	}
}

@mixin BadFavIcon {
	// Used for favicon image of password url
	.favIcon > img {
		min-height: 100%;
		max-height: 2.2rem;
		position: relative;
		object-fit: cover;
		object-position: center left;
		border-radius: 3px;
		min-width: 1.7rem;
		border-radius: 0.2rem;
		overflow: hidden;

		&.badIcon {
			min-height: calc(100% + 0.4rem);
			max-height: calc(100% + 0.4rem);
			max-width: calc(100% + 0.4rem);
		}

		&.badIcon[alt] {
			color: transparent;

			&::after {
				display: flex;
				justify-content: center;
				align-items: center;
				color: $extTextColor2;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: inherit;
				background-color: $extBgColor3;
				text-align: center;
				content: attr(alt);
				font-size: 1.2rem;
				font-weight: bold;
			}
		}
	}
}

@mixin extHover($suffix) {
	& #{$suffix} {
		transition: transform 0.1s ease-in-out;
		transition: filter 0.1s ease-in-out;
	}
	&:hover {
		& #{$suffix} {
			transform: translateY(-1px);
			filter: brightness(110%);
		}
	}
}

@mixin ellipsisHelperPopup {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@mixin popupBtn {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: none;
	background: $extBgColor3;
	border-radius: 0.2rem;
	padding: 0.4rem 1rem;
	color: $extTextColor1;
	font-size: 0.7rem;
	font-weight: 500;

	&.big {
		font-size: 0.8rem;
		font-weight: 600;
	}

	&:not(.grey) {
		@include accessibility("");
		@include extHover("");
	}

	svg {
		path,
		circle {
			fill: $extTextColor1;
		}

		width: 0.9rem;
		height: 0.9rem;
	}

	&.primary {
		background: $extAccentColor1;
		color: $white;

		& svg {
			path,
			circle {
				fill: $white;
			}
		}
	}

	&.grey {
		cursor: default;
		outline: none;
		color: $grey;
		background: $extBgColor3;

		& svg {
			path,
			circle {
				fill: $grey;
			}
		}
	}
}

@mixin webkitBlur($value) {
	-webkit-filter: blur($value);
	-moz-filter: blur($value);
	-o-filter: blur($value);
	-ms-filter: blur($value);
	filter: blur($value);
}

@mixin backdropFilter($saturate, $blur, $opacity) {
	background-color: rgba($bgRgbValues, $opacity);
	-webkit-backdrop-filter: saturate($saturate) blur($blur);
	backdrop-filter: saturate($saturate) blur($blur);
}

/*======================================================================================================*/
/*======================================================================================================*/
/*======================================================================================================*/
/*=================================== STYLE USED IN DIFFERENT PLACES ===================================*/
/*======================================================================================================*/
/*======================================================================================================*/
/*======================================================================================================*/

/* ==================== PASSWORDS LIST RULES =================== */
/* ======================= CREDENTIAL DIV ====================== */

@mixin Credential {
	.Credential {
		width: 100%;
		margin: 0.5rem 0;
		border: 1px solid $extBgColor3;
		border-radius: 2px;

		&.highlight {
			background-color: $extBgColor3;
			border-radius: 5px;

			.passwordButtons {
				height: 100%;
				display: flex;
				align-items: center;

				.passwordButtons-action {
					background-color: $extBgColor2;
				}

				@include completionCircle {
					.CompletionCircle .timer-icon .circleToComplete {
						fill: $extBgColor3;
					}
				}
			}
		}

		.Credential-btn {
			width: 100%;
			height: 100%;
			background: unset;
			outline: none;
			border: none;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0.3rem;
			box-sizing: border-box;
			border-radius: 0.2rem;

			@include accessibility("");
			@include extHover("");

			> * {
				height: 80%;
			}

			.favIcon {
				width: 30px;
				height: 30px;
				position: relative;
				flex-shrink: 0;
				margin-right: 0.5rem;

				img {
					height: 100%;
					width: 100%;
					position: unset;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
				}
			}

			.noFavIconSpacer {
				padding: 0.15rem;
			}

			.passwordData {
				.username {
					display: flex;
					align-items: center;
					min-height: 1rem;

					svg {
						height: 0.5rem;
						width: 0.5rem;
						flex-shrink: 0;
						margin-right: 0.3rem;
						overflow: visible;

						path,
						circle,
						g {
							fill: $grey;
						}
					}

					.noUsername {
						height: 0.27rem;
						width: 4.3rem;
						border-radius: 2rem;
						background: $ciment;
						opacity: 50%;
					}
				}
			}

			.copyButtons {
				margin: 0.4rem 0 0.6rem 0.6rem;

				span {
					text-align: right;
					font-size: 0.5rem;
					font-weight: 600;
					color: $extTextColor1;
				}
			}

			/* ==================== PASSWORDS LIST RULES =================== */
			/* ================= ACCOUNT NAME AND USERNAME ================= */
			.passwordData {
				position: relative;
				width: 100%;
				height: 100%;
				overflow-x: hidden;
				display: flex;
				flex-direction: column;
				justify-content: center;
				text-align: start;

				span {
					height: 1rem;
					width: 100%;
					align-items: center;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;

					&:first-child {
						vertical-align: text-bottom;
						color: $extTextColor1;
						font-size: 0.8rem;
						font-weight: 600;
					}

					&:last-child {
						vertical-align: text-top;
						color: $extTextColor2;
						font-size: 0.7rem;
						font-weight: 400;
						fill: $extTextColor1;
					}
				}
			}

			/* ==================== PASSWORDS LIST RULES =================== */
			/* ===================== PASSWORDS BUTTONS ===================== */
			.passwordButtons {
				height: 100%;
				display: flex;
				align-items: center;
				z-index: 2;
				color: $extTextColor2;

				&.hover {
					opacity: 0%;
					pointer-events: none;
					animation: opacity 0.4s ease-in-out;
				}

				.passwordButtons-action {
					background-color: $extBgColor3;
					border-radius: 0.2rem;
					border: none;
					fill: $extTextColor2;
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 0 0.2rem;
					padding: 0;
					width: convertToREM(30);
					height: convertToREM(30);

					.copyListButton svg {
						width: 95%;
					}

					svg {
						width: auto;
						height: convertToREM(17);
					}

					svg > *,
					g > * {
						fill: $extTextColor2;
					}

					@include extHover("");

					&:hover {
						background-color: $extAccentColor2;
						cursor: pointer;

						svg > *,
						g > * {
							fill: $white;
						}
					}
				}

				@include completionCircle {
					.CompletionCircle .timer-icon .circleToComplete {
						fill: $extBgColor3;
					}
				}

				span {
					width: 100%;
					text-align: right;
					font-size: 0.6rem;
					margin: 0 0.3rem 0 0.5rem;
					line-height: 95%;
				}
			}

			&:hover {
				box-shadow: 0 0 15px 0 rgba(137, 154, 181, 0.1);

				.passwordButtons {
					&.hover {
						opacity: 100%;
						pointer-events: unset;
						animation: opacity 0.3s ease-in-out;
					}
				}
			}
		}

		@include completionCircle;

		@content;
	}
}

@mixin SearchBarSection {
	.searchBarSection {
		display: flex;
		width: 100%;

		&:not(.compact) {
			flex-direction: column-reverse;

			.searchButton {
				margin-top: 0.7rem;
			}

			.searchBar {
				padding-left: 0;
			}
		}

		.flex {
			flex-shrink: 0;
		}

		.searchButton {
			display: flex;
			align-items: center;
			position: relative;
			flex-grow: 0;
			flex-shrink: 0;
			cursor: pointer;
			font-size: 0.55rem;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			z-index: 1;

			& input {
				position: absolute;
				opacity: 0;
				cursor: pointer;
				height: 0;
				width: 0;

				&:checked ~ .checkmark {
					background-color: $extAccentColor2;
					color: $white;
					padding: 0.3rem 0.7rem 0.3rem 1.7rem;
				}

				@include accessibility("~ .checkmark");
				@include extHover("~ .checkmark");
			}

			.checkmark {
				background-color: $extBgColor2;
				padding: 0.3rem 0.7rem;
				border-radius: 100px;
				z-index: -1;
				color: $extTextColor2;
			}

			> .favIcon {
				position: absolute;
				height: 60%;
				width: 0.8rem;
				top: 20%;
				left: 0.6rem;
				display: flex;
				align-items: center;
				justify-items: center;

				img {
					top: 0;
					left: 0;
					height: 100%;
					min-height: 0;
					border-radius: 2px;
					width: 100%;
					min-width: 0;

					&.badIcon::after {
						font-size: 0.6rem;
					}
				}
			}
		}

		.searchBar {
			width: 100%;
			position: relative;
			display: flex;
			flex-grow: 1;
			padding-left: 0.5rem;

			.searchTerm {
				width: 100%;
				outline: none;
				border: none;
				border-radius: 50px 0 0 50px;
				color: $extTextColor1;
				font-size: 0.6rem;
				font-weight: bold;
				background: none;

				&::placeholder {
					color: $grey;
				}

				&:focus {
					&::placeholder {
						color: $lCoal;
					}
				}
			}

			.searchBarButton {
				width: 1.2rem;
				height: 1.2rem;
				background: none;
				border: none;
				border-radius: 50px;
				cursor: pointer;
				position: relative;

				@include accessibility("");
				@include extHover("");

				svg {
					position: absolute;
					top: 50%;
					left: 50%;
					min-width: 1rem;
					width: 1rem;
					height: 1rem;
					transform: translate(-50%, -50%);

					path,
					circle {
						fill: $grey;
					}
				}

				&.delete {
					background-color: $extBgColor2;
					svg {
						min-width: 0.6rem;
						width: 0.6rem;
						height: 0.6rem;

						path,
						circle {
							fill: $white;
						}
					}

					&:hover {
						background-color: $extPique;
					}
				}
			}
		}

		@content;
	}
}
