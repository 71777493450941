@use "../utils/variables" as *;
@use "../utils/mixins" as *;
@use "../components/forgot" as *;
@use "../components/tabs" as *;
@use "../components/input" as *;
@use "../components/select" as *;
@use "../components/licenseWarnMsg" as *;
@use "../components/toggler/toggler" as *;

@mixin lockerFrame($page: "", $inChangePin: false) {
	.lockerFrame {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
		flex: 2;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.forgotPass {
			margin-top: 10px;
			margin-bottom: -20px;
			width: 400px;
			text-align: left;
			font-weight: 500;
			color: $grey;
			font-size: 13px;
			display: flex;
			justify-content: space-between;

			@include mediaLarge {
				margin-top: 2vh;
				margin-bottom: -4vh;
				width: 30vw;
				font-size: clamp(0.7rem, 1.1vw, 0.9rem);
			}

			span,
			.supportLink {
				cursor: pointer;
				transition: $t1;

				&:hover {
					color: $black;

					.darkMode & {
						color: $white;
					}
				}
			}

			.supportLink {
				color: $greyShades-grey;
				font-weight: 500;
				font-size: 0.9rem;
				text-decoration: none;

				&:visited {
					color: $greyShades-grey;
				}

				&:hover {
					color: $black;
				}
			}
		}

		.head.pin,
		.head.mfa {
			font-size: 15px;
			font-weight: 500;

			.head-action {
				color: $anthra;
				cursor: pointer;
				margin-right: 1rem;

				svg {
					height: 11px;
					transform: rotate(180deg);

					@include mediaLarge {
						height: 0.7vw;
					}

					path {
						fill: $anthra;
					}
				}
			}

			.head-title {
				color: $black;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 64%;
			}
		}

		.head.mfa {
			h2 {
				.darkMode & {
					color: $greyShades-white;
				}
			}

			.backupCode {
				.darkMode & {
					color: $matSilver;
				}
			}
		}

		.locker {
			background-color: $white;
			width: 400px;
			border: 2px solid $lGrey;
			box-shadow: 0 0 25px $sh1;
			border-radius: 5px;
			padding: 35px 30px;
			position: relative;

			@include mediaLarge {
				width: 27vw;
				box-shadow: 0 0 3vw $sh1;
				padding: 2vw 2vw;
			}

			@if $inChangePin == false {
				.darkMode & {
					background-color: var(
						--params-changePin-bg-color,
						$dm-log1
					);
					border: 2px solid $dm-b4;
					box-shadow: 0 0 15px $dm-sh0;
				}
			}

			&.mfaLocker {
				position: relative;
				min-height: 27rem;

				.mfaPinLabel {
					position: absolute;
					width: 100%;
					top: -2rem;
					left: -2px;
					z-index: -1;
					background-color: $purpleShades-purple;
					border: 2px solid $purpleShades-purple;
					color: $greyShades-white;
					text-align: center;
					font-family: Montserrat;
					font-size: 0.78rem;
					font-weight: 600;
					border-radius: 0.6rem;
					padding: 0.42rem 0rem 0.78rem 0rem;
				}
			}

			.head {
				display: flex;
				justify-content: space-between;
				margin-bottom: 25px;

				&.mfa {
					display: grid;
					grid-template-columns: 1fr;
					gap: 0.6rem;

					.titleWrapper {
						display: flex;
						justify-content: space-between;
						margin: 0;
					}

					h2 {
						margin-top: 3rem;
						text-transform: none;

						.darkMode & {
							color: $greyShades-white;
						}
					}

					.authFormSubtitle {
						color: $greyShades-wetGrey;
						font-size: 0.9rem;
						font-weight: 500;
						margin: 0;

						.darkMode & {
							color: $matSilver;
						}
					}

					.backupButton {
						width: fit-content;
						padding: 0;
						background: none;
						border: none;
						color: $accent-blueAction;
						font-family: Montserrat;
						font-size: 0.78rem;
						font-weight: 600;
						cursor: pointer;

						.darkMode & {
							color: $accent-blueNeonShade;
						}
					}
				}

				@include mediaLarge {
					margin-bottom: 3vh;
				}

				h2 {
					color: $black;
					font-size: 21px;
					font-weight: 600;
					margin: 0;
					text-transform: uppercase;

					@include mediaLarge {
						font-size: clamp(0.7rem, 1.3vw, 1.3rem);
					}

					.darkMode & {
						color: $ciment;
					}
				}

				span {
					.darkMode & {
						svg path {
							fill: $ciment;
						}
					}
				}

				.options {
					cursor: pointer;

					@if $page != "login" {
						@include mediaLarge {
							height: 1.5vw;
						}
					}

					svg {
						height: 25px;
						transition: 0.6s ease;

						@include mediaMedium {
							height: 1.5vw;
						}
					}

					&.active {
						svg path {
							fill: $black;

							.darkMode & {
								fill: $dm-green1;
							}
						}
					}
				}

				&.pin,
				&.mfa {
					@include mediaLarge {
						font-size: clamp(0.6rem, 1vw, 1rem);
						margin-bottom: 1vh;
					}

					.head-action {
						.darkMode & {
							color: $dm-red1;
							text-shadow: 0 0 15px $dm-rL;
						}

						svg path {
							.darkMode & {
								stroke: $dm-red1;
							}
						}
					}

					.head-title {
						.darkMode & {
							color: $ciment;
						}
					}
				}
			}

			form {
				display: flex;
				flex-direction: column;
				margin-top: 35px;
				margin-bottom: 35px;
				min-height: 90%;
				box-sizing: border-box;

				@include mediaLarge {
					margin-top: 1vh;
					margin-bottom: 0;
				}

				input {
					margin-bottom: 15px;
					border: none;
					border-radius: 5px;
					background-color: $bg-lGrey;
					padding: 21px 14px;
					font-size: 17px;
					font-weight: 500;
					letter-spacing: 0.02em;

					@include mediaLarge {
						margin-bottom: 2vh;
						font-size: clamp(0.6rem, 1vw, 1rem);
						padding: 2.3vh 1.3vw;
					}

					.darkMode & {
						background-color: $dm-b4;
						color: $ciment;
						font-weight: 500;
					}

					&.noBotM {
						margin-bottom: 0;
					}

					&:focus {
						outline: none;
					}

					&::placeholder {
						.darkMode & {
							color: $anthra;
						}
					}
				}

				&.pin {
					margin-top: 0;

					.errorMsg {
						font-size: 0.7rem;
						color: $red;
						font-weight: 500;
						margin: 0 0 0.5rem 0.1rem;
					}

					h2 {
						text-transform: uppercase;
						font-size: 21px;
						font-weight: 600;
						color: $black;
						margin-bottom: 30px;

						@include mediaLarge {
							font-size: clamp(0.7rem, 1.3vw, 1.3rem);
							margin-bottom: 2.5vh;
						}

						.darkMode & {
							color: $ciment;
						}
					}

					.screen {
						box-sizing: border-box;
						height: 3.3rem;
						width: 100%;
						border-radius: 5px;
						border: 2px solid $ciment;
						background-color: $bg-lGrey;
						font-family: $kod;
						font-size: 25px;
						font-weight: 500;
						position: relative;
						padding: 22px 20px 15px;
						letter-spacing: 3px;
						margin-bottom: 2rem;
						display: flex;
						align-items: center;

						&.error {
							border-color: $red;
						}

						@include mediaLarge {
							font-size: clamp(0.7rem, 1.3vw, 1.3rem);
							margin-bottom: 1.3rem;
							padding: 2.6vh 2vw 2vh;
						}

						.darkMode & {
							border: 2px solid $dm-b4;
							background-color: $dm-b4;
							color: $lGrey;
						}

						.screen-action {
							font-size: 0;
							position: absolute;
							right: 18px;
							top: calc(50% - 8.5px);
							cursor: pointer;

							@include mediaLarge {
								right: 1.3vw;
								top: calc(50% - 1vh);
							}

							svg {
								height: 17px;

								@include mediaLarge {
									height: 2vh;
								}

								path {
									transition: $t1;

									.darkMode & {
										fill: $ciment;
									}
								}

								&:hover path {
									stroke: $black;

									.darkMode & {
										fill: $dm-red1;
										stroke: $dm-red1;
									}
								}
							}
						}
					}

					.pad {
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						align-items: flex-start;

						.pad-btn {
							display: block;
							position: relative;
							width: calc(20% - 15px);
							border-radius: 5px;
							margin-bottom: 0.8rem;
							border: 2px solid $ciment;
							cursor: pointer;
							transition: $t1;
							right: 0;
							bottom: 0;

							@include mediaLarge {
								width: calc(20% - 1.1vw);
							}

							.darkMode & {
								border: 2px solid $dm-b4;
							}

							&:hover {
								box-shadow: 1px 7px 12px $sh1;
								bottom: 4px;
								right: 2px;

								@include mediaLarge {
									bottom: 0.3vh;
									right: 0.1vw;
								}

								.darkMode & {
									box-shadow: 1px 7px 17px $dm-sh0;
									bottom: 4px;
									right: 2px;
								}
							}

							&:hover::after {
								content: "";
								position: absolute;
								bottom: -10px;
								right: -10px;
								height: 100%;
								width: 100%;
							}

							&::before {
								content: "";
								display: block;
								padding-top: 100%;
							}

							span {
								position: absolute;
								top: 0;
								left: 0;
								bottom: 0;
								right: 0;
								background-color: $bg-lGrey;
								font-family: $kod;
								font-size: 25px;
								font-weight: 600;
								line-height: 100%;
								height: 100%;
								border-radius: 3px;
								text-align: center;
								display: flex;
								align-items: center;
								justify-content: center;

								@include mediaLarge {
									font-size: clamp(0.7rem, 1.8vw, 1.3rem);
								}

								.darkMode & {
									color: $white;
									background-color: $dm-b4;
								}
							}

							small {
								position: absolute;
								bottom: 0.1rem;
								right: 0.3rem;
								font-weight: 600;
								color: $grey;
							}
						}

						&.hideKeys {
							.pad-btn small {
								display: none;
							}
						}
					}

					.keyboardModFrame {
						display: flex;
						align-items: center;
						margin-top: 0.7rem;

						@include TogglerFrame {
							margin-right: 0.5rem;
						}

						.keyboardModFrame-info {
							margin-left: 0.2rem;
							font-size: 0.9rem;
							font-weight: 500;
							color: $anthra;

							.darkMode & {
								color: $ciment;
							}
						}
					}
				}
			}

			.inputContainer {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				display: flex;
				margin-top: 2.2rem;
				padding: 1rem 0.9rem;
				box-sizing: border-box;
				border-radius: 5px;
				border: 2px solid $matSilver;
				border-radius: 5px;
				background-color: $greyShades-snow;

				.darkMode & {
					background-color: $dm-b4;
					border: none;
				}

				.mfaInput {
					flex: 1;
					font-family: Montserrat;
					font-size: clamp(0.6rem, 1vw, 1rem);
					font-weight: 500;
					letter-spacing: 0.02rem;
					background: none;
					border: none;

					.darkMode & {
						color: $greyShades-white;
					}

					&::placeholder {
						color: $greyShades-grey;
						font-size: 0.9rem;
						font-weight: 500;

						.darkMode & {
							color: $matSilver;
						}
					}

					&:focus {
						outline: none;
					}
				}

				.deleteButton {
					height: 1.4rem;
					width: 1.4rem;
					background: none;
					border: none;

					.icon {
						width: 100%;
						height: 100%;

						* {
							fill: $brand-black;
							stroke: $brand-black;

							.darkMode & {
								fill: $greyShades-white;
								stroke: $greyShades-white;
							}
						}
					}
				}
			}

			@include tabs {
				.tabsHead {
					.tabsHead-child.active {
						.darkMode & {
							color: $white;
						}

						&::after {
							.darkMode & {
								background-color: $dm-green1;
								box-shadow: 0 0 25px $dm-gL;
							}
						}
					}
				}

				@if $page == "login" {
					.tabsBody {
						@include mediaMinCustom(1600px) {
							padding-top: 30px;
						}
					}
				}
			}

			@include forgot {
				.forgot {
					@include mediaMinCustom(1600px) {
						padding-top: 3vh;
					}
				}
			}

			.actions {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 15px;

				@include mediaLarge {
					margin-top: 3vh;
				}

				&.pin {
					align-items: baseline;
					margin-top: 0;

					@include select {
						.selectFrame {
							flex-direction: row;
							align-items: center;

							.selectFrame-header {
								margin-bottom: 0;

								label {
									color: $grey;

									@if $page == "login" {
										.darkMode & {
											color: $grey;
										}
									} @else {
										.darkMode & {
											color: var(--labelColor, $anthra);
										}
									}
								}
							}

							select {
								border: none;
								padding: 0 1.5rem 0 0.7rem;

								.darkMode & {
									border: none;
									background-image: url("../../assets/images/selectArrowDM.svg");
								}
							}
						}
					}
				}

				@include select {
					.selectFrame {
						.selectFrame-header {
							label {
								.darkMode & {
									color: $anthra;
								}
							}
						}

						select {
							.darkMode & {
								background-image: url("../../assets/images/selectArrowDM.svg");
							}
						}
					}
				}

				button {
					@include mediaLarge {
						font-size: clamp(1rem, 1.4vw, 1.3rem);
						padding: 1.6vh 1.7vw;
					}

					.darkMode & {
						background-color: $dm-b4;
						color: $dm-green1;
						text-shadow: 0 0 10px $dm-gL2;
					}
				}
			}

			.buttons {
				button {
					font-size: 1rem;
					width: 100%;
				}
			}

			.regularAuthSSO {
				display: flex;
				flex-direction: column;
				flex: 2;
				margin-top: 1rem;
				margin-bottom: 1rem;

				.regularAuthSSO-title {
					color: $black;
					font-weight: 600;
					font-size: 0.9rem;
					display: flex;
					align-items: center;
					margin-bottom: 0.4rem;

					.darkMode & {
						color: $lGrey;
					}

					svg {
						height: auto;
						width: 0.65rem;
						min-width: 0.65rem;
						margin-right: 0.5rem;

						path,
						circle {
							fill: $violet;

							.darkMode & {
								fill: $dm-lBlue;
							}
						}
					}
				}

				.regularAuthSSO-desc {
					color: $anthra;
					font-size: 0.8rem;
					font-weight: 500;

					.darkMode & {
						color: $ciment;
					}
				}
			}

			.changePinHead {
				display: flex;

				.changePinHead-container {
					flex: 2;

					h2 {
						font-size: 21px;
						font-weight: 500;
						margin-top: 0;

						.darkMode & {
							color: $ciment;
						}
					}

					.changePinHead-steps {
						display: flex;

						.changePinHead-step {
							background-color: $ciment;
							padding: 3px 15px;
							border-radius: 3px;
							margin-right: 10px;
							transition: $t1;

							&.active {
								background-color: $orange;
								padding: 3px 60px;
							}
						}
					}
				}

				.changePinHead-activeStep {
					font-size: 18px;
					color: $grey;
					letter-spacing: 2px;
				}
			}
		}

		@if $page == "login" {
			.licenseWarnPop {
				padding: 0;
				min-width: 25rem;
				margin: 2rem 0;
				overflow-y: auto;
				overflow-x: hidden;
			}

			@include licenseWarnMsg;
		}
	}

	@content;
}
