@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;

@mixin NavMenu($navMini: false) {
	.nav {
		padding-left: 10px;
		padding-right: 10px;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		overflow-x: hidden;
		justify-content: center;

		@include mediaMinCustom(1600px) {
			flex: 2;

			@if $navMini {
				align-items: flex-end;
			}
		}

		@include mediaCustom {
			padding-left: 0.3vw;
			padding-right: 10px;

			@if $navMini {
				padding-left: 0;
				padding-right: 0;
				align-items: center;
			}
		}

		@include mediaMedium {
			align-self: flex-start;
			justify-content: flex-start;
			margin-top: 1.875rem;
		}

		.spacer {
			padding: 25px 0;

			@include mediaCustom {
				padding: 2vh 0;
			}

			@include mediaMedium {
				margin-top: 30px;
			}
		}
	}

	> .nav {
		@include mediaMedium {
			display: none;
		}
	}
}
