@use "../../utils" as *;
@use "../../organisms/navigation" as *;
@use "../../components" as *;

@mixin Navigation {
	#layoutNav {
		@include DebugTool(1, "#layoutNav");
		z-index: 500;
		width: 196px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
		transition: 0.3s ease-in-out;
		background-color: $bg-white;

		@include mediaLarge {
			min-width: 12.25rem;
			max-width: 15rem;
		}

		@include mediaMedium {
			width: 100%;
			box-shadow: unset;
		}

		.darkMode & {
			background-color: $dm-b2;
		}

		@include NavTop;
		@include NavMenu;
		@include NavMobile;
		@include NavLinks;
		@include ButtonLayout("navControl");
		@include NavCredits;
		@include NavMini {
			width: 83px;

			@include mediaLarge {
				min-width: 83px;
				width: 5vw;
				max-width: 70px;
			}
		}

		&.guacTermActive {
			z-index: 0;
		}
	}
}
