@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;
@use "../../components/" as *;

@mixin Default {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1001;
	background-color: $bg-actionPop;

	@include fileLine;
	@include repsFrame;
}
