@use "../utils/mixins" as *;
@use "../utils/variables" as *;
@use "./createMFA" as *;
@use "./removeMFA" as *;

@mixin HeadlessPopup {
	.headlessPopFrameWrapper {
		position: fixed;
		z-index: 500;
		top: 0px;
		left: 0px;
		width: 100vw;
		height: 100vh;
		backdrop-filter: blur(2px);
		background-color: $transparentlGrey;
		display: flex;
		justify-content: center;
		align-items: center;

		&.closeOnBackgroundClick {
			cursor: url("../../assets/images/close.svg"), auto;
		}

		.HeadlessPop {
			width: 34.02rem;
			max-height: 95%;
			overflow-y: auto;
			overflow-x: hidden;
			cursor: auto;

			@media (max-width: 575px) {
				width: 20rem;
			}

			.headlessPopHeader {
				display: flex;
				justify-content: flex-end;
				margin-bottom: 0.6rem;

				&.closeCursor {
					cursor: url("../../assets/images/close.svg"), auto;
				}

				.popupCloseButton {
					width: 1.8rem;
					height: 1.8rem;
					border: none;
					background: none;
					cursor: pointer;
					padding: 0rem;

					.icon {
						width: 100%;
						height: 100%;

						* {
							stroke: $matSilver;

							.darkMode & {
								stroke: $dm-b4;
								fill: $dm-b4;
							}
						}
					}
				}
			}

			.headlessPopFrame {
				background-color: $white;
				padding: 0.9rem 1.2rem;
				width: 31.62rem;
				display: flex;
				flex-direction: column;

				@media (max-width: 575px) {
					width: 20rem;
					box-sizing: border-box;
				}

				.darkMode & {
					background-color: $dm-b3;
				}

				.headlessPopTitle {
					.headlessPopName {
						font-size: 1.26rem;
						font-weight: 600;
						margin: 0;

						.darkMode & {
							color: $greyShades-white;
						}

						@media (max-width: 575px) {
							font-size: 1rem;
						}
					}

					.headlessPopSubtitle {
						margin-left: 0.6rem;
						color: $matSilver;
						font-size: 0.9rem;
						font-weight: 600;
						display: flex;
						align-items: center;

						.location {
							color: $black;
							display: inline-flex;
							align-items: center;
							margin-left: 0.6rem;

							.darkMode & {
								color: $greyShades-white;
							}

							* {
								margin-right: 0.3rem;
							}
						}

						@media (max-width: 575px) {
							margin: 0 0.15rem 0 0.9rem;
						}
					}
				}

				@include CreateMFA();
				@include RemoveMFA();

				.submitButton {
					position: relative;
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					height: 2.58;
					padding: 0.8rem 1.02rem;
					border: none;
					border-radius: 0.3rem;
					background-color: $accent-blueAction;
					font-family: "Montserrat", Helvetica;
					color: $greyShades-white;
					font-size: 0.9rem;
					font-weight: 600;
					white-space: nowrap;
					cursor: pointer;

					.darkMode & {
						background-color: $purpleShades-galaxySauce;
					}

					.icon {
						position: absolute;
					}
				}
			}

			.headlessPopFooter {
				padding-top: 1.2rem;

				.supportLink {
					color: $greyShades-grey;
					font-family: Montserrat;
					font-size: 0.9rem;
					font-weight: 500;
					text-decoration: none;
					cursor: pointer;

					&:visited,
					&:active {
						color: $greyShades-grey;
						text-decoration: none;
					}
				}
			}
		}
	}
}
