@use "../utils/variables" as *;
@use "../utils/mixins" as *;

@mixin timer2 {
	.timer2 {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $bg-padlockPop;
		font-weight: bold;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 5;
		text-align: center;
		font-family: $mon;

		.darkMode & {
			background-color: $dm-padlockPop;
		}

		span {
			text-transform: uppercase;
			color: $anthra;
			max-width: 70%;
			font-size: 18px;

			@include mediaLarge {
				font-size: clamp(0.7rem, 1.1vw, 1.1rem);
			}

			.darkMode & {
				color: $ciment;
			}
		}

		button {
			margin-top: 45px;
			background-color: $blue;
			border: none;
			border-radius: 5px;
			color: $white;
			font-size: 15px;
			letter-spacing: 0.02em;
			text-transform: uppercase;
			font-weight: 600;
			padding: 20px 25px;
			cursor: pointer;

			@include mediaLarge {
				margin-top: 5vh;
				font-size: clamp(0.7rem, 1vw, 1rem);
				padding: convertToREM(10) 1.7vw;
			}

			.darkMode & {
				background-color: $dm-b4;
				color: $dm-green1;
				text-shadow: 0 0 10px $dm-gL2;
			}

			&:focus {
				outline: none;
			}
		}
	}
}
