@use "../../../utils/variables" as *;
@use "./helpers" as *;
@use "../../../components" as *;

@mixin generatePass {
	#GeneratePass {
		.password {
			display: flex;
			flex-direction: column;
			justify-content: center;

			.display {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				margin: 0.3rem 0 0.5rem;

				button {
					display: flex;
					align-items: center;
					justify-content: center;
					background: none;
					border: none;
					height: 100%;
					margin: 0 0.25rem;
					padding: 0.25rem;
					border-radius: 0.2rem;
					@include accessibility("");

					svg {
						path {
							fill: $grey;
						}
					}

					span {
						color: $extTextColor1;
						font-size: 1.5rem;
						font-weight: 600;
						word-break: break-all;
						text-align: center;

						&:after {
							content: "";
							background-image: url("../../../../assets/images/clone.svg");
							background-size: 0.8rem 0.8rem;
							display: inline-block;
							width: 0.8rem;
							height: 0.8rem;
							opacity: 40%;
							margin-left: 0.5rem;
							transition:
								transform 0.1s ease-in-out,
								opacity 0.1s ease-in-out;
							will-change: transform, opacity;
						}

						&:hover:after {
							transition:
								transform 0.1s ease-in-out,
								opacity 0.1s ease-in-out;
							transform: scale(1.05);
							opacity: 60%;
						}

						b {
							color: $extPique;
						}

						i {
							font-style: normal;
							color: $extAccentColor2;
						}

						&.small {
							font-size: 1.3rem;
						}

						&.smaller {
							font-size: 1rem;
						}
					}
				}
			}

			.complementary {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.generateBtn {
					@include popupBtn;
				}
			}
		}

		.policy {
			margin-top: 1.5rem;

			.dropdown {
				display: flex;
				justify-content: center;
				min-width: 5rem;
				margin: 0 auto;
				position: relative;

				.iconContainer {
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					width: 2rem;
					display: flex;
					align-items: center;
					justify-content: center;

					svg {
						width: 0.8rem;
						height: 0.8rem;

						path,
						circle,
						g {
							fill: $extTextColor2;
						}
					}
				}

				label {
					position: relative;
					border-radius: 0.2rem;

					select {
						font-family: $mon;
						font-weight: 600;
						min-width: 5rem;
						max-width: 7rem;
						height: 1.7rem;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						padding: 0.2rem;
						padding-left: 2rem;
						border-radius: 10px;
						font-size: 0.65rem;
						background: $extBgColor3;
						color: $extTextColor1;
						border: none;
						outline: none;
						display: inline-block;
						-webkit-appearance: none;
						-moz-appearance: none;
						appearance: none;
						cursor: pointer;

						@include accessibility("");
					}

					@include extHover("");
				}
			}

			.buttonContainer {
				display: flex;
				margin-top: 1rem;
				gap: 0.5rem;

				.policyBtn {
					display: flex;
					position: relative;
					flex-grow: 1;
					flex-shrink: 0;
					cursor: pointer;
					align-items: center;
					font-size: 0.7rem;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
					z-index: 1;

					input {
						position: absolute;
						opacity: 0;
						cursor: pointer;
						height: 0;
						width: 0;

						&:checked ~ span {
							background-color: $extAccentColor2;
							color: $white;
						}

						@include extHover(" ~ span");
						@include accessibility(" ~ span");
					}

					span {
						border: 2px solid transparent;
						background-color: $extBgColor3;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 0.2rem;
						height: 1.7rem;
						flex-grow: 1;
						z-index: -1;
						color: $extTextColor2;
					}

					&:last-child {
						flex-shrink: 1;

						span {
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
					}
				}
			}

			.sliderContainer {
				margin-top: 1rem;
				background: $extBgColor3;
				border-radius: 0.2rem;
				color: $extTextColor1;

				&.disabled-input {
					opacity: 0.5;
					cursor: not-allowed;
				}

				.sliderContainer-content {
					display: flex;
					justify-content: space-between;
					padding: 0.5rem 0.8rem 0;

					span {
						overflow: hidden;
						max-width: 45%;
						text-overflow: ellipsis;

						&:nth-child(1) {
							font-size: 0.7rem;
						}

						&:nth-child(2) {
							font-weight: 600;
						}

						&:last-child {
							opacity: 0;
							pointer-events: none;
						}
					}
				}

				input[type="range"] {
					-webkit-appearance: none;
					margin: 0;
					height: 7px;
					background: $extBgColor2;
					border-radius: 5px;
					background-image: linear-gradient(
						$extAccentColor2,
						$extAccentColor2
					);
					background-repeat: no-repeat;

					&::-webkit-slider-thumb {
						-webkit-appearance: none;
						height: 20px;
						width: 20px;
						border-radius: 50%;
						background: $extBgColor1;
						border: 2px $extAccentColor2 solid;
						cursor: pointer;
						box-shadow: 0 0 2px 0 #555;
					}

					&::-webkit-slider-runnable-track {
						-webkit-appearance: none;
						box-shadow: none;
						border: none;
						background: transparent;
					}

					&::-webkit-slider-thumb:hover {
						box-shadow: #2834b72e 0px 0px 0px 4px;
					}

					&::-webkit-slider-thumb:active {
						box-shadow: #2834b710 0px 0px 0px 8px;
						transition:
							box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
							left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
							bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
					}

					&::-webkit-slider-thumb {
						transition: background 0.3s ease-in-out;
					}

					@include accessibility("::-webkit-slider-thumb");
					@include accessibility("::-moz-range-thumb");
					@include accessibility("::-ms-thumb");

					&:focus {
						box-shadow: none;
						outline: none;
					}
				}
			}

			&:not(.manual) {
				.dropdown {
					label {
						select {
							background: coral;
							max-width: unset;

							option {
								background: $extBgColor3;
							}
						}
					}

					.iconContainer {
						svg {
							path,
							circle,
							g {
								fill: $extTextColor1;
							}
						}
					}
				}

				.buttonContainer {
					.policyBtn {
						input {
							&:checked ~ span {
								background-color: coral;
								color: $white;
								background-color: $extBgColor3;
								color: $extTextColor1;
								border-color: coral;
							}
						}

						span {
							background-color: $extBgColor3;
							color: $extTextColor2;
						}

						&:hover {
							input:checked ~ span {
								border-color: coral;
							}
						}
					}
				}

				.sliderContainer {
					input[type="range"] {
						background-image: linear-gradient(coral, coral);

						&::-webkit-slider-thumb {
							border: 2px coral solid;
						}

						&:disabled {
							background-image: linear-gradient($grey, $grey);
							cursor: not-allowed;

							&::-webkit-slider-thumb {
								border: 2px $grey solid;
							}
						}
					}
				}
			}
		}

		.overSaveButtonsSpacer {
			margin-top: 3rem;
		}

		@include buttonIndic;

		.saveButtons {
			display: flex;
			gap: 0.5rem;
			margin: 0 0 1rem;

			button.autoFill {
				width: 100%;
				@include popupBtn;
				padding: 0.55rem 0.7rem;
				position: relative;
				justify-content: center;

				svg {
					position: absolute;
					left: 0.55rem;
				}

				&.secondary {
					svg {
						width: 0.7rem;
						height: 0.7rem;
						transform: rotate(180deg);
					}
				}
			}

			button.primary {
				&:disabled {
					background-color: $greyShades-grey;
					color: $black;
				}
			}
		}
	}
}
