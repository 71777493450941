@use "../../utils/variables" as *;
@use "../../layout/extension/base/helpers" as *;

@mixin navContainerPopup {
	.navContainer {
		position: relative;
		margin: 0 $marginPopup;

		.navigation {
			display: flex;
			justify-content: space-between;
			overflow: hidden;
			padding-bottom: 0.1rem;
			padding-top: $marginPopup;
			opacity: 1;
			transition: opacity 0.4s ease-out;

			.popup-navLink {
				padding-bottom: 0.5rem;
				padding-top: 1.7rem;
				margin: 0 0.3rem;
				position: relative;
				text-decoration: none;
				color: $extTextColor2;
				font-weight: 600;
				font-size: 0.8rem;
				transition: color 0.2s ease-in-out;
				user-select: none;
				border-radius: 0.3rem;
				@include accessibility("");

				svg {
					position: absolute;
					top: 1.2rem;
					left: 50%;
					min-width: 1.4rem;
					width: 1.4rem;
					height: 1rem;
					transform: translate(-50%, -100%);

					&.bigger {
						top: 1.25rem;
						min-width: 1.7rem;
						width: 1.7rem;
						height: 1.3rem;
					}

					path,
					circle {
						fill: $extTextColor2;
					}
					path[data-name~="secondTone"],
					g[data-name~="secondTone"] path {
						fill: $extBgColor2;
					}
				}

				&::before {
					content: "";
					position: absolute;
					bottom: 0%;
					left: 50%;
					width: 3rem;
					height: 0.5rem;
					border-radius: 3rem;
					transform: translate(-50%, 200%);
					background: $extAccentColor1;
					transition: transform 0.2s ease-in-out;
				}

				&.active {
					transform: translateY(-10%);
					transition: color 0.2s ease-in-out;

					&::before {
						transition: transform 0.2s ease-in-out;
						transform: translate(-50%, 150%);
					}
				}

				&:hover,
				&.active {
					transition: color 0.2s ease-in-out;
					color: $extTextColor1;

					svg {
						path,
						circle {
							fill: $extAccentColor2;
						}
						path[data-name~="secondTone"],
						g[data-name~="secondTone"] path {
							fill: $extBgColor2;
						}
					}
				}
			}
		}

		.overflowContainer {
			position: absolute;
			bottom: 0;
			height: 100%;
			width: 100%;
			display: flex;
			align-items: flex-end;
			margin: 0 -10px;
			padding: 0 10px calc(2 * $marginPopup - $scroolBarSizePopup);
			overflow: hidden;
			pointer-events: none;

			& > footer {
				transform: translateY(150%);
				transition: transform 0.5s ease-in;
			}
		}

		&.footer {
			.overflowContainer {
				pointer-events: unset;

				& > footer {
					transition: transform 0.4s ease-out;
					transform: unset;
				}
			}

			.navigation {
				transition: opacity 0.4s ease-out;
				opacity: 0;
				pointer-events: none;
			}
		}
	}
}
