@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;
@use "../../utils/functions" as *;
@use "../../components/inputs/numInput" as *;

@mixin ToggleDefault($color: $pastel-green, $togglerSize: "normal") {
	.toggler {
		@include DebugTool(3, ".toggler", false);
		height: 25px;
		width: 45px;
		border-radius: 300px;
		border: 2px solid $black;
		background: transparent;
		padding-left: 4px;
		cursor: pointer;
		position: relative;

		.darkMode & {
			border: 2px solid $ciment;
		}

		&.on {
			background: $color;
			border: 2px solid $color;
			justify-content: flex-end;

			.darkMode & {
				background: $dm-lBlue;
				border: 2px solid $dm-lBlue;
			}
		}

		.buttonTrigger {
			position: absolute;
			transition: 0.3s ease;
			top: 2px;
			left: 3px;
			background-color: $black;
			border-radius: 100%;
			height: 21px;
			width: 21px;
			animation-direction: reverse;
			animation-fill-mode: backwards;

			@if $togglerSize == "mini" {
				animation: 1s ease-in-out miniToggleChange;
			} @else {
				animation: 1s ease-in-out ToggleChange;
			}

			.darkMode & {
				background-color: $ciment;
			}

			&.on {
				background-color: $white;
				left: 26px;
				animation-direction: normal;
				animation-fill-mode: backwards;

				@if $togglerSize == "mini" {
					animation: 1s ease-in-out miniToggleChange;
				} @else {
					animation: 1s ease-in-out ToggleChange;
				}

				.darkMode & {
					background-color: $lGrey;
				}
			}
		}

		.buttonTriggerNotAnim {
			@extend .buttonTrigger;
			animation: none !important;
			animation-direction: normal !important;
			animation-fill-mode: none !important;

			.darkMode & {
				background-color: $ciment;
			}
		}

		@content;
	}
}

@mixin ToggleDashboard {
	.togglerFrame {
		height: 80%;
		width: 80%;

		.toggler {
			@include DebugTool(3, ".toggler", false);
			height: 100%;
			width: 100%;
			border-radius: 300px;
			border: 2px solid $matSilver;
			background: $greyShades-matSilver;
			// padding-left: 4px;
			cursor: pointer;
			position: relative;

			.darkMode & {
				border: 2px solid $ciment;
			}

			&.on {
				background: rgba(var(--colorProduct), 1);
				border: 2px solid rgba(var(--colorProduct), 1);
				justify-content: flex-end;

				.darkMode & {
					background: $dm-lBlue;
					border: 2px solid $dm-lBlue;
				}
			}

			.buttonTrigger {
				transition: 0.3s ease;
				position: absolute;
				left: 20%;
				top: 50%;
				transform: translate(-50%, -50%);
				border: 1px solid rgba(0, 0, 0, 0.1);
				background-color: $white;
				border-radius: 100%;
				height: 9px;
				width: 9px;
				width: 70%;
				height: 170%;

				animation: 1s ease-in-out ToggleDashboardChange;
				animation-direction: reverse;
				animation-fill-mode: backwards;

				.darkMode & {
					background-color: $ciment;
				}

				&.on {
					background-color: $white;
					// border: none;
					left: 77%;
					animation: 1s ease-in-out ToggleDashboardChange;
					animation-direction: normal;
					animation-fill-mode: backwards;

					.darkMode & {
						background-color: $lGrey;
					}
				}
			}
		}
	}
}

////////////////////////////////////////
/////////////   MULTILIST   ////////////
////////////////////////////////////////

@mixin MultiListDefault {
	.multiList {
		@include DebugTool(3, ".multiList");
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-top: convertToREM(5);
		max-width: 37vw;

		.multiList-container {
			background-color: $bg-lGrey;
			color: $black;
			font-size: 11px;
			font-weight: 500;
			letter-spacing: 0.02em;
			padding: 5px 7px;
			border-radius: 15px;
			margin-right: 3px;
			margin-bottom: 3px;
			display: flex;

			@include mediaLarge {
				max-width: 31.7rem;
			}

			.darkMode & {
				background-color: $dm-b5;
				color: $ciment;
				font-weight: 600;
			}
		}

		.multiList-title {
			max-width: 38rem;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		svg {
			cursor: pointer;
			height: 8px;
			width: 8px;
			margin-left: 10px;
			align-self: center;

			@include mediaLarge {
				min-width: 1rem;
			}

			path {
				stroke: $black;

				.darkMode & {
					stroke: $black;
				}
			}
		}

		@content;
	}
}

@mixin MultiListWrongMail {
	.wrongMailMultiList {
		color: $red;
		border: 1.5px solid $red;
		cursor: pointer;
	}
}

////////////////////////////////////////
////////////   INPUT TEXT   ////////////
////////////////////////////////////////

@mixin InputFrameDefault($location: "") {
	.inputFrame {
		@include DebugTool(3, ".inputFrame");
		display: flex;
		flex-direction: column;
		position: relative;
		margin-bottom: 20px;
		width: 100%;
		transition: 0.3s ease;

		@include mediaLarge {
			margin-bottom: 2vh;
		}

		&.unfocused {
			@include mediaLarge {
				margin-bottom: 0;
			}
		}

		.errorMsg {
			font-size: 0.7rem;
			color: $red;
			font-weight: 500;

			&.addPassErrMsg {
				margin-top: 0.3rem;
			}
		}

		.errorBottom {
			.containerMailErrorMessage {
				display: flex;
				justify-content: space-between;
				width: 100%;
			}
		}

		.validateContainer {
			position: absolute;
			right: 0.5rem;
			top: 20%;
			background-color: $white;
			border-radius: 0.2rem;
			display: flex;
			align-items: center;
			width: 1.7rem;
			height: 1.7rem;
			justify-content: center;
			cursor: pointer;

			.darkMode & {
				background-color: $dm-b4;
			}

			.icon {
				width: 1.3rem;
				height: 1.3rem;

				path {
					fill: $greyShades-grey;

					.darkMode & {
						fill: $white;
					}
				}
			}
		}

		.inputMultiHelper {
			z-index: 10;
			display: flex;
			justify-content: flex-end;
			gap: 0.5rem;

			span {
				font-size: clamp(0.6rem, 0.9vw, 0.9rem);
				font-weight: 500;
				color: $anthra;
			}
		}

		&:not(.forceShowTitle) {
			.inputTitle {
				opacity: 0;
			}
		}

		.inputTitle {
			display: flex;
			align-items: center;
			transition: $t1;
			margin-bottom: 8px;
			gap: 0.5em;

			.inputTitleButton {
				border: none;
				background: none;
				font-weight: 600;
				font-size: 0.7rem;
				cursor: pointer;

				.darkMode & {
					color: $lGrey;
				}
			}

			.questionMark {
				@include flexPosition(center, center);
			}

			&.revert {
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}

			.inputHeading {
				margin: 0;
				color: $black;
				font-size: 15px;
				font-weight: 500;
				position: relative;
				display: flex;
				gap: 0.4rem;
				width: 100%;

				&.spacing {
					margin-top: -0.6rem;
					margin-bottom: 0.4rem;
				}

				@include mediaLarge {
					font-size: clamp(0.7rem, 1vw, 1rem);
				}

				.darkMode & {
					color: $ciment;
				}

				.inputTitleContainer {
					h4 {
						display: flex;
						align-items: center;
						margin: 0;

						:first-child {
							margin-left: 0.2rem;
						}
					}

					.inputPp {
						font-size: 0.7rem;
						margin: 0;
					}
				}
			}
		}

		.inputIndicators {
			display: flex;
			justify-content: space-between;
			line-height: 1.3rem;
		}

		.inputActionsContainer {
			display: flex;
			gap: 0.6rem;
			height: 100%;

			&:empty {
				min-width: 3.8rem;
				height: 2rem;
			}

			svg {
				path {
					.darkMode & {
						fill: $matSilver;
					}
				}
			}
		}

		.inputWrap {
			@if $location == "headlessMFA" {
				background-color: $greyShades-snow;
				padding: 0 0.9rem;
				height: 3rem;

				.darkMode & {
					background-color: $dm-b4;
				}
			}

			&.telAutocompletePositionOver {
				&.hasTitle {
					.inputWrapActions {
						padding: 2.3rem 0 0;
					}
				}
			}

			&.addInside {
				gap: 0;

				.inputWrapActions {
					position: absolute;
					right: 0.6rem;
				}
			}

			.inputActionsContainer {
				&.hasTitle {
					.inputWrapActions {
						padding: 2.6rem 0 0;

						&.iconPosition {
							padding: 0.6rem 0;
						}

						&.iconPositionClose {
							position: relative;
							z-index: 10;
							padding: 0.8rem 0;
						}
					}
				}

				.inputWrapActions {
					cursor: pointer;
					padding: 1rem 0 0;

					.clone-icon {
						position: relative;
						top: -0.7rem;
						right: 0.6rem;
						width: 1.1rem;
						padding: 0.3rem;
						background-color: $white;
						border-radius: convertToREM(5);

						.darkMode & {
							background-color: $dm-b2;
						}

						path {
							fill: $grey;

							.darkMode & {
								fill: $white;
							}
						}
					}

					.generate-icon {
						width: 1.4rem;
					}

					.darkMode & {
						color: $white;
					}
				}
			}
		}

		.inputWrap {
			display: flex;
			position: relative;
			gap: 0 0.6rem;
			border-radius: 8px;

			&.sameLine {
				display: block;

				&.withMargin {
					margin: 0 0 -1rem 0.5rem;
				}
			}

			&.focusedBorder {
				border-radius: 8px;
				border: 1.5px solid $newPrimaryBg;

				.darkMode & {
					border: 1.5px solid $newBlue;
				}
			}

			&.focusedError {
				border-radius: 8px;
				border: 1.5px solid $red;
			}

			&.textarea {
				background-color: $greyShades-snow;

				.darkMode & {
					background-color: $dm-b3;
					border: 1.5px solid $dm-b4;
				}

				::placeholder {
					color: $grey;
				}

				.sshKey-file {
					position: absolute;
					top: 0.7rem;
					right: 0.8rem;
					padding: 0.1rem 0.1rem 0;
					background-color: $white;
					cursor: pointer;
					border-radius: convertToREM(5);

					.container-file-input {
						label {
							cursor: pointer;
						}

						input {
							display: none;
						}
					}

					.darkMode & {
						background-color: $dm-b2;

						svg {
							path {
								fill: $ciment;
							}
						}
					}

					.close {
						width: 1rem;

						g {
							path {
								fill: $newGrey;

								.darkMode & {
									fill: $ciment;
								}
							}
						}
					}
				}
			}

			&.errorTop {
				align-items: flex-end;

				.containerMailErrorMessage {
					justify-content: flex-end;
					width: 100%;
				}
			}

			&.telAutocompletePositionOver {
				.react-tel-input .flag-dropdown .country-list {
					bottom: 100%;
				}
			}

			.inputWrapContainer {
				width: 100%;

				.inputAndActionContainer {
					.inputContainer {
						display: flex;
						align-items: center;
						box-sizing: border-box;
						font-family: $mon;
						flex: 2;
						padding: clamp(0.6rem, 1.8vh, 0.9rem)
							clamp(0.7rem, 2vw, 0.9rem);
						color: $black;
						font-size: 15px;
						font-weight: 500;
						width: 100%;
						border-radius: 8px;
						border: 1.5px solid $ciment;
						background-color: $greyShades-snow;

						.darkMode & {
							background-color: $dm-b3;
							border: 1.5px solid $dm-b4;
						}

						&.focusedBorder {
							border: 1.5px solid $newPrimaryBg;

							.darkMode & {
								border: 1.5px solid $newBlue;
							}
						}

						&.focusedError {
							border-radius: 8px;
							border: 1.5px solid $red;
						}

						.onContainerPosition {
							position: absolute;
							top: 0;

							.charIndic {
								font-size: 11px;
								font-weight: 500;
								width: max-content;
								font-size: convertToREM(9);
								margin-left: -0.9rem;
							}
						}

						input {
							flex: 1;
							border: none;
							padding: 0;
						}

						.inputActions {
							flex: 0;
							position: initial;
						}

						&.customInput {
							padding: clamp(0.6rem, 1.8vh, 0.4rem)
								clamp(0.7rem, 2vw, 0.9rem);
						}
					}

					.inputActionsContainer {
						align-items: center;
						justify-content: center;
						width: 2rem;
						border-radius: 0.1rem;
						background-color: $white;

						svg {
							path {
								fill: $newGrey;
							}
						}

						.inputWrapActions {
							padding: 0;
							height: fit-content;

							svg {
								display: flex;
								width: 1.3rem;
								padding: 0.2rem;
							}
						}
					}
				}
			}

			.inputContainer {
				display: flex;
				align-items: center;
				position: relative;
				flex: 1;
				border-radius: 8px;
				background-color: $greyShades-snow;

				&.hasBackgroundColorOnlyOnField {
					background-color: transparent;
				}

				&.container-file {
					box-sizing: border-box;
					font-family: "Montserrat", sans-serif;
					flex: 2 1;
					padding: clamp(0.6rem, 1.8vh, 0.9rem)
						clamp(0.7rem, 2vw, 0.9rem);
					color: #181a3a;
					font-size: 15px;
					font-weight: 500;
					border: 0.5px solid #d7d9ea;
					border-radius: 5px;
					width: 100%;
					background-color: #f6f7fe;
					white-space: nowrap;

					.content-text-file {
						@include ellipsis(1);
					}

					.darkMode & {
						background-color: $dm-b3;
					}

					svg {
						width: 2rem;
						margin-right: 0.5rem;

						.darkMode & {
							path {
								fill: $ciment;
							}
						}
					}
				}

				&.inputMoreHeight {
					height: 5rem;
				}

				&.focusedBorder {
					border: 1.5px solid $newPrimaryBg;

					.darkMode & {
						border: 1.5px solid $newBlue;
					}
				}

				&.focusedError {
					input {
						border: 1.5px solid $red;
					}
				}
			}

			.noMoreSmsQuota {
				background-color: $lGrey;
				border-radius: 3px;
				width: 100%;
				padding: 0.5rem 1rem;
				font-size: clamp(0.7rem, 0.9vw, 0.9rem);

				.darkMode & {
					background-color: $dm-b2;
					color: $ciment;
				}
			}

			.inputActions {
				position: absolute;
				top: 0;
				right: 0.6rem;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-end;

				.backgroud-icon {
					background-color: $white;
					padding: 0.3rem 0.3rem 0 0.3rem;
					margin: -0.1rem 0.4rem 0;
					border-radius: 5px;

					.close {
						width: 1rem;
						cursor: pointer !important;

						g {
							path {
								fill: $newGrey;

								.darkMode & {
									fill: $ciment;
								}
							}
						}
					}

					.darkMode & {
						background-color: $black;
					}
				}

				.action {
					display: flex;
					cursor: pointer;
					align-items: center;
					justify-content: center;
					width: 2rem;
					height: 1.9rem;
					border-radius: 0.1rem;
					background-color: $white;
					margin-right: 0.5rem;
					border-radius: convertToREM(5);

					.darkMode & {
						background-color: $dm-b2;
					}

					svg {
						path,
						rect {
							fill: $newGrey;

							.darkMode & {
								fill: $matSilver;
							}
						}
					}
				}
			}

			.react-tel-input {
				display: contents;

				.form-control {
					margin-left: 3rem;
				}

				.flag-dropdown {
					background: none;
					border: none;

					.selected-flag {
						background: none;
					}

					.country-list {
						max-height: 8rem;
						width: 15rem;
						box-shadow: 1px 2px 0.4rem rgba(0, 0, 0, 0.13);
						left: 0.6rem;
					}
				}

				input {
					width: calc(100% - 3rem);
				}
			}

			.passFont {
				font-family: $rob;
				font-weight: 500;
			}

			input {
				box-sizing: border-box;
				font-family: $mon;
				flex: 2;
				padding: clamp(0.6rem, 1.8vh, 0.9rem) clamp(0.7rem, 2vw, 0.9rem);
				color: $black;
				font-size: 15px;
				font-weight: 500;
				border: 0.5px solid $greyShades-matSilver;
				border-radius: 5px;
				width: 100%;
				background-color: $greyShades-snow;

				&.inputMoreHeight {
					height: 5rem;
					padding-bottom: 3rem;
				}

				&:not([type="password"]) {
					text-overflow: ellipsis;
				}

				&[type="totp"] {
					padding-right: 3.3125rem;
				}

				&.passProtectedKey:not([type="text"]) {
					font-size: 1.5rem;
					margin-top: -0.4rem;
				}

				@include mediaLarge {
					font-size: clamp(0.7rem, 1vw, 1rem);
				}

				&.passFont {
					padding-right: 2.5rem;
				}

				&::placeholder {
					font-family: $mon;
					color: $grey;
					font-size: 15px;

					@include mediaLarge {
						font-size: clamp(0.7rem, 1vw, 1rem);
					}

					.darkMode & {
						color: $grey;
					}
				}

				&:focus {
					outline: none;
				}

				.darkMode & {
					color: $ciment;
					border: 1.5px solid $dm-b4;
					background-color: $dm-b3;
				}
			}

			.moz-cover-clear {
				display: none;
			}

			input::-webkit-calendar-picker-indicator {
				margin-left: 0;

				.darkMode & {
					filter: invert(1);
				}
			}

			input::-webkit-datetime-edit-ampm-field {
				display: none;
			}

			input[type="time"]::-webkit-clear-button {
				-webkit-appearance: none;
				-moz-appearance: none;
				-o-appearance: none;
				-ms-appearance: none;
				appearance: none;
				margin: -10px;
			}

			textarea {
				flex: 2;
				font-family: $mon;
				background: none;
				padding: 14px 15px;
				color: $black;
				font-size: 15px;
				font-weight: 500;
				border: 1.5px solid $ciment;
				border-radius: 5px;

				@include mediaLarge {
					padding: 1.7vh 1vw;
					font-size: clamp(0.7rem, 1vw, 1rem);
				}

				.darkMode & {
					color: $ciment;
					border: 1.5px solid $anthra;
				}

				&::placeholder {
					font-family: $mon;
					color: $black;
					font-size: 15px;

					@include mediaLarge {
						font-size: clamp(0.7rem, 1vw, 1rem);
					}

					.darkMode & {
						color: $grey;
					}
				}

				&:focus {
					outline: none;
				}

				&::-webkit-resizer {
					cursor: resize;
					display: none;
				}
			}

			.autoCompleteFrame {
				position: absolute;
				left: 7px;
				bottom: 0;

				@include mediaLarge {
					left: 0.5vw;
				}

				.autoComplete {
					position: absolute;
					top: 0;
					display: flex;
					flex-direction: column;
					background-color: $white;
					box-shadow: 0px 5px 10px $sh3;
					z-index: 20;

					.darkMode & {
						background-color: $dm-b3;
						box-shadow: 0px 5px 10px $dm-sh0;
					}

					.autoComplete-child {
						padding: 13px 17px;
						cursor: pointer;
						transition: $t1;
						border-left: 2px solid $white;
						font-size: 13px;
						border-bottom: 1px solid $lGrey;
						color: $grey;
						display: flex;
						align-items: center;
						white-space: nowrap;

						@include mediaLarge {
							padding: 1.5vh 1vw;
							font-size: clamp(0.6rem, 0.8vw, 0.9rem);
						}

						.darkMode & {
							border-left: 2px solid $dm-b5;
							border-bottom: 1px solid $dm-b4;
							color: $grey;
						}

						svg {
							max-height: 15px;
							margin-right: 7px;
							max-width: 15px;

							@include mediaLarge {
								max-height: 1.2vw;
								margin-right: 1vw;
								max-width: 1.2vw;
							}
						}

						b {
							color: $black;
							white-space: nowrap;

							.darkMode & {
								color: $ciment;
								transition: $t1;
							}

							&.m {
								margin-left: 0.8rem;
							}
						}

						&.focused {
							border-left: 2px solid $blue;

							.darkMode & {
								border-left: 2px solid $dm-lBlue;
							}
						}

						&:hover {
							border-left: 2px solid $ciment;

							.darkMode & {
								border-left: 2px solid $dm-lBlue;
							}

							b {
								color: $bg-blue;

								.darkMode & {
									color: $dm-lBlue;
								}
							}
						}

						&.warning {
							display: flex;
							align-items: center;
							background-color: $lGrey;
							border-left: 2px solid $lGrey;

							.darkMode & {
								background-color: $dm-b2;
								border-left: 2px solid $dm-b2;
								border-bottom: none;
							}

							svg {
								path#contourExclam {
									.darkMode & {
										stroke: $ciment;
									}
								}

								path#exclam {
									.darkMode & {
										fill: $ciment;
									}
								}

								circle#rond {
									.darkMode & {
										fill: $ciment;
									}
								}
							}

							&:hover {
								border-left: 2px solid $lGrey;

								.darkMode & {
									border-left: 2px solid $dm-b2;
								}

								b {
									color: $black;

									.darkMode & {
										color: $ciment;
									}
								}
							}

							b {
								margin-left: 5px;
								display: block;
								max-width: 400px;

								@include mediaLarge {
									margin-left: 0.5vw;
									max-width: 20vw;
								}
							}
						}
					}
				}
			}

			> .action {
				position: absolute;
				right: 13px;
				font-size: 15px;
				transition: $t1;
				cursor: pointer;
				z-index: 2;

				&.loading {
					color: $anthra;
					cursor: default;

					.darkMode & {
						color: $grey;
					}
				}

				@include mediaLarge {
					font-size: clamp(0.7rem, 1vw, 1rem);
				}

				.darkMode & {
					color: $ciment;
				}
			}
		}

		&.pass {
			.inputWrap {
				input {
					padding-right: 3rem;

					@include mediaLarge {
						padding-right: 3vw;
					}
				}

				.action {
					right: 93px;

					@include mediaLarge {
						right: 6vw;
					}

					&.export {
						right: 2.5rem;

						&.copy {
							margin-left: 0.6rem;
							cursor: pointer;
						}
					}
				}

				svg {
					path {
						.darkMode & {
							fill: $ciment;
						}
					}

					rect {
						.darkMode & {
							fill: $ciment;
						}
					}
				}
			}
		}

		.loadMsg {
			display: flex;
			align-items: center;
			font-size: 0.7rem;
			color: $blue;
			font-weight: 500;
			margin-left: 0.1rem;
			margin-top: 0.2rem;

			.darkMode & {
				color: $ciment;
			}

			span {
				margin-left: 0.3rem;
				margin-bottom: 0.15rem;
			}
		}

		.strength {
			display: flex;
			flex-direction: row;
			align-items: center;

			span {
				width: 1.8rem;
				height: 0.3rem;
				border-radius: 5px;
				background-color: $matSilver;
				margin-right: 0.3rem;

				.darkMode & {
					background-color: $dm-b4;
				}
			}

			p {
				font-size: 0.8rem;
				color: $newGrey;
				margin: 0;

				.darkMode & {
					color: $matSilver;
				}
			}

			.strenghtPDM {
				color: $black !important;

				.darkMode & {
					color: $ciment !important;
				}
			}
		}

		.charIndic {
			color: $anthra;
			font-size: convertToREM(9);
			padding-top: 4px;
			opacity: 1;
			height: 100%;

			@include mediaLarge {
				font-size: clamp(0.6rem, 0.8vw, 0.9rem);
				padding-top: 0.6vh;
			}

			&.red {
				color: $dm-red1;
			}
		}

		.charIndicPassword {
			@extend .charIndic;
			position: absolute;
			top: 0rem;
			color: $grey;
			font-size: convertToREM(9);
			padding: 0.25rem 0 0 1rem;
		}

		.charIndicFocus {
			@extend .charIndic;
			display: flex;
			margin-right: 4.7rem;
			opacity: 0;
			height: 0;
		}

		&.focused {
			margin-top: 20px;

			.inputTitle,
			span.action {
				opacity: 1;
			}

			input,
			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			textarea {
				border: 1.5px solid $black;

				.darkMode & {
					border: 1.5px solid $grey;
				}
			}

			.charIndic {
				@extend .charIndic;
				position: absolute;
				color: $grey;
				font-size: convertToREM(9);
				padding: 0.25rem 0 0 1rem;

				&.area {
					font-size: convertToREM(9);
					top: 1.6rem;
					margin-left: 0.2rem;
				}

				@include mediaLarge {
					visibility: unset;
				}
			}
		}
	}

	.ssoTemporaryPassword {
		.genBtn {
			width: 3vw;
		}

		.showGenMenu {
			svg {
				height: 0.8rem;
			}
		}

		.genOptionsList {
			justify-content: center;
			flex-wrap: wrap;
			gap: 0.3rem;
		}

		.numInput {
			display: flex;
			justify-content: center;
		}

		&.pass {
			.actionWrapper {
				right: 4vw;
			}

			.inputWrap {
				input {
					padding-right: clamp(5rem, 1.8vh, 0.9rem);

					.darkMode & {
						background-color: $dm-b3;
					}
				}
			}
		}
	}

	@content;
}

@mixin InputFrameGenMenu {
	.genMenu {
		margin-top: 10px;
		border-radius: 5px;

		.title-container {
			white-space: nowrap;
			display: flex;
			align-items: center;
			flex: 1;

			.title {
				margin-right: 0.4rem;
			}

			svg {
				padding: 0 0.5rem 0.2rem 0;

				path {
					fill: $greyShades-wetGrey;

					.darkMode & {
						fill: $greyShades-matSilver;
					}
				}
			}

			h3 {
				font-size: 0.75rem;
				color: $greyShades-wetGrey;
			}

			.desc-ppName {
				white-space: nowrap;

				.ppName {
					@include ellipsis(1);
					word-break: break-all;
				}
			}
		}

		@include NumInput() {
			.numInput {
				position: absolute;
				right: 0;
				display: flex;
				align-items: center;
				padding: 0 0.2rem;
				background-color: $greyShades-snow;
			}
		}

		&.hasBorders {
			border: 1.5px solid $lGrey;
			padding: 13px 13px;

			.darkMode & {
				border: 1.5px solid $dm-b4;
			}
		}

		@include mediaLarge {
			margin-top: 1vh;

			&.hasBorders {
				border: 1.5px solid $lGrey;
				padding: 1.5vh 1vw;
			}
		}

		&.inAddFolder {
			padding: 0;
			margin: 0 auto convertToREM(20) 0;
			border: none;
			width: calc(100% - convertToREM(80));

			@include mediaLarge {
				width: calc(100% - 5vw);
			}

			> h3 {
				margin-bottom: convertToREM(8);
			}

			.genOptionsList {
				align-items: center;
				column-gap: 0.3rem;

				.genOptionList-item {
					padding: convertToREM(3) convertToREM(8);
					font-size: convertToREM(11);
					margin: 0;
					font-weight: 500;
					text-align: center;
				}

				.numInput {
					margin-left: convertToREM(10);

					.counterValue {
						font-size: 13px;
						width: 2.3rem;
						height: 1rem;
					}

					.counterBtn {
						max-height: 1.2rem;
						max-width: 1.2rem;
						min-height: 1.2rem;
						min-width: 1.2rem;

						svg {
							height: 0.6rem;
							width: 0.6rem;
						}
					}
				}
			}
		}

		h3 {
			font-size: convertToREM(12);
			margin-top: 0;
			display: block;
			color: $greyShades-wetGrey;
			font-weight: 500;

			@include mediaLarge {
				font-size: clamp(0.6rem, 0.9vw, 0.9rem);
			}

			.darkMode & {
				color: $greyShades-matSilver;
			}
		}

		.genOptionsList {
			display: flex;
			align-items: center;
			gap: 0.3rem;

			.genOptionList-item {
				background-color: $bg-lGrey;
				border-radius: 3px;
				padding: 5px 8px;
				color: $anthra;
				font-size: 13px;
				cursor: pointer;
				transition: $t01;
				text-wrap: nowrap;

				@include mediaLarge {
					padding: 0.8vh 0.5vw;
					font-size: clamp(0.7rem, 1vw, 1rem);
				}

				.darkMode & {
					background-color: $dm-b4;
					color: $anthra;
				}

				&.active {
					background-color: $brand-black;
					color: $white;

					.darkMode & {
						border-color: $dm-lBlue;
						color: $ciment;
					}
				}
			}
		}

		&.pp {
			display: flex;
			flex-direction: column;
			border: none;
			align-items: flex-start;
			padding: 0;
			margin-bottom: 8px;

			&.noMTop {
				margin-top: 0;

				.genOptionsList {
					margin-top: 0;
				}
			}

			.genOptionsList {
				flex-direction: column;
				align-items: baseline;
				margin-top: 10px;

				@include mediaLarge {
					margin-top: 1vh;
				}

				.genOptionList-element {
					display: flex;
					align-items: center;
					gap: 15px;

					.numInput {
						flex: 0;
						margin-right: 15px;

						@include mediaLarge {
							margin-right: 1vw;
						}
					}

					b {
						font-size: 14px;
						font-weight: 500;
						color: $black;

						@include mediaLarge {
							font-size: clamp(0.7rem, 1vw, 1rem);
						}

						.darkMode & {
							color: $ciment;
						}
					}
				}

				.genOptionList-item {
					@include mediaLarge {
						padding: 0.7vh 0.5vw;
						font-size: 13px;
					}

					.darkMode & {
						background-color: $dm-b4;
						color: $anthra;
						border: 1.5px solid $dm-b5;
					}

					&.active {
						background-color: $brand-black;
						color: $white;

						.darkMode & {
							border-color: $dm-lBlue;
							color: $ciment;
						}
					}

					&:not(.active) + b {
						color: $grey;
					}
				}

				.strength-text {
					margin-top: 0.7rem;

					.strenghtPDM {
						margin-left: 0.5rem;
						font-size: 14px;
						font-weight: 500 !important;
						color: $black !important;

						@include mediaLarge {
							font-size: clamp(0.7rem, 1vw, 1rem);
						}

						.darkMode & {
							color: $ciment !important;
						}
					}
				}
			}
		}
	}
}

@mixin InputFrameError {
	.inputFrame {
		&.error {
			.inputWrap {
				input {
					border: 2px solid red !important;
				}

				.errorMsg {
					font-size: 0.7rem;
					color: $red;
					font-weight: 500;
				}
			}

			.containerMailErrorMessage {
				display: flex;
				align-items: flex-start;

				.errorMsg {
					font-size: 0.75rem;
					color: $red;
					font-weight: 500;
				}
			}
		}

		.msg {
			font-size: 0.7rem;
			color: $orange;
			font-weight: 500;
			margin-left: 0.1rem;
			margin-top: 0.2rem;
		}
	}
	@content;
}

@mixin InputForceShowTitle {
	.inputFrame.forceShowTitle {
		&.focused {
			margin-top: unset;
		}

		.inputTitle {
			opacity: 1;
		}
	}
}

@mixin InputGeneratePass {
	.inputWrap {
		.genBtn {
			// min-width: 80px;
			font-size: 15px;
			text-align: center;
			cursor: pointer;
			transition: $t1;

			@include mediaLarge {
				min-width: 2vw;
				font-size: clamp(0.7rem, 1vw, 1rem);
			}

			.darkMode & {
				color: $ciment;
			}

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.showGenMenu {
		font-size: 13px;
		color: $anthra;
		margin-top: 8px;
		cursor: pointer;
		display: flex;
		align-items: center;

		@include mediaLarge {
			font-size: clamp(0.6rem, 0.8vw, 0.9rem);
			margin-top: 1vh;
		}

		.darkMode & {
			color: $anthra;
		}

		svg {
			margin-left: 5px;
			height: 13px;

			@include mediaLarge {
				margin-left: 0.4vw;
				height: 1vw;
			}

			path {
				fill: $anthra;

				.darkMode & {
					fill: $ciment;
				}
			}
		}
	}
}

@mixin InputSearchDefault {
	.searchContainer {
		width: 100%;
		height: 2.1rem;
		margin: 0.5rem 0;
		background-color: $bg-lGrey;
		display: flex;
		align-items: center;
		gap: 0.7rem;
		padding: 0.6rem 0.8rem;
		box-sizing: border-box;

		.inputFrame {
			height: 100%;
			margin-top: 0 !important;
			margin-bottom: 0;

			.inputWrap {
				height: 100%;

				input {
					flex: 1;
					border: none;
					background: none;
					font-size: 0.8rem;
					font-weight: 500;
					color: $black;
					display: flex;
					justify-content: center;
					align-items: center;

					&:focus {
						outline: none;
					}

					&::placeholder {
						font-size: 0.8rem;
					}
				}

				svg {
					padding: 0.15rem;
					border-radius: 50%;
					background-color: $orange;

					* {
						fill: $white;
						stroke: $white;
					}
				}
			}
		}

		svg {
			border: none;
			max-height: 80%;
			width: auto;

			*:not(rect) {
				stroke: $anthra;
			}
		}
	}
}
