@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;
@use "../../components/" as *;

@mixin LoaderPop {
	.loader-wrapper {
		@include mediaLarge {
			display: none;
		}
	}
}
