@use "./variables" as *;

@mixin DebugTool($level: 1, $name: ".", $position: true) {
	// press ATL + CTRL + D to enter the debug mode
	$maxLevel: 6;
	$adjustColor: calc($level / $maxLevel * 360);

	$minSize: 1rem;
	$adjustSize: ($maxLevel - $level) * 0.4rem;

	.debugTools & {
		@if ($position) {
			position: relative;
		}

		&:after {
			content: $name;
			position: absolute;
			z-index: -1;
			font-size: $adjustSize;
			inset: 0;
			background: hwb($adjustColor 0% 0% / 0.1);
			color: hwb($adjustColor 0% 0% / 0);
			border: 5px solid hwb($adjustColor 0% 0% / 0.4);
			z-index: 99999 + $level;
			transition: 0.2s ease-in-out;
			pointer-events: none;

			@if ($level == 1) {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
			}
		}

		&:empty:after {
			content: none;
		}

		&:hover:after {
			transition: 0.2s ease-in-out;
			color: hwb($adjustColor 0% 0% / 1);
		}
	}
}

@mixin ellipsis($lines: false, $addWrap: false) {
	@if $lines {
		display: -webkit-box;
		-webkit-line-clamp: $lines;
		-webkit-box-orient: vertical;
		word-break: break-word;
		overflow: hidden;
		white-space: normal;
	} @else {
		overflow: hidden;
		text-overflow: ellipsis;

		@if $addWrap {
			white-space: nowrap;
		}
	}
}

@mixin flexPosition($justify: center, $align: center) {
	display: flex;

	@if $justify {
		justify-content: $justify;
	}
	@if $align {
		align-items: $align;
	}
}

@mixin backGroundIcon($radius: false) {
	width: 1.2rem;
	height: 1.2rem;
	padding: 0.3rem;
	background-color: $white;
	border-radius: 0.313rem;

	.darkMode & {
		background-color: $dm-b2;
	}

	@if $radius {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
		padding: 0.2rem;
	}

	@content;
}

@mixin containerInfoSlide(
	$customHeight: auto,
	$fistPosIcon: false,
	$secondPosIcon: false,
	$customGrid: null,
	$repeatValue: null,
	$inExtension: false
) {
	display: grid;
	grid-template-columns: $customGrid 1fr 8% 10%;
	grid-column-gap: 0.2rem;
	align-items: center;
	width: auto;
	height: $customHeight;
	background-color: $greyShades-snow;
	border-radius: 0.4rem;

	@include mediaLarge {
		grid-template-columns: $customGrid 1fr repeat($repeatValue, 10%);
	}

	@if $fistPosIcon {
		.iconFirstPos {
			grid-area: 1 / 2 / 2 / 3;
		}
	}

	@if $secondPosIcon {
		.iconSecondPos {
			grid-area: 1 / 3 / 2 / 4;

			&.last {
				grid-area: 1 / 4 / 2 / 5;
			}
		}
	}

	.iconLastPos {
		grid-area: 1 / 4 / 2 / 5;
	}

	.iconFirstPos,
	.iconSecondPos,
	.iconThirdPos,
	.iconLastPos {
		width: 1rem !important;
		cursor: pointer;
		@include backGroundIcon();

		path {
			fill: $newGrey;

			.darkMode & {
				fill: $greyShades-matSilver;
			}
		}

		&.copyClip & :not(.locktransfer) {
			path {
				fill: #dbdbe2;
			}
		}
	}

	@if $inExtension == true {
		.iconThirdPos,
		.iconLastPos {
			height: 1.4rem;
			width: 1.6rem !important;
		}
	}
}

/* --------------------------------- RESETS --------------------------------- */

@mixin resetBtn($cleanText: false) {
	appearance: none;
	background-color: transparent;
	border: none;
	cursor: pointer;
	margin: 0;
	padding: 0;
	font-family: $mon;
	font-weight: 600;

	@if $cleanText {
		text-indent: -9999px;
		overflow: hidden;
	}
}

@mixin resetList {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

/* ------------------------------ MEDIA QUERIES ----------------------------- */

@mixin mediaLarge {
	@media screen and (max-width: 1600px) {
		@content;
	}
}

@mixin mediaMedium {
	@media screen and (max-width: 1024px) {
		@content;
	}
}

@mixin mediaMaxCustom($breakpoint: 1600px) {
	@media screen and (max-width: $breakpoint) {
		@content;
	}
}

@mixin mediaMinCustom($breakpoint: 1024px) {
	@media screen and (min-width: #{$breakpoint + 1}) {
		@content;
	}
}

@mixin mediaCustom($minBreakpoint: 1024px, $maxBreakpoint: 1600px) {
	@media screen and (min-width: #{$minBreakpoint + 1}) and (max-width: $maxBreakpoint) {
		@content;
	}
}

@mixin mediaMaxHeightCustom($breakpoint) {
	@media screen and (max-height: $breakpoint) {
		@content;
	}
}

@mixin mediaMinHeightCustom($breakpoint) {
	@media screen and (min-height: $breakpoint) {
		@content;
	}
}
