@use "../../utils/variables" as *;
@use "../../utils/mixins" as *;
@use "../../components" as *;
@use "./thinPopIPassPh" as *;
@use "./thinPopLeftList" as *;
@use "./ssoFrame" as *;
@use "./changeWindowDimensions" as *;
@use "../../components/input/_mixin" as *;

@mixin ThinBodyPop($popup) {
	@include tooltipHelper;

	.helperLink {
		position: absolute;
		background-color: $orange;
		right: -2.5rem;
		top: 6.9rem;
		padding: 0.5rem;
		border-top-right-radius: 0.5rem;
		border-bottom-right-radius: 0.5rem;
		cursor: pointer;
		display: flex;
		align-items: center;

		&.blue {
			background-color: $accent-blueMetallic;
		}

		&.headerLeft {
			top: 1rem;
		}

		> svg {
			height: 1.5rem;
			width: 1.5rem;
		}
	}

	#thinPopBody.move-pass-body {
		padding: 1rem 1.3rem;

		p {
			max-width: 150px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	#thinPopBody {
		display: flex;
		flex-direction: column;
		padding: 2.8rem 1.3rem 1rem 1.3rem;

		.withPadding {
			position: relative;
			flex: 1;
			overflow-y: scroll;
			-ms-overflow-style: none;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				display: none;
			}

			.categoryContainer {
				h4 {
					margin: 0 0 0.4rem 0;
				}

				.fileLine.clickable {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					padding: 0.8rem;

					svg {
						margin-right: 0.8rem;
					}
				}
			}

			.inputFrame {
				.inputTitle {
					.inputHeading {
						font-weight: 600;
						max-width: 55%;
						user-select: none;
					}
				}

				&.passwordInput {
					.inputWrap {
						.genBtn {
							cursor: pointer;
							padding-left: 0.8rem;
						}
					}
				}

				.showGenMenu {
					cursor: pointer;

					svg {
						height: 0.6rem;
						width: 0.6rem;
						margin-left: 0.4rem;

						* {
							fill: $black;

							.darkMode & {
								fill: $white;
							}
						}
					}
				}

				.genMenu {
					margin: 0;

					.title {
						margin-right: 0.4rem;
					}

					h3 {
						margin: 10px 0 0.8rem 0;
						font-size: 0.75rem;
					}

					.genOptionsList {
						display: flex;
						flex-wrap: wrap;
						align-items: center;

						&.customGap {
							gap: 0.15rem;
						}

						.genOptionList-item {
							background-color: $greyShades-snow;
							border-radius: 6.25rem;
							color: $black;
							font-size: 0.68rem;
							cursor: pointer;
							transition: $t01;
							font-weight: 500;
							padding: 0.25rem 0.5rem;

							.darkMode & {
								background-color: $black;
								color: $white;
							}

							&.noPP {
								.darkMode & {
									background-color: $white;
									color: $black;
								}
							}

							&.active {
								background-color: $newPrimaryBg;
								color: $white;

								.darkMode & {
									border-color: $dm-lBlue;
									color: $ciment;
								}
							}

							&.activeWithPP {
								background-color: $dm-b3;
								color: $white;
							}
						}
					}

					.numInput {
						.counterBtn {
							max-height: 1.2rem;
							max-width: 1.2rem;
							min-height: 1.2rem;
							min-width: 1.2rem;

							&.onPopUpPass {
								max-height: 2rem;
								max-width: 2rem;

								svg {
									height: 0.8rem;
									width: 0.8rem;
								}
							}

							svg {
								height: 0.6rem;
								width: 0.6rem;
							}
						}

						.counterValue {
							-webkit-appearance: none;
							-moz-appearance: textfield;
							font-size: 13px;
							width: 2.3rem;
							height: 1rem;

							&::-webkit-inner-spin-button,
							&::-webkit-outer-spin-button {
								-webkit-appearance: none;
								appearance: none;
							}

							&::-moz-number-spin-up,
							&::-moz-number-spin-down {
								-webkit-appearance: none;
								appearance: none;
							}

							&.onPopUpPass {
								background-color: $greyShades-snow;

								.darkMode & {
									background-color: $dm-b3;
								}
							}
						}
					}
				}

				@include MultiListDefault;

				&.urlInput,
				&.tagsInput {
					.inputWrap {
						.action {
							position: relative;
							right: 0;
							padding-left: 0.8rem;
						}
					}
				}
			}

			.forceShowtextRight {
				color: $newPrimaryBg;
				font-family: Telegraf;
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				cursor: pointer;

				.darkMode & {
					color: $newBlue;
				}
			}

			.forceShowTitle {
				.inputTitle {
					opacity: 1;
				}
			}

			.seeMoreOptions {
				position: sticky;
				bottom: 0;
				z-index: 10;
				padding: 0.6rem 0;
				background-color: $white;
				cursor: pointer;
				display: flex;
				align-items: center;

				.darkMode & {
					background-color: $dm-b3;
				}

				svg {
					margin-left: 0.6rem;
					fill: $black;
					stroke: $black;

					.darkMode & {
						fill: $ciment;
						stroke: $ciment;
					}
				}
			}
		}

		.buttons {
			padding: 0.8rem 0;
			width: 100%;

			&.licenseButton {
				.icon {
					width: 1rem;
					height: 1rem;
					top: 30%;
				}
			}
			button {
				position: relative;

				// svg {
				// 	position: absolute;
				// 	top: calc(50% - .45rem);
				// 	left: .8rem;
				// 	height: .9rem;
				// 	width: .9rem;

				// 	* {
				// 		fill: $white;
				// 	}
				// }

				// &.grey {
				// 	svg {
				// 		* {
				// 			fill: $black;
				// 		}
				// 	}
				// }
			}

			button.clear {
				background-color: $greyShades-snow;

				.darkMode & {
					background-color: $dm-b3;

					svg {
						* {
							fill: $lGrey;
						}
					}
				}

				svg {
					rotate: 180deg;

					* {
						fill: $black;
						stroke-width: 0.4rem;
					}
				}
			}
		}
	}
}
