@use "./variables" as *;
@use "./mixins" as *;
@use "../components" as *;
@use "../layout/webApp/popup" as *;

.App {
	font-family: $mon;

	&.hideOverflow {
		overflow: hidden;
	}

	// Scrollbar specifications for Firefox
	* {
		scrollbar-width: thin;
		scrollbar-color: rgba(215, 217, 234, 0.5) rgba(246, 247, 254, 0);
	}
}

*.unselectable {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

*.noVisible {
	visibility: hidden !important;
}

.svgNoStroke g path {
	stroke: none !important;
	fill: $orange !important;
}

.r90 {
	transform: rotate(90deg);
}

.r180 {
	transform: rotate(180deg);
}

.r270 {
	transform: rotate(270deg);
}

.clickable {
	cursor: pointer;
}

.animPagin {
	path {
		fill: $grey;
	}
}

.copyToClip {
	max-height: 15px;
	max-width: 15px;
	cursor: pointer;
	vertical-align: middle;
	margin-left: 13px;

	&:hover path {
		fill: $orange;
	}

	path {
		transition: $t1;
	}
}

.spacer {
	padding: 25px;

	@include mediaLarge {
		padding: 1.5vw;
	}
}

@include timer;
@include toastr;
@include loaders;

/* ----------------------------- POPINS / POPUPS ---------------------------- */

@include miniPopFrame;
@include popinFrame;
@include thinPopinFrame;
@include ssh;
