@use "../utils/variables" as *;

@mixin ConfirmMFA {
	.ConfirmMFA {
		.p {
			color: $greyShades-grey;
			font-family: "Montserrat", Helvetica;
			font-size: 0.9rem;
			font-weight: 500;
			letter-spacing: 0;
			line-height: normal;
			position: relative;
			width: 22.02rem;
			margin: 0 0 0.66rem 0;

			.darkMode & {
				color: $greyShades-matSilver;
			}
		}

		.inputLabel {
			font-family: "Montserrat";
			color: $greyShades-wetGrey;
			font-size: 0.78rem;
			font-weight: 500;

			.darkMode & {
				color: $greyShades-matSilver;
			}
		}

		.inputWrapper {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0.9rem 0.6rem;
			box-sizing: border-box;
			border-radius: 0.3rem;
			background-color: $greyShades-snow;
			border: 1px solid $matSilver;

			.darkMode & {
				background-color: $dm-b4;

				&:not(.invalid) {
					border: $dm-b2;
				}
			}

			.mfaInput {
				flex: 1;
				border-top-left-radius: 0.3rem;
				border-bottom-left-radius: 0.3rem;
				font-family: "Montserrat";
				color: $brand-black;
				font-size: 0.9rem;
				font-weight: 500;
				background: none;
				border: none;

				.darkMode & {
					color: $greyShades-white;
				}

				&::placeholder {
					font-family: "Montserrat";
					color: $greyShades-grey;
					font-size: 0.9rem;
					font-weight: 400;

					.darkMode & {
						color: $greyShades-matSilver;
					}
				}

				&:focus {
					outline: none;
				}
			}

			&.invalid {
				border-color: $red;
			}

			.resetButton {
				width: 1.6rem;
				height: 1.6rem;
				background: none;
				border: none;
				cursor: pointer;

				.icon {
					display: block;
					height: 100%;
					width: 100%;

					* {
						fill: $brand-black;
						stroke: $brand-black;

						.darkMode & {
							stroke: $greyShades-white;
							fill: $greyShades-white;
						}
					}
				}
			}
		}

		.invalidCode {
			color: $red;
			font-size: 0.66rem;
			font-weight: 500;
		}

		.buttonsContainer {
			width: 22rem;
			display: grid;
			grid-template-areas: "a b b b b b b b b b";
			gap: 0.9rem;

			.button {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0.66rem 1.02rem;
				border: none;
				border-radius: 0.3rem;
				font-family: "Montserrat";
				font-size: 0.9rem;
				font-weight: 600;
				cursor: pointer;

				.icon {
					height: 0.9rem;
					width: 0.9rem;
				}
			}

			.confirmMFAPreviousButton {
				grid-area: a;
				color: $greyShades-grey;
				background-color: $greyShades-snow;
				display: flex;
				justify-content: space-between;

				.icon {
					* {
						fill: $greyShades-grey;
					}
				}

				.darkMode & {
					background-color: $dm-b3;
					color: $greyShades-snow;

					.icon {
						* {
							fill: $greyShades-snow;
						}
					}
				}
			}

			.confirmMFANextButton {
				grid-area: b;
				color: $greyShades-white;
				background-color: $accent-blueAction;

				.darkMode & {
					background-color: $purpleShades-galaxySauce;
					color: $greyShades-snow;

					.icon {
						* {
							fill: $greyShades-snow;
						}
					}
				}

				.icon {
					position: absolute;
					top: calc(50% - 0.45rem);
					right: 1.02rem;

					* {
						fill: $greyShades-white;
					}
				}
			}

			.chevron {
				height: 15px;
				position: relative;
			}
		}
	}
}
