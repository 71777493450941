@use "../../../utils/variables" as *;

@mixin robustness {
	$colorBarDef: $extBgColor2;
	$colorBar1: red;
	$colorBar2: orange;
	$colorBar3: #0cf380;

	.robust {
		font-size: 0.6rem;
		color: $extTextColor1;

		.colorBars {
			display: flex;
			gap: 0.4rem;
			max-width: 10rem;

			> div {
				margin-top: 0.4rem;
				width: 2.5rem;
				height: 0.25rem;
				border-radius: 1rem;
				background: $colorBarDef;
				position: relative;
				overflow: hidden;

				&::before,
				&::after {
					content: "";
					position: absolute;
					inset: 0;
					width: 0;
				}
			}

			&.level0 > div {
				&:nth-child(1) {
					background: $colorBar1;
				}
			}

			&.level0.prevLevel1 > div {
				&::before,
				&::after {
					width: 100%;
					transform-origin: 100%;
				}
				&:nth-child(1) {
					background: $colorBar1;
				}
				&:nth-child(2) {
					background: $colorBarDef;
				}
				&:nth-child(1)::before {
					background: $colorBar2;
					transition: width 0.2s 0.2s ease-in-out;
					width: 0%;
				}
				&:nth-child(2)::before {
					background: $colorBar2;
					transition: width 0.2s ease-in-out;
					width: 0%;
				}
			}

			&.level0.prevLevel2 > div {
				&::before,
				&::after {
					width: 100%;
					transform-origin: 100%;
				}
				&:nth-child(1) {
					background: $colorBar1;
				}
				&:nth-child(2) {
					background: $colorBarDef;
				}
				&:nth-child(3) {
					background: $colorBarDef;
				}
				&:nth-child(1)::after {
					background: $colorBar3;
					transition: width 0.2s 0.4s ease-in-out;
					width: 0%;
				}
				&:nth-child(2)::after {
					background: $colorBar3;
					transition: width 0.2s 0.2s ease-in-out;
					width: 0%;
				}
				&:nth-child(3)::after {
					background: $colorBar3;
					transition: width 0.2s ease-in-out;
					width: 0%;
				}
			}

			&.level1 > div {
				&:nth-child(1) {
					background: $colorBar1;
				}
				&:nth-child(1)::before {
					background: $colorBar2;
					transition: width 0.2s ease-in-out;
					width: 100%;
				}
				&:nth-child(2)::before {
					background: $colorBar2;
					transition: width 0.2s 0.2s ease-in-out;
					width: 100%;
				}
			}

			&.level1.prevLevel2 > div {
				&::before,
				&::after {
					width: 100%;
					transform-origin: 100%;
				}
				&:nth-child(1) {
					background: $colorBar2;
				}
				&:nth-child(2) {
					background: $colorBar2;
				}
				&:nth-child(3) {
					background: $colorBarDef;
				}
				&:nth-child(1)::after {
					background: $colorBar3;
					transition: width 0.2s 0.4s ease-in-out;
					width: 0%;
				}
				&:nth-child(2)::after {
					background: $colorBar3;
					transition: width 0.2s 0.2s ease-in-out;
					width: 0%;
				}
				&:nth-child(3)::after {
					background: $colorBar3;
					transition: width 0.2s ease-in-out;
					width: 0%;
				}
			}

			&.level2 > div {
				&:nth-child(1) {
					background: $colorBar2;
				}
				&:nth-child(2) {
					background: $colorBar2;
				}
				&:nth-child(1)::after {
					background: $colorBar3;
					transition: width 0.2s ease-in-out;
					width: 100%;
				}
				&:nth-child(2)::after {
					background: $colorBar3;
					transition: width 0.2s 0.2s ease-in-out;
					width: 100%;
				}
				&:nth-child(3)::after {
					background: $colorBar3;
					transition: width 0.2s 0.4s ease-in-out;
					width: 100%;
				}
			}

			&.level2.prevLevel0 > div {
				&:nth-child(1) {
					background: $colorBar1;
				}
				&:nth-child(2) {
					background: $colorBarDef;
				}
				&:nth-child(1)::after {
					background: $colorBar3;
					transition: width 0.2s ease-in-out;
					width: 100%;
				}
				&:nth-child(2)::after {
					background: $colorBar3;
					transition: width 0.2s 0.2s ease-in-out;
					width: 100%;
				}
				&:nth-child(3)::after {
					background: $colorBar3;
					transition: width 0.2s 0.4s ease-in-out;
					width: 100%;
				}
			}
		}

		&:not(.anim) {
			.colorBars {
				> div,
				> div::before,
				> div::after {
					transition: all 0s !important;
				}
			}
		}
	}
}
