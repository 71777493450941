@use "../utils/variables" as *;
@use "./input" as *;

@mixin SetupMFA {
	.SetupMFA {
		.contentContainer {
			border-radius: 0.3rem;
			position: relative;
		}

		.p {
			color: $greyShades-grey;
			font-family: "Montserrat", Helvetica;
			font-size: 0.9rem;
			font-weight: 500;
			letter-spacing: 0;
			line-height: normal;
			position: relative;
			width: 22.02rem;
			margin: 0 0 1.5rem 0;

			.darkMode & {
				color: $greyShades-matSilver;
			}
		}

		.QR {
			align-items: flex-start;
			align-self: stretch;
			display: flex;
			flex: 0 0 auto;
			flex-direction: column;
			gap: 0.9rem;
			position: relative;

			.img {
				width: 15rem;
				height: 15rem;
				margin: 0 auto;
			}
		}

		.img {
			flex: 0 0 auto;
			position: relative;
			width: 367px;
		}

		.text-wrapper-3 {
			margin-top: 0.9rem;
			color: $greyShades-grey;
			font-family: "Montserrat", Helvetica;
			font-size: 0.72rem;
			font-weight: 500;
			width: fit-content;
			cursor: pointer;

			.darkMode & {
				color: $greyShades-matSilver;
			}

			&.focused {
				color: $accent-blueAction;

				.darkMode & {
					color: $purpleShades-dreamAccent;
				}
			}
		}

		.codeWrapper {
			align-items: flex-start;
			align-self: stretch;
			border-radius: 0.3rem;
			display: flex;
			flex: 0 0 auto;
			flex-direction: column;
			gap: 0.9rem;
			position: relative;
			width: 100%;
			margin-top: 1.5rem;
		}

		.codeDescriptor {
			align-self: stretch;
			color: $greyShades-grey;
			font-family: "Montserrat", Helvetica;
			font-size: 0.9rem;
			font-weight: 500;
			letter-spacing: 0;
			line-height: normal;
			margin: 0;
			position: relative;

			.darkMode & {
				color: $greyShades-matSilver;
			}
		}

		.codeContainer {
			width: 100%;
			display: grid;
			align-items: center;
			grid-template-columns: 1fr 1.8rem;
			gap: 0.6rem;
			background-color: $greyShades-snow;
			padding: 0.6rem 0.75rem;
			box-sizing: border-box;

			.darkMode & {
				background-color: $dm-b4;
			}

			.code {
				display: grid;
				grid-template-columns: repeat(5, 1fr);
				gap: 0.3rem;

				.codeFragment {
					color: $brand-black;
					font-size: 0.78rem;
					font-weight: 600;
					line-height: 1.1rem;
					letter-spacing: 0.16rem;
					margin: 0;

					.darkMode & {
						color: $greyShades-white;
					}

					.number {
						color: $newBlue;
						font-style: normal;
					}
				}
			}

			.copyButton {
				width: 1.8rem;
				height: 1.8rem;
				padding: 0.3rem;
				box-sizing: border-box;
				background-color: $greyShades-white;
				border: none;
				border-radius: 0.18rem;
				cursor: pointer;

				.darkMode & {
					background-color: $dm-b3;
				}

				.icon {
					* {
						fill: $greyShades-wetGrey;
						stroke: $greyShades-wetGrey;

						.darkMode & {
							fill: $greyShades-white;
							stroke: $greyShades-white;
						}
					}
				}
			}
		}

		.left {
			height: 0.96rem;
			position: relative;
			width: 6.66rem;
		}

		.setupMFANextButton {
			.icon {
				right: 1.02rem;
				top: calc(50% - 0.63rem);
				height: 1.26rem;
				width: 1.26rem;
			}
		}
	}
}
