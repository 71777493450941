@use "../../utils/mixins" as *;
@use "../../utils/variables" as *;

@mixin NewSwitch {
	.newSwitch {
		display: flex;
		align-items: center;

		.small {
			--switch-width: 1.7rem;
			--switch-height: 1rem;
			--circle-diameter: 0.75rem;
		}

		.medium {
			--switch-width: 2rem;
			--switch-height: 1.1rem;
			--circle-diameter: 0.9rem;
		}

		.large {
			--switch-width: 2.75rem;
			--switch-height: 1.5rem;
			--circle-diameter: 1.25rem !important;
		}

		.switch {
			/* switch */

			--switch-bg: #d7d9ea;
			--switch-bg-disabled: rgb(115, 115, 144);
			--switch-checked-bg: #0cf380;
			--switch-offset: calc(
				(var(--switch-height) - var(--circle-diameter)) / 2
			);
			--switch-transition: all 0.2s cubic-bezier(0.27, 0.2, 0.25, 1.51);

			/* circle */
			--circle-bg: #fff;
			--circle-shadow: 1px 1px 2px rgba(146, 146, 146, 0.45);
			--circle-checked-shadow: -1px 1px 2px rgba(163, 163, 163, 0.45);
			--circle-transition: var(--switch-transition);

			/* effect line*/
			--effect-width: calc(var(--circle-diameter) / 2);
			--effect-height: calc(var(--effect-width) / 2 - 1px);
			--effect-bg: var(--circle-bg);
			--effect-border-radius: 1px;
			--effect-transition: all 0.2s ease-in-out;

			.darkMode & {
				--switch-checked-bg: #519fde;
			}

			.hasIcons {
				--icon-transition: all 0.2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
				--icon-cross-color: var(--switch-bg);
				--icon-cross-size: 12px;
				--icon-checkmark-color: var(--switch-checked-bg);
				--icon-checkmark-size: 20px;
			}

			input {
				display: none;
			}
		}

		.switch {
			display: inline-block;
		}

		.switch svg {
			-webkit-transition: var(--icon-transition);
			-o-transition: var(--icon-transition);
			transition: var(--icon-transition);
			position: absolute;
			height: auto;
		}

		.switch .checkmark {
			width: var(--icon-checkmark-size);
			color: var(--icon-checkmark-color);
			-webkit-transform: scale(0);
			-ms-transform: scale(0);
			transform: scale(0);
		}

		.switch .cross {
			width: var(--icon-cross-size);
			color: var(--icon-cross-color);
		}

		.slider {
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			width: var(--switch-width);
			height: var(--switch-height);
			background: var(--switch-bg);
			border-radius: 999px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			position: relative;
			-webkit-transition: var(--switch-transition);
			-o-transition: var(--switch-transition);
			transition: var(--switch-transition);
			cursor: pointer;

			.darkMode & {
				background: $dm-b4;
			}
		}

		.circle {
			width: var(--circle-diameter);
			height: var(--circle-diameter);
			background: var(--circle-bg);
			border-radius: inherit;
			-webkit-box-shadow: var(--circle-shadow);
			box-shadow: var(--circle-shadow);
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-transition: var(--circle-transition);
			-o-transition: var(--circle-transition);
			transition: var(--circle-transition);
			z-index: 1;
			position: absolute;
			left: var(--switch-offset);
			top: calc(50% - calc(var(--circle-diameter) / 2));
		}

		.slider::before {
			content: "";
			position: absolute;
			width: var(--effect-width);
			height: var(--effect-height);
			left: calc(var(--switch-offset) + (var(--effect-width) / 2));
			background: var(--effect-bg);
			border-radius: var(--effect-border-radius);
			-webkit-transition: var(--effect-transition);
			-o-transition: var(--effect-transition);
			transition: var(--effect-transition);
		}

		/* actions */

		.switch input:checked + .slider {
			background: var(--switch-checked-bg);
		}

		.switch input:disabled + .slider {
			background: var(--switch-bg-disabled);
		}

		.switch input:checked + .slider .checkmark {
			-webkit-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);
		}

		.switch input:checked + .slider .cross {
			-webkit-transform: scale(0);
			-ms-transform: scale(0);
			transform: scale(0);
		}

		.switch input:checked + .slider::before {
			left: calc(
				100% - var(--effect-width) - (var(--effect-width) / 2) -
					var(--switch-offset)
			);
		}

		.switch input:checked + .slider .circle {
			left: calc(100% - var(--circle-diameter) - var(--switch-offset));
			-webkit-box-shadow: var(--circle-checked-shadow);
			box-shadow: var(--circle-checked-shadow);
		}
	}
}
