@use "../../utils/variables" as *;
@use "../../layout/extension/base/helpers" as *;

@mixin background {
	.background {
		position: absolute;
		inset: 0;
		background: $extBgColor1;
		z-index: -1;
		overflow: hidden;

		&.biggerForHatDisplay {
			height: calc(100% + 2.5rem);
		}

		.color1 {
			position: absolute;
			top: 1rem;
			right: 5rem;
			height: 20%;
			width: 20%;
			background: $extPique;
			border-radius: 100%;
			opacity: 70%;
			@include webkitBlur(50px);
			transform-origin: 4rem;
			animation: infinite-spinning 20s infinite;
		}

		.color2 {
			position: absolute;
			top: 0rem;
			right: 1rem;
			height: 10%;
			width: 20%;
			background: $bg-blue;
			border-radius: 100%;
			opacity: 60%;

			@include webkitBlur(30px);
			transform-origin: 4rem;
			animation: infinite-spinning 20s infinite;
		}

		.mask {
			position: absolute;
			top: 0;
			bottom: 0;
			width: 100%;
			margin-top: 15%;
			background: linear-gradient(
				180deg,
				rgba($bgRgbValues, 0) 0%,
				rgba($bgRgbValues, 0.7) 10%,
				rgba($bgRgbValues, 0.3) 100%
			);
		}

		#cloud-circle {
			width: 500px;
			height: 275px;
			border-radius: 0%;
			filter: url(#filter);
			box-shadow: 400px 400px 60px 0px $extBgColor1;
			position: absolute;
			top: -120px;
			left: -320px;
		}

		.bottomShades {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 25%;
			background: linear-gradient(
				180deg,
				rgba($bgRgbValues, 0) 0%,
				rgba($bottomShadesRgb, 0.5) 10%,
				rgba($bottomShadesRgb, 0.3) 100%
			);
		}
	}

	.label {
		position: relative;
		color: $grey;
		padding-top: 0.6rem !important;

		&::after {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			content: attr(data-label);
			font-size: 0.6rem;
			font-weight: 500;

			@include ellipsisHelperPopup;
		}

		& > *:first-child {
			margin-top: 0.3rem !important;

			&.emptyText {
				margin-top: 4rem !important;
			}
		}
	}

	.move-pass-section {
		margin-top: 0 !important;

		.whiteBackground {
			height: 2.5rem;
		}

		.leftTitle-container {
			gap: 0.3rem;
		}
	}
	section {
		border-radius: 0.3rem;
		box-sizing: border-box;
		padding: 0.4rem 0;

		* {
			box-sizing: border-box;
		}

		&:not(:first-child) {
			margin-top: 1rem;
		}

		&:not(.full) {
			@include backdropFilter(180%, 20px, 0.5);
			padding: 0.4rem;
		}
	}

	.flex {
		display: flex;
	}

	.warningHatFrame {
		padding: 0.3rem 0.6rem 1rem;
		max-width: 100%;
		box-sizing: border-box;

		.loaderBarsWrapper {
			margin-right: 0.5rem;
		}

		.warningHat {
			padding: 0.4rem 0.6rem;
			display: flex;
			align-items: center;
			background: $bg-padlockPop;
			backdrop-filter: blur(3px);
			border-radius: 5px;
			z-index: 2;

			> svg {
				min-width: 1.3rem;
				width: 1.3rem;
				margin-right: 0.5rem;
			}

			.warningHat-item {
				display: flex;
				flex-direction: column;

				b {
					font-size: 0.55rem;
					margin-bottom: 0.1rem;
					color: $black;
				}

				span {
					font-size: 0.6rem;
					color: $anthra;
				}
			}
		}
	}
}
