@use "../utils/variables" as *;
@use "../utils/mixins" as *;
@use "./tooltipHelper" as *;

@mixin repsFrame {
	.repsFrame {
		border: 1.5px solid $lGrey;
		border-radius: 3px;
		display: flex;
		flex-direction: column;
		overflow-x: hidden;

		@include tooltipHelper("locktransfer", true);

		.darkMode & {
			border: 1.5px solid $dm-b4;
		}

		input {
			margin: 0;
			margin-right: 0.7rem;

			@include mediaLarge {
				margin-right: 1vw;
			}
		}

		.baseRep {
			margin-bottom: 1.5rem;

			@include mediaLarge {
				margin-bottom: 2rem;
			}

			> .repHead {
				background-color: $lGrey;
				cursor: pointer;

				.darkMode & {
					background-color: $dm-b2;
				}

				svg {
					path {
						fill: $orange;
					}
				}
			}

			.repHead {
				display: flex;
				align-items: center;
				padding: 10px;
				margin-bottom: 0.5rem;

				@include mediaLarge {
					padding: 0.5rem 1vw;
				}

				h3 {
					margin: 0;
					font-size: 1rem;

					@include mediaLarge {
						font-size: clamp(0.8rem, 1.1vw, 1.1rem);
					}

					.darkMode & {
						color: $lGrey;
					}
				}

				svg {
					margin-right: 0.7rem;

					@include mediaLarge {
						margin-right: 0.5vw;
						height: auto;
						width: 0.9rem;
					}
				}
			}

			.reps {
				padding-left: 1rem;
			}

			> .reps > .rep {
				margin-bottom: 0;
			}
		}

		.rep {
			.repHead {
				margin: 0.1rem 0;
				display: flex;
				align-items: center;
				padding: 0.5rem;
				font-weight: 500;
				color: $anthra;
				transition: $t1;

				@include mediaLarge {
					padding: 0.3rem 0.5rem;
				}

				.darkMode & {
					color: $ciment;
				}

				svg {
					height: auto;
					width: 1.2rem;
					min-width: 1.2rem;
					margin-right: 0.5rem;

					@include mediaLarge {
						width: 1rem;
						min-width: 1rem;
					}

					path {
						transition: $t1;

						.darkMode & {
							fill: $dm-b5;
						}
					}
				}

				&:not(.actual):not(.disabled):hover {
					color: $black;

					.darkMode & {
						color: $lGrey;
					}

					svg path {
						fill: $actionBlue;

						.darkMode & {
							fill: $dm-lBlue;
						}
					}
				}

				&.selected {
					color: $black;

					.darkMode & {
						color: $lGrey;
					}

					svg path {
						fill: $actionBlue;

						.darkMode & {
							fill: $dm-lBlue;
						}
					}
				}

				&.disabled {
					color: $ciment;

					.darkMode & {
						color: $anthra;
					}

					svg path {
						fill: $ciment;

						.darkMode & {
							fill: $dm-b4;
						}
					}
				}
			}

			.subReps {
				padding-left: 1rem;
				display: flex;
				flex-direction: column;
				position: relative;

				@include mediaLarge {
					padding-left: 0.8rem;
				}

				&::after {
					content: "";
					position: absolute;
					left: 0.7rem;
					top: 0.7rem;
					height: calc(100% - 1.4rem);
					width: 2px;
					background-color: $ciment;
					border-radius: 5px;
					opacity: 0.5;
				}

				.subRep {
					padding: 10px;
					cursor: pointer;
					font-weight: 500;
					color: $anthra;
					transition: $t1;

					svg {
						margin-right: 13px;

						path {
							transition: $t1;
						}
					}

					&:hover,
					&.selected {
						color: $black;

						svg path {
							fill: $actionBlue;
						}
					}
				}
			}
		}
	}
}
