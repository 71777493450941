@use "../utils/variables" as *;

@mixin catTree($inSelectCategory: false) {
	.CatTree {
		height: 0;
		position: relative;
		flex: 1;
		overflow-x: visible;
		scrollbar-width: thin;
		scrollbar-color: rgba(215, 217, 234, 0.5) rgba(246, 247, 254, 0);
		box-sizing: border-box;
		min-width: 100%;

		.contentWrapper {
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: column;

			.loaderContainer {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		ul,
		li {
			list-style: none;
			padding: 0;
		}

		.categoriesListContainer {
			position: relative;
			padding: 0.7rem 0.2rem 0.7rem 1.1rem;
			box-sizing: border-box;
		}

		.categoriesListContainer,
		.searchListContainer,
		.contentWrapper > .loaderContainer {
			flex: 1;
			max-height: 100%;
			background-color: $white;
			box-shadow: 0 0 0.4rem $sh1;
			border-radius: 5px 1px 1px 5px;

			.darkMode & {
				background-color: $dm-b3;
				box-shadow: 0 0 0.4rem $sh1;
				border-radius: 5px 1px 1px 5px;
			}

			&:not(.blueBorder) {
				padding-right: 1.5px;
			}

			&.blueBorder {
				border-right: 3px $actionBlue solid;

				.darkMode & {
					border-right: 3px $lBlue solid;
				}
			}

			@if $inSelectCategory {
				> .categoriesList,
				> .searchList {
					margin: 0;
					padding: 0 0.4rem 0 0;
					overflow-y: scroll;
					scrollbar-width: thin;
					scrollbar-color: rgba(215, 217, 234, 0.5)
						rgba(246, 247, 254, 0);

					.SearchedCat {
						.darkMode & {
							background-color: $dm-b2;
						}

						h2 {
							.darkMode & {
								color: $white;
							}
						}

						&#selected {
							h2 em {
								.darkMode & {
									color: $white;
								}
							}
						}
					}
				}
			}
		}

		.categoriesList,
		.searchList {
			height: 100%;
			border-radius: 3px;
			padding-top: 0.8rem;
			margin-left: 0.75rem;

			.Category {
				margin-bottom: 0.8rem;

				&.root {
					.deployHide {
						svg {
							height: 1rem;
							width: 1rem;
						}

						svg * {
							fill: $anthra;

							.darkMode & {
								fill: $ciment;
							}
						}
					}
				}

				&:not(.root) {
					.categoriesList {
						border-left: 0.2rem solid $lGrey;

						.darkMode & {
							border-left: solid $dm-b2;
						}

						.Category {
							.darkMode & {
								margin-left: 0.4rem;
							}
						}
					}

					&:not(.main) {
						#showTree {
							z-index: 10;

							.gradient {
								height: 100%;
								width: 1.6rem;
								background: -moz-linear-gradient(
									90deg,
									rgba(1, 1, 1, 0) 0%,
									$white 80%
								); /* FF3.6-15 */
								background: -webkit-linear-gradient(
									90deg,
									rgba(1, 1, 1, 0) 0%,
									$white 80%
								); /* Chrome10-25,Safari5.1-6 */
								background: linear-gradient(
									90deg,
									rgba(1, 1, 1, 0) 0%,
									$white 80%
								); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
							}

							p,
							svg {
								background-color: $white;
							}
						}
					}

					.deployHide {
						svg {
							height: 0.8rem;
							width: 0.8rem;
						}
					}
				}

				.categoryTitle {
					position: relative;
					padding: 0 0.4rem;
					box-sizing: border-box;
					color: $black;
					border-radius: 3px;
					display: flex;
					justify-content: space-between;

					.selectableContainer {
						height: 100%;
						flex: 1;
						display: flex;
						align-items: center;
						padding-left: 0.2rem;
						overflow: hidden;

						svg {
							min-width: 2rem;

							&.folderPositionSvg {
								margin-left: -0.1rem;

								path[data-name~="secondTone"] {
									fill: $orange;
								}
							}

							&:not(.folderPositionSvg) {
								g[data-name~="secondTone"] {
									path,
									circle {
										.darkMode & {
											fill: $blue;
										}
									}
								}

								g[data-name~="people"] {
									path {
										.darkMode & {
											fill: $matSilver;
										}
									}
								}
							}
						}
					}

					&#selected {
						color: $white;
						background-color: $orange;

						.arrowDown {
							border-top: 0.4rem solid $white;
						}

						.arrowRight {
							border-left: 0.4rem solid $white;
						}

						.deployHide {
							svg * {
								fill: $white;
							}
						}

						> .selectableContainer:not(.is-shared-folder) svg {
							* {
								fill: $white;
							}
						}

						#showTree {
							color: $white;

							.gradient {
								background: -moz-linear-gradient(
									90deg,
									rgba(1, 1, 1, 0) 0%,
									$orange 80%
								); /* FF3.6-15 */
								background: -webkit-linear-gradient(
									90deg,
									rgba(1, 1, 1, 0) 0%,
									$orange 80%
								); /* Chrome10-25,Safari5.1-6 */
								background: linear-gradient(
									90deg,
									rgba(1, 1, 1, 0) 0%,
									$orange 80%
								); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
							}

							p,
							svg {
								background-color: $orange;
							}

							svg * {
								fill: $white;
							}
						}
					}

					&.unselectable {
						h2 {
							color: $anthra;
						}
					}

					.emptySpace {
						width: 1rem;
						height: 1rem;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					.deployHide {
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 0 0.3rem 0 0.1rem;

						.darkMode & {
							fill: $ciment;
						}

						&.inactive {
							svg {
								* {
									fill: $grey;
								}
							}
						}

						&.inactive:hover {
							cursor: default;
						}
					}

					button {
						background: none;
						border: none;

						.arrowDown {
							border-top: 0.4rem solid $anthra;
							border-left: 0.3rem solid transparent;
							border-right: 0.3rem solid transparent;
						}

						.arrowRight {
							border-left: 0.4rem solid $anthra;
							border-top: 0.3rem solid transparent;
							border-bottom: 0.3rem solid transparent;
						}
					}

					button:hover {
						cursor: pointer;
					}

					> svg * {
						fill: $blue;
					}

					#showTree {
						position: absolute;
						right: 0;
						top: 0;
						height: 100%;
						display: flex;
						justify-content: flex-end;
						align-items: center;
						color: $anthra;
						font-weight: 600;
						z-index: 5;
						animation: fadeIn linear 0.3s;
						-webkit-animation: fadeIn linear 0.3s;
						-moz-animation: fadeIn linear 0.3s;

						.gradient {
							height: 100%;
							width: 1.6rem;
							background: -moz-linear-gradient(
								90deg,
								rgba(1, 1, 1, 0) 0%,
								$bg-lGrey 80%
							); /* FF3.6-15 */
							background: -webkit-linear-gradient(
								90deg,
								rgba(1, 1, 1, 0) 0%,
								$bg-lGrey 80%
							); /* Chrome10-25,Safari5.1-6 */
							background: linear-gradient(
								90deg,
								rgba(1, 1, 1, 0) 0%,
								$bg-lGrey 80%
							); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

							.darkMode & {
								background: -moz-linear-gradient(
									90deg,
									rgba(1, 1, 1, 0) 0%,
									$dm-b2 80%
								); /* FF3.6-15 */
								background: -webkit-linear-gradient(
									90deg,
									rgba(1, 1, 1, 0) 0%,
									$dm-b2 80%
								); /* Chrome10-25,Safari5.1-6 */
								background: linear-gradient(
									90deg,
									rgba(1, 1, 1, 0) 0%,
									$dm-b2 80%
								); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
							}
						}

						p,
						svg {
							background-color: $bg-lGrey;
						}

						p {
							display: flex;

							.darkMode & {
								color: $ciment;
								background-color: $dm-b2;
							}
						}

						svg {
							height: 100%;
							width: 1rem;
							padding-right: 0.6rem;

							.darkMode & {
								background-color: $dm-b2;
							}

							* {
								.darkMode & {
									fill: $ciment;
								}
							}
						}
					}
				}

				&.main,
				&.root {
					> .categoryTitle {
						background-color: $bg-lGrey;

						.darkMode & {
							background-color: $dm-b2;
						}
					}
				}

				&.root {
					> .categoryTitle {
						.selectableContainer {
							svg {
								width: 0.8rem;
								height: 0.8rem;
							}

							> svg * {
								fill: $orange;
							}
						}
					}
				}
			}
		}

		.noResult {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			.noResult-container {
				display: flex;
				flex-direction: column;
				align-items: center;

				p {
					text-align: center;
				}
			}
		}

		.SearchedCat {
			padding: 0.8rem 0.4rem;
			box-sizing: border-box;
			color: $black;
			border-radius: 3px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: $bg-lGrey;
			margin: 0.4rem 0;

			h2 {
				font-size: 1.05rem;
				font-weight: 500;

				&.rootCat {
					font-weight: 600;
				}

				em {
					position: relative;
					color: $orange;
					font-style: normal;

					.highlight {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: $orange;
						opacity: 0.2;
					}
				}
			}

			&#selected {
				color: $white;
				background-color: $orange;

				h2 {
					color: $white;

					em {
						color: $white;
					}

					.highlight {
						background: none;
					}
				}

				.arrowDown {
					border-top: 0.4rem solid $white;
				}

				.arrowRight {
					border-left: 0.4rem solid $white;
				}

				> svg * {
					fill: $white;
				}

				#showTree {
					color: $white;
					background-color: $orange;

					svg * {
						fill: $white;
					}
				}
			}

			&.unselectable {
				h2 {
					color: $anthra;
				}

				svg {
					* {
						fill: $anthra;
					}
				}
			}

			button:hover {
				cursor: pointer;
			}

			> svg * {
				fill: $blue;
			}

			h2 {
				flex: 1;
				margin: 0 0 0 0.8rem;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			#showTree {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				color: $anthra;
				font-weight: 600;
				background: none;
				border: none;
				height: 100;

				p {
					margin: 0;
				}

				svg {
					height: 1rem;
					width: 1rem;
					margin-right: 0.6rem;
				}
			}

			.Category {
				&.root,
				&.main {
					background-color: $lGrey;
				}
			}
		}

		.Category {
			border-radius: 3px;

			.categoryTitle {
				height: 2rem;
				display: flex;
				align-items: center;

				.darkMode & {
					background-color: $dm-b2;
				}
			}

			.loaderContainer {
				width: 100%;
				display: flex;
				justify-content: center;
				margin-top: 0.8rem;

				.spinner {
					.rect {
						background-color: $ciment;

						.darkMode & {
							background-color: $lAnthra;
						}
					}
				}
			}
		}

		.searchedCat {
			display: flex;
			align-items: center;
		}

		.buttons {
			width: 100%;
		}
	}
}
