@use "../../../utils/variables" as *;
@use "./helpers" as *;

@mixin listPassBase {
	#ListPass {
		flex-direction: column;
		justify-content: space-between;
		display: flex;
		flex-direction: column;
		align-items: center;
		flex: 2;

		.loaderWrapper {
			flex: 2;
			display: flex;
			justify-content: center;

			> .spinner {
				margin: 0;
			}
		}

		@include BadFavIcon;
		@include SearchBarSection;

		/* ==================== PASSWORDS LIST RULES =================== */
		/* ========================== MAIN LIST ======================== */
		.listContainer {
			width: 100%;
			flex: 2;
			display: flex;
			flex-direction: column;
			align-items: center;
			box-sizing: border-box;

			&.empty {
				flex: 2;
				justify-content: center;
			}

			> span {
				font-weight: 500;
				color: $lCoal;
				font-size: 0.9rem;
				margin: 1rem 0;
			}

			.emptyText {
				width: 100%;
			}

			.noResults {
				display: flex;
				flex-direction: column;
				align-items: center;

				svg {
					min-width: 6rem;
					width: 6rem;
					height: auto;
					margin-bottom: 1rem;
				}
			}

			/* ==================== PASSWORDS LIST RULES =================== */
			/* ========================== CATEGORY ========================= */
			.category {
				margin-bottom: 1.4rem;
				width: 100%;

				h1 {
					background-color: $extBgColor3;
					border-radius: 0.5rem;
					width: fit-content;
					padding: 0.3rem 0.5rem 0.3rem 0.4rem;
					line-height: 0.75rem;
					word-break: break-word;
					font-size: 0.55rem;
					display: grid;
					grid-template-columns: repeat(2, auto);
					column-gap: 0.28rem;
					align-items: center;

					svg {
						min-width: 0.7rem;
						width: 0.7rem;
						height: auto;
						margin-bottom: 0.05rem;
						flex-shrink: 0;

						path {
							fill: $extPique;
						}
					}
				}
			}

			ul {
				h1 {
					span {
						height: 0.6rem;
						margin-right: 0.4rem;

						img {
							height: 100%;
						}
					}
				}

				/* ==================== PASSWORDS LIST RULES =================== */
				/* ======================= CREDENTIAL DIV ====================== */
				@include Credential;
			}

			.allSites {
				color: $grey;
				font-size: 0.6rem;
				margin-bottom: 1.5rem;

				button {
					color: $lBlue;
					cursor: pointer;
					background: unset;
					padding: unset;
					margin: unset;
					border: none;
					font-size: inherit;
					font-weight: inherit;

					&:focus {
						outline: none;
						stroke: none;
						text-decoration: underline;
					}
				}
			}
		}
	}
}
